/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableColumnTypes } from '../enums/table';

export function deepClone(value: any): any {
  return JSON.parse(JSON.stringify(value));
}

export function getNumberWithOrdinal(num: number): string {
  if (!num) return '';
  const suffixes: string[] = ['th', 'st', 'nd', 'rd'];
  const remaninder: number = num % 100;
  return num + (suffixes[(remaninder - 20) % 10] || suffixes[remaninder] || suffixes[0]);
}

// eslint-disable-next-line
export function valueExists(value: any): boolean {
  return value !== null && value !== undefined;
}

export function isEqual(value1: any, value2: any): boolean {
  return JSON.stringify(value1) === JSON.stringify(value2);
}

export function parseJwt (token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function replaceNonASCIICharacters(inputString: string) {
  // Replace en dash, em dash, and curly quotes
  inputString = inputString.replace(/–/g, "-").replace(/—/g, "--").replace(/“/g, '"').replace(/”/g, '"').replace(/‘/g, "'").replace(/’/g, "'");

  // Replace accented characters
  inputString = inputString.replace(/é/g, "e").replace(/ü/g, "u").replace(/ñ/g, "n");

  // Replace special symbols
  inputString = inputString.replace(/©/g, "(C)").replace(/®/g, "(R)").replace(/™/g, "(TM)");

  return inputString;
}

export const defaultColumns: any[] = ['select', 'order_number', 'address_1', 'status', 'reviewer', 'client', 'actions'];
export const defaultColumnsDashboard: any[] = ['order_number', 'address_1', 'status', 'reviewer', 'client'];

export const clientListingColumns: any[] = ['company_name', 'phone_contact', 'address_1', 'city', 'state', 'zip', 'is_active', 'actions'];

export const userListingColumns = [
  'select',
  'first_name',
  'email',
  'role',
  'created_on',
  'status',
  'actions',
]

export const ordersListingColumns: any[] = [
  {
    title: 'Order #',
    name: 'order_number',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'Address 1',
    name: 'address_1',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'Address 2',
    name: 'address_2',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'City',
    name: 'city',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'State',
    name: 'state',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'Zip',
    name: 'zip',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'Order Status',
    name: 'status',
    selected: false,
    type: TableColumnTypes.CHIP
  },
  {
    title: 'Order Sub Status',
    name: 'sub_status',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'Tier',
    name: 'tier',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'Is Manager',
    name: 'is_manager',
    selected: false,
    type: TableColumnTypes.ICON
  },
  {
    title: 'Reviewer Name',
    name: 'reviewer',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'Client Name',
    name: 'client',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'Hours',
    name: 'hours',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'Upload Date & Time',
    name: 'created_on',
    selected: false,
    type: TableColumnTypes.DATE_TIME
  },
  {
    title: 'Due Date',
    name: 'due_date',
    selected: false,
    type: TableColumnTypes.DATE
  },
  {
    title: 'Version Number',
    name: 'version_number',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  {
    title: 'Conversion Status',
    name: 'conversion_status',
    selected: false,
    type: TableColumnTypes.CIRCLE
  },
  {
    title: 'Product Type',
    name: 'product_type',
    selected: false,
    type: TableColumnTypes.TEXT
  },
  // {
  //   title: 'Days In Current Status',
  //   name: 'days_in_review',
  //   selected: false,
  //   type: TableColumnTypes.TEXT
  // },
];

export const states = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];


export const citiesByState = {
  NY: [
    'Holtsville',
    'Fishers Island',
    'New York',
    'Staten Island',
    'Bronx',
    'Amawalk',
    'Ardsley',
    'Ardsley On Hudson',
    'Armonk',
    'Baldwin Place',
    'Bedford',
    'Bedford Hills',
    'Brewster',
    'Briarcliff Manor',
    'Buchanan',
    'Carmel',
    'Chappaqua',
    'Cold Spring',
    'Crompond',
    'Cross River',
    'Croton Falls',
    'Croton On Hudson',
    'Dobbs Ferry',
    'Elmsford',
    'Garrison',
    'Goldens Bridge',
    'Granite Springs',
    'Harrison',
    'Hartsdale',
    'Hawthorne',
    'Irvington',
    'Jefferson Valley',
    'Katonah',
    'Lake Peekskill',
    'Larchmont',
    'Lincolndale',
    'Mahopac',
    'Mahopac Falls',
    'Mamaroneck',
    'Maryknoll',
    'Millwood',
    'Mohegan Lake',
    'Montrose',
    'Mount Kisco',
    'Mount Vernon',
    'North Salem',
    'Ossining',
    'Peekskill',
    'Cortlandt Manor',
    'Pleasantville',
    'Port Chester',
    'Pound Ridge',
    'Purchase',
    'Purdys',
    'Putnam Valley',
    'Rye',
    'Scarsdale',
    'Shenorock',
    'Shrub Oak',
    'Somers',
    'South Salem',
    'Tarrytown',
    'Thornwood',
    'Valhalla',
    'Verplanck',
    'Waccabuc',
    'Yorktown Heights',
    'White Plains',
    'West Harrison',
    'Yonkers',
    'Hastings On Hudson',
    'Tuckahoe',
    'Bronxville',
    'Eastchester',
    'New Rochelle',
    'Pelham',
    'Suffern',
    'Arden',
    'Bear Mountain',
    'Bellvale',
    'Blauvelt',
    'Blooming Grove',
    'Bullville',
    'Campbell Hall',
    'Central Valley',
    'Chester',
    'Circleville',
    'Congers',
    'Florida',
    'Fort Montgomery',
    'Garnerville',
    'Goshen',
    'Greenwood Lake',
    'Harriman',
    'Haverstraw',
    'Highland Falls',
    'Highland Mills',
    'Hillburn',
    'Howells',
    'Johnson',
    'Middletown',
    'Monroe',
    'Monsey',
    'Mountainville',
    'Nanuet',
    'New City',
    'New Hampton',
    'New Milford',
    'Nyack',
    'Orangeburg',
    'Otisville',
    'Palisades',
    'Pearl River',
    'Piermont',
    'Pine Island',
    'Pomona',
    'Slate Hill',
    'Sloatsburg',
    'Southfields',
    'Sparkill',
    'Spring Valley',
    'Sterling Forest',
    'Stony Point',
    'Sugar Loaf',
    'Tallman',
    'Tappan',
    'Thiells',
    'Thompson Ridge',
    'Tomkins Cove',
    'Tuxedo Park',
    'Unionville',
    'Valley Cottage',
    'Warwick',
    'Washingtonville',
    'West Haverstraw',
    'West Nyack',
    'West Point',
    'Westtown',
    'Floral Park',
    'Elmont',
    'Glen Oaks',
    'Franklin Square',
    'Great Neck',
    'Manhasset',
    'New Hyde Park',
    'Port Washington',
    'Inwood',
    'Long Island City',
    'Astoria',
    'Sunnyside',
    'Brooklyn',
    'Flushing',
    'College Point',
    'Whitestone',
    'Bayside',
    'Little Neck',
    'Oakland Gardens',
    'Fresh Meadows',
    'Corona',
    'East Elmhurst',
    'Jackson Heights',
    'Elmhurst',
    'Rego Park',
    'Forest Hills',
    'Woodside',
    'Maspeth',
    'Middle Village',
    'Ridgewood',
    'Jamaica',
    'Cambria Heights',
    'Saint Albans',
    'Springfield Gardens',
    'Howard Beach',
    'Kew Gardens',
    'Ozone Park',
    'Richmond Hill',
    'South Richmond Hill',
    'South Ozone Park',
    'Woodhaven',
    'Rosedale',
    'Hollis',
    'Bellerose',
    'Queens Village',
    'Mineola',
    'Albertson',
    'Atlantic Beach',
    'Baldwin',
    'Carle Place',
    'Cedarhurst',
    'East Rockaway',
    'Freeport',
    'Garden City',
    'Glen Cove',
    'Glen Head',
    'Glenwood Landing',
    'Greenvale',
    'Hempstead',
    'West Hempstead',
    'Uniondale',
    'East Meadow',
    'Hewlett',
    'Island Park',
    'Lawrence',
    'Locust Valley',
    'Long Beach',
    'Lynbrook',
    'Malverne',
    'Merrick',
    'Old Westbury',
    'Point Lookout',
    'Rockville Centre',
    'Oceanside',
    'Roosevelt',
    'Roslyn',
    'Roslyn Heights',
    'Sea Cliff',
    'Valley Stream',
    'Westbury',
    'Rockville Center',
    'Williston Park',
    'Woodmere',
    'Far Rockaway',
    'Arverne',
    'Rockaway Park',
    'Breezy Point',
    'Amityville',
    'Babylon',
    'North Babylon',
    'West Babylon',
    'Bayport',
    'Bay Shore',
    'Bayville',
    'Bellmore',
    'Bellport',
    'Bethpage',
    'Blue Point',
    'Bohemia',
    'Brentwood',
    'Brightwaters',
    'Brookhaven',
    'Centereach',
    'Centerport',
    'Central Islip',
    'Cold Spring Harbor',
    'Commack',
    'Copiague',
    'Coram',
    'Deer Park',
    'East Islip',
    'East Northport',
    'East Norwich',
    'East Setauket',
    'Farmingdale',
    'Farmingville',
    'Great River',
    'Greenlawn',
    'Holbrook',
    'Huntington',
    'Huntington Station',
    'Melville',
    'Islandia',
    'Islip',
    'Islip Terrace',
    'Jericho',
    'Kings Park',
    'Lake Grove',
    'Levittown',
    'Lindenhurst',
    'Massapequa',
    'Massapequa Park',
    'Medford',
    'Miller Place',
    'Mill Neck',
    'Mount Sinai',
    'Nesconset',
    'Northport',
    'Oakdale',
    'Ocean Beach',
    'Oyster Bay',
    'Patchogue',
    'Port Jefferson Station',
    'Port Jefferson',
    'Rocky Point',
    'Ronkonkoma',
    'Saint James',
    'Sayville',
    'Seaford',
    'Selden',
    'Shoreham',
    'Smithtown',
    'Hauppauge',
    'Sound Beach',
    'Stony Brook',
    'Syosset',
    'Wading River',
    'Wantagh',
    'West Islip',
    'West Sayville',
    'Woodbury',
    'Wyandanch',
    'Hicksville',
    'Plainview',
    'Old Bethpage',
    'Riverhead',
    'Amagansett',
    'Aquebogue',
    'Bridgehampton',
    'Calverton',
    'Center Moriches',
    'Cutchogue',
    'East Hampton',
    'East Marion',
    'East Moriches',
    'Eastport',
    'East Quogue',
    'Greenport',
    'Hampton Bays',
    'Jamesport',
    'Laurel',
    'Manorville',
    'Mastic',
    'Mastic Beach',
    'Mattituck',
    'Middle Island',
    'Montauk',
    'Moriches',
    'New Suffolk',
    'Orient',
    'Peconic',
    'Quogue',
    'Remsenburg',
    'Ridge',
    'Sagaponack',
    'Sag Harbor',
    'Shelter Island',
    'Shelter Island Heights',
    'Shirley',
    'Southampton',
    'South Jamesport',
    'Southold',
    'Speonk',
    'Upton',
    'Wainscott',
    'Water Mill',
    'Westhampton',
    'Westhampton Beach',
    'Yaphank',
    'Alcove',
    'Alplaus',
    'Altamont',
    'Amsterdam',
    'Athens',
    'Auriesville',
    'Austerlitz',
    'Averill Park',
    'Ballston Lake',
    'Ballston Spa',
    'Berlin',
    'Berne',
    'Brainard',
    'Broadalbin',
    'Burnt Hills',
    'Buskirk',
    'Canaan',
    'Carlisle',
    'Caroga Lake',
    'Castleton On Hudson',
    'Central Bridge',
    'Charlotteville',
    'Chatham',
    'Cherry Plain',
    'Clarksville',
    'Climax',
    'Cobleskill',
    'Coeymans',
    'Coeymans Hollow',
    'Cohoes',
    'Columbiaville',
    'Coxsackie',
    'Cropseyville',
    'Delanson',
    'Delmar',
    'Dormansville',
    'Duanesburg',
    'Eagle Bridge',
    'Earlton',
    'East Berne',
    'East Chatham',
    'East Greenbush',
    'East Nassau',
    'East Schodack',
    'East Worcester',
    'Clifton Park',
    'Esperance',
    'Feura Bush',
    'Fonda',
    'Fort Hunter',
    'Fort Johnson',
    'Fultonham',
    'Fultonville',
    'Gallupville',
    'Galway',
    'Ghent',
    'Gilboa',
    'Glenmont',
    'Gloversville',
    'Grafton',
    'Greenville',
    'Guilderland',
    'Guilderland Center',
    'Hagaman',
    'Hannacroix',
    'Hoosick',
    'Hoosick Falls',
    'Howes Cave',
    'Jefferson',
    'Johnsonville',
    'Johnstown',
    'Kinderhook',
    'Knox',
    'Lake Pleasant',
    'Latham',
    'Malden Bridge',
    'Maryland',
    'Mayfield',
    'Mechanicville',
    'Medusa',
    'Melrose',
    'Middleburgh',
    'Nassau',
    'New Baltimore',
    'New Lebanon',
    'Newtonville',
    'Niverville',
    'North Blenheim',
    'North Chatham',
    'North Hoosick',
    'Northville',
    'Old Chatham',
    'Pattersonville',
    'Petersburg',
    'Piseco',
    'Poestenkill',
    'Quaker Street',
    'Ravena',
    'Rensselaer',
    'Rensselaerville',
    'Rexford',
    'Richmondville',
    'Rotterdam Junction',
    'Round Lake',
    'Sand Lake',
    'Schaghticoke',
    'Schenevus',
    'Schodack Landing',
    'Schoharie',
    'Selkirk',
    'Slingerlands',
    'Sloansville',
    'South Bethlehem',
    'Speculator',
    'Spencertown',
    'Sprakers',
    'Stamford',
    'Stephentown',
    'Stillwater',
    'Stottville',
    'Stuyvesant',
    'Stuyvesant Falls',
    'Summit',
    'Surprise',
    'Tribes Hill',
    'Troy',
    'Valatie',
    'Valley Falls',
    'Voorheesville',
    'Warnerville',
    'Waterford',
    'Watervliet',
    'Wells',
    'West Coxsackie',
    'Westerlo',
    'West Fulton',
    'West Lebanon',
    'West Sand Lake',
    'Worcester',
    'Wynantskill',
    'Albany',
    'Schenectady',
    'Kingston',
    'Accord',
    'Acra',
    'Arkville',
    'Ashland',
    'Bearsville',
    'Big Indian',
    'Bloomington',
    'Boiceville',
    'Cairo',
    'Catskill',
    'Chichester',
    'Connelly',
    'Cornwallville',
    'Cottekill',
    'Cragsmoor',
    'Denver',
    'Durham',
    'East Durham',
    'East Jewett',
    'Elka Park',
    'Ellenville',
    'Esopus',
    'Fleischmanns',
    'Freehold',
    'Glasco',
    'Glenford',
    'Grand Gorge',
    'Greenfield Park',
    'Haines Falls',
    'Halcottsville',
    'Hensonville',
    'High Falls',
    'Highmount',
    'Hunter',
    'Hurley',
    'Jewett',
    'Kerhonkson',
    'Lake Hill',
    'Lake Katrine',
    'Lanesville',
    'Leeds',
    'Lexington',
    'Malden On Hudson',
    'Maplecrest',
    'Margaretville',
    'Mount Marion',
    'Mount Tremper',
    'Napanoch',
    'New Kingston',
    'Oak Hill',
    'Olivebridge',
    'Palenville',
    'Phoenicia',
    'Pine Hill',
    'Port Ewen',
    'Prattsville',
    'Preston Hollow',
    'Purling',
    'Rifton',
    'Rosendale',
    'Round Top',
    'Roxbury',
    'Ruby',
    'Saugerties',
    'Shandaken',
    'Shokan',
    'South Cairo',
    'Spring Glen',
    'Stone Ridge',
    'Tannersville',
    'Tillson',
    'Ulster Park',
    'Wawarsing',
    'West Camp',
    'West Hurley',
    'West Kill',
    'West Park',
    'West Shokan',
    'Willow',
    'Windham',
    'Woodstock',
    'Amenia',
    'Ancram',
    'Ancramdale',
    'Annandale On Hudson',
    'Bangall',
    'Barrytown',
    'Beacon',
    'Billings',
    'Castle Point',
    'Chelsea',
    'Claverack',
    'Clinton Corners',
    'Clintondale',
    'Copake',
    'Copake Falls',
    'Cornwall',
    'Cornwall On Hudson',
    'Craryville',
    'Dover Plains',
    'Elizaville',
    'Fishkill',
    'Gardiner',
    'Germantown',
    'Glenham',
    'Highland',
    'Hillsdale',
    'Hollowville',
    'Holmes',
    'Hopewell Junction',
    'Hudson',
    'Hughsonville',
    'Hyde Park',
    'Lagrangeville',
    'Livingston',
    'Marlboro',
    'Maybrook',
    'Mellenville',
    'Millbrook',
    'Millerton',
    'Milton',
    'Modena',
    'Montgomery',
    'Newburgh',
    'New Windsor',
    'New Paltz',
    'Patterson',
    'Pawling',
    'Philmont',
    'Pine Bush',
    'Pine Plains',
    'Plattekill',
    'Pleasant Valley',
    'Poughquag',
    'Red Hook',
    'Rhinebeck',
    'Rhinecliff',
    'Rock Tavern',
    'Salisbury Mills',
    'Salt Point',
    'Staatsburg',
    'Stanfordville',
    'Stormville',
    'Tivoli',
    'Vails Gate',
    'Verbank',
    'Walden',
    'Walker Valley',
    'Wallkill',
    'Wappingers Falls',
    'Wassaic',
    'West Copake',
    'Wingdale',
    'Poughkeepsie',
    'Monticello',
    'Barryville',
    'Bethel',
    'Bloomingburg',
    'Burlingham',
    'Callicoon',
    'Callicoon Center',
    'Claryville',
    'Cochecton',
    'Cuddebackville',
    'Eldred',
    'Fallsburg',
    'Ferndale',
    'Fremont Center',
    'Glen Spey',
    'Glen Wild',
    'Grahamsville',
    'Hankins',
    'Harris',
    'Highland Lake',
    'Hortonville',
    'Huguenot',
    'Hurleyville',
    'Jeffersonville',
    'Kauneonga Lake',
    'Kenoza Lake',
    'Kiamesha Lake',
    'Lake Huntington',
    'Liberty',
    'Livingston Manor',
    'Loch Sheldrake',
    'Long Eddy',
    'Mongaup Valley',
    'Mountain Dale',
    'Narrowsburg',
    'Neversink',
    'North Branch',
    'Obernburg',
    'Parksville',
    'Phillipsport',
    'Pond Eddy',
    'Port Jervis',
    'Rock Hill',
    'Roscoe',
    'Forestburgh',
    'Smallwood',
    'South Fallsburg',
    'Sparrow Bush',
    'Summitville',
    'Swan Lake',
    'Thompsonville',
    'Westbrookville',
    'White Lake',
    'White Sulphur Springs',
    'Woodbourne',
    'Woodridge',
    'Wurtsboro',
    'Youngsville',
    'Yulan',
    'Glens Falls',
    'South Glens Falls',
    'Queensbury',
    'Adirondack',
    'Argyle',
    'Athol',
    'Bakers Mills',
    'Blue Mountain Lake',
    'Bolton Landing',
    'Brant Lake',
    'Cambridge',
    'Chestertown',
    'Clemons',
    'Cleverdale',
    'Comstock',
    'Corinth',
    'Cossayuna',
    'Diamond Point',
    'Fort Ann',
    'Fort Edward',
    'Gansevoort',
    'Granville',
    'Greenfield Center',
    'Greenwich',
    'Hadley',
    'Hague',
    'Hampton',
    'Hartford',
    'Hudson Falls',
    'Huletts Landing',
    'Indian Lake',
    'Johnsburg',
    'Kattskill Bay',
    'Lake George',
    'Lake Luzerne',
    'Long Lake',
    'Middle Falls',
    'Middle Granville',
    'Middle Grove',
    'Minerva',
    'Newcomb',
    'North Creek',
    'North Granville',
    'North Hudson',
    'North River',
    'Olmstedville',
    'Paradox',
    'Porter Corners',
    'Pottersville',
    'Putnam Station',
    'Riparius',
    'Rock City Falls',
    'Sabael',
    'Salem',
    'Saratoga Springs',
    'Schroon Lake',
    'Schuylerville',
    'Severance',
    'Shushan',
    'Silver Bay',
    'Stony Creek',
    'Ticonderoga',
    'Victory Mills',
    'Warrensburg',
    'Wevertown',
    'Whitehall',
    'Plattsburgh',
    'Altona',
    'Keeseville',
    'Au Sable Forks',
    'Bloomingdale',
    'Bombay',
    'Brainardsville',
    'Brushton',
    'Burke',
    'Cadyville',
    'Champlain',
    'Chateaugay',
    'Chazy',
    'Childwold',
    'Churubusco',
    'Constable',
    'Cranberry Lake',
    'Crown Point',
    'Dannemora',
    'Dickinson Center',
    'Elizabethtown',
    'Ellenburg',
    'Ellenburg Center',
    'Ellenburg Depot',
    'Essex',
    'Fort Covington',
    'Gabriels',
    'Jay',
    'Keene',
    'Keene Valley',
    'Lake Clear',
    'Lake Placid',
    'Lawrenceville',
    'Lewis',
    'Lyon Mountain',
    'Malone',
    'Mineville',
    'Moira',
    'Mooers',
    'Mooers Forks',
    'Moriah',
    'Moriah Center',
    'Morrisonville',
    'New Russia',
    'Nicholville',
    'North Bangor',
    'North Lawrence',
    'Owls Head',
    'Paul Smiths',
    'Peru',
    'Piercefield',
    'Port Henry',
    'Port Kent',
    'Rainbow Lake',
    'Ray Brook',
    'Redford',
    'Rouses Point',
    'Saint Regis Falls',
    'Saranac',
    'Saranac Lake',
    'Schuyler Falls',
    'Tupper Lake',
    'Upper Jay',
    'Vermontville',
    'West Chazy',
    'Westport',
    'Whippleville',
    'Willsboro',
    'Wilmington',
    'Witherbee',
    'Apulia Station',
    'Auburn',
    'Aurora',
    'Baldwinsville',
    'Bernhards Bay',
    'Brewerton',
    'Bridgeport',
    'Camillus',
    'Canastota',
    'Cato',
    'Cayuga',
    'Cazenovia',
    'Central Square',
    'Chittenango',
    'Cicero',
    'Cincinnatus',
    'Clay',
    'Cleveland',
    'Clockville',
    'Constantia',
    'Cortland',
    'Delphi Falls',
    'De Ruyter',
    'Dryden',
    'Durhamville',
    'East Homer',
    'East Syracuse',
    'Elbridge',
    'Erieville',
    'Etna',
    'Fabius',
    'Fair Haven',
    'Fayette',
    'Fayetteville',
    'Freeville',
    'Fulton',
    'Genoa',
    'Georgetown',
    'Groton',
    'Hannibal',
    'Hastings',
    'Homer',
    'Jamesville',
    'Jordan',
    'King Ferry',
    'Kirkville',
    'Lacona',
    'La Fayette',
    'Little York',
    'Liverpool',
    'Locke',
    'Lycoming',
    'Mc Graw',
    'Mc Lean',
    'Mallory',
    'Manlius',
    'Maple View',
    'Marcellus',
    'Marietta',
    'Martville',
    'Memphis',
    'Meridian',
    'Mexico',
    'Minetto',
    'Minoa',
    'Montezuma',
    'Moravia',
    'Mottville',
    'Nedrow',
    'New Haven',
    'New Woodstock',
    'North Bay',
    'North Pitcher',
    'Oswego',
    'Parish',
    'Pennellville',
    'Peterboro',
    'Phoenix',
    'Pitcher',
    'Plainville',
    'Pompey',
    'Poplar Ridge',
    'Port Byron',
    'Preble',
    'Pulaski',
    'Red Creek',
    'Richland',
    'Sandy Creek',
    'Savannah',
    'Scipio Center',
    'Seneca Falls',
    'Skaneateles',
    'Skaneateles Falls',
    'South Butler',
    'South Otselic',
    'Sterling',
    'Sylvan Beach',
    'Truxton',
    'Tully',
    'Union Springs',
    'Verona Beach',
    'Wampsville',
    'Warners',
    'Waterloo',
    'Weedsport',
    'West Monroe',
    'Syracuse',
    'Alder Creek',
    'Altmar',
    'Ava',
    'Barneveld',
    'Beaver Falls',
    'Blossvale',
    'Boonville',
    'Bouckville',
    'Brantingham',
    'Bridgewater',
    'Brookfield',
    'Burlington Flats',
    'Camden',
    'Canajoharie',
    'Cassville',
    'Chadwicks',
    'Cherry Valley',
    'Clark Mills',
    'Clayville',
    'Clinton',
    'Cold Brook',
    'Constableville',
    'Cooperstown',
    'Croghan',
    'Deansboro',
    'Dolgeville',
    'Eagle Bay',
    'Earlville',
    'East Springfield',
    'Eaton',
    'Edmeston',
    'Fly Creek',
    'Forestport',
    'Fort Plain',
    'Frankfort',
    'Franklin Springs',
    'Garrattsville',
    'Glenfield',
    'Greig',
    'Hamilton',
    'Hartwick',
    'Herkimer',
    'Hinckley',
    'Hoffmeister',
    'Holland Patent',
    'Hubbardsville',
    'Ilion',
    'Inlet',
    'Jordanville',
    'Knoxboro',
    'Lee Center',
    'Leonardsville',
    'Little Falls',
    'Lowville',
    'Lyons Falls',
    'Mc Connellsville',
    'Madison',
    'Marcy',
    'Martinsburg',
    'Middleville',
    'Mohawk',
    'Morrisville',
    'Munnsville',
    'Nelliston',
    'New Berlin',
    'New Hartford',
    'New Lisbon',
    'Newport',
    'New York Mills',
    'North Brookfield',
    'Old Forge',
    'Oneida',
    'Oriskany',
    'Oriskany Falls',
    'Orwell',
    'Palatine Bridge',
    'Poland',
    'Port Leyden',
    'Prospect',
    'Raquette Lake',
    'Redfield',
    'Remsen',
    'Richfield Springs',
    'Rome',
    'Roseboom',
    'Saint Johnsville',
    'Salisbury Center',
    'Sangerfield',
    'Sauquoit',
    'Schuyler Lake',
    'Sharon Springs',
    'Sherburne',
    'Sherrill',
    'Smyrna',
    'Solsville',
    'Springfield Center',
    'Stittville',
    'Stratford',
    'Taberg',
    'Thendara',
    'Turin',
    'Van Hornesville',
    'Vernon',
    'Vernon Center',
    'Verona',
    'Washington Mills',
    'Waterville',
    'West Burlington',
    'Westdale',
    'West Eaton',
    'West Edmeston',
    'Westernville',
    'Westford',
    'West Leyden',
    'Westmoreland',
    'West Winfield',
    'Whitesboro',
    'Williamstown',
    'Woodgate',
    'Yorkville',
    'Utica',
    'Watertown',
    'Fort Drum',
    'Adams',
    'Adams Center',
    'Alexandria Bay',
    'Antwerp',
    'Belleville',
    'Black River',
    'Brasher Falls',
    'Brier Hill',
    'Brownville',
    'Calcium',
    'Canton',
    'Cape Vincent',
    'Carthage',
    'Castorland',
    'Chase Mills',
    'Chaumont',
    'Chippewa Bay',
    'Clayton',
    'Colton',
    'Copenhagen',
    'Deer River',
    'Deferiet',
    'De Kalb Junction',
    'Denmark',
    'Depauville',
    'De Peyster',
    'Dexter',
    'Edwards',
    'Ellisburg',
    'Evans Mills',
    'Felts Mills',
    'Fine',
    'Wellesley Island',
    'Fishers Landing',
    'Gouverneur',
    'Great Bend',
    'Hailesboro',
    'Hammond',
    'Hannawa Falls',
    'Harrisville',
    'Helena',
    'Henderson',
    'Henderson Harbor',
    'Hermon',
    'Heuvelton',
    'Hogansburg',
    'La Fargeville',
    'Limerick',
    'Lisbon',
    'Lorraine',
    'Madrid',
    'Mannsville',
    'Massena',
    'Morristown',
    'Natural Bridge',
    'Newton Falls',
    'Norfolk',
    'Norwood',
    'Ogdensburg',
    'Oswegatchie',
    'Parishville',
    'Philadelphia',
    'Pierrepont Manor',
    'Plessis',
    'Potsdam',
    'Pyrites',
    'Raymondville',
    'Redwood',
    'Rensselaer Falls',
    'Richville',
    'Rodman',
    'Rooseveltown',
    'Russell',
    'Sackets Harbor',
    'South Colton',
    'Star Lake',
    'Theresa',
    'Thousand Island Park',
    'Three Mile Bay',
    'Waddington',
    'Wanakena',
    'West Stockholm',
    'Winthrop',
    'Afton',
    'Andes',
    'Apalachin',
    'Bainbridge',
    'Barton',
    'Berkshire',
    'Bible School Park',
    'Blodgett Mills',
    'Bloomville',
    'Bovina Center',
    'Candor',
    'Castle Creek',
    'Chenango Bridge',
    'Chenango Forks',
    'Colliersville',
    'Conklin',
    'Corbettsville',
    'Davenport',
    'Davenport Center',
    'Delancey',
    'Delhi',
    'Deposit',
    'Downsville',
    'East Branch',
    'East Meredith',
    'East Pharsalia',
    'Endicott',
    'Endwell',
    'Fishs Eddy',
    'Franklin',
    'Gilbertsville',
    'Glen Aubrey',
    'Greene',
    'Guilford',
    'Hamden',
    'Hancock',
    'Harford',
    'Harpersfield',
    'Harpursville',
    'Hobart',
    'Johnson City',
    'Killawog',
    'Kirkwood',
    'Laurens',
    'Lisle',
    'Mc Donough',
    'Maine',
    'Marathon',
    'Masonville',
    'Meridale',
    'Milford',
    'Morris',
    'Mount Upton',
    'Mount Vision',
    'Newark Valley',
    'Nichols',
    'Nineveh',
    'North Norwich',
    'Norwich',
    'Oneonta',
    'Otego',
    'Ouaquaga',
    'Owego',
    'Oxford',
    'Plymouth',
    'Port Crane',
    'Portlandville',
    'Richford',
    'Shinhopple',
    'Sidney',
    'Sidney Center',
    'Smithboro',
    'Smithville Flats',
    'South Kortright',
    'South New Berlin',
    'South Plymouth',
    'Tioga Center',
    'Treadwell',
    'Trout Creek',
    'Tunnel',
    'Unadilla',
    'Vestal',
    'Walton',
    'Wells Bridge',
    'West Davenport',
    'West Oneonta',
    'Whitney Point',
    'Willet',
    'Willseyville',
    'Windsor',
    'Binghamton',
    'Akron',
    'Alden',
    'Alexander',
    'Angola',
    'Appleton',
    'Arcade',
    'Athol Springs',
    'Attica',
    'Barker',
    'Basom',
    'Batavia',
    'Bliss',
    'Boston',
    'Bowmansville',
    'Brant',
    'Burt',
    'Centerville',
    'Chaffee',
    'Clarence',
    'Clarence Center',
    'Colden',
    'Collins',
    'Collins Center',
    'Corfu',
    'Cowlesville',
    'Crittenden',
    'Dale',
    'Darien Center',
    'Dayton',
    'Delevan',
    'Depew',
    'Derby',
    'Dunkirk',
    'East Amherst',
    'East Aurora',
    'East Bethany',
    'East Concord',
    'East Pembroke',
    'Eden',
    'Elba',
    'Elma',
    'Farmersville Station',
    'Farnham',
    'Forestville',
    'Fredonia',
    'Freedom',
    'Gainesville',
    'Gasport',
    'Getzville',
    'Glenwood',
    'Gowanda',
    'Grand Island',
    'Hamburg',
    'Holland',
    'Irving',
    'Java Center',
    'Java Village',
    'Lake View',
    'Lancaster',
    'Lawtons',
    'Lewiston',
    'Lockport',
    'Lyndonville',
    'Machias',
    'Marilla',
    'Medina',
    'Middleport',
    'Model City',
    'Newfane',
    'Niagara University',
    'North Boston',
    'North Collins',
    'North Evans',
    'North Java',
    'North Tonawanda',
    'Oakfield',
    'Olcott',
    'Orchard Park',
    'Perrysburg',
    'Pike',
    'Ransomville',
    'Sanborn',
    'Sandusky',
    'Sardinia',
    'Sheridan',
    'Silver Creek',
    'South Dayton',
    'South Wales',
    'Spring Brook',
    'Springville',
    'Stafford',
    'Stella Niagara',
    'Strykersville',
    'Tonawanda',
    'Van Buren Point',
    'Varysburg',
    'Versailles',
    'Wales Center',
    'West Falls',
    'West Valley',
    'Wilson',
    'Yorkshire',
    'Youngstown',
    'Buffalo',
    'Niagara Falls',
    'Adams Basin',
    'Albion',
    'Alton',
    'Avon',
    'Bellona',
    'Bergen',
    'Branchport',
    'Brockport',
    'Byron',
    'Caledonia',
    'Canandaigua',
    'Farmington',
    'Castile',
    'Churchville',
    'Clarendon',
    'Clarkson',
    'Clifton Springs',
    'Clyde',
    'Conesus',
    'Dansville',
    'Dresden',
    'East Bloomfield',
    'East Rochester',
    'East Williamson',
    'Fairport',
    'Fancher',
    'Fishers',
    'Geneseo',
    'Geneva',
    'Gorham',
    'Groveland',
    'Hall',
    'Hamlin',
    'Hemlock',
    'Henrietta',
    'Hilton',
    'Bloomfield',
    'Holley',
    'Honeoye',
    'Honeoye Falls',
    'Ionia',
    'Kendall',
    'Kent',
    'Keuka Park',
    'Knowlesville',
    'Lakeville',
    'Leicester',
    'Le Roy',
    'Lima',
    'Linwood',
    'Livonia',
    'Livonia Center',
    'Lyons',
    'Macedon',
    'Manchester',
    'Marion',
    'Mendon',
    'Middlesex',
    'Morton',
    'Mount Morris',
    'Mumford',
    'Naples',
    'Newark',
    'North Chili',
    'North Greece',
    'North Rose',
    'Nunda',
    'Oaks Corners',
    'Ontario',
    'Ontario Center',
    'Ovid',
    'Palmyra',
    'Pavilion',
    'Penfield',
    'Penn Yan',
    'Perkinsville',
    'Perry',
    'Phelps',
    'Piffard',
    'Pittsford',
    'Portageville',
    'Port Gibson',
    'Pultneyville',
    'Retsof',
    'Romulus',
    'Rose',
    'Rush',
    'Rushville',
    'Scottsburg',
    'Scottsville',
    'Seneca Castle',
    'Shortsville',
    'Silver Lake',
    'Silver Springs',
    'Sodus',
    'Sodus Point',
    'Sonyea',
    'South Byron',
    'South Lima',
    'Spencerport',
    'Springwater',
    'Stanley',
    'Union Hill',
    'Victor',
    'Walworth',
    'Warsaw',
    'Waterport',
    'Wayland',
    'Webster',
    'West Bloomfield',
    'West Henrietta',
    'Willard',
    'Williamson',
    'Wolcott',
    'Wyoming',
    'York',
    'Rochester',
    'Jamestown',
    'Allegany',
    'Allentown',
    'Alma',
    'Angelica',
    'Ashville',
    'Belfast',
    'Bemus Point',
    'Black Creek',
    'Bolivar',
    'Brocton',
    'Caneadea',
    'Cassadaga',
    'Cattaraugus',
    'Celoron',
    'Ceres',
    'Chautauqua',
    'Cherry Creek',
    'Clymer',
    'Conewango Valley',
    'Cuba',
    'Dewittville',
    'East Otto',
    'East Randolph',
    'Ellicottville',
    'Ellington',
    'Falconer',
    'Fillmore',
    'Findley Lake',
    'Franklinville',
    'Frewsburg',
    'Friendship',
    'Gerry',
    'Great Valley',
    'Greenhurst',
    'Hinsdale',
    'Houghton',
    'Hume',
    'Kennedy',
    'Kill Buck',
    'Lakewood',
    'Leon',
    'Lily Dale',
    'Limestone',
    'Little Genesee',
    'Little Valley',
    'Maple Springs',
    'Mayville',
    'Niobe',
    'Olean',
    'Otto',
    'Panama',
    'Portland',
    'Portville',
    'Randolph',
    'Richburg',
    'Ripley',
    'Rushford',
    'Saint Bonaventure',
    'Salamanca',
    'Sherman',
    'Sinclairville',
    'Steamburg',
    'Stockton',
    'Stow',
    'West Clarksville',
    'Westfield',
    'Westons Mills',
    'Addison',
    'Alfred',
    'Alfred Station',
    'Almond',
    'Alpine',
    'Andover',
    'Arkport',
    'Atlanta',
    'Avoca',
    'Bath',
    'Beaver Dams',
    'Belmont',
    'Big Flats',
    'Bradford',
    'Breesport',
    'Brooktondale',
    'Burdett',
    'Cameron',
    'Cameron Mills',
    'Campbell',
    'Canaseraga',
    'Canisteo',
    'Cayuta',
    'Chemung',
    'Cohocton',
    'Coopers Plains',
    'Corning',
    'Dalton',
    'Dundee',
    'Erin',
    'Greenwood',
    'Hammondsport',
    'Hector',
    'Himrod',
    'Hornell',
    'Horseheads',
    'Hunt',
    'Interlaken',
    'Ithaca',
    'Jacksonville',
    'Jasper',
    'Kanona',
    'Lakemont',
    'Lindley',
    'Lockwood',
    'Lodi',
    'Lowman',
    'Mecklenburg',
    'Millport',
    'Montour Falls',
    'Newfield',
    'Odessa',
    'Painted Post',
    'Pine City',
    'Pine Valley',
    'Prattsburgh',
    'Pulteney',
    'Reading Center',
    'Rexville',
    'Rock Stream',
    'Savona',
    'Scio',
    'Slaterville Springs',
    'Lansing',
    'Spencer',
    'Swain',
    'Troupsburg',
    'Trumansburg',
    'Tyrone',
    'Van Etten',
    'Watkins Glen',
    'Waverly',
    'Wayne',
    'Wellsburg',
    'Wellsville',
    'Whitesville',
    'Woodhull',
    'Elmira'
  ],
  PR: [
    'Adjuntas',
    'Aguada',
    'Aguadilla',
    'Maricao',
    'Anasco',
    'Angeles',
    'Arecibo',
    'Bajadero',
    'Barceloneta',
    'Boqueron',
    'Cabo Rojo',
    'Penuelas',
    'Camuy',
    'Castaner',
    'Rosario',
    'Sabana Grande',
    'Ciales',
    'Utuado',
    'Dorado',
    'Ensenada',
    'Florida',
    'Garrochales',
    'Guanica',
    'Guayanilla',
    'Hatillo',
    'Hormigueros',
    'Isabela',
    'Jayuya',
    'Lajas',
    'Lares',
    'Las Marias',
    'Manati',
    'Moca',
    'Rincon',
    'Quebradillas',
    'Mayaguez',
    'San German',
    'San Sebastian',
    'Morovis',
    'Sabana Hoyos',
    'San Antonio',
    'Vega Alta',
    'Vega Baja',
    'Yauco',
    'Aguas Buenas',
    'Aguirre',
    'Aibonito',
    'Maunabo',
    'Arroyo',
    'Mercedita',
    'Ponce',
    'Naguabo',
    'Naranjito',
    'Orocovis',
    'Palmer',
    'Patillas',
    'Caguas',
    'Canovanas',
    'Ceiba',
    'Cayey',
    'Fajardo',
    'Cidra',
    'Puerto Real',
    'Punta Santiago',
    'Roosevelt Roads',
    'Rio Blanco',
    'Rio Grande',
    'Salinas',
    'San Lorenzo',
    'Santa Isabel',
    'Vieques',
    'Villalba',
    'Yabucoa',
    'Coamo',
    'Las Piedras',
    'Loiza',
    'Luquillo',
    'Culebra',
    'Juncos',
    'Gurabo',
    'Coto Laurel',
    'Comerio',
    'Corozal',
    'Guayama',
    'La Plata',
    'Humacao',
    'Barranquitas',
    'Juana Diaz',
    'San Juan',
    'Fort Buchanan',
    'Toa Baja',
    'Sabana Seca',
    'Toa Alta',
    'Bayamon',
    'Catano',
    'Guaynabo',
    'Trujillo Alto',
    'Saint Just',
    'Carolina'
  ],
  VI: [
    'St Thomas',
    'Christiansted',
    'St John',
    'Frederiksted',
    'Kingshill'
  ],
  MA: [
    'Agawam',
    'Amherst',
    'Barre',
    'Belchertown',
    'Blandford',
    'Bondsville',
    'Brimfield',
    'Chester',
    'Chesterfield',
    'Chicopee',
    'Cummington',
    'Easthampton',
    'East Longmeadow',
    'East Otis',
    'Feeding Hills',
    'Gilbertville',
    'Goshen',
    'Granby',
    'Granville',
    'Hadley',
    'Hampden',
    'Hardwick',
    'Hatfield',
    'Haydenville',
    'Holyoke',
    'Huntington',
    'Leeds',
    'Leverett',
    'Ludlow',
    'Monson',
    'North Amherst',
    'Northampton',
    'Florence',
    'North Hatfield',
    'Oakham',
    'Palmer',
    'Plainfield',
    'Russell',
    'Shutesbury',
    'Southampton',
    'South Barre',
    'South Hadley',
    'Southwick',
    'Thorndike',
    'Three Rivers',
    'Wales',
    'Ware',
    'Warren',
    'West Chesterfield',
    'Westfield',
    'West Hatfield',
    'West Springfield',
    'West Warren',
    'Whately',
    'Wheelwright',
    'Wilbraham',
    'Williamsburg',
    'Woronoco',
    'Worthington',
    'Springfield',
    'Longmeadow',
    'Indian Orchard',
    'Pittsfield',
    'Adams',
    'Ashley Falls',
    'Becket',
    'Berkshire',
    'Cheshire',
    'Dalton',
    'Glendale',
    'Great Barrington',
    'Hinsdale',
    'Housatonic',
    'Lanesborough',
    'Lee',
    'Lenox',
    'Lenox Dale',
    'Middlefield',
    'Mill River',
    'Monterey',
    'North Adams',
    'North Egremont',
    'Otis',
    'Richmond',
    'Sandisfield',
    'Savoy',
    'Sheffield',
    'South Egremont',
    'Southfield',
    'South Lee',
    'Stockbridge',
    'Tyringham',
    'West Stockbridge',
    'Williamstown',
    'Windsor',
    'Greenfield',
    'Ashfield',
    'Athol',
    'Bernardston',
    'Buckland',
    'Charlemont',
    'Colrain',
    'Conway',
    'Deerfield',
    'Drury',
    'Erving',
    'Heath',
    'Lake Pleasant',
    'Millers Falls',
    'Monroe Bridge',
    'Montague',
    'Gill',
    'New Salem',
    'Northfield',
    'Orange',
    'Petersham',
    'Rowe',
    'Royalston',
    'Shelburne Falls',
    'South Deerfield',
    'Sunderland',
    'Turners Falls',
    'Warwick',
    'Wendell',
    'Wendell Depot',
    'Fitchburg',
    'Ashburnham',
    'Ashby',
    'Ayer',
    'Devens',
    'Baldwinville',
    'East Templeton',
    'Gardner',
    'Westminster',
    'Groton',
    'Harvard',
    'Hubbardston',
    'Leominster',
    'Littleton',
    'Lunenburg',
    'Pepperell',
    'Shirley',
    'Still River',
    'Templeton',
    'Townsend',
    'West Groton',
    'West Townsend',
    'Winchendon',
    'Winchendon Springs',
    'Auburn',
    'Berlin',
    'Blackstone',
    'Boylston',
    'Brookfield',
    'Charlton',
    'Charlton City',
    'Charlton Depot',
    'Clinton',
    'East Brookfield',
    'Douglas',
    'East Princeton',
    'Fiskdale',
    'Grafton',
    'Holden',
    'Holland',
    'Jefferson',
    'Lancaster',
    'Leicester',
    'Linwood',
    'Manchaug',
    'Millbury',
    'Millville',
    'New Braintree',
    'Northborough',
    'Northbridge',
    'North Brookfield',
    'North Grafton',
    'North Oxford',
    'North Uxbridge',
    'Oxford',
    'Princeton',
    'Rochdale',
    'Rutland',
    'Shrewsbury',
    'Southbridge',
    'South Grafton',
    'South Lancaster',
    'Spencer',
    'Sterling',
    'Sturbridge',
    'Upton',
    'Uxbridge',
    'Webster',
    'Dudley',
    'Westborough',
    'West Boylston',
    'West Brookfield',
    'West Millbury',
    'Whitinsville',
    'Sutton',
    'Worcester',
    'Cherry Valley',
    'Paxton',
    'Framingham',
    'Acton',
    'Boxborough',
    'Ashland',
    'Bedford',
    'Hanscom AFB',
    'Bolton',
    'Carlisle',
    'Concord',
    'Fayville',
    'Holliston',
    'Hopedale',
    'Hopkinton',
    'Hudson',
    'Marlborough',
    'Maynard',
    'Mendon',
    'Milford',
    'Natick',
    'Sherborn',
    'Southborough',
    'Lincoln',
    'Stow',
    'Sudbury',
    'Wayland',
    'Woodville',
    'Woburn',
    'Burlington',
    'Andover',
    'Billerica',
    'Chelmsford',
    'Dracut',
    'Dunstable',
    'Haverhill',
    'Georgetown',
    'Groveland',
    'Lawrence',
    'Methuen',
    'North Andover',
    'Lowell',
    'Merrimac',
    'North Billerica',
    'North Chelmsford',
    'North Reading',
    'Nutting Lake',
    'Pinehurst',
    'Reading',
    'Tewksbury',
    'Tyngsboro',
    'Wakefield',
    'West Boxford',
    'Westford',
    'Wilmington',
    'Winchester',
    'Lynn',
    'Saugus',
    'Swampscott',
    'Nahant',
    'Amesbury',
    'Beverly',
    'Boxford',
    'Byfield',
    'Danvers',
    'Essex',
    'Gloucester',
    'Hamilton',
    'Hathorne',
    'Ipswich',
    'Lynnfield',
    'Manchester',
    'Marblehead',
    'Middleton',
    'Newburyport',
    'Newbury',
    'Salisbury',
    'Peabody',
    'Prides Crossing',
    'Rockport',
    'Rowley',
    'Salem',
    'South Hamilton',
    'Topsfield',
    'Wenham',
    'West Newbury',
    'Accord',
    'Bellingham',
    'Brant Rock',
    'Canton',
    'Cohasset',
    'Dedham',
    'Dover',
    'East Mansfield',
    'East Walpole',
    'Foxboro',
    'Franklin',
    'Greenbush',
    'Green Harbor',
    'Hingham',
    'Hull',
    'Humarock',
    'Mansfield',
    'Marshfield',
    'Marshfield Hills',
    'Medfield',
    'Medway',
    'Millis',
    'Minot',
    'Norfolk',
    'North Marshfield',
    'North Scituate',
    'Norwell',
    'Norwood',
    'Ocean Bluff',
    'Scituate',
    'Sharon',
    'Sheldonville',
    'South Walpole',
    'Stoughton',
    'Walpole',
    'Westwood',
    'Wrentham',
    'Boston',
    'Roxbury',
    'Roxbury Crossing',
    'Dorchester',
    'Dorchester Center',
    'Mattapan',
    'South Boston',
    'East Boston',
    'Charlestown',
    'Jamaica Plain',
    'Roslindale',
    'West Roxbury',
    'Allston',
    'Brighton',
    'Hyde Park',
    'Readville',
    'Cambridge',
    'Somerville',
    'Malden',
    'Everett',
    'Chelsea',
    'Revere',
    'Winthrop',
    'Medford',
    'West Medford',
    'Quincy',
    'Melrose',
    'Stoneham',
    'Braintree',
    'Milton',
    'Milton Village',
    'Weymouth',
    'East Weymouth',
    'South Weymouth',
    'North Weymouth',
    'Brockton',
    'Avon',
    'Bridgewater',
    'Bryantville',
    'Carver',
    'Duxbury',
    'East Bridgewater',
    'Easton',
    'Elmwood',
    'Halifax',
    'Hanover',
    'Hanson',
    'Holbrook',
    'Middleboro',
    'Manomet',
    'Lakeville',
    'Monponsett',
    'Abington',
    'North Carver',
    'North Easton',
    'North Pembroke',
    'Pembroke',
    'Plymouth',
    'Kingston',
    'South Carver',
    'Plympton',
    'Randolph',
    'Rockland',
    'South Easton',
    'West Bridgewater',
    'White Horse Beach',
    'Whitman',
    'Lexington',
    'Brookline',
    'Brookline Village',
    'Waltham',
    'North Waltham',
    'New Town',
    'Babson Park',
    'Newton',
    'Newton Center',
    'Newtonville',
    'Newton Highlands',
    'Newton Lower Falls',
    'Newton Upper Falls',
    'West Newton',
    'Auburndale',
    'Chestnut Hill',
    'Waban',
    'Watertown',
    'Arlington',
    'Arlington Heights',
    'Belmont',
    'Waverley',
    'Wellesley Hills',
    'Wellesley',
    'Needham',
    'Weston',
    'Needham Heights',
    'Nonantum',
    'Buzzards Bay',
    'Cataumet',
    'Chilmark',
    'East Falmouth',
    'East Sandwich',
    'East Wareham',
    'Edgartown',
    'Falmouth',
    'Woods Hole',
    'Menemsha',
    'Monument Beach',
    'Nantucket',
    'North Falmouth',
    'Oak Bluffs',
    'Onset',
    'Pocasset',
    'Sagamore',
    'Sagamore Beach',
    'Sandwich',
    'Siasconset',
    'Silver Beach',
    'Vineyard Haven',
    'Wareham',
    'West Chop',
    'West Falmouth',
    'West Tisbury',
    'West Wareham',
    'Hyannis',
    'Barnstable',
    'Brewster',
    'Centerville',
    'Chatham',
    'Cotuit',
    'Cummaquid',
    'Dennis',
    'Dennis Port',
    'East Dennis',
    'Eastham',
    'East Orleans',
    'Forestdale',
    'Harwich',
    'Harwich Port',
    'Hyannis Port',
    'Marstons Mills',
    'Mashpee',
    'North Chatham',
    'North Eastham',
    'North Truro',
    'Orleans',
    'Osterville',
    'Provincetown',
    'South Chatham',
    'South Dennis',
    'South Harwich',
    'South Orleans',
    'South Wellfleet',
    'South Yarmouth',
    'Truro',
    'Wellfleet',
    'West Barnstable',
    'West Chatham',
    'West Dennis',
    'West Harwich',
    'West Hyannisport',
    'West Yarmouth',
    'Yarmouth Port',
    'Assonet',
    'Attleboro',
    'Chartley',
    'Cuttyhunk',
    'Dartmouth',
    'Dighton',
    'East Freetown',
    'East Taunton',
    'Fairhaven',
    'Fall River',
    'Somerset',
    'Marion',
    'Mattapoisett',
    'New Bedford',
    'Acushnet',
    'North Dartmouth',
    'South Dartmouth',
    'North Attleboro',
    'Plainville',
    'Attleboro Falls',
    'North Dighton',
    'Norton',
    'Raynham',
    'Raynham Center',
    'Rehoboth',
    'Rochester',
    'Seekonk',
    'Swansea',
    'Berkley',
    'Taunton',
    'Westport',
    'Westport Point'
  ],
  RI: [
    'Adamsville',
    'Albion',
    'Ashaway',
    'Barrington',
    'Block Island',
    'Bradford',
    'Bristol',
    'Carolina',
    'Charlestown',
    'Chepachet',
    'Clayville',
    'Coventry',
    'West Greenwich',
    'East Greenwich',
    'Exeter',
    'Fiskeville',
    'Forestdale',
    'Foster',
    'Glendale',
    'Greene',
    'Greenville',
    'Harmony',
    'Harrisville',
    'Hope',
    'Hope Valley',
    'Hopkinton',
    'Jamestown',
    'Kenyon',
    'Little Compton',
    'Manville',
    'Mapleville',
    'Newport',
    'Middletown',
    'North Kingstown',
    'North Scituate',
    'Oakland',
    'Pascoag',
    'Pawtucket',
    'Central Falls',
    'Cumberland',
    'Lincoln',
    'Portsmouth',
    'Prudence Island',
    'Rockville',
    'Saunderstown',
    'Shannock',
    'Slatersville',
    'Slocum',
    'Tiverton',
    'Wakefield',
    'Kingston',
    'Narragansett',
    'Peace Dale',
    'Warren',
    'Warwick',
    'Westerly',
    'West Kingston',
    'West Warwick',
    'Wood River Junction',
    'Woonsocket',
    'North Smithfield',
    'Wyoming',
    'Providence',
    'Cranston',
    'North Providence',
    'East Providence',
    'Riverside',
    'Rumford',
    'Smithfield',
    'Johnston'
  ],
  NH: [
    'Amherst',
    'Auburn',
    'Brookline',
    'Candia',
    'Chester',
    'Deerfield',
    'Derry',
    'East Candia',
    'East Derry',
    'Epping',
    'Francestown',
    'Fremont',
    'Goffstown',
    'Dunbarton',
    'Greenfield',
    'Greenville',
    'Hollis',
    'Hudson',
    'Litchfield',
    'Londonderry',
    'Merrimack',
    'Milford',
    'Mont Vernon',
    'Nashua',
    'New Boston',
    'New Ipswich',
    'North Salem',
    'Pelham',
    'Raymond',
    'Salem',
    'Lyndeborough',
    'Temple',
    'Wilton',
    'Windham',
    'Manchester',
    'Hooksett',
    'Bedford',
    'Waterville Valley',
    'Andover',
    'Ashland',
    'Barnstead',
    'Belmont',
    'Bradford',
    'Bristol',
    'Campton',
    'Canterbury',
    'Center Barnstead',
    'Center Harbor',
    'Center Sandwich',
    'Contoocook',
    'Danbury',
    'East Andover',
    'Elkins',
    'Epsom',
    'Franklin',
    'Gilmanton',
    'Glencliff',
    'Grafton',
    'Hebron',
    'Henniker',
    'Hill',
    'Hillsborough',
    'Holderness',
    'Laconia',
    'Gilford',
    'Lincoln',
    'Lochmere',
    'Meredith',
    'Moultonborough',
    'Newbury',
    'New Hampton',
    'New London',
    'Chichester',
    'North Sandwich',
    'North Sutton',
    'Northwood',
    'North Woodstock',
    'Pittsfield',
    'Plymouth',
    'Rumney',
    'Salisbury',
    'Sanbornton',
    'South Newbury',
    'South Sutton',
    'Stinson Lake',
    'Suncook',
    'Tilton',
    'Warner',
    'Warren',
    'Washington',
    'Weare',
    'Wentworth',
    'Springfield',
    'Thornton',
    'Wilmot',
    'Winnisquam',
    'Nottingham',
    'West Nottingham',
    'Woodstock',
    'Concord',
    'Bow',
    'Loudon',
    'Keene',
    'Antrim',
    'Ashuelot',
    'Bennington',
    'Chesterfield',
    'Dublin',
    'Sullivan',
    'Swanzey',
    'Fitzwilliam',
    'Gilsum',
    'Hancock',
    'Harrisville',
    'Hinsdale',
    'Jaffrey',
    'Marlborough',
    'Marlow',
    'Nelson',
    'Peterborough',
    'Rindge',
    'Spofford',
    'Stoddard',
    'Troy',
    'West Chesterfield',
    'Westmoreland',
    'West Peterborough',
    'West Swanzey',
    'Winchester',
    'Littleton',
    'Berlin',
    'Bethlehem',
    'Bretton Woods',
    'Colebrook',
    'Errol',
    'Franconia',
    'Gorham',
    'Groveton',
    'Jefferson',
    'Lancaster',
    'Lisbon',
    'Sugar Hill',
    'Milan',
    'Mount Washington',
    'North Stratford',
    'Pittsburg',
    'Randolph',
    'Twin Mountain',
    'West Stewartstown',
    'Whitefield',
    'Acworth',
    'Alstead',
    'Charlestown',
    'Drewsville',
    'Lempster',
    'South Acworth',
    'Walpole',
    'North Walpole',
    'Bath',
    'Canaan',
    'Claremont',
    'Cornish',
    'Cornish Flat',
    'Enfield',
    'Enfield Center',
    'Etna',
    'Georges Mills',
    'Goshen',
    'Grantham',
    'Guild',
    'Hanover',
    'Lebanon',
    'Haverhill',
    'Lyme',
    'Lyme Center',
    'Meriden',
    'Monroe',
    'Newport',
    'North Haverhill',
    'Orford',
    'Piermont',
    'Pike',
    'Plainfield',
    'Sunapee',
    'West Lebanon',
    'Woodsville',
    'Portsmouth',
    'Newington',
    'Alton',
    'Alton Bay',
    'Atkinson',
    'Bartlett',
    'Center Conway',
    'Center Ossipee',
    'Center Strafford',
    'Center Tuftonboro',
    'Chocorua',
    'Conway',
    'Danville',
    'Dover',
    'Madbury',
    'Durham',
    'Barrington',
    'East Hampstead',
    'East Kingston',
    'East Wakefield',
    'Eaton Center',
    'Exeter',
    'Farmington',
    'Freedom',
    'Gilmanton Iron Works',
    'Glen',
    'Rochester',
    'Greenland',
    'Hampstead',
    'Hampton',
    'Hampton Falls',
    'Intervale',
    'Jackson',
    'Kearsarge',
    'Kingston',
    'Madison',
    'Melvin Village',
    'Milton',
    'Milton Mills',
    'Mirror Lake',
    'New Castle',
    'New Durham',
    'Newfields',
    'Newmarket',
    'Newton',
    'Newton Junction',
    'North Conway',
    'Lee',
    'North Hampton',
    'Ossipee',
    'Plaistow',
    'Rollinsford',
    'Rye',
    'Rye Beach',
    'Sanbornville',
    'Sandown',
    'Seabrook',
    'Silver Lake',
    'Somersworth',
    'Effingham',
    'South Tamworth',
    'Strafford',
    'Stratham',
    'Tamworth',
    'Union',
    'West Ossipee',
    'Wolfeboro',
    'Wolfeboro Falls',
    'Wonalancet'
  ],
  ME: [
    'Berwick',
    'Cape Neddick',
    'Eliot',
    'Kittery',
    'Kittery Point',
    'North Berwick',
    'Ogunquit',
    'South Berwick',
    'York',
    'York Beach',
    'York Harbor',
    'Acton',
    'Alfred',
    'Bailey Island',
    'Bar Mills',
    'Biddeford',
    'Biddeford Pool',
    'Bowdoinham',
    'Bridgton',
    'Brownfield',
    'Brunswick',
    'Bustins Island',
    'Cape Porpoise',
    'Casco',
    'Center Lovell',
    'Chebeague Island',
    'Cliff Island',
    'Cornish',
    'Cumberland Center',
    'Denmark',
    'East Baldwin',
    'Lebanon',
    'East Parsonsfield',
    'Sebago',
    'East Waterboro',
    'Freeport',
    'Fryeburg',
    'Gorham',
    'Gray',
    'Harrison',
    'Hiram',
    'Hollis Center',
    'Kennebunk',
    'Kennebunkport',
    'Parsonsfield',
    'Limerick',
    'Limington',
    'Long Island',
    'Lovell',
    'Moody',
    'Naples',
    'Newfield',
    'North Bridgton',
    'North Waterboro',
    'Windham',
    'Ocean Park',
    'Old Orchard Beach',
    'Orrs Island',
    'Porter',
    'Pownal',
    'Scarborough',
    'Raymond',
    'Saco',
    'Sanford',
    'Sebago Lake',
    'Shapleigh',
    'South Casco',
    'South Freeport',
    'Harpswell',
    'South Windham',
    'Springvale',
    'Standish',
    'Steep Falls',
    'Topsham',
    'Waterboro',
    'Waterford',
    'Wells',
    'West Baldwin',
    'Westbrook',
    'Buxton',
    'West Kennebunk',
    'West Newfield',
    'Yarmouth',
    'North Yarmouth',
    'Portland',
    'Falmouth',
    'South Portland',
    'Cape Elizabeth',
    'Peaks Island',
    'Cumberland Foreside',
    'Auburn',
    'Andover',
    'Bethel',
    'Bryant Pond',
    'Buckfield',
    'Canton',
    'Durham',
    'Danville',
    'Dixfield',
    'Dryden',
    'East Andover',
    'East Dixfield',
    'East Livermore',
    'East Poland',
    'Stoneham',
    'East Wilton',
    'Greene',
    'Hanover',
    'Hebron',
    'Jay',
    'Lewiston',
    'Lisbon',
    'Lisbon Falls',
    'Livermore',
    'Livermore Falls',
    'Greenwood',
    'Mechanic Falls',
    'Mexico',
    'Minot',
    'Monmouth',
    'New Gloucester',
    'Newry',
    'North Jay',
    'Leeds',
    'North Monmouth',
    'North Turner',
    'North Waterford',
    'Norway',
    'Oxford',
    'Paris',
    'Poland',
    'Roxbury',
    'Rumford',
    'Sabattus',
    'South Paris',
    'Turner',
    'Wayne',
    'Weld',
    'West Bethel',
    'Bowdoin',
    'West Minot',
    'West Paris',
    'Peru',
    'West Poland',
    'Sumner',
    'Wilton',
    'Augusta',
    'Coopers Mills',
    'Dresden',
    'East Winthrop',
    'Farmingdale',
    'Gardiner',
    'Randolph',
    'Hallowell',
    'Jefferson',
    'Kents Hill',
    'Litchfield',
    'Manchester',
    'Mount Vernon',
    'Whitefield',
    'Palermo',
    'Readfield',
    'Richmond',
    'South China',
    'South Gardiner',
    'Vienna',
    'Windsor',
    'Winthrop',
    'Bangor',
    'Abbot',
    'Aurora',
    'Bradford',
    'Bradley',
    'Brewer',
    'Brookton',
    'Brownville',
    'Brownville Junction',
    'Bucksport',
    'Burlington',
    'Greenbush',
    'Carmel',
    'Castine',
    'Charleston',
    'Danforth',
    'Dover Foxcroft',
    'Corinth',
    'Eddington',
    'Holden',
    'East Millinocket',
    'East Orland',
    'Etna',
    'Exeter',
    'Frankfort',
    'Greenville',
    'Greenville Junction',
    'Guilford',
    'Hampden',
    'Howland',
    'Hudson',
    'Kenduskeag',
    'Kingman',
    'Lagrange',
    'Lambert Lake',
    'Lee',
    'Levant',
    'Lincoln',
    'Mattawamkeag',
    'Medway',
    'Milford',
    'Millinocket',
    'Milo',
    'Monson',
    'Olamon',
    'Old Town',
    'Orono',
    'Orient',
    'Orland',
    'Orrington',
    'Passadumkeag',
    'Penobscot',
    'Rockwood',
    'Sangerville',
    'Sebec',
    'Shirley Mills',
    'Springfield',
    'Stetson',
    'Stillwater',
    'Topsfield',
    'Vanceboro',
    'Waite',
    'West Enfield',
    'Winn',
    'Winterport',
    'Wytopitlock',
    'Bath',
    'Alna',
    'Boothbay',
    'Boothbay Harbor',
    'Bristol',
    'Chamberlain',
    'Damariscotta',
    'East Boothbay',
    'Friendship',
    'Georgetown',
    'Isle Of Springs',
    'Bremen',
    'Newcastle',
    'New Harbor',
    'Nobleboro',
    'Edgecomb',
    'Pemaquid',
    'Phippsburg',
    'Cushing',
    'Round Pond',
    'Sebasco Estates',
    'South Bristol',
    'Squirrel Island',
    'Trevett',
    'Waldoboro',
    'Walpole',
    'Washington',
    'West Boothbay Harbor',
    'Southport',
    'Wiscasset',
    'Woolwich',
    'Ellsworth',
    'Addison',
    'Gouldsboro',
    'Bar Harbor',
    'Beals',
    'Bernard',
    'Birch Harbor',
    'Blue Hill',
    'Brooklin',
    'Brooksville',
    'Calais',
    'Cherryfield',
    'Columbia Falls',
    'Corea',
    'Cranberry Isles',
    'Cutler',
    'Deer Isle',
    'Dennysville',
    'East Blue Hill',
    'East Machias',
    'Eastport',
    'Franklin',
    'Frenchboro',
    'Grand Lake Stream',
    'Hancock',
    'Harborside',
    'Harrington',
    'Hulls Cove',
    'Isle Au Haut',
    'Islesford',
    'Jonesboro',
    'Jonesport',
    'Little Deer Isle',
    'Lubec',
    'Bass Harbor',
    'Machias',
    'Machiasport',
    'Meddybemps',
    'Milbridge',
    'Mount Desert',
    'Northeast Harbor',
    'Sullivan',
    'Pembroke',
    'Perry',
    'Princeton',
    'Prospect Harbor',
    'Robbinston',
    'Salsbury Cove',
    'Sargentville',
    'Seal Cove',
    'Seal Harbor',
    'Sedgwick',
    'Sorrento',
    'Southwest Harbor',
    'Steuben',
    'Stonington',
    'Sunset',
    'Surry',
    'Swans Island',
    'Wesley',
    'Whiting',
    'Winter Harbor',
    'Baileyville',
    'Houlton',
    'Ashland',
    'Benedicta',
    'Blaine',
    'Bridgewater',
    'Caribou',
    'Clayton Lake',
    'Crouseville',
    'Eagle Lake',
    'Easton',
    'Estcourt Station',
    'Fort Fairfield',
    'Fort Kent',
    'Fort Kent Mills',
    'Frenchville',
    'Grand Isle',
    'Island Falls',
    'Limestone',
    'Madawaska',
    'Mapleton',
    'Mars Hill',
    'Monticello',
    'New Limerick',
    'New Sweden',
    'Oakfield',
    'Oxbow',
    'Patten',
    'Perham',
    'Portage',
    'Presque Isle',
    'Saint Agatha',
    'Saint David',
    'Saint Francis',
    'Sheridan',
    'Sherman',
    'Stacyville',
    'Sinclair',
    'Smyrna Mills',
    'Wallagrass',
    'Stockholm',
    'Van Buren',
    'Washburn',
    'Westfield',
    'Rockland',
    'Camden',
    'Glen Cove',
    'Hope',
    'Islesboro',
    'Lincolnville',
    'Lincolnville Center',
    'Matinicus',
    'Monhegan',
    'North Haven',
    'Owls Head',
    'Port Clyde',
    'Rockport',
    'South Thomaston',
    'Spruce Head',
    'Tenants Harbor',
    'Thomaston',
    'Union',
    'Vinalhaven',
    'Warren',
    'West Rockport',
    'Waterville',
    'Albion',
    'Anson',
    'Athens',
    'Belfast',
    'Belgrade',
    'Belgrade Lakes',
    'Bingham',
    'Brooks',
    'Burnham',
    'Cambridge',
    'Canaan',
    'Caratunk',
    'China Village',
    'Clinton',
    'Corinna',
    'Detroit',
    'Dexter',
    'Dixmont',
    'East Newport',
    'East Vassalboro',
    'Eustis',
    'Fairfield',
    'Farmington',
    'Garland',
    'Farmington Falls',
    'Freedom',
    'Harmony',
    'Hartland',
    'Hinckley',
    'Jackman',
    'Kingfield',
    'Liberty',
    'Madison',
    'Monroe',
    'Morrill',
    'Newport',
    'New Portland',
    'New Sharon',
    'New Vineyard',
    'Norridgewock',
    'North Anson',
    'North Vassalboro',
    'Oakland',
    'Oquossoc',
    'Palmyra',
    'Phillips',
    'Pittsfield',
    'Plymouth',
    'Rangeley',
    'Saint Albans',
    'Sandy Point',
    'Searsmont',
    'Searsport',
    'Shawmut',
    'Skowhegan',
    'Smithfield',
    'Solon',
    'Stockton Springs',
    'Stratton',
    'Strong',
    'Temple',
    'West Forks',
    'Thorndike',
    'Troy',
    'Unity',
    'Vassalboro',
    'West Farmington'
  ],
  VT: [
    'White River Junction',
    'Ascutney',
    'Barnard',
    'Bethel',
    'Bradford',
    'Bridgewater',
    'Bridgewater Corners',
    'Brookfield',
    'Brownsville',
    'Chelsea',
    'Corinth',
    'East Corinth',
    'East Randolph',
    'East Ryegate',
    'East Thetford',
    'Fairlee',
    'Groton',
    'Hartford',
    'Hartland',
    'Hartland Four Corners',
    'Mc Indoe Falls',
    'Newbury',
    'North Hartland',
    'North Pomfret',
    'North Thetford',
    'Norwich',
    'Plymouth',
    'Post Mills',
    'Quechee',
    'Randolph',
    'Randolph Center',
    'Reading',
    'Sharon',
    'South Pomfret',
    'South Royalton',
    'South Ryegate',
    'South Strafford',
    'South Woodstock',
    'Strafford',
    'Taftsville',
    'Thetford',
    'Thetford Center',
    'Topsham',
    'Tunbridge',
    'Vershire',
    'Wells River',
    'West Fairlee',
    'West Hartford',
    'West Newbury',
    'West Topsham',
    'Wilder',
    'Windsor',
    'Woodstock',
    'Bellows Falls',
    'Cambridgeport',
    'Cavendish',
    'Chester',
    'Chester Depot',
    'Grafton',
    'Londonderry',
    'Ludlow',
    'North Springfield',
    'Perkinsville',
    'Peru',
    'Proctorsville',
    'Saxtons River',
    'South Londonderry',
    'Springfield',
    'Westminster',
    'Westminster Station',
    'Weston',
    'Bennington',
    'Arlington',
    'Dorset',
    'East Arlington',
    'East Dorset',
    'Manchester',
    'Manchester Center',
    'North Bennington',
    'North Pownal',
    'Pownal',
    'Shaftsbury',
    'Brattleboro',
    'Bondville',
    'East Dover',
    'Jacksonville',
    'Jamaica',
    'Marlboro',
    'Newfane',
    'Putney',
    'Readsboro',
    'South Newfane',
    'Stamford',
    'Townshend',
    'Vernon',
    'Wardsboro',
    'West Dover',
    'West Dummerston',
    'West Halifax',
    'West Townshend',
    'West Wardsboro',
    'Whitingham',
    'Williamsville',
    'Wilmington',
    'Burlington',
    'South Burlington',
    'Winooski',
    'Colchester',
    'Alburgh',
    'Bakersfield',
    'Belvidere Center',
    'Bristol',
    'Cambridge',
    'Charlotte',
    'East Berkshire',
    'East Fairfield',
    'Enosburg Falls',
    'Essex',
    'Essex Junction',
    'Fairfax',
    'Fairfield',
    'Ferrisburgh',
    'Franklin',
    'Grand Isle',
    'Highgate Center',
    'Highgate Springs',
    'Hinesburg',
    'Huntington',
    'Isle La Motte',
    'Jeffersonville',
    'Jericho',
    'Jonesville',
    'Milton',
    'Monkton',
    'Montgomery',
    'Montgomery Center',
    'New Haven',
    'North Ferrisburgh',
    'North Hero',
    'Richford',
    'Richmond',
    'Saint Albans',
    'Saint Albans Bay',
    'Shelburne',
    'Sheldon',
    'Sheldon Springs',
    'South Hero',
    'Starksboro',
    'Swanton',
    'Underhill',
    'Underhill Center',
    'Vergennes',
    'Waterville',
    'Westford',
    'Williston',
    'Montpelier',
    'Adamant',
    'Barre',
    'Cabot',
    'Calais',
    'East Barre',
    'East Calais',
    'East Montpelier',
    'Eden',
    'Eden Mills',
    'Graniteville',
    'Hyde Park',
    'Johnson',
    'Lake Elmore',
    'Marshfield',
    'Moretown',
    'Morrisville',
    'Moscow',
    'Northfield',
    'Northfield Falls',
    'North Hyde Park',
    'North Montpelier',
    'Plainfield',
    'Roxbury',
    'South Barre',
    'Waterbury',
    'Stowe',
    'Waitsfield',
    'Warren',
    'Washington',
    'Waterbury Center',
    'Websterville',
    'Williamstown',
    'Wolcott',
    'Woodbury',
    'Worcester',
    'Rutland',
    'Belmont',
    'Benson',
    'Bomoseen',
    'Brandon',
    'Bridport',
    'Castleton',
    'Center Rutland',
    'Chittenden',
    'Cuttingsville',
    'Danby',
    'East Middlebury',
    'East Poultney',
    'East Wallingford',
    'Fair Haven',
    'Florence',
    'Forest Dale',
    'Gaysville',
    'Granville',
    'Hancock',
    'Hydeville',
    'Killington',
    'Middlebury',
    'Middletown Springs',
    'Mount Holly',
    'North Clarendon',
    'Orwell',
    'Pawlet',
    'Pittsfield',
    'Pittsford',
    'Poultney',
    'Proctor',
    'Ripton',
    'Rochester',
    'Rupert',
    'Salisbury',
    'Shoreham',
    'Stockbridge',
    'Wallingford',
    'Wells',
    'West Pawlet',
    'West Rupert',
    'West Rutland',
    'Whiting',
    'Saint Johnsbury',
    'Albany',
    'Barnet',
    'Barton',
    'Beebe Plain',
    'Concord',
    'Coventry',
    'Craftsbury',
    'Craftsbury Common',
    'Danville',
    'Derby',
    'Derby Line',
    'East Burke',
    'East Charleston',
    'East Hardwick',
    'East Haven',
    'East Saint Johnsbury',
    'Glover',
    'Granby',
    'Greensboro',
    'Greensboro Bend',
    'Hardwick',
    'Irasburg',
    'Island Pond',
    'Lowell',
    'Lower Waterford',
    'Lyndon',
    'Lyndon Center',
    'Lyndonville',
    'Morgan',
    'Newport',
    'Newport Center',
    'North Concord',
    'North Troy',
    'Orleans',
    'Passumpsic',
    'Peacham',
    'Saint Johnsbury Center',
    'Sheffield',
    'Sutton',
    'Troy',
    'West Burke',
    'West Charleston',
    'West Danville',
    'Westfield',
    'Averill',
    'Beecher Falls',
    'Canaan',
    'Gilman',
    'Guildhall',
    'Lunenburg',
    'Norton'
  ],
  CT: [
    'Avon',
    'Bloomfield',
    'Windsor',
    'Bristol',
    'Burlington',
    'Broad Brook',
    'Canaan',
    'Canton',
    'Canton Center',
    'Colebrook',
    'Collinsville',
    'East Berlin',
    'East Canaan',
    'East Glastonbury',
    'East Granby',
    'East Hartland',
    'East Windsor Hill',
    'Ellington',
    'Farmington',
    'Falls Village',
    'Glastonbury',
    'Granby',
    'Berlin',
    'Lakeville',
    'Manchester',
    'Bolton',
    'New Britain',
    'New Hartford',
    'Norfolk',
    'North Canton',
    'North Granby',
    'Pine Meadow',
    'Plainville',
    'Barkhamsted',
    'Poquonock',
    'Riverton',
    'Vernon Rockville',
    'Rocky Hill',
    'Salisbury',
    'Sharon',
    'Simsbury',
    'Somers',
    'Somersville',
    'South Glastonbury',
    'South Windsor',
    'Stafford',
    'Stafford Springs',
    'Staffordville',
    'Suffield',
    'Taconic',
    'Tariffville',
    'Enfield',
    'Tolland',
    'Unionville',
    'East Windsor',
    'Weatogue',
    'West Granby',
    'West Hartland',
    'West Simsbury',
    'West Suffield',
    'Winchester Center',
    'Windsor Locks',
    'Winsted',
    'Hartford',
    'West Hartford',
    'East Hartford',
    'Wethersfield',
    'Newington',
    'Willimantic',
    'Abington',
    'Amston',
    'Andover',
    'Ballouville',
    'Brooklyn',
    'Chaplin',
    'Columbia',
    'Coventry',
    'Danielson',
    'Dayville',
    'Eastford',
    'East Killingly',
    'East Woodstock',
    'Fabyan',
    'Grosvenor Dale',
    'Hampton',
    'Hebron',
    'Lebanon',
    'Mansfield Center',
    'Mansfield Depot',
    'North Franklin',
    'North Grosvenordale',
    'North Windham',
    'Pomfret',
    'Pomfret Center',
    'Putnam',
    'Quinebaug',
    'Rogers',
    'Scotland',
    'South Willington',
    'South Windham',
    'South Woodstock',
    'Storrs Mansfield',
    'Thompson',
    'Ashford',
    'Willington',
    'Windham',
    'Woodstock',
    'Woodstock Valley',
    'New London',
    'Baltic',
    'Canterbury',
    'Central Village',
    'East Lyme',
    'Bozrah',
    'Gales Ferry',
    'Gilman',
    'Mashantucket',
    'Ledyard',
    'Groton',
    'Hanover',
    'Jewett City',
    'Montville',
    'Moosup',
    'Mystic',
    'Niantic',
    'North Stonington',
    'Norwich',
    'Preston',
    'Oakdale',
    'Old Lyme',
    'Old Mystic',
    'Oneco',
    'Plainfield',
    'Quaker Hill',
    'South Lyme',
    'Sterling',
    'Stonington',
    'Pawcatuck',
    'Taftville',
    'Uncasville',
    'Versailles',
    'Voluntown',
    'Waterford',
    'Wauregan',
    'West Mystic',
    'Yantic',
    'Ansonia',
    'Beacon Falls',
    'Botsford',
    'Branford',
    'Cheshire',
    'Centerbrook',
    'Chester',
    'Clinton',
    'Cobalt',
    'Colchester',
    'Cromwell',
    'Deep River',
    'Derby',
    'Killingworth',
    'Salem',
    'Durham',
    'East Haddam',
    'East Hampton',
    'Essex',
    'Guilford',
    'Haddam',
    'Hadlyme',
    'Hawleyville',
    'Higganum',
    'Ivoryton',
    'Madison',
    'Marion',
    'Marlborough',
    'Meriden',
    'Middlefield',
    'Middle Haddam',
    'Middletown',
    'Milford',
    'Milldale',
    'Monroe',
    'Moodus',
    'Newtown',
    'North Branford',
    'Northford',
    'North Haven',
    'North Westchester',
    'Old Saybrook',
    'Orange',
    'Oxford',
    'Plantsville',
    'Portland',
    'Rockfall',
    'Sandy Hook',
    'Seymour',
    'Shelton',
    'South Britain',
    'Southbury',
    'Southington',
    'Stevenson',
    'Wallingford',
    'Stratford',
    'Westbrook',
    'New Haven',
    'East Haven',
    'Hamden',
    'West Haven',
    'Bethany',
    'Woodbridge',
    'Bridgeport',
    'Trumbull',
    'Easton',
    'Waterbury',
    'Prospect',
    'Wolcott',
    'Bantam',
    'Bethlehem',
    'Bridgewater',
    'Cornwall',
    'Cornwall Bridge',
    'Gaylordsville',
    'Goshen',
    'Kent',
    'Lakeside',
    'Litchfield',
    'Middlebury',
    'Morris',
    'Naugatuck',
    'New Milford',
    'New Preston Marble Dale',
    'Northfield',
    'Oakville',
    'Pequabuck',
    'Plymouth',
    'Roxbury',
    'Sherman',
    'South Kent',
    'Terryville',
    'Thomaston',
    'Torrington',
    'Harwinton',
    'Washington',
    'Washington Depot',
    'Watertown',
    'West Cornwall',
    'Woodbury',
    'Bethel',
    'Brookfield',
    'Cos Cob',
    'Danbury',
    'New Fairfield',
    'Darien',
    'Fairfield',
    'Georgetown',
    'Greenwich',
    'Greens Farms',
    'New Canaan',
    'Norwalk',
    'Old Greenwich',
    'Redding Center',
    'Redding Ridge',
    'Ridgefield',
    'Riverside',
    'Westport',
    'Weston',
    'Southport',
    'Redding',
    'Wilton',
    'Stamford'
  ],
  NJ: [
    'Avenel',
    'Bayonne',
    'Bloomfield',
    'Fairfield',
    'Boonton',
    'Caldwell',
    'Carteret',
    'Cedar Grove',
    'Cliffside Park',
    'Clifton',
    'Cranford',
    'East Orange',
    'Edgewater',
    'Essex Fells',
    'Fairview',
    'Fanwood',
    'Fort Lee',
    'Garfield',
    'Garwood',
    'Glen Ridge',
    'Harrison',
    'Hoboken',
    'North Arlington',
    'Kearny',
    'Kenilworth',
    'Lake Hiawatha',
    'Lincoln Park',
    'Linden',
    'Livingston',
    'Maplewood',
    'Millburn',
    'Montclair',
    'Verona',
    'Montville',
    'Mountain Lakes',
    'North Bergen',
    'Orange',
    'West Orange',
    'Parsippany',
    'Passaic',
    'Wallington',
    'Pine Brook',
    'Warren',
    'Plainfield',
    'Port Reading',
    'Rahway',
    'Clark',
    'Colonia',
    'Roseland',
    'Watchung',
    'Rutherford',
    'Lyndhurst',
    'Carlstadt',
    'East Rutherford',
    'Moonachie',
    'Wood Ridge',
    'Scotch Plains',
    'Sewaren',
    'Short Hills',
    'South Orange',
    'South Plainfield',
    'Springfield',
    'Towaco',
    'Union',
    'Weehawken',
    'Union City',
    'Vauxhall',
    'Westfield',
    'Mountainside',
    'West New York',
    'Secaucus',
    'Woodbridge',
    'Jersey City',
    'Newark',
    'Belleville',
    'Nutley',
    'Irvington',
    'Elizabeth',
    'Roselle',
    'Roselle Park',
    'Hillside',
    'Elizabethport',
    'Allendale',
    'Bloomingdale',
    'Butler',
    'Elmwood Park',
    'Fair Lawn',
    'Franklin',
    'Franklin Lakes',
    'Glenwood',
    'Hamburg',
    'Haskell',
    'Hewitt',
    'Highland Lakes',
    'Ho Ho Kus',
    'Little Falls',
    'Mc Afee',
    'Mahwah',
    'Midland Park',
    'Newfoundland',
    'Oakland',
    'Oak Ridge',
    'Ogdensburg',
    'Pequannock',
    'Pompton Lakes',
    'Pompton Plains',
    'Ramsey',
    'Ridgewood',
    'Glen Rock',
    'Ringwood',
    'Riverdale',
    'Saddle River',
    'Stockholm',
    'Sussex',
    'Vernon',
    'Waldwick',
    'Wanaque',
    'Wayne',
    'West Milford',
    'Wyckoff',
    'Paterson',
    'Hawthorne',
    'Haledon',
    'Totowa',
    'Hackensack',
    'Bogota',
    'Hasbrouck Heights',
    'Leonia',
    'South Hackensack',
    'Maywood',
    'Teterboro',
    'Alpine',
    'Bergenfield',
    'Closter',
    'Cresskill',
    'Demarest',
    'Dumont',
    'Emerson',
    'Englewood',
    'Englewood Cliffs',
    'Harrington Park',
    'Haworth',
    'Hillsdale',
    'Little Ferry',
    'Lodi',
    'Montvale',
    'New Milford',
    'Northvale',
    'Norwood',
    'Oradell',
    'Palisades Park',
    'Paramus',
    'Park Ridge',
    'Ridgefield',
    'Ridgefield Park',
    'River Edge',
    'Rochelle Park',
    'Saddle Brook',
    'Teaneck',
    'Tenafly',
    'Westwood',
    'Township Of Washington',
    'Woodcliff Lake',
    'Red Bank',
    'Shrewsbury',
    'Fort Monmouth',
    'Fair Haven',
    'Adelphia',
    'Allenhurst',
    'Asbury Park',
    'Belmar',
    'Atlantic Highlands',
    'Avon By The Sea',
    'Belford',
    'Bradley Beach',
    'Cliffwood',
    'Colts Neck',
    'Deal',
    'Eatontown',
    'Englishtown',
    'Farmingdale',
    'Freehold',
    'Hazlet',
    'Howell',
    'Highlands',
    'Holmdel',
    'Keansburg',
    'Keyport',
    'Leonardo',
    'Lincroft',
    'Little Silver',
    'Long Branch',
    'Marlboro',
    'Matawan',
    'Middletown',
    'Monmouth Beach',
    'Morganville',
    'Navesink',
    'Neptune',
    'Oakhurst',
    'Ocean Grove',
    'Oceanport',
    'Port Monmouth',
    'Rumson',
    'Spring Lake',
    'Tennent',
    'West Long Branch',
    'Wickatunk',
    'Dover',
    'Mine Hill',
    'Picatinny Arsenal',
    'Allamuchy',
    'Andover',
    'Augusta',
    'Belvidere',
    'Blairstown',
    'Branchville',
    'Montague',
    'Budd Lake',
    'Buttzville',
    'Califon',
    'Changewater',
    'Columbia',
    'Delaware',
    'Denville',
    'Flanders',
    'Glasser',
    'Great Meadows',
    'Greendell',
    'Hackettstown',
    'Hibernia',
    'Hopatcong',
    'Hope',
    'Ironia',
    'Johnsonburg',
    'Kenvil',
    'Lafayette',
    'Lake Hopatcong',
    'Landing',
    'Layton',
    'Ledgewood',
    'Long Valley',
    'Middleville',
    'Mount Arlington',
    'Netcong',
    'Newton',
    'Oxford',
    'Port Murray',
    'Rockaway',
    'Randolph',
    'Schooleys Mountain',
    'Sparta',
    'Stanhope',
    'Stillwater',
    'Succasunna',
    'Swartswood',
    'Mount Tabor',
    'Tranquility',
    'Vienna',
    'Wallpack Center',
    'Washington',
    'Wharton',
    'Summit',
    'Basking Ridge',
    'Bedminster',
    'Berkeley Heights',
    'Bernardsville',
    'Brookside',
    'Cedar Knolls',
    'Chatham',
    'Chester',
    'Far Hills',
    'Florham Park',
    'Gillette',
    'Gladstone',
    'Green Village',
    'East Hanover',
    'Liberty Corner',
    'Lyons',
    'Madison',
    'Mendham',
    'Millington',
    'Morris Plains',
    'Morristown',
    'Convent Station',
    'Mount Freedom',
    'New Providence',
    'New Vernon',
    'Peapack',
    'Pluckemin',
    'Pottersville',
    'Stirling',
    'Whippany',
    'Alloway',
    'Cherry Hill',
    'Atco',
    'Barnegat',
    'Barnegat Light',
    'Barrington',
    'Beach Haven',
    'Berlin',
    'Beverly',
    'Birmingham',
    'Blackwood',
    'Bridgeport',
    'Browns Mills',
    'Burlington',
    'Cedar Brook',
    'Chatsworth',
    'Clarksboro',
    'Clementon',
    'Columbus',
    'Deepwater',
    'Ewan',
    'Gibbsboro',
    'Gibbstown',
    'Glassboro',
    'Glendora',
    'Gloucester City',
    'Bellmawr',
    'Grenloch',
    'Haddonfield',
    'Haddon Heights',
    'Hainesport',
    'Hammonton',
    'Hancocks Bridge',
    'Harrisonville',
    'Jobstown',
    'Juliustown',
    'Voorhees',
    'Lawnside',
    'Willingboro',
    'Lumberton',
    'Magnolia',
    'Manahawkin',
    'Mantua',
    'Maple Shade',
    'Marlton',
    'Mount Laurel',
    'Medford',
    'Mickleton',
    'Moorestown',
    'Mount Ephraim',
    'Mount Holly',
    'Mount Royal',
    'Mullica Hill',
    'National Park',
    'New Lisbon',
    'Palmyra',
    'Paulsboro',
    'Pedricktown',
    'Pemberton',
    'Penns Grove',
    'Pennsville',
    'Pitman',
    'Quinton',
    'Rancocas',
    'Richwood',
    'Riverside',
    'Riverton',
    'Runnemede',
    'Salem',
    'Sewell',
    'Sicklerville',
    'Somerdale',
    'Stratford',
    'Swedesboro',
    'Thorofare',
    'Tuckerton',
    'Vincentown',
    'Waterford Works',
    'Wenonah',
    'West Berlin',
    'West Creek',
    'Westville',
    'Williamstown',
    'Winslow',
    'Woodbury',
    'Woodbury Heights',
    'Woodstown',
    'Camden',
    'Audubon',
    'Oaklyn',
    'Collingswood',
    'Merchantville',
    'Pennsauken',
    'Absecon',
    'Avalon',
    'Brigantine',
    'Cape May',
    'Cape May Court House',
    'Cape May Point',
    'Cologne',
    'Dennisville',
    'Egg Harbor City',
    'Elwood',
    'Goshen',
    'Green Creek',
    'Leeds Point',
    'Linwood',
    'Marmora',
    'New Gretna',
    'Northfield',
    'Ocean City',
    'Ocean View',
    'Oceanville',
    'Pleasantville',
    'Egg Harbor Township',
    'Pomona',
    'Port Republic',
    'Rio Grande',
    'Sea Isle City',
    'Somers Point',
    'South Dennis',
    'South Seaville',
    'Stone Harbor',
    'Strathmere',
    'Tuckahoe',
    'Villas',
    'Whitesboro',
    'Wildwood',
    'Woodbine',
    'Bridgeton',
    'Buena',
    'Cedarville',
    'Clayton',
    'Deerfield Street',
    'Delmont',
    'Dividing Creek',
    'Dorchester',
    'Dorothy',
    'Elmer',
    'Estell Manor',
    'Fairton',
    'Fortescue',
    'Franklinville',
    'Greenwich',
    'Heislerville',
    'Landisville',
    'Leesburg',
    'Malaga',
    'Mauricetown',
    'Mays Landing',
    'Millville',
    'Milmay',
    'Minotola',
    'Mizpah',
    'Monroeville',
    'Newfield',
    'Newport',
    'Newtonville',
    'Norma',
    'Port Elizabeth',
    'Port Norris',
    'Richland',
    'Rosenhayn',
    'Shiloh',
    'Vineland',
    'Atlantic City',
    'Margate City',
    'Longport',
    'Ventnor City',
    'Allentown',
    'Belle Mead',
    'Blawenburg',
    'Bordentown',
    'Millstone Township',
    'Cookstown',
    'Cranbury',
    'Cream Ridge',
    'Chesterfield',
    'Florence',
    'Hightstown',
    'Hopewell',
    'Imlaystown',
    'Jackson',
    'Kingston',
    'Lambertville',
    'New Egypt',
    'Pennington',
    'Plainsboro',
    'Princeton',
    'Princeton Junction',
    'Ringoes',
    'Rocky Hill',
    'Roebling',
    'Roosevelt',
    'Rosemont',
    'Sergeantsville',
    'Skillman',
    'Stockton',
    'Titusville',
    'Windsor',
    'Wrightstown',
    'Trenton',
    'Joint Base Mdl',
    'Lawrence Township',
    'Robbinsville',
    'Lakewood',
    'Allenwood',
    'Bayville',
    'Beachwood',
    'Brick',
    'Brielle',
    'Forked River',
    'Island Heights',
    'Lakehurst',
    'Lanoka Harbor',
    'Lavallette',
    'Manasquan',
    'Mantoloking',
    'Normandy Beach',
    'Ocean Gate',
    'Pine Beach',
    'Point Pleasant Beach',
    'Sea Girt',
    'Seaside Heights',
    'Seaside Park',
    'Toms River',
    'Waretown',
    'Manchester Township',
    'Annandale',
    'Asbury',
    'Baptistown',
    'Bloomsbury',
    'Bound Brook',
    'Bridgewater',
    'Broadway',
    'Clinton',
    'Dayton',
    'Dunellen',
    'East Brunswick',
    'Edison',
    'Flagtown',
    'Flemington',
    'Franklin Park',
    'Kendall Park',
    'Frenchtown',
    'Glen Gardner',
    'Hampton',
    'Helmetta',
    'High Bridge',
    'Iselin',
    'Monroe Township',
    'Keasbey',
    'Lebanon',
    'Little York',
    'Manville',
    'Martinsville',
    'Metuchen',
    'Hillsborough',
    'Middlesex',
    'Milford',
    'Milltown',
    'Monmouth Junction',
    'Neshanic Station',
    'Piscataway',
    'Old Bridge',
    'Oldwick',
    'Parlin',
    'Perth Amboy',
    'Fords',
    'Phillipsburg',
    'Pittstown',
    'Quakertown',
    'Raritan',
    'Readington',
    'Sayreville',
    'Somerset',
    'Somerville',
    'South Amboy',
    'South Bound Brook',
    'South River',
    'Spotswood',
    'Stanton',
    'Stewartsville',
    'Three Bridges',
    'Whitehouse',
    'Whitehouse Station',
    'Zarephath',
    'New Brunswick',
    'North Brunswick',
    'Highland Park'
  ],
  AE: [
    'Apo',
    'Dpo',
    'Fpo'
  ],
  PA: [
    'Aliquippa',
    'Ambridge',
    'Atlasburg',
    'Baden',
    'Bairdford',
    'Bakerstown',
    'Beaver',
    'Beaver Falls',
    'Belle Vernon',
    'Brackenridge',
    'Bradfordwoods',
    'Bridgeville',
    'Buena Vista',
    'Bulger',
    'Bunola',
    'Burgettstown',
    'Charleroi',
    'Cheswick',
    'Clairton',
    'Clinton',
    'Conway',
    'Coulters',
    'Creighton',
    'Cuddy',
    'Curtisville',
    'Donora',
    'Dravosburg',
    'East Mc Keesport',
    'Elizabeth',
    'Elrama',
    'Freedom',
    'Georgetown',
    'Gibsonia',
    'Glassport',
    'Crescent',
    'Greenock',
    'Harwick',
    'Hookstown',
    'Indianola',
    'Industry',
    'Joffre',
    'Langeloth',
    'Lawrence',
    'Leetsdale',
    'Mc Donald',
    'Midland',
    'Midway',
    'Monaca',
    'Monessen',
    'Monongahela',
    'Morgan',
    'Natrona Heights',
    'New Brighton',
    'New Eagle',
    'New Kensington',
    'Oakdale',
    'Pricedale',
    'Rochester',
    'Rural Ridge',
    'Russellton',
    'Shippingport',
    'Slovan',
    'South Heights',
    'Sturgeon',
    'Sutersville',
    'Tarentum',
    'Trafford',
    'Warrendale',
    'Webster',
    'West Elizabeth',
    'West Newton',
    'Wexford',
    'Wildwood',
    'Allison Park',
    'Bethel Park',
    'Braddock',
    'Carnegie',
    'Coraopolis',
    'Duquesne',
    'East Pittsburgh',
    'Glenshaw',
    'Homestead',
    'West Mifflin',
    'Imperial',
    'Ingomar',
    'South Park',
    'Mckeesport',
    'Mc Kees Rocks',
    'North Versailles',
    'Oakmont',
    'Pitcairn',
    'Presto',
    'Sewickley',
    'Springdale',
    'Turtle Creek',
    'Monroeville',
    'Verona',
    'Wilmerding',
    'Pittsburgh',
    'Washington',
    'Aleppo',
    'Amity',
    'Avella',
    'Beallsville',
    'Bentleyville',
    'Bobtown',
    'Brave',
    'Canonsburg',
    'Carmichaels',
    'Cecil',
    'Clarksville',
    'Claysville',
    'Cokeburg',
    'Crucible',
    'Dilliner',
    'Prosperity',
    'Eighty Four',
    'Ellsworth',
    'Finleyville',
    'Fredericktown',
    'Garards Fort',
    'Gastonville',
    'Graysville',
    'Greensboro',
    'Hendersonville',
    'Hickory',
    'Holbrook',
    'Houston',
    'Jefferson',
    'Marianna',
    'Mather',
    'Meadow Lands',
    'Millsboro',
    'Mount Morris',
    'Muse',
    'Nemacolin',
    'New Freeport',
    'Nineveh',
    'Rices Landing',
    'Richeyville',
    'Rogersville',
    'Scenery Hill',
    'Southview',
    'Spraggs',
    'Strabane',
    'Sycamore',
    'Taylorstown',
    'Van Voorhis',
    'Venetia',
    'Vestaburg',
    'Waynesburg',
    'West Alexander',
    'West Finley',
    'Westland',
    'West Middletown',
    'Wind Ridge',
    'Uniontown',
    'Adah',
    'Addison',
    'Allenport',
    'Allison',
    'Brier Hill',
    'Brownfield',
    'Brownsville',
    'California',
    'Cardale',
    'Chalk Hill',
    'Chestnut Ridge',
    'Coal Center',
    'Confluence',
    'Connellsville',
    'Daisytown',
    'Dawson',
    'Denbo',
    'Dickerson Run',
    'Dunbar',
    'Dunlevy',
    'East Millsboro',
    'Elco',
    'Fairbank',
    'Fairchance',
    'Farmington',
    'Fayette City',
    'Gans',
    'Gibbon Glade',
    'Grindstone',
    'Hibbs',
    'Hiller',
    'Hopwood',
    'Indian Head',
    'Isabella',
    'Jacobs Creek',
    'Keisterville',
    'La Belle',
    'Lake Lynn',
    'Leckrone',
    'Leisenring',
    'Lemont Furnace',
    'Mc Clellandtown',
    'Markleysburg',
    'Martin',
    'Masontown',
    'Melcroft',
    'Merrittstown',
    'Mill Run',
    'Mount Braddock',
    'Newell',
    'New Geneva',
    'New Salem',
    'Normalville',
    'Ohiopyle',
    'Oliver',
    'Perryopolis',
    'Point Marion',
    'Republic',
    'Ronco',
    'Roscoe',
    'Smithfield',
    'Smithton',
    'Smock',
    'Star Junction',
    'Stockdale',
    'Uledi',
    'Ursina',
    'Vanderbilt',
    'Waltersburg',
    'West Leisenring',
    'White',
    'Wickhaven',
    'Somerset',
    'Hidden Valley',
    'Acosta',
    'Alum Bank',
    'Bedford',
    'Berlin',
    'Boswell',
    'Boynton',
    'Breezewood',
    'Buffalo Mills',
    'Clearville',
    'Crystal Spring',
    'Everett',
    'Fairhope',
    'Fishertown',
    'Fort Hill',
    'Friedens',
    'Garrett',
    'Gray',
    'Hyndman',
    'Jenners',
    'Jennerstown',
    'Kantner',
    'Listie',
    'Manns Choice',
    'Markleton',
    'Meyersdale',
    'New Baltimore',
    'New Paris',
    'Quecreek',
    'Rockwood',
    'Salisbury',
    'Schellsburg',
    'Shanksville',
    'Sipesville',
    'Springs',
    'Stoystown',
    'Wellersburg',
    'West Salisbury',
    'Greensburg',
    'Acme',
    'Adamsburg',
    'Alverton',
    'Apollo',
    'Ardara',
    'Armbrust',
    'Arona',
    'Avonmore',
    'Bovard',
    'Bradenville',
    'Calumet',
    'Champion',
    'Claridge',
    'Crabtree',
    'Darragh',
    'Delmont',
    'Derry',
    'Donegal',
    'East Vandergrift',
    'Everson',
    'Export',
    'Forbes Road',
    'Grapeville',
    'Hannastown',
    'Harrison City',
    'Herminie',
    'Hostetter',
    'Hunker',
    'Hutchinson',
    'Hyde Park',
    'Irwin',
    'Jeannette',
    'Jones Mills',
    'Larimer',
    'Latrobe',
    'Laughlintown',
    'Leechburg',
    'Ligonier',
    'Lowber',
    'Loyalhanna',
    'Luxor',
    'Madison',
    'Mammoth',
    'Manor',
    'Mount Pleasant',
    'Murrysville',
    'New Alexandria',
    'New Derry',
    'New Stanton',
    'North Apollo',
    'Norvelt',
    'Penn',
    'Pleasant Unity',
    'Rector',
    'Rillton',
    'Ruffs Dale',
    'Salina',
    'Saltsburg',
    'Schenley',
    'Scottdale',
    'Slickville',
    'Southwest',
    'Spring Church',
    'Stahlstown',
    'Tarrs',
    'United',
    'Vandergrift',
    'Wendel',
    'Westmoreland City',
    'Whitney',
    'Wyano',
    'Youngstown',
    'Youngwood',
    'Yukon',
    'Indiana',
    'Alverda',
    'Anita',
    'Arcadia',
    'Aultman',
    'Northern Cambria',
    'Big Run',
    'Black Lick',
    'Blairsville',
    'Brush Valley',
    'Burnside',
    'Carrolltown',
    'Chambersville',
    'Cherry Tree',
    'Clarksburg',
    'Clune',
    'Clymer',
    'Commodore',
    'Coolspring',
    'Coral',
    'Creekside',
    'De Lancey',
    'Dixonville',
    'Elderton',
    'Elmora',
    'Emeigh',
    'Ernest',
    'Frostburg',
    'Gipsy',
    'Glen Campbell',
    'Hamilton',
    'Heilwood',
    'Hillsdale',
    'Home',
    'Homer City',
    'Josephine',
    'Kent',
    'La Jose',
    'Lucernemines',
    'Mc Intyre',
    'Mahaffey',
    'Marchand',
    'Marion Center',
    'Marsteller',
    'Mentcle',
    'Nicktown',
    'Northpoint',
    'Oliveburg',
    'Penn Run',
    'Punxsutawney',
    'Ringgold',
    'Rochester Mills',
    'Rossiter',
    'Saint Benedict',
    'Shelocta',
    'Spangler',
    'Sprankle Mills',
    'Starford',
    'Timblin',
    'Torrance',
    'Valier',
    'Walston',
    'West Lebanon',
    'Worthville',
    'Du Bois',
    'Benezett',
    'Brandy Camp',
    'Brockport',
    'Brockway',
    'Brookville',
    'Byrnedale',
    'Clarington',
    'Corsica',
    'Dagus Mines',
    'Driftwood',
    'Emporium',
    'Falls Creek',
    'Force',
    'Johnsonburg',
    'Kersey',
    'Knox Dale',
    'Luthersburg',
    'Penfield',
    'Reynoldsville',
    'Ridgway',
    'Rockton',
    'Saint Marys',
    'Sigel',
    'Sinnamahoning',
    'Stump Creek',
    'Summerville',
    'Sykesville',
    'Troutville',
    'Weedville',
    'Wilcox',
    'Johnstown',
    'Armagh',
    'Beaverdale',
    'Belsano',
    'Bolivar',
    'Cairnbrook',
    'Cassandra',
    'Central City',
    'Colver',
    'Davidsville',
    'Dilltown',
    'Dunlo',
    'Ebensburg',
    'Elton',
    'Hollsopple',
    'Hooversville',
    'Jerome',
    'Lilly',
    'Loretto',
    'Mineral Point',
    'Nanty Glo',
    'New Florence',
    'Parkhill',
    'Portage',
    'Revloc',
    'Robinson',
    'Saint Michael',
    'Salix',
    'Seanor',
    'Seward',
    'Sidman',
    'South Fork',
    'Strongstown',
    'Summerhill',
    'Tire Hill',
    'Twin Rocks',
    'Vintondale',
    'Wilmore',
    'Windber',
    'Butler',
    'Boyers',
    'Branchton',
    'Bruin',
    'Cabot',
    'Callery',
    'Chicora',
    'Connoquenessing',
    'East Brady',
    'East Butler',
    'Eau Claire',
    'Evans City',
    'Fenelton',
    'Forestville',
    'Foxburg',
    'Harmony',
    'Harrisville',
    'Herman',
    'Hilliards',
    'Karns City',
    'Lyndora',
    'Mars',
    'North Washington',
    'Parker',
    'Petrolia',
    'Portersville',
    'Prospect',
    'Renfrew',
    'Saint Petersburg',
    'Sarver',
    'Saxonburg',
    'Slippery Rock',
    'Turkey City',
    'Valencia',
    'West Sunbury',
    'Zelienople',
    'Cranberry Township',
    'New Castle',
    'Adamsville',
    'Atlantic',
    'Bessemer',
    'Clark',
    'Clarks Mills',
    'Darlington',
    'Edinburg',
    'Ellwood City',
    'Enon Valley',
    'Farrell',
    'Fombell',
    'Fredonia',
    'Greenville',
    'Grove City',
    'Hadley',
    'Hartstown',
    'Hillsville',
    'Jackson Center',
    'Jamestown',
    'Koppel',
    'Mercer',
    'New Bedford',
    'New Galilee',
    'New Wilmington',
    'Pulaski',
    'Sandy Lake',
    'Sharon',
    'Hermitage',
    'Sharpsville',
    'Sheakleyville',
    'Stoneboro',
    'Transfer',
    'Villa Maria',
    'Volant',
    'Wampum',
    'West Middlesex',
    'West Pittsburg',
    'Wheatland',
    'Kittanning',
    'Adrian',
    'Beyer',
    'Cadogan',
    'Callensburg',
    'Clarion',
    'Cooksburg',
    'Cowansville',
    'Crown',
    'Curllsville',
    'Dayton',
    'Distant',
    'Fairmount City',
    'Fisher',
    'Ford City',
    'Ford Cliff',
    'Freeport',
    'Hawthorn',
    'Knox',
    'Leeper',
    'Limestone',
    'Lucinda',
    'Mc Grann',
    'Manorville',
    'Marienville',
    'Mayport',
    'New Bethlehem',
    'Nu Mine',
    'Oak Ridge',
    'Plumville',
    'Rimersburg',
    'Rural Valley',
    'Sagamore',
    'Seminole',
    'Shippenville',
    'Sligo',
    'Smicksburg',
    'Snydersburg',
    'Strattanville',
    'Templeton',
    'Vowinckel',
    'Widnoon',
    'Worthington',
    'Yatesboro',
    'Oil City',
    'Carlton',
    'Chandlers Valley',
    'Clarendon',
    'Cochranton',
    'Conneaut Lake',
    'Cooperstown',
    'Cranberry',
    'East Hickory',
    'Endeavor',
    'Franklin',
    'Fryburg',
    'Guys Mills',
    'Hydetown',
    'Irvine',
    'Kossuth',
    'Lickingville',
    'Ludlow',
    'Marble',
    'Meadville',
    'Pittsfield',
    'Pleasantville',
    'Polk',
    'Reno',
    'Rouseville',
    'Russell',
    'Seneca',
    'Sheffield',
    'Sugar Grove',
    'Tidioute',
    'Tiona',
    'Tionesta',
    'Titusville',
    'Townville',
    'Tylersburg',
    'Utica',
    'Venus',
    'Warren',
    'West Hickory',
    'Youngsville',
    'Clintonville',
    'Emlenton',
    'Kennerdell',
    'Lamartine',
    'Albion',
    'Bear Lake',
    'Cambridge Springs',
    'Centerville',
    'Columbus',
    'Conneautville',
    'Corry',
    'Cranesville',
    'East Springfield',
    'Edinboro',
    'Elgin',
    'Fairview',
    'Garland',
    'Girard',
    'Grand Valley',
    'Harborcreek',
    'Harmonsburg',
    'Lake City',
    'Linesville',
    'Mc Kean',
    'Mill Village',
    'North East',
    'North Springfield',
    'Riceville',
    'Saegertown',
    'Spartansburg',
    'Springboro',
    'Spring Creek',
    'Union City',
    'Venango',
    'Waterford',
    'Wattsburg',
    'West Springfield',
    'Erie',
    'Altoona',
    'Alexandria',
    'Ashville',
    'Beccaria',
    'Bellwood',
    'Blandburg',
    'Brisbin',
    'Broad Top',
    'Calvin',
    'Cassville',
    'Chest Springs',
    'Claysburg',
    'Coalport',
    'Coupon',
    'Cresson',
    'Curryville',
    'Defiance',
    'Dudley',
    'Duncansville',
    'Dysart',
    'East Freedom',
    'Entriken',
    'Fallentimber',
    'Flinton',
    'Gallitzin',
    'Glasgow',
    'Glen Hope',
    'Hastings',
    'Hesston',
    'Hollidaysburg',
    'Hopewell',
    'Houtzdale',
    'Huntingdon',
    'Imler',
    'Irvona',
    'James Creek',
    'Loysburg',
    'Mc Connellstown',
    'Madera',
    'Martinsburg',
    'Morann',
    'New Enterprise',
    'Newry',
    'Osceola Mills',
    'Osterburg',
    'Patton',
    'Petersburg',
    'Queen',
    'Ramey',
    'Riddlesburg',
    'Roaring Spring',
    'Robertsdale',
    'Saint Boniface',
    'Sandy Ridge',
    'Saxton',
    'Six Mile Run',
    'Smithmill',
    'Smokerun',
    'Sproul',
    'Spruce Creek',
    'Tipton',
    'Todd',
    'Tyrone',
    'Waterfall',
    'Wells Tannery',
    'Westover',
    'Williamsburg',
    'Wood',
    'Woodbury',
    'Bradford',
    'Austin',
    'Crosby',
    'Custer City',
    'Cyclone',
    'Derrick City',
    'De Young',
    'Duke Center',
    'East Smethport',
    'Eldred',
    'Gifford',
    'Hazel Hurst',
    'James City',
    'Kane',
    'Lewis Run',
    'Mount Jewett',
    'Port Allegany',
    'Rew',
    'Rixford',
    'Roulette',
    'Shinglehouse',
    'Smethport',
    'Turtlepoint',
    'State College',
    'University Park',
    'Aaronsburg',
    'Allport',
    'Beech Creek',
    'Bellefonte',
    'Bigler',
    'Blanchard',
    'Boalsburg',
    'Centre Hall',
    'Clarence',
    'Clearfield',
    'Coburn',
    'Curwensville',
    'Drifting',
    'Fleming',
    'Frenchville',
    'Glen Richey',
    'Grampian',
    'Grassflat',
    'Hawk Run',
    'Howard',
    'Hyde',
    'Julian',
    'Karthaus',
    'Kylertown',
    'Lamar',
    'Lanse',
    'Lecontes Mills',
    'Lemont',
    'Madisonburg',
    'Milesburg',
    'Millheim',
    'Mineral Springs',
    'Mingoville',
    'Morrisdale',
    'Moshannon',
    'Munson',
    'New Millport',
    'Olanta',
    'Orviston',
    'Pennsylvania Furnace',
    'Philipsburg',
    'Pine Grove Mills',
    'Port Matilda',
    'Pottersdale',
    'Rebersburg',
    'Shawville',
    'Snow Shoe',
    'Spring Mills',
    'Wallaceton',
    'Warriors Mark',
    'West Decatur',
    'Winburne',
    'Woodland',
    'Woodward',
    'Wellsboro',
    'Alba',
    'Arnot',
    'Blossburg',
    'Columbia Cross Roads',
    'Coudersport',
    'Covington',
    'Cowanesque',
    'Elkland',
    'Gaines',
    'Galeton',
    'Genesee',
    'Gillett',
    'Granville Summit',
    'Harrison Valley',
    'Knoxville',
    'Lawrenceville',
    'Liberty',
    'Mainesburg',
    'Mansfield',
    'Middlebury Center',
    'Millerton',
    'Mills',
    'Morris',
    'Morris Run',
    'Nelson',
    'Osceola',
    'Sabinsville',
    'Sylvania',
    'Tioga',
    'Troy',
    'Ulysses',
    'Westfield',
    'Camp Hill',
    'Allensville',
    'Annville',
    'Belleville',
    'Berrysburg',
    'Blain',
    'Boiling Springs',
    'Bowmansdale',
    'Burnham',
    'Campbelltown',
    'Carlisle',
    'Cocolamus',
    'Cornwall',
    'Dalmatia',
    'Dauphin',
    'Dillsburg',
    'Duncannon',
    'East Waterford',
    'Elizabethtown',
    'Elizabethville',
    'Elliottsburg',
    'Enola',
    'Fredericksburg',
    'Grantham',
    'Grantville',
    'Granville',
    'Gratz',
    'Halifax',
    'Hershey',
    'Highspire',
    'Honey Grove',
    'Hummelstown',
    'Ickesburg',
    'Jonestown',
    'Kleinfeltersville',
    'Landisburg',
    'Lawn',
    'Lebanon',
    'Lemoyne',
    'Lewistown',
    'Liverpool',
    'Loysville',
    'Lykens',
    'Mc Alisterville',
    'Mechanicsburg',
    'Mc Veytown',
    'Mapleton Depot',
    'Marysville',
    'Mattawana',
    'Mexico',
    'Middletown',
    'Mifflin',
    'Mifflintown',
    'Mill Creek',
    'Millersburg',
    'Millerstown',
    'Milroy',
    'Mount Gretna',
    'Mount Holly Springs',
    'Mount Union',
    'Myerstown',
    'New Bloomfield',
    'New Buffalo',
    'New Cumberland',
    'New Germantown',
    'New Kingstown',
    'Newmanstown',
    'Newport',
    'Newton Hamilton',
    'Oakland Mills',
    'Ono',
    'Palmyra',
    'Pillow',
    'Plainfield',
    'Port Royal',
    'Quentin',
    'Reedsville',
    'Rexmont',
    'Richfield',
    'Richland',
    'Schaefferstown',
    'Shermans Dale',
    'Summerdale',
    'Thompsontown',
    'Wiconisco',
    'Williamstown',
    'Yeagertown',
    'Harrisburg',
    'Chambersburg',
    'Amberson',
    'Artemas',
    'Big Cove Tannery',
    'Blairs Mills',
    'Blue Ridge Summit',
    'Burnt Cabins',
    'Concord',
    'Doylesburg',
    'Dry Run',
    'Fannettsburg',
    'Fayetteville',
    'Fort Littleton',
    'Fort Loudon',
    'Greencastle',
    'Harrisonville',
    'Hustontown',
    'Lemasters',
    'Lurgan',
    'Mc Connellsburg',
    'Marion',
    'Mercersburg',
    'Mont Alto',
    'Needmore',
    'Neelyton',
    'Newburg',
    'Newville',
    'Orbisonia',
    'Orrstown',
    'Pleasant Hall',
    'Quincy',
    'Rockhill Furnace',
    'Rouzerville',
    'Roxbury',
    'Saint Thomas',
    'Saltillo',
    'Scotland',
    'Shade Gap',
    'Shady Grove',
    'Shippensburg',
    'Shirleysburg',
    'South Mountain',
    'Spring Run',
    'State Line',
    'Three Springs',
    'Upperstrasburg',
    'Walnut Bottom',
    'Warfordsburg',
    'Waynesboro',
    'Williamson',
    'Willow Hill',
    'Zullinger',
    'Abbottstown',
    'Airville',
    'Arendtsville',
    'Aspers',
    'Bendersville',
    'Biglerville',
    'Brogue',
    'Cashtown',
    'Codorus',
    'Craley',
    'Dallastown',
    'Delta',
    'Dover',
    'East Berlin',
    'East Prospect',
    'Emigsville',
    'Etters',
    'Fairfield',
    'Fawn Grove',
    'Felton',
    'Franklintown',
    'Gardners',
    'Gettysburg',
    'Glen Rock',
    'Glenville',
    'Hanover',
    'Idaville',
    'Lewisberry',
    'Littlestown',
    'Loganville',
    'Mc Knightstown',
    'Mc Sherrystown',
    'Manchester',
    'Mount Wolf',
    'New Freedom',
    'New Oxford',
    'New Park',
    'Orrtanna',
    'Porters Sideling',
    'Railroad',
    'Red Lion',
    'Rossville',
    'Seven Valleys',
    'Shrewsbury',
    'Spring Grove',
    'Stewartstown',
    'Thomasville',
    'Wellsville',
    'Windsor',
    'Wrightsville',
    'York Haven',
    'York New Salem',
    'York Springs',
    'Peach Glen',
    'York',
    'Akron',
    'Bainbridge',
    'Bart',
    'Bausman',
    'Bird In Hand',
    'Blue Ball',
    'Bowmansville',
    'Brownstown',
    'Christiana',
    'Columbia',
    'Conestoga',
    'Denver',
    'Drumore',
    'East Earl',
    'East Petersburg',
    'Elm',
    'Ephrata',
    'Gap',
    'Goodville',
    'Gordonville',
    'Holtwood',
    'Hopeland',
    'Intercourse',
    'Kinzers',
    'Kirkwood',
    'Lampeter',
    'Landisville',
    'Leola',
    'Lititz',
    'Manheim',
    'Marietta',
    'Martindale',
    'Maytown',
    'Millersville',
    'Mount Joy',
    'Mountville',
    'Narvon',
    'New Holland',
    'New Providence',
    'Paradise',
    'Peach Bottom',
    'Penryn',
    'Pequea',
    'Quarryville',
    'Reamstown',
    'Refton',
    'Reinholds',
    'Rheems',
    'Ronks',
    'Lancaster',
    'Silver Spring',
    'Smoketown',
    'Stevens',
    'Strasburg',
    'Talmage',
    'Terre Hill',
    'Washington Boro',
    'West Willow',
    'Willow Street',
    'Witmer',
    'Williamsport',
    'Antes Fort',
    'Avis',
    'Cammal',
    'Canton',
    'Castanea',
    'Cedar Run',
    'Cogan Station',
    'Cross Fork',
    'Dewart',
    'Eagles Mere',
    'Grover',
    'Hughesville',
    'North Bend',
    'Jersey Mills',
    'Jersey Shore',
    'Lairdsville',
    'Linden',
    'Lock Haven',
    'Loganton',
    'Mc Elhattan',
    'Mc Ewensville',
    'Mackeyville',
    'Mill Hall',
    'Montgomery',
    'Montoursville',
    'Muncy',
    'Muncy Valley',
    'Picture Rocks',
    'Ralston',
    'Renovo',
    'Roaring Branch',
    'Salona',
    'Shunk',
    'Slate Run',
    'Trout Run',
    'Turbotville',
    'Tylersville',
    'Unityville',
    'Waterville',
    'Watsontown',
    'Westport',
    'Woolrich',
    'Sunbury',
    'Allenwood',
    'Beaver Springs',
    'Beavertown',
    'Benton',
    'Bloomsburg',
    'Catawissa',
    'Danville',
    'Dornsife',
    'Elysburg',
    'Freeburg',
    'Hartleton',
    'Herndon',
    'Hummels Wharf',
    'Marion Heights',
    'Kreamer',
    'Kulpmont',
    'Laurelton',
    'Leck Kill',
    'Lewisburg',
    'Lightstreet',
    'Locust Gap',
    'Mc Clure',
    'Middleburg',
    'Mifflinburg',
    'Millmont',
    'Millville',
    'Milton',
    'Montandon',
    'Mount Carmel',
    'Mount Pleasant Mills',
    'New Berlin',
    'New Columbia',
    'Northumberland',
    'Numidia',
    'Orangeville',
    'Paxinos',
    'Paxtonville',
    'Penns Creek',
    'Port Trevorton',
    'Potts Grove',
    'Coal Township',
    'Rebuck',
    'Riverside',
    'Selinsgrove',
    'Shamokin',
    'Shamokin Dam',
    'Snydertown',
    'Stillwater',
    'Swengel',
    'Trevorton',
    'Troxelville',
    'Vicksburg',
    'Washingtonville',
    'Weikert',
    'West Milton',
    'White Deer',
    'Wilburton',
    'Winfield',
    'Pottsville',
    'Aristes',
    'Ashland',
    'Auburn',
    'Branchdale',
    'Brockton',
    'Cressona',
    'Cumbola',
    'Frackville',
    'Friedensburg',
    'Gilberton',
    'Girardville',
    'Gordon',
    'Hegins',
    'Klingerstown',
    'Landingville',
    'Lavelle',
    'Llewellyn',
    'Locustdale',
    'Lost Creek',
    'Mahanoy City',
    'Mahanoy Plane',
    'Mar Lin',
    'Mary D',
    'Middleport',
    'Minersville',
    'Muir',
    'New Philadelphia',
    'New Ringgold',
    'Orwigsburg',
    'Pine Grove',
    'Pitman',
    'Port Carbon',
    'Ravine',
    'Ringtown',
    'Sacramento',
    'Saint Clair',
    'Schuylkill Haven',
    'Seltzer',
    'Shenandoah',
    'Spring Glen',
    'Summit Station',
    'Tower City',
    'Tremont',
    'Tuscarora',
    'Valley View',
    'Zion Grove',
    'Lehigh Valley',
    'Ackermanville',
    'Alburtis',
    'Aquashicola',
    'Bangor',
    'Bath',
    'Bethlehem',
    'Bowmanstown',
    'Breinigsville',
    'Catasauqua',
    'Center Valley',
    'Cherryville',
    'Coopersburg',
    'Coplay',
    'Danielsville',
    'Durham',
    'Easton',
    'East Greenville',
    'East Texas',
    'Emmaus',
    'Flicksville',
    'Fogelsville',
    'Whitehall',
    'Germansville',
    'Green Lane',
    'Hellertown',
    'Hereford',
    'Kunkletown',
    'Laurys Station',
    'Limeport',
    'Macungie',
    'Martins Creek',
    'Nazareth',
    'Neffs',
    'New Tripoli',
    'Northampton',
    'Old Zionsville',
    'Orefield',
    'Palm',
    'Palmerton',
    'Pen Argyl',
    'Pennsburg',
    'Perkiomenville',
    'Red Hill',
    'Riegelsville',
    'Schnecksville',
    'Slatedale',
    'Slatington',
    'Springtown',
    'Stockertown',
    'Sumneytown',
    'Tatamy',
    'Treichlers',
    'Trexlertown',
    'Walnutport',
    'Wind Gap',
    'Zionsville',
    'Allentown',
    'Hazleton',
    'Albrightsville',
    'Andreas',
    'Ashfield',
    'Barnesville',
    'Beaver Meadows',
    'Coaldale',
    'Conyngham',
    'Delano',
    'Drifton',
    'Drums',
    'Ebervale',
    'Freeland',
    'Harleigh',
    'Jim Thorpe',
    'Junedale',
    'Kelayres',
    'Lansford',
    'Lattimer Mines',
    'Lehighton',
    'Mcadoo',
    'Milnesville',
    'Nesquehoning',
    'Nuremberg',
    'Oneida',
    'Parryville',
    'Quakake',
    'Rock Glen',
    'Saint Johns',
    'Sheppton',
    'Sugarloaf',
    'Summit Hill',
    'Sybertsville',
    'Tamaqua',
    'Tresckow',
    'Weatherly',
    'Weston',
    'East Stroudsburg',
    'Analomink',
    'Bartonsville',
    'Brodheadsville',
    'Buck Hill Falls',
    'Bushkill',
    'Canadensis',
    'Cresco',
    'Delaware Water Gap',
    'Dingmans Ferry',
    'Effort',
    'Gilbert',
    'Henryville',
    'Kresgeville',
    'Long Pond',
    'Marshalls Creek',
    'Matamoras',
    'Milford',
    'Millrift',
    'Minisink Hills',
    'Mountainhome',
    'Mount Bethel',
    'Mount Pocono',
    'Pocono Summit',
    'Pocono Lake',
    'Pocono Lake Preserve',
    'Pocono Manor',
    'Pocono Pines',
    'Portland',
    'Reeders',
    'Saylorsburg',
    'Sciota',
    'Scotrun',
    'Shawnee On Delaware',
    'Skytop',
    'Stroudsburg',
    'Swiftwater',
    'Tamiment',
    'Tannersville',
    'Unity House',
    'Archbald',
    'Beach Lake',
    'Carbondale',
    'Chinchilla',
    'Clarks Summit',
    'Clifford',
    'Dalton',
    'Damascus',
    'Elmhurst',
    'Equinunk',
    'Factoryville',
    'Fleetville',
    'Forest City',
    'Gouldsboro',
    'Greeley',
    'Greentown',
    'Hamlin',
    'Hawley',
    'Herrick Center',
    'Honesdale',
    'Jermyn',
    'Jessup',
    'Lackawaxen',
    'Lake Ariel',
    'Lake Como',
    'Lakeville',
    'Lakewood',
    'La Plume',
    'Lenoxville',
    'Milanville',
    'Moscow',
    'Newfoundland',
    'Nicholson',
    'Olyphant',
    'Orson',
    'Paupack',
    'Peckville',
    'Pleasant Mount',
    'Poyntelle',
    'Preston Park',
    'Prompton',
    'Rowland',
    'Shohola',
    'South Canaan',
    'South Sterling',
    'Starlight',
    'Starrucca',
    'Sterling',
    'Tafton',
    'Thompson',
    'Tobyhanna',
    'Tyler Hill',
    'Union Dale',
    'Waverly',
    'Waymart',
    'White Mills',
    'Scranton',
    'Moosic',
    'Taylor',
    'Old Forge',
    'Beach Haven',
    'Bear Creek',
    'Berwick',
    'Blakeslee',
    'Cambra',
    'Dallas',
    'Dushore',
    'Falls',
    'Forksville',
    'Glen Lyon',
    'Harveys Lake',
    'Hillsgrove',
    'Hunlock Creek',
    'Huntington Mills',
    'Laceyville',
    'Lake Harmony',
    'Lake Winola',
    'Laporte',
    'Lehman',
    'Lopez',
    'Mehoopany',
    'Meshoppen',
    'Mifflinville',
    'Mildred',
    'Nanticoke',
    'Nescopeck',
    'Noxen',
    'Pittston',
    'Duryea',
    'Wyoming',
    'Plymouth',
    'Ransom',
    'Shawanese',
    'Shickshinny',
    'Sweet Valley',
    'Tunkhannock',
    'Wapwallopen',
    'White Haven',
    'Wilkes Barre',
    'Kingston',
    'Mountain Top',
    'Shavertown',
    'Luzerne',
    'Montrose',
    'Athens',
    'Brackney',
    'Brooklyn',
    'Burlington',
    'Camptown',
    'Dimock',
    'East Smithfield',
    'Friendsville',
    'Gibson',
    'Great Bend',
    'Hallstead',
    'Harford',
    'Hop Bottom',
    'Jackson',
    'Kingsley',
    'Lanesboro',
    'Lawton',
    'Le Raysville',
    'Little Meadows',
    'Milan',
    'Monroeton',
    'New Albany',
    'New Milford',
    'Rome',
    'Sayre',
    'South Gibson',
    'South Montrose',
    'Springville',
    'Stevensville',
    'Sugar Run',
    'Susquehanna',
    'Towanda',
    'Ulster',
    'Warren Center',
    'Wyalusing',
    'Wysox',
    'Doylestown',
    'Bedminster',
    'Blooming Glen',
    'Buckingham',
    'Carversville',
    'Chalfont',
    'Colmar',
    'Danboro',
    'Dublin',
    'Earlington',
    'Erwinna',
    'Ferndale',
    'Forest Grove',
    'Fountainville',
    'Franconia',
    'Furlong',
    'Gardenville',
    'Hilltown',
    'Holicong',
    'Jamison',
    'Kintnersville',
    'Lahaska',
    'Line Lexington',
    'Lumberville',
    'Mechanicsville',
    'Milford Square',
    'Montgomeryville',
    'New Hope',
    'Newtown',
    'Ottsville',
    'Penns Park',
    'Perkasie',
    'Pineville',
    'Pipersville',
    'Plumsteadville',
    'Point Pleasant',
    'Quakertown',
    'Revere',
    'Richboro',
    'Richlandtown',
    'Rushland',
    'Salford',
    'Salfordville',
    'Sellersville',
    'Silverdale',
    'Solebury',
    'Souderton',
    'Southampton',
    'Spinnerstown',
    'Telford',
    'Trumbauersville',
    'Tylersport',
    'Upper Black Eddy',
    'Warminster',
    'Warrington',
    'Washington Crossing',
    'Woxall',
    'Wycombe',
    'Zionhill',
    'Abington',
    'Ambler',
    'Ardmore',
    'Bala Cynwyd',
    'Huntingdon Valley',
    'Bristol',
    'Broomall',
    'Bryn Athyn',
    'Bryn Mawr',
    'Cheltenham',
    'Chester',
    'Aston',
    'Brookhaven',
    'Chester Heights',
    'Clifton Heights',
    'Philadelphia',
    'Bensalem',
    'Croydon',
    'Crum Lynne',
    'Darby',
    'Dresher',
    'Drexel Hill',
    'Elkins Park',
    'Edgemont',
    'Essington',
    'Fairless Hills',
    'Flourtown',
    'Folcroft',
    'Folsom',
    'Fort Washington',
    'Gladwyne',
    'Glenolden',
    'Glen Riddle Lima',
    'Glenside',
    'Gradyville',
    'Hatboro',
    'Haverford',
    'Holmes',
    'Horsham',
    'Jenkintown',
    'Langhorne',
    'Lansdowne',
    'Lenni',
    'Feasterville Trevose',
    'Levittown',
    'Garnet Valley',
    'Marcus Hook',
    'Media',
    'Springfield',
    'Merion Station',
    'Morrisville',
    'Morton',
    'Narberth',
    'Newtown Square',
    'Norwood',
    'Oreland',
    'Prospect Park',
    'Ridley Park',
    'Sharon Hill',
    'Wayne',
    'Swarthmore',
    'Upper Darby',
    'Havertown',
    'Villanova',
    'Wallingford',
    'Willow Grove',
    'Woodlyn',
    'Wyncote',
    'Wynnewood',
    'Paoli',
    'Atglen',
    'Avondale',
    'Berwyn',
    'Brandamore',
    'Chadds Ford',
    'Chatham',
    'Cheyney',
    'Coatesville',
    'Cochranville',
    'Concordville',
    'Devon',
    'Downingtown',
    'Exton',
    'Glen Mills',
    'Glenmoore',
    'Honey Brook',
    'Immaculata',
    'Kelton',
    'Kemblesville',
    'Kennett Square',
    'Landenberg',
    'Lewisville',
    'Lincoln University',
    'Lionville',
    'Lyndell',
    'Malvern',
    'Mendenhall',
    'Modena',
    'New London',
    'Nottingham',
    'Oxford',
    'Parkesburg',
    'Pocopson',
    'Pomeroy',
    'Sadsburyville',
    'Suplee',
    'Thorndale',
    'Thornton',
    'Toughkenamon',
    'Unionville',
    'Wagontown',
    'West Chester',
    'West Grove',
    'Westtown',
    'Southeastern',
    'Norristown',
    'Bridgeport',
    'King Of Prussia',
    'Audubon',
    'Eagleville',
    'Fairview Village',
    'Arcola',
    'Birchrunville',
    'Blue Bell',
    'Cedars',
    'Chester Springs',
    'Collegeville',
    'Conshohocken',
    'Creamery',
    'Devault',
    'Frederick',
    'Gwynedd',
    'Gwynedd Valley',
    'Harleysville',
    'Hatfield',
    'Kimberton',
    'Kulpsville',
    'Lafayette Hill',
    'Lansdale',
    'Lederach',
    'Mainland',
    'Mont Clare',
    'North Wales',
    'Oaks',
    'Parker Ford',
    'Phoenixville',
    'Plymouth Meeting',
    'Pottstown',
    'Royersford',
    'Saint Peters',
    'Sassamansville',
    'Schwenksville',
    'Skippack',
    'Spring City',
    'Spring House',
    'Spring Mount',
    'Uwchland',
    'Valley Forge',
    'West Point',
    'Worcester',
    'Zieglerville',
    'Adamstown',
    'Bally',
    'Barto',
    'Bechtelsville',
    'Bernville',
    'Bethel',
    'Birdsboro',
    'Blandon',
    'Bowers',
    'Boyertown',
    'Centerport',
    'Douglassville',
    'Earlville',
    'Elverson',
    'Fleetwood',
    'Geigertown',
    'Gilbertsville',
    'Hamburg',
    'Kempton',
    'Kutztown',
    'Leesport',
    'Lenhartsville',
    'Limekiln',
    'Lyon Station',
    'Maxatawny',
    'Mertztown',
    'Mohnton',
    'Mohrsville',
    'Monocacy Station',
    'Morgantown',
    'Mount Aetna',
    'New Berlinville',
    'Oley',
    'Pine Forge',
    'Port Clinton',
    'Rehrersburg',
    'Robesonia',
    'Shartlesville',
    'Shoemakersville',
    'Strausstown',
    'Temple',
    'Topton',
    'Virginville',
    'Wernersville',
    'Womelsdorf',
    'Reading'
  ],
  DE: [
    'Bear',
    'Newark',
    'Claymont',
    'Delaware City',
    'Hockessin',
    'Kirkwood',
    'Middletown',
    'Montchanin',
    'New Castle',
    'Odessa',
    'Port Penn',
    'Rockland',
    'Saint Georges',
    'Townsend',
    'Winterthur',
    'Yorklyn',
    'Wilmington',
    'Dover',
    'Dover AFB',
    'Bethany Beach',
    'Bethel',
    'Bridgeville',
    'Camden Wyoming',
    'Cheswold',
    'Clayton',
    'Dagsboro',
    'Delmar',
    'Ellendale',
    'Felton',
    'Fenwick Island',
    'Frankford',
    'Frederica',
    'Georgetown',
    'Greenwood',
    'Harbeson',
    'Harrington',
    'Hartly',
    'Houston',
    'Kenton',
    'Laurel',
    'Lewes',
    'Lincoln',
    'Little Creek',
    'Magnolia',
    'Milford',
    'Marydel',
    'Millsboro',
    'Millville',
    'Milton',
    'Nassau',
    'Ocean View',
    'Rehoboth Beach',
    'Seaford',
    'Selbyville',
    'Smyrna',
    'Viola',
    'Woodside'
  ],
  DC: [
    'Washington',
    'Naval Anacost Annex',
    'Washington Navy Yard',
    'Parcel Return Service',
    'North Pole'
  ],
  VA: [
    'Dulles',
    'Aldie',
    'Amissville',
    'Arcola',
    'Manassas',
    'Marshall',
    'Middleburg',
    'Catlett',
    'Centreville',
    'Clifton',
    'Orlean',
    'Paeonian Springs',
    'Paris',
    'Philomont',
    'Purcellville',
    'Bluemont',
    'Bristow',
    'Broad Run',
    'Calverton',
    'Casanova',
    'Rectortown',
    'Round Hill',
    'Catharpin',
    'Delaplane',
    'Ashburn',
    'Chantilly',
    'Gainesville',
    'Hamilton',
    'Lincoln',
    'Sterling',
    'Haymarket',
    'Herndon',
    'Leesburg',
    'Lovettsville',
    'Nokesville',
    'Upperville',
    'Warrenton',
    'Reston',
    'Waterford',
    'The Plains',
    'Dhs',
    'Annandale',
    'Burke',
    'Dumfries',
    'Dunn Loring',
    'Fairfax',
    'Fairfax Station',
    'Falls Church',
    'Fort Belvoir',
    'Great Falls',
    'Greenway',
    'Lorton',
    'Merrifield',
    'Mc Lean',
    'West Mclean',
    'Mount Vernon',
    'Newington',
    'Oakton',
    'Occoquan',
    'Quantico',
    'Springfield',
    'Triangle',
    'Vienna',
    'Woodbridge',
    'Arlington',
    'Fort Myer',
    'Alexandria',
    'Fredericksburg',
    'Bowling Green',
    'Brooke',
    'Burgess',
    'Burr Hill',
    'Callao',
    'Caret',
    'Center Cross',
    'Champlain',
    'Coles Point',
    'Colonial Beach',
    'Corbin',
    'Dahlgren',
    'Dogue',
    'Dunnsville',
    'Edwardsville',
    'Farnham',
    'Garrisonville',
    'Hague',
    'Hartwood',
    'Haynesville',
    'Heathsville',
    'Hustle',
    'Irvington',
    'Jersey',
    'Kilmarnock',
    'King George',
    'Kinsale',
    'Ladysmith',
    'Lancaster',
    'Laneview',
    'Lively',
    'Locust Grove',
    'Loretto',
    'Lottsburg',
    'Merry Point',
    'Milford',
    'Mollusk',
    'Montross',
    'Morattico',
    'Mount Holly',
    'Ninde',
    'Nuttsville',
    'Oldhams',
    'Ophelia',
    'Partlow',
    'Port Royal',
    'Rappahannock Academy',
    'Reedville',
    'Rhoadesville',
    'Rollins Fork',
    'Ruby',
    'Ruther Glen',
    'Sealston',
    'Sharps',
    'Spotsylvania',
    'Sparta',
    'Stafford',
    'Stratford',
    'Tappahannock',
    'Thornburg',
    'Unionville',
    'Village',
    'Warsaw',
    'Weems',
    'Sandy Point',
    'White Stone',
    'Wicomico Church',
    'Woodford',
    'Zacata',
    'Winchester',
    'Bentonville',
    'Berryville',
    'Boyce',
    'Brucetown',
    'Chester Gap',
    'Clear Brook',
    'Cross Junction',
    'Fishers Hill',
    'Flint Hill',
    'Front Royal',
    'Gore',
    'Hume',
    'Huntly',
    'Strasburg',
    'Linden',
    'Markham',
    'Maurertown',
    'Middletown',
    'Millwood',
    'Rileyville',
    'Fort Valley',
    'Star Tannery',
    'Stephens City',
    'Stephenson',
    'Toms Brook',
    'White Post',
    'Woodstock',
    'Culpeper',
    'Aroda',
    'Banco',
    'Bealeton',
    'Boston',
    'Brandy Station',
    'Brightwood',
    'Castleton',
    'Elkwood',
    'Etlan',
    'Goldvein',
    'Graves Mill',
    'Haywood',
    'Hood',
    'Jeffersonton',
    'Leon',
    'Lignum',
    'Madison',
    'Midland',
    'Mitchells',
    'Oakpark',
    'Pratts',
    'Radiant',
    'Rapidan',
    'Remington',
    'Reva',
    'Richardsville',
    'Rixeyville',
    'Rochelle',
    'Somerville',
    'Sperryville',
    'Stevensburg',
    'Sumerduck',
    'Syria',
    'Viewtown',
    'Washington',
    'Wolftown',
    'Woodville',
    'Harrisonburg',
    'Basye',
    'Bergton',
    'Bridgewater',
    'Broadway',
    'Criders',
    'Dayton',
    'Edinburg',
    'Elkton',
    'Fulks Run',
    'Hinton',
    'Keezletown',
    'Lacey Spring',
    'Linville',
    'Luray',
    'Mc Gaheysville',
    'Mount Crawford',
    'Mount Jackson',
    'Mount Solon',
    'New Market',
    'Orkney Springs',
    'Penn Laird',
    'Quicksburg',
    'Pleasant Valley',
    'Shenandoah',
    'Singers Glen',
    'Stanley',
    'Timberville',
    'Charlottesville',
    'Afton',
    'Arrington',
    'Barboursville',
    'Batesville',
    'Covesville',
    'Crozet',
    'Dyke',
    'Earlysville',
    'Esmont',
    'Faber',
    'Fishersville',
    'Free Union',
    'Gordonsville',
    'Greenwood',
    'Ivy',
    'Keene',
    'Keswick',
    'Locust Dale',
    'Lovingston',
    'Lyndhurst',
    'Montpelier Station',
    'Nellysford',
    'North Garden',
    'Orange',
    'Palmyra',
    'Piney River',
    'Quinque',
    'Roseland',
    'Ruckersville',
    'Schuyler',
    'Shipman',
    'Somerset',
    'Stanardsville',
    'Troy',
    'Tyro',
    'Waynesboro',
    'White Hall',
    'Woodberry Forest',
    'Achilles',
    'Amelia Court House',
    'Ark',
    'Arvonia',
    'Ashland',
    'Aylett',
    'Barhamsville',
    'Beaumont',
    'Beaverdam',
    'Bena',
    'Bohannon',
    'Bremo Bluff',
    'Bruington',
    'Bumpass',
    'Cardinal',
    'Cartersville',
    'Charles City',
    'Christchurch',
    'Church View',
    'Cobbs Creek',
    'Columbia',
    'Crozier',
    'Cumberland',
    'Deltaville',
    'Diggs',
    'Doswell',
    'Dutton',
    'Fork Union',
    'Foster',
    'Glen Allen',
    'Gloucester',
    'Gloucester Point',
    'Goochland',
    'Grimstead',
    'Gum Spring',
    'Gwynn',
    'Hadensville',
    'Hallieford',
    'Hanover',
    'Hardyville',
    'Hartfield',
    'Hayes',
    'Henrico',
    'Hudgins',
    'Jamaica',
    'Jamestown',
    'Jetersville',
    'Kents Store',
    'King And Queen Court House',
    'King William',
    'Lanexa',
    'Lightfoot',
    'Little Plymouth',
    'Locust Hill',
    'Louisa',
    'Macon',
    'Maidens',
    'Manakin Sabot',
    'Mannboro',
    'Manquin',
    'Maryus',
    'Mascot',
    'Mathews',
    'Mattaponi',
    'Mechanicsville',
    'Midlothian',
    'Millers Tavern',
    'Mineral',
    'Moon',
    'Moseley',
    'New Canton',
    'New Kent',
    'New Point',
    'Newtown',
    'Norge',
    'North',
    'Oilville',
    'Onemo',
    'Ordinary',
    'Port Haywood',
    'Powhatan',
    'Providence Forge',
    'Quinton',
    'Rockville',
    'Ruthville',
    'Saint Stephens Church',
    'Saluda',
    'Sandston',
    'Sandy Hook',
    'Schley',
    'Severn',
    'Shacklefords',
    'State Farm',
    'Stevensville',
    'Studley',
    'Susan',
    'Toano',
    'Topping',
    'Trevilians',
    'Richmond',
    'Urbanna',
    'Wake',
    'Walkerton',
    'Ware Neck',
    'Water View',
    'West Point',
    'White Marsh',
    'Wicomico',
    'Williamsburg',
    'Woods Cross Roads',
    'Montpelier',
    'Accomac',
    'Assawoman',
    'Atlantic',
    'Battery Park',
    'Belle Haven',
    'Birdsnest',
    'Bloxom',
    'Cape Charles',
    'Capeville',
    'Carrollton',
    'Carrsville',
    'Cheriton',
    'Chesapeake',
    'Chincoteague Island',
    'Wallops Island',
    'Craddockville',
    'Davis Wharf',
    'Eastville',
    'Exmore',
    'Franktown',
    'Greenbackville',
    'Greenbush',
    'Hacksneck',
    'Hallwood',
    'Harborton',
    'Horntown',
    'Oak Hall',
    'Isle Of Wight',
    'Jamesville',
    'Jenkins Bridge',
    'Keller',
    'Locustville',
    'Machipongo',
    'Mappsville',
    'Marionville',
    'Mears',
    'Melfa',
    'Modest Town',
    'Nassawadox',
    'Nelsonia',
    'New Church',
    'Onancock',
    'Onley',
    'Oyster',
    'Painter',
    'Parksley',
    'Pungoteague',
    'Quinby',
    'Rescue',
    'Sanford',
    'Saxis',
    'Seaview',
    'Smithfield',
    'Suffolk',
    'Tangier',
    'Tasley',
    'Temperanceville',
    'Townsend',
    'Virginia Beach',
    'Wachapreague',
    'Wardtown',
    'Wattsville',
    'Willis Wharf',
    'Windsor',
    'Withams',
    'Norfolk',
    'Newport News',
    'Fort Eustis',
    'Hampton',
    'Fort Monroe',
    'Poquoson',
    'Yorktown',
    'Lackey',
    'Seaford',
    'Portsmouth',
    'Fort Lee',
    'Petersburg',
    'Virginia State University',
    'Alberta',
    'Ammon',
    'Blackstone',
    'Boykins',
    'Branchville',
    'Capron',
    'Carson',
    'Chester',
    'Chesterfield',
    'Church Road',
    'Colonial Heights',
    'Courtland',
    'Dendron',
    'Dewitt',
    'Dinwiddie',
    'Disputanta',
    'Dolphin',
    'Drewryville',
    'Ebony',
    'Elberon',
    'Emporia',
    'Ford',
    'Franklin',
    'Freeman',
    'Gasburg',
    'Hopewell',
    'Ivor',
    'Jarratt',
    'Lawrenceville',
    'Mc Kenney',
    'Meredithville',
    'Newsoms',
    'Prince George',
    'Rawlings',
    'Sedley',
    'Skippers',
    'Spring Grove',
    'Stony Creek',
    'Surry',
    'Sussex',
    'Sutherland',
    'Valentines',
    'Wakefield',
    'Warfield',
    'Waverly',
    'White Plains',
    'Wilsons',
    'Yale',
    'Zuni',
    'Claremont',
    'Farmville',
    'Baskerville',
    'Boydton',
    'Bracey',
    'Brodnax',
    'Buckingham',
    'Burkeville',
    'Charlotte Court House',
    'Chase City',
    'Clarksville',
    'Crewe',
    'Cullen',
    'Dillwyn',
    'Drakes Branch',
    'Dundas',
    'Evergreen',
    'Fort Mitchell',
    'Green Bay',
    'Hampden Sydney',
    'Kenbridge',
    'Keysville',
    'La Crosse',
    'Lunenburg',
    'Meherrin',
    'Nottoway',
    'Pamplin',
    'Phenix',
    'Prospect',
    'Randolph',
    'Red House',
    'Red Oak',
    'Rice',
    'Saxe',
    'Skipwith',
    'South Hill',
    'Victoria',
    'Wylliesburg',
    'Roanoke',
    'Ararat',
    'Axton',
    'Bassett',
    'Belspring',
    'Bent Mountain',
    'Blacksburg',
    'Blue Ridge',
    'Boones Mill',
    'Buchanan',
    'Callaway',
    'Christiansburg',
    'Cascade',
    'Catawba',
    'Check',
    'Claudville',
    'Cloverdale',
    'Collinsville',
    'Copper Hill',
    'Critz',
    'Daleville',
    'Dublin',
    'Eagle Rock',
    'Eggleston',
    'Elliston',
    'Ferrum',
    'Fieldale',
    'Fincastle',
    'Floyd',
    'Glade Hill',
    'Glen Lyn',
    'Goodview',
    'Hardy',
    'Henry',
    'Huddleston',
    'Indian Valley',
    'Mc Coy',
    'Martinsville',
    'Meadows Of Dan',
    'Moneta',
    'Montvale',
    'Narrows',
    'Newbern',
    'New Castle',
    'Newport',
    'New River',
    'Oriskany',
    'Paint Bank',
    'Parrott',
    'Patrick Springs',
    'Pearisburg',
    'Pembroke',
    'Penhook',
    'Pilot',
    'Pittsville',
    'Radford',
    'Redwood',
    'Rich Creek',
    'Ridgeway',
    'Riner',
    'Ripplemead',
    'Rocky Mount',
    'Salem',
    'Sandy Level',
    'Shawsville',
    'Spencer',
    'Staffordsville',
    'Stanleytown',
    'Stuart',
    'Thaxton',
    'Troutville',
    'Union Hall',
    'Vesta',
    'Villamont',
    'Vinton',
    'Wirtz',
    'Woolwine',
    'Bristol',
    'Abingdon',
    'Andover',
    'Appalachia',
    'Bee',
    'Ben Hur',
    'Big Stone Gap',
    'Birchleaf',
    'Blackwater',
    'Castlewood',
    'Cleveland',
    'Clinchco',
    'Clintwood',
    'Coeburn',
    'Damascus',
    'Dante',
    'Davenport',
    'Dryden',
    'Duffield',
    'Dungannon',
    'East Stone Gap',
    'Ewing',
    'Fort Blackmore',
    'Gate City',
    'Haysi',
    'Hiltons',
    'Honaker',
    'Jonesville',
    'Keokee',
    'Lebanon',
    'Mc Clure',
    'Mendota',
    'Nickelsville',
    'Nora',
    'Norton',
    'Pennington Gap',
    'Pound',
    'Rosedale',
    'Rose Hill',
    'Saint Charles',
    'Saint Paul',
    'Weber City',
    'Whitetop',
    'Wise',
    'Pulaski',
    'Atkins',
    'Austinville',
    'Barren Springs',
    'Bastian',
    'Bland',
    'Broadford',
    'Cana',
    'Ceres',
    'Chilhowie',
    'Cripple Creek',
    'Crockett',
    'Draper',
    'Dugspur',
    'Elk Creek',
    'Emory',
    'Fancy Gap',
    'Fries',
    'Galax',
    'Glade Spring',
    'Hillsville',
    'Hiwassee',
    'Independence',
    'Ivanhoe',
    'Lambsburg',
    'Laurel Fork',
    'Marion',
    'Max Meadows',
    'Meadowview',
    'Mouth Of Wilson',
    'Rocky Gap',
    'Rural Retreat',
    'Saltville',
    'Speedwell',
    'Sugar Grove',
    'Tannersville',
    'Troutdale',
    'Willis',
    'Woodlawn',
    'Wytheville',
    'Staunton',
    'Augusta Springs',
    'Bacova',
    'Blue Grass',
    'Brownsburg',
    'Buena Vista',
    'Churchville',
    'Clifton Forge',
    'Covington',
    'Craigsville',
    'Crimora',
    'Deerfield',
    'Doe Hill',
    'Fairfield',
    'Fort Defiance',
    'Glen Wilton',
    'Goshen',
    'Greenville',
    'Grottoes',
    'Head Waters',
    'Hot Springs',
    'Iron Gate',
    'Lexington',
    'Low Moor',
    'Mc Dowell',
    'Middlebrook',
    'Millboro',
    'Mint Spring',
    'Montebello',
    'Monterey',
    'Mount Sidney',
    'Mustoe',
    'New Hope',
    'Port Republic',
    'Raphine',
    'Rockbridge Baths',
    'Selma',
    'Steeles Tavern',
    'Stuarts Draft',
    'Swoope',
    'Verona',
    'Vesuvius',
    'Warm Springs',
    'West Augusta',
    'Weyers Cave',
    'Williamsville',
    'Lynchburg',
    'Altavista',
    'Alton',
    'Amherst',
    'Appomattox',
    'Bedford',
    'Big Island',
    'Blairs',
    'Brookneal',
    'Buffalo Junction',
    'Callands',
    'Chatham',
    'Clifford',
    'Clover',
    'Cluster Springs',
    'Coleman Falls',
    'Concord',
    'Crystal Hill',
    'Danville',
    'Dry Fork',
    'Evington',
    'Forest',
    'Gladstone',
    'Gladys',
    'Glasgow',
    'Goode',
    'Gretna',
    'Halifax',
    'Howardsville',
    'Hurt',
    'Java',
    'Keeling',
    'Long Island',
    'Lowry',
    'Lynch Station',
    'Madison Heights',
    'Monroe',
    'Naruna',
    'Nathalie',
    'Natural Bridge',
    'Natural Bridge Station',
    'Nelson',
    'Norwood',
    'Ringgold',
    'Rustburg',
    'Scottsburg',
    'Scottsville',
    'South Boston',
    'Spout Spring',
    'Sutherlin',
    'Sweet Briar',
    'Vernon Hill',
    'Virgilina',
    'Wingina',
    'Amonate',
    'Bandy',
    'Big Rock',
    'Bishop',
    'Bluefield',
    'Boissevain',
    'Breaks',
    'Burkes Garden',
    'Cedar Bluff',
    'Doran',
    'Falls Mills',
    'Grundy',
    'Horsepen',
    'Hurley',
    'Jewell Ridge',
    'Keen Mountain',
    'Mavisdale',
    'Maxie',
    'North Tazewell',
    'Oakwood',
    'Pilgrims Knob',
    'Pocahontas',
    'Pounding Mill',
    'Raven',
    'Red Ash',
    'Richlands',
    'Rowe',
    'Shortt Gap',
    'Swords Creek',
    'Tazewell',
    'Vansant',
    'Whitewood',
    'Wolford'
  ],
  MD: [
    'Dhs',
    'Waldorf',
    'Abell',
    'Accokeek',
    'Aquasco',
    'Avenue',
    'Barstow',
    'Bel Alton',
    'Benedict',
    'Brandywine',
    'Broomes Island',
    'Bryans Road',
    'Bryantown',
    'Bushwood',
    'California',
    'Callaway',
    'Chaptico',
    'Charlotte Hall',
    'Cheltenham',
    'Clements',
    'Cobb Island',
    'Coltons Point',
    'Compton',
    'Dameron',
    'Dowell',
    'Drayden',
    'Faulkner',
    'Great Mills',
    'Helen',
    'Hollywood',
    'Hughesville',
    'Huntingtown',
    'Indian Head',
    'Ironsides',
    'Issue',
    'La Plata',
    'Leonardtown',
    'Lexington Park',
    'Loveville',
    'Lusby',
    'Marbury',
    'Mechanicsville',
    'Morganza',
    'Mount Victoria',
    'Nanjemoy',
    'Newburg',
    'Park Hall',
    'Patuxent River',
    'Piney Point',
    'Pomfret',
    'Port Republic',
    'Port Tobacco',
    'Prince Frederick',
    'Ridge',
    'Rock Point',
    'Saint Inigoes',
    'Saint Leonard',
    'Saint Marys City',
    'Scotland',
    'Solomons',
    'Sunderland',
    'Tall Timbers',
    'Valley Lee',
    'Welcome',
    'White Plains',
    'Southern Md Facility',
    'Annapolis Junction',
    'Lanham',
    'Beltsville',
    'Laurel',
    'Bladensburg',
    'Lothian',
    'Mount Rainier',
    'North Beach',
    'Bowie',
    'Brentwood',
    'Capitol Heights',
    'Chesapeake Beach',
    'Churchton',
    'Clinton',
    'Owings',
    'Riverdale',
    'College Park',
    'Fort Washington',
    'Oxon Hill',
    'Suitland',
    'District Heights',
    'Temple Hills',
    'Deale',
    'Dunkirk',
    'Fort George G Meade',
    'Friendship',
    'Fulton',
    'Andrews Air Force Base',
    'Savage',
    'Shady Side',
    'Galesville',
    'Greenbelt',
    'Glenn Dale',
    'Upper Marlboro',
    'Harwood',
    'Highland',
    'West River',
    'Tracys Landing',
    'Hyattsville',
    'Jessup',
    'Bethesda',
    'Glen Echo',
    'Chevy Chase',
    'Cabin John',
    'Olney',
    'Brookeville',
    'Poolesville',
    'Barnesville',
    'Beallsville',
    'Boyds',
    'Dickerson',
    'Rockville',
    'Potomac',
    'Derwood',
    'Sandy Spring',
    'Ashton',
    'Brinklow',
    'Burtonsville',
    'Spencerville',
    'Clarksburg',
    'Damascus',
    'Germantown',
    'Gaithersburg',
    'Washington Grove',
    'Montgomery Village',
    'Kensington',
    'Garrett Park',
    'Suburb Maryland Fac',
    'Silver Spring',
    'Takoma Park',
    'Aberdeen',
    'Aberdeen Proving Ground',
    'Abingdon',
    'Gunpowder',
    'Arnold',
    'Baldwin',
    'Bel Air',
    'Belcamp',
    'Benson',
    'Boring',
    'Brooklandville',
    'Butler',
    'Chase',
    'Churchville',
    'Clarksville',
    'Cockeysville',
    'Hunt Valley',
    'Crownsville',
    'Darlington',
    'Davidsonville',
    'Dayton',
    'Edgewater',
    'Edgewood',
    'Ellicott City',
    'Columbia',
    'Fallston',
    'Finksburg',
    'Forest Hill',
    'Fork',
    'Fort Howard',
    'Freeland',
    'Gambrills',
    'Gibson Island',
    'Glen Arm',
    'Glen Burnie',
    'Glyndon',
    'Hampstead',
    'Elkridge',
    'Hanover',
    'Harmans',
    'Havre De Grace',
    'Hydes',
    'Jarrettsville',
    'Joppa',
    'Kingsville',
    'Lineboro',
    'Linthicum Heights',
    'Long Green',
    'Lutherville Timonium',
    'Manchester',
    'Marriottsville',
    'Maryland Line',
    'Mayo',
    'Millersville',
    'Monkton',
    'Odenton',
    'Crofton',
    'Owings Mills',
    'Parkton',
    'Pasadena',
    'Perry Hall',
    'Perryman',
    'Phoenix',
    'Pylesville',
    'Randallstown',
    'Reisterstown',
    'Riderwood',
    'Riva',
    'Severn',
    'Severna Park',
    'Simpsonville',
    'Sparks Glencoe',
    'Stevenson',
    'Street',
    'Upperco',
    'Upper Falls',
    'Westminster',
    'Whiteford',
    'White Hall',
    'White Marsh',
    'Woodstock',
    'Baltimore',
    'Towson',
    'Gwynn Oak',
    'Pikesville',
    'Sparrows Point',
    'Middle River',
    'Essex',
    'Dundalk',
    'Brooklyn',
    'Curtis Bay',
    'Halethorpe',
    'Catonsville',
    'Parkville',
    'Nottingham',
    'Rosedale',
    'Windsor Mill',
    'Annapolis',
    'Cumberland',
    'Accident',
    'Barton',
    'Bittinger',
    'Bloomington',
    'Corriganville',
    'Eckhart Mines',
    'Ellerslie',
    'Flintstone',
    'Friendsville',
    'Frostburg',
    'Grantsville',
    'Kitzmiller',
    'Lonaconing',
    'Luke',
    'Mc Henry',
    'Midland',
    'Midlothian',
    'Mount Savage',
    'Oakland',
    'Oldtown',
    'Pinto',
    'Rawlings',
    'Spring Gap',
    'Swanton',
    'Westernport',
    'Easton',
    'Barclay',
    'Bethlehem',
    'Betterton',
    'Bozman',
    'Cambridge',
    'Centreville',
    'Chester',
    'Chestertown',
    'Church Creek',
    'Church Hill',
    'Claiborne',
    'Cordova',
    'Crapo',
    'Crocheron',
    'Crumpton',
    'Denton',
    'East New Market',
    'Federalsburg',
    'Fishing Creek',
    'Galena',
    'Goldsboro',
    'Grasonville',
    'Greensboro',
    'Henderson',
    'Hillsboro',
    'Hurlock',
    'Ingleside',
    'Kennedyville',
    'Mcdaniel',
    'Madison',
    'Marydel',
    'Massey',
    'Millington',
    'Neavitt',
    'Newcomb',
    'Oxford',
    'Preston',
    'Price',
    'Queen Anne',
    'Queenstown',
    'Rhodesdale',
    'Ridgely',
    'Rock Hall',
    'Royal Oak',
    'Saint Michaels',
    'Secretary',
    'Sherwood',
    'Stevensville',
    'Still Pond',
    'Sudlersville',
    'Taylors Island',
    'Templeville',
    'Tilghman',
    'Toddville',
    'Trappe',
    'Wingate',
    'Wittman',
    'Woolford',
    'Worton',
    'Wye Mills',
    'Frederick',
    'Adamstown',
    'Big Pool',
    'Boonsboro',
    'Braddock Heights',
    'Brownsville',
    'Brunswick',
    'Buckeystown',
    'Burkittsville',
    'Cascade',
    'Cavetown',
    'Chewsville',
    'Clear Spring',
    'Cooksville',
    'Emmitsburg',
    'Fairplay',
    'Funkstown',
    'Glenelg',
    'Glenwood',
    'Hagerstown',
    'Hancock',
    'Ijamsville',
    'Jefferson',
    'Keedysville',
    'Keymar',
    'Knoxville',
    'Ladiesburg',
    'Libertytown',
    'Lisbon',
    'Little Orleans',
    'Maugansville',
    'Middletown',
    'Monrovia',
    'Mount Airy',
    'Myersville',
    'New Market',
    'New Midway',
    'New Windsor',
    'Point Of Rocks',
    'Rocky Ridge',
    'Rohrersville',
    'Sabillasville',
    'Saint James',
    'Sharpsburg',
    'Smithsburg',
    'Sykesville',
    'Taneytown',
    'Thurmont',
    'Tuscarora',
    'Union Bridge',
    'Unionville',
    'Walkersville',
    'West Friendship',
    'Williamsport',
    'Woodbine',
    'Woodsboro',
    'Salisbury',
    'Allen',
    'Berlin',
    'Bishopville',
    'Bivalve',
    'Crisfield',
    'Deal Island',
    'Eden',
    'Ewell',
    'Fruitland',
    'Girdletree',
    'Hebron',
    'Linkwood',
    'Manokin',
    'Mardela Springs',
    'Marion Station',
    'Nanticoke',
    'Newark',
    'Ocean City',
    'Parsonsburg',
    'Pittsville',
    'Pocomoke City',
    'Powellville',
    'Princess Anne',
    'Quantico',
    'Rehobeth',
    'Sharptown',
    'Showell',
    'Snow Hill',
    'Stockton',
    'Tyaskin',
    'Tylerton',
    'Upper Fairmount',
    'Vienna',
    'Westover',
    'Whaleyville',
    'Willards',
    'Delmar',
    'North East',
    'Perry Point',
    'Perryville',
    'Port Deposit',
    'Rising Sun',
    'Warwick',
    'Cecilton',
    'Charlestown',
    'Chesapeake City',
    'Childs',
    'Colora',
    'Conowingo',
    'Earleville',
    'Elk Mills',
    'Elkton',
    'Georgetown'
  ],
  WV: [
    'Bluefield',
    'Athens',
    'Beeson',
    'Bramwell',
    'Bud',
    'Covel',
    'Freeman',
    'Herndon',
    'Hiawatha',
    'Kegley',
    'Kellysville',
    'Lashmeet',
    'Matoaka',
    'Montcalm',
    'Nemours',
    'Princeton',
    'Rock',
    'Wolfe',
    'Welch',
    'Anawalt',
    'Avondale',
    'Bartley',
    'Berwind',
    'Big Sandy',
    'Bradshaw',
    'Brenton',
    'Clear Fork',
    'Coal Mountain',
    'Cucumber',
    'Cyclone',
    'Davy',
    'Eckman',
    'Elbert',
    'Elkhorn',
    'Fanrock',
    'Gary',
    'Hanover',
    'Hemphill',
    'Hensley',
    'Iaeger',
    'Ikes Fork',
    'Isaban',
    'Itmann',
    'Jenkinjones',
    'Jesse',
    'Jolo',
    'Justice',
    'Kimball',
    'Kopperston',
    'Kyle',
    'Lynco',
    'Marianna',
    'Matheny',
    'Maybeury',
    'Mohawk',
    'Newhall',
    'New Richmond',
    'Northfork',
    'North Spring',
    'Oceana',
    'Pageton',
    'Panther',
    'Paynesville',
    'Pineville',
    'Premier',
    'Raysal',
    'Rock View',
    'Roderfield',
    'Simon',
    'Squire',
    'Switchback',
    'Thorpe',
    'War',
    'Warriormine',
    'Wilcoe',
    'Wyoming',
    'Lewisburg',
    'Fairlea',
    'Alderson',
    'Arbovale',
    'Asbury',
    'Ballard',
    'Bartow',
    'Buckeye',
    'Caldwell',
    'Cass',
    'Crawley',
    'Dunmore',
    'Forest Hill',
    'Frankford',
    'Gap Mills',
    'Grassy Meadows',
    'Green Bank',
    'Greenville',
    'Hillsboro',
    'Lindside',
    'Marlinton',
    'Maxwelton',
    'White Sulphur Springs',
    'Pence Springs',
    'Peterstown',
    'Renick',
    'Ronceverte',
    'Secondcreek',
    'Sinks Grove',
    'Smoot',
    'Talcott',
    'Union',
    'Waiteville',
    'Wayside',
    'Williamsburg',
    'Wolfcreek',
    'Alloy',
    'Alum Creek',
    'Amma',
    'Arnett',
    'Artie',
    'Ashford',
    'Bancroft',
    'Belle',
    'Bickmore',
    'Bim',
    'Blair',
    'Bloomingrose',
    'Blount',
    'Blue Creek',
    'Bob White',
    'Bomont',
    'Boomer',
    'Buffalo',
    'Cabin Creek',
    'Cannelton',
    'Cedar Grove',
    'Charlton Heights',
    'Clay',
    'Clear Creek',
    'Clendenin',
    'Clothier',
    'Colcord',
    'Comfort',
    'Costa',
    'Danville',
    'Dawes',
    'Deep Water',
    'Dixie',
    'Dorothy',
    'Drybranch',
    'Dry Creek',
    'Duck',
    'Dunbar',
    'East Bank',
    'Eleanor',
    'Elkview',
    'Eskdale',
    'Ethel',
    'Falling Rock',
    'Foster',
    'Fraziers Bottom',
    'Gallagher',
    'Gauley Bridge',
    'Glasgow',
    'Glen',
    'Glen Ferris',
    'Gordon',
    'Handley',
    'Hansford',
    'Henderson',
    'Hernshaw',
    'Hewett',
    'Hometown',
    'Hugheston',
    'Indore',
    'Institute',
    'Ivydale',
    'Jeffrey',
    'Kanawha Falls',
    'Kimberly',
    'Kincaid',
    'Lake',
    'Leon',
    'Liberty',
    'Lizemores',
    'London',
    'Madison',
    'Mammoth',
    'Maysel',
    'Miami',
    'Montgomery',
    'Mount Carbon',
    'Naoma',
    'Nebo',
    'Nellis',
    'Nitro',
    'Orgas',
    'Ottawa',
    'Page',
    'Peytona',
    'Pinch',
    'Poca',
    'Pond Gap',
    'Powellton',
    'Pratt',
    'Procious',
    'Racine',
    'Red House',
    'Ridgeview',
    'Robson',
    'Rock Creek',
    'Saint Albans',
    'Saxon',
    'Seth',
    'Sharples',
    'Mount Olive',
    'Smithers',
    'Southside',
    'Sylvester',
    'Tad',
    'Tornado',
    'Turtle Creek',
    'Twilight',
    'Uneeda',
    'Van',
    'Wharton',
    'Whitesville',
    'Widen',
    'Winfield',
    'Winifrede',
    'Advent',
    'Arnoldsburg',
    'Chloe',
    'Cottageville',
    'Evans',
    'Gandeeville',
    'Gay',
    'Given',
    'Hartford',
    'Kenna',
    'Left Hand',
    'Le Roy',
    'Letart',
    'Looneyville',
    'Mason',
    'Millstone',
    'Millwood',
    'Mount Alto',
    'New Haven',
    'Newton',
    'Normantown',
    'Orma',
    'Reedy',
    'Ripley',
    'Sandyville',
    'Spencer',
    'Wallback',
    'Walton',
    'West Columbia',
    'Charleston',
    'South Charleston',
    'Martinsburg',
    'Bakerton',
    'Berkeley Springs',
    'Bunker Hill',
    'Charles Town',
    'Falling Waters',
    'Gerrardstown',
    'Glengary',
    'Great Cacapon',
    'Halltown',
    'Harpers Ferry',
    'Hedgesville',
    'Inwood',
    'Kearneysville',
    'Levels',
    'Millville',
    'Paw Paw',
    'Points',
    'Ranson',
    'Ridgeway',
    'Rippon',
    'Shenandoah Junction',
    'Shepherdstown',
    'Slanesville',
    'Summit Point',
    'Alkol',
    'Apple Grove',
    'Ashton',
    'Barboursville',
    'Big Creek',
    'Branchland',
    'Ceredo',
    'Chapmanville',
    'Culloden',
    'Dunlow',
    'East Lynn',
    'Fort Gay',
    'Gallipolis Ferry',
    'Genoa',
    'Glenwood',
    'Griffithsville',
    'Hamlin',
    'Harts',
    'Hurricane',
    'Julian',
    'Kenova',
    'Kiahsville',
    'Lavalette',
    'Lesage',
    'Midkiff',
    'Milton',
    'Myra',
    'Ona',
    'Pecks Mill',
    'Point Pleasant',
    'Prichard',
    'Ranger',
    'Salt Rock',
    'Scott Depot',
    'Shoals',
    'Sod',
    'Spurlockville',
    'Sumerco',
    'Teays',
    'Wayne',
    'West Hamlin',
    'Woodville',
    'Yawkey',
    'Logan',
    'Accoville',
    'Amherstdale',
    'Baisden',
    'Bruno',
    'Chauncey',
    'Cora',
    'Davin',
    'Gilbert',
    'Henlawson',
    'Holden',
    'Kistler',
    'Lorado',
    'Lyburn',
    'Mallory',
    'Man',
    'Mount Gay',
    'Omar',
    'Peach Creek',
    'Sarah Ann',
    'Stollings',
    'Switzer',
    'Verdunville',
    'Verner',
    'Wharncliffe',
    'Whitman',
    'Wilkinson',
    'Yolyn',
    'Williamson',
    'Borderland',
    'Breeden',
    'Chattaroy',
    'Crum',
    'Delbarton',
    'Dingess',
    'Edgarton',
    'Kermit',
    'Lenore',
    'Matewan',
    'Naugatuck',
    'Newtown',
    'North Matewan',
    'Ragland',
    'Rawl',
    'Red Jacket',
    'Varney',
    'Wilsondale',
    'Huntington',
    'Beckley',
    'Allen Junction',
    'Amigo',
    'Ansted',
    'Beaver',
    'Bolt',
    'Bradley',
    'Camp Creek',
    'Coal City',
    'Cool Ridge',
    'Corinne',
    'Crab Orchard',
    'Danese',
    'Daniels',
    'Dothan',
    'Eccles',
    'Edmond',
    'Fairdale',
    'Fayetteville',
    'Flat Top',
    'Ghent',
    'Glen Daniel',
    'Glen Fork',
    'Glen Jean',
    'Glen Rogers',
    'Glen White',
    'Harper',
    'Helen',
    'Hico',
    'Hilltop',
    'Josephine',
    'Lanark',
    'Lansing',
    'Layland',
    'Lester',
    'Lochgelly',
    'Lookout',
    'Maben',
    'Mabscott',
    'Mac Arthur',
    'Mc Graws',
    'Saulsville',
    'Midway',
    'Minden',
    'Mount Hope',
    'Mullens',
    'Oak Hill',
    'Odd',
    'Pax',
    'Piney View',
    'Prince',
    'Princewick',
    'Prosperity',
    'Raleigh',
    'Ravencliff',
    'Rhodell',
    'Sabine',
    'Scarbro',
    'Shady Spring',
    'Skelton',
    'Slab Fork',
    'Sophia',
    'Spanishburg',
    'Stanaford',
    'Stephenson',
    'Surveyor',
    'Thurmond',
    'Victor',
    'Winona',
    'Wyco',
    'Hinton',
    'Charmco',
    'Rainelle',
    'Elton',
    'Green Sulphur Springs',
    'Jumping Branch',
    'Lerona',
    'Leslie',
    'Meadow Bridge',
    'Meadow Creek',
    'Nimitz',
    'Pipestem',
    'Quinwood',
    'Rupert',
    'Sandstone',
    'Spring Dale',
    'White Oak',
    'Wheeling',
    'Beech Bottom',
    'Benwood',
    'Bethany',
    'Cameron',
    'Chester',
    'Colliers',
    'Dallas',
    'Follansbee',
    'Glen Dale',
    'Glen Easton',
    'Mcmechen',
    'Moundsville',
    'New Cumberland',
    'Newell',
    'Proctor',
    'New Manchester',
    'Short Creek',
    'Triadelphia',
    'Valley Grove',
    'Weirton',
    'Wellsburg',
    'West Liberty',
    'Windsor Heights',
    'Parkersburg',
    'Vienna',
    'Mineral Wells',
    'Belleville',
    'Belmont',
    'Big Bend',
    'Big Springs',
    'Brohard',
    'Creston',
    'Davisville',
    'Elizabeth',
    'Friendly',
    'Grantsville',
    'Macfarlan',
    'Middlebourne',
    'Mount Zion',
    'Munday',
    'New Martinsville',
    'Paden City',
    'Palestine',
    'Petroleum',
    'Porters Falls',
    'Ravenswood',
    'Reader',
    'Rockport',
    'Saint Marys',
    'Sistersville',
    'Smithville',
    'Walker',
    'Washington',
    'Waverly',
    'Williamstown',
    'Buckhannon',
    'Fenwick',
    'Erbacon',
    'Craigsville',
    'Cowen',
    'Camden On Gauley',
    'Snowshoe',
    'Adrian',
    'Cleveland',
    'Diana',
    'French Creek',
    'Frenchton',
    'Hacker Valley',
    'Helvetia',
    'Kanawha Head',
    'Lorentz',
    'Pickens',
    'Rock Cave',
    'Selbyville',
    'Tallmansville',
    'Volga',
    'Elkins',
    'Belington',
    'Beverly',
    'Bowden',
    'Coalton',
    'Dailey',
    'Davis',
    'Richwood',
    'Dryfork',
    'Durbin',
    'Upperglade',
    'Ellamore',
    'Glady',
    'Hambleton',
    'Harman',
    'Hendricks',
    'Huttonsville',
    'Junior',
    'Kerens',
    'Mabie',
    'Mill Creek',
    'Monterville',
    'Montrose',
    'Norton',
    'Parsons',
    'Webster Springs',
    'Red Creek',
    'Slatyfork',
    'Thomas',
    'Valley Bend',
    'Valley Head',
    'Whitmer',
    'Bergoo',
    'Clarksburg',
    'Alma',
    'Alum Bridge',
    'Anmoore',
    'Auburn',
    'Berea',
    'Bridgeport',
    'Burnsville',
    'Cairo',
    'Camden',
    'Center Point',
    'Coxs Mills',
    'Crawford',
    'Ellenboro',
    'Flemington',
    'Folsom',
    'Galloway',
    'Glenville',
    'Grafton',
    'Gypsy',
    'Harrisville',
    'Haywood',
    'Hepzibah',
    'Horner',
    'Independence',
    'Ireland',
    'Jacksonburg',
    'Jane Lew',
    'Linn',
    'Lost Creek',
    'Lumberport',
    'Meadowbrook',
    'Moatsville',
    'Mount Clare',
    'Newburg',
    'New Milton',
    'Orlando',
    'Pennsboro',
    'Philippi',
    'Pine Grove',
    'Pullman',
    'Reynoldsville',
    'Rosemont',
    'Rowlesburg',
    'Salem',
    'Sand Fork',
    'Shinnston',
    'Shirley',
    'Simpson',
    'Smithburg',
    'Smithfield',
    'Spelter',
    'Thornton',
    'Troy',
    'Tunnelton',
    'Walkersville',
    'Wallace',
    'West Milford',
    'Weston',
    'West Union',
    'Wilsonburg',
    'Wyatt',
    'Morgantown',
    'Albright',
    'Arthurdale',
    'Blacksville',
    'Bretz',
    'Bruceton Mills',
    'Cassville',
    'Dellslow',
    'Granville',
    'Kingwood',
    'Maidsville',
    'Masontown',
    'Osage',
    'Pentress',
    'Pursglove',
    'Reedsville',
    'Fairmont',
    'Barrackville',
    'Baxter',
    'Big Run',
    'Burton',
    'Carolina',
    'Colfax',
    'Enterprise',
    'Fairview',
    'Farmington',
    'Four States',
    'Grant Town',
    'Hundred',
    'Idamay',
    'Kingmont',
    'Littleton',
    'Mannington',
    'Metz',
    'Montana Mines',
    'Rachel',
    'Rivesville',
    'Wana',
    'Worthington',
    'Sutton',
    'Birch River',
    'Cedarville',
    'Copen',
    'Dille',
    'Exchange',
    'Flatwoods',
    'Frametown',
    'Gassaway',
    'Heaters',
    'Little Birch',
    'Napier',
    'Rosedale',
    'Shock',
    'Summersville',
    'Belva',
    'Calvin',
    'Canvas',
    'Drennen',
    'Gilboa',
    'Keslers Cross Lanes',
    'Leivasy',
    'Mount Lookout',
    'Mount Nebo',
    'Nallen',
    'Nettie',
    'Pool',
    'Swiss',
    'Tioga',
    'Augusta',
    'Aurora',
    'Bayard',
    'Burlington',
    'Capon Bridge',
    'Delray',
    'Eglon',
    'Elk Garden',
    'Fort Ashby',
    'Gormania',
    'Green Spring',
    'Keyser',
    'Lahmansville',
    'Mount Storm',
    'New Creek',
    'Piedmont',
    'Ridgeley',
    'Rio',
    'Romney',
    'Shanks',
    'Springfield',
    'Terra Alta',
    'Wiley Ford',
    'Baker',
    'Brandywine',
    'Circleville',
    'Franklin',
    'High View',
    'Lost City',
    'Mathias',
    'Riverton',
    'Sugar Grove',
    'Bloomery',
    'Fisher',
    'Capon Springs',
    'Maysville',
    'Moorefield',
    'Milam',
    'Old Fields',
    'Petersburg',
    'Wardensville',
    'Purgitsville',
    'Cabins',
    'Yellow Spring',
    'Upper Tract',
    'Seneca Rocks',
    'Onego'
  ],
  NC: [
    'Advance',
    'Ararat',
    'Belews Creek',
    'Bethania',
    'Boonville',
    'Clemmons',
    'Cleveland',
    'Cooleemee',
    'Danbury',
    'Dobson',
    'East Bend',
    'Germanton',
    'Hamptonville',
    'King',
    'Lawsonville',
    'Lewisville',
    'Lowgap',
    'Madison',
    'Mayodan',
    'Mocksville',
    'Mount Airy',
    'Pfafftown',
    'Pilot Mountain',
    'Pine Hall',
    'Pinnacle',
    'Rural Hall',
    'Sandy Ridge',
    'Siloam',
    'Stoneville',
    'Toast',
    'Tobaccoville',
    'Walkertown',
    'Walnut Cove',
    'Westfield',
    'Woodleaf',
    'Yadkinville',
    'Winston Salem',
    'Winston-salem',
    'Alamance',
    'Altamahaw',
    'Asheboro',
    'Bear Creek',
    'Bennett',
    'Biscoe',
    'Blanch',
    'Bonlee',
    'Browns Summit',
    'Burlington',
    'Bynum',
    'Candor',
    'Cedar Falls',
    'Cedar Grove',
    'Climax',
    'Colfax',
    'Cumnock',
    'Denton',
    'Eagle Springs',
    'Efland',
    'Elon',
    'Ether',
    'Franklinville',
    'Gibsonville',
    'Goldston',
    'Graham',
    'Gulf',
    'Haw River',
    'Highfalls',
    'High Point',
    'Hillsborough',
    'Jackson Springs',
    'Jamestown',
    'Julian',
    'Kernersville',
    'Eden',
    'Leasburg',
    'Lexington',
    'Liberty',
    'Linwood',
    'Mc Leansville',
    'Mebane',
    'Milton',
    'Mount Gilead',
    'Oak Ridge',
    'Pelham',
    'Pittsboro',
    'Pleasant Garden',
    'Prospect Hill',
    'Providence',
    'Ramseur',
    'Randleman',
    'Reidsville',
    'Robbins',
    'Ruffin',
    'Sanford',
    'Saxapahaw',
    'Seagrove',
    'Sedalia',
    'Semora',
    'Siler City',
    'Snow Camp',
    'Sophia',
    'Southmont',
    'Staley',
    'Star',
    'Stokesdale',
    'Summerfield',
    'Swepsonville',
    'Thomasville',
    'Trinity',
    'Troy',
    'Wallburg',
    'Welcome',
    'Wentworth',
    'West End',
    'Whitsett',
    'Yanceyville',
    'Greensboro',
    'Angier',
    'Apex',
    'Bahama',
    'Benson',
    'Broadway',
    'Buies Creek',
    'Bullock',
    'Bunn',
    'Butner',
    'Carrboro',
    'Cary',
    'Chapel Hill',
    'Clayton',
    'Coats',
    'Creedmoor',
    'Four Oaks',
    'Franklinton',
    'Fuquay Varina',
    'Garner',
    'Goldsboro',
    'Henderson',
    'Holly Springs',
    'Hurdle Mills',
    'Kenly',
    'Kipling',
    'Kittrell',
    'Knightdale',
    'Lillington',
    'Louisburg',
    'Macon',
    'Mamers',
    'Manson',
    'Micro',
    'Middleburg',
    'Middlesex',
    'Moncure',
    'Morrisville',
    'New Hill',
    'Norlina',
    'Oxford',
    'Pine Level',
    'Princeton',
    'Ridgeway',
    'Rolesville',
    'Rougemont',
    'Roxboro',
    'Selma',
    'Smithfield',
    'Stem',
    'Stovall',
    'Timberlake',
    'Townsville',
    'Vaughan',
    'Wake Forest',
    'Warrenton',
    'Wendell',
    'Willow Spring',
    'Wilsons Mills',
    'Wise',
    'Youngsville',
    'Zebulon',
    'Raleigh',
    'Durham',
    'Rocky Mount',
    'Aulander',
    'Aurora',
    'Bailey',
    'Bath',
    'Battleboro',
    'Belhaven',
    'Bellarthur',
    'Bethel',
    'Black Creek',
    'Blounts Creek',
    'Castalia',
    'Chocowinity',
    'Como',
    'Conetoe',
    'Conway',
    'Edward',
    'Elm City',
    'Enfield',
    'Engelhard',
    'Everetts',
    'Fairfield',
    'Falkland',
    'Farmville',
    'Fountain',
    'Fremont',
    'Garysburg',
    'Gaston',
    'Greenville',
    'Grimesland',
    'Halifax',
    'Hamilton',
    'Hassell',
    'Henrico',
    'Hobgood',
    'Hollister',
    'Jackson',
    'Jamesville',
    'Kelford',
    'Lewiston Woodville',
    'Littleton',
    'Lucama',
    'Macclesfield',
    'Margarettsville',
    'Milwaukee',
    'Murfreesboro',
    'Nashville',
    'Oak City',
    'Pantego',
    'Parmele',
    'Pendleton',
    'Pikeville',
    'Pinetops',
    'Pinetown',
    'Pleasant Hill',
    'Potecasi',
    'Red Oak',
    'Rich Square',
    'Roanoke Rapids',
    'Robersonville',
    'Roxobel',
    'Saratoga',
    'Scotland Neck',
    'Scranton',
    'Seaboard',
    'Severn',
    'Sharpsburg',
    'Simpson',
    'Sims',
    'Speed',
    'Spring Hope',
    'Stantonsburg',
    'Stokes',
    'Swanquarter',
    'Tarboro',
    'Tillery',
    'Walstonburg',
    'Washington',
    'Weldon',
    'Whitakers',
    'Williamston',
    'Wilson',
    'Woodland',
    'Elizabeth City',
    'Ahoskie',
    'Avon',
    'Aydlett',
    'Barco',
    'Belvidere',
    'Buxton',
    'Camden',
    'Cofield',
    'Coinjock',
    'Colerain',
    'Columbia',
    'Corapeake',
    'Corolla',
    'Creswell',
    'Currituck',
    'Hertford',
    'Edenton',
    'Eure',
    'Frisco',
    'Gates',
    'Gatesville',
    'Grandy',
    'Harbinger',
    'Harrellsville',
    'Hatteras',
    'Hobbsville',
    'Jarvisburg',
    'Kill Devil Hills',
    'Kitty Hawk',
    'Knotts Island',
    'Manns Harbor',
    'Manteo',
    'Maple',
    'Merry Hill',
    'Moyock',
    'Nags Head',
    'Ocracoke',
    'Plymouth',
    'Point Harbor',
    'Poplar Branch',
    'Powells Point',
    'Powellsville',
    'Rodanthe',
    'Roduco',
    'Roper',
    'Salvo',
    'Shawboro',
    'Shiloh',
    'South Mills',
    'Stumpy Point',
    'Sunbury',
    'Tyner',
    'Wanchese',
    'Waves',
    'Windsor',
    'Winfall',
    'Winton',
    'Albemarle',
    'Alexis',
    'Ansonville',
    'Badin',
    'Barium Springs',
    'Belmont',
    'Bessemer City',
    'Boiling Springs',
    'Bostic',
    'Caroleen',
    'Casar',
    'Cherryville',
    'China Grove',
    'Cliffside',
    'Concord',
    'Cornelius',
    'Cramerton',
    'Crouse',
    'Dallas',
    'Davidson',
    'Denver',
    'Earl',
    'East Spencer',
    'Ellenboro',
    'Faith',
    'Fallston',
    'Forest City',
    'Gastonia',
    'Huntersville',
    'Gold Hill',
    'Granite Quarry',
    'Grover',
    'Harris',
    'Harrisburg',
    'Henrietta',
    'High Shoals',
    'Indian Trail',
    'Iron Station',
    'Kannapolis',
    'Kings Mountain',
    'Landis',
    'Lattimore',
    'Lawndale',
    'Lilesville',
    'Lincolnton',
    'Locust',
    'Lowell',
    'Mc Adenville',
    'Mc Farlan',
    'Marshville',
    'Matthews',
    'Midland',
    'Mineral Springs',
    'Misenheimer',
    'Monroe',
    'Mooresboro',
    'Mooresville',
    'Morven',
    'Mount Holly',
    'Mount Mourne',
    'Mount Pleasant',
    'Mount Ulla',
    'Newell',
    'New London',
    'Norwood',
    'Oakboro',
    'Paw Creek',
    'Peachland',
    'Pineville',
    'Polkton',
    'Polkville',
    'Richfield',
    'Rockwell',
    'Rutherfordton',
    'Salisbury',
    'Shelby',
    'Spencer',
    'Spindale',
    'Stanfield',
    'Stanley',
    'Troutman',
    'Union Mills',
    'Vale',
    'Waco',
    'Wadesboro',
    'Waxhaw',
    'Wingate',
    'Charlotte',
    'Fayetteville',
    'Fort Bragg',
    'Pope Army Airfield',
    'Aberdeen',
    'Autryville',
    'Barnesville',
    'Bladenboro',
    'Bunnlevel',
    'Calypso',
    'Cameron',
    'Carthage',
    'Clinton',
    'Cordova',
    'Cumberland',
    'Dublin',
    'Dudley',
    'Dunn',
    'Elizabethtown',
    'Ellerbe',
    'Erwin',
    'Fairmont',
    'Faison',
    'Falcon',
    'Gibson',
    'Godwin',
    'Hamlet',
    'Hoffman',
    'Hope Mills',
    'Kenansville',
    'Lakeview',
    'Laurel Hill',
    'Laurinburg',
    'Lemon Springs',
    'Linden',
    'Lumber Bridge',
    'Lumberton',
    'Marietta',
    'Marston',
    'Maxton',
    'Mount Olive',
    'Newton Grove',
    'Norman',
    'Olivia',
    'Orrum',
    'Pinehurst',
    'Parkton',
    'Pembroke',
    'Pinebluff',
    'Proctorville',
    'Raeford',
    'Red Springs',
    'Rex',
    'Rockingham',
    'Roseboro',
    'Rowland',
    'Saint Pauls',
    'Salemburg',
    'Shannon',
    'Southern Pines',
    'Spring Lake',
    'Stedman',
    'Tar Heel',
    'Turkey',
    'Vass',
    'Wade',
    'Wagram',
    'Warsaw',
    'White Oak',
    'Wilmington',
    'Ash',
    'Atkinson',
    'Bolivia',
    'Bolton',
    'Brunswick',
    'Burgaw',
    'Carolina Beach',
    'Castle Hayne',
    'Cerro Gordo',
    'Chadbourn',
    'Clarendon',
    'Clarkton',
    'Council',
    'Currie',
    'Delco',
    'Evergreen',
    'Fair Bluff',
    'Garland',
    'Hallsboro',
    'Hampstead',
    'Harrells',
    'Holly Ridge',
    'Ivanhoe',
    'Kelly',
    'Kure Beach',
    'Lake Waccamaw',
    'Leland',
    'Longwood',
    'Magnolia',
    'Maple Hill',
    'Nakina',
    'Riegelwood',
    'Rocky Point',
    'Rose Hill',
    'Shallotte',
    'Sneads Ferry',
    'Southport',
    'Supply',
    'Tabor City',
    'Teachey',
    'Oak Island',
    'Wallace',
    'Calabash',
    'Sunset Beach',
    'Ocean Isle Beach',
    'Whiteville',
    'Willard',
    'Winnabow',
    'Wrightsville Beach',
    'Kinston',
    'Albertson',
    'Alliance',
    'Arapahoe',
    'Atlantic',
    'Atlantic Beach',
    'Ayden',
    'Bayboro',
    'Beaufort',
    'Beulaville',
    'Bridgeton',
    'Cedar Island',
    'Chinquapin',
    'Comfort',
    'Cove City',
    'Davis',
    'Deep Run',
    'Dover',
    'Ernul',
    'Gloucester',
    'Grantsboro',
    'Grifton',
    'Harkers Island',
    'Havelock',
    'Cherry Point',
    'Hobucken',
    'Hookerton',
    'Hubert',
    'Jacksonville',
    'Camp Lejeune',
    'Tarawa Terrace',
    'Midway Park',
    'Mccutcheon Field',
    'La Grange',
    'Lowland',
    'Marshallberg',
    'Maury',
    'Maysville',
    'Merritt',
    'Morehead City',
    'New Bern',
    'Newport',
    'Oriental',
    'Pink Hill',
    'Pollocksville',
    'Richlands',
    'Salter Path',
    'Sealevel',
    'Seven Springs',
    'Smyrna',
    'Snow Hill',
    'Stacy',
    'Stella',
    'Stonewall',
    'Swansboro',
    'Trenton',
    'Vanceboro',
    'Vandemere',
    'Williston',
    'Winterville',
    'Emerald Isle',
    'Hickory',
    'Banner Elk',
    'Blowing Rock',
    'Boomer',
    'Boone',
    'Catawba',
    'Claremont',
    'Collettsville',
    'Connelly Springs',
    'Conover',
    'Creston',
    'Crossnore',
    'Crumpler',
    'Deep Gap',
    'Drexel',
    'Elkin',
    'Elk Park',
    'Ennice',
    'Ferguson',
    'Statesville',
    'Fleetwood',
    'Glade Valley',
    'Glen Alpine',
    'Glendale Springs',
    'Granite Falls',
    'Grassy Creek',
    'Lenoir',
    'Harmony',
    'Hays',
    'Hiddenite',
    'Hildebran',
    'Hudson',
    'Jefferson',
    'Jonas Ridge',
    'Jonesville',
    'Lansing',
    'Laurel Springs',
    'Linville',
    'Linville Falls',
    'Mc Grady',
    'Maiden',
    'Millers Creek',
    'Minneapolis',
    'Montezuma',
    'Moravian Falls',
    'Morganton',
    'North Wilkesboro',
    'Newland',
    'Newton',
    'Olin',
    'Patterson',
    'Pineola',
    'Piney Creek',
    'Plumtree',
    'Purlear',
    'Icard',
    'Rhodhiss',
    'Roaring Gap',
    'Roaring River',
    'Ronda',
    'Rutherford College',
    'Scottville',
    'Sherrills Ford',
    'Sparta',
    'State Road',
    'Stony Point',
    'Sugar Grove',
    'Taylorsville',
    'Terrell',
    'Thurmond',
    'Todd',
    'Traphill',
    'Turnersburg',
    'Union Grove',
    'Valdese',
    'Valle Crucis',
    'Vilas',
    'Warrensville',
    'West Jefferson',
    'Wilkesboro',
    'Zionville',
    'Scotts',
    'Alexander',
    'Almond',
    'Arden',
    'Bakersville',
    'Balsam',
    'Balsam Grove',
    'Barnardsville',
    'Bat Cave',
    'Black Mountain',
    'Brevard',
    'Bryson City',
    'Burnsville',
    'Candler',
    'Canton',
    'Cashiers',
    'Cedar Mountain',
    'Cherokee',
    'Chimney Rock',
    'Clyde',
    'Columbus',
    'Cullowhee',
    'Dana',
    'Dillsboro',
    'East Flat Rock',
    'Edneyville',
    'Enka',
    'Etowah',
    'Fairview',
    'Flat Rock',
    'Fletcher',
    'Fontana Dam',
    'Franklin',
    'Gerton',
    'Glenville',
    'Glenwood',
    'Hazelwood',
    'Hendersonville',
    'Green Mountain',
    'Highlands',
    'Horse Shoe',
    'Hot Springs',
    'Lake Junaluska',
    'Lake Lure',
    'Lake Toxaway',
    'Leicester',
    'Little Switzerland',
    'Lynn',
    'Maggie Valley',
    'Marion',
    'Marshall',
    'Mars Hill',
    'Micaville',
    'Mill Spring',
    'Montreat',
    'Mountain Home',
    'Mills River',
    'Naples',
    'Nebo',
    'Old Fort',
    'Otto',
    'Penland',
    'Penrose',
    'Pisgah Forest',
    'Ridgecrest',
    'Robbinsville',
    'Rosman',
    'Saluda',
    'Sapphire',
    'Scaly Mountain',
    'Skyland',
    'Spruce Pine',
    'Swannanoa',
    'Sylva',
    'Topton',
    'Tryon',
    'Tuckasegee',
    'Tuxedo',
    'Waynesville',
    'Weaverville',
    'Webster',
    'Whittier',
    'Zirconia',
    'Asheville',
    'Andrews',
    'Brasstown',
    'Culberson',
    'Hayesville',
    'Marble',
    'Murphy',
    'Warne'
  ],
  SC: [
    'Alcolu',
    'Ballentine',
    'Bamberg',
    'Batesburg',
    'Bethune',
    'Bishopville',
    'Blackstock',
    'Blair',
    'Blythewood',
    'Bowman',
    'Camden',
    'Cameron',
    'Carlisle',
    'Cassatt',
    'Cayce',
    'Chapin',
    'Chappells',
    'Cope',
    'Cordova',
    'Dalzell',
    'Davis Station',
    'Denmark',
    'Eastover',
    'Elgin',
    'Elliott',
    'Elloree',
    'Eutawville',
    'Gable',
    'Gadsden',
    'Gaston',
    'Gilbert',
    'Great Falls',
    'Greeleyville',
    'Heath Springs',
    'Holly Hill',
    'Hopkins',
    'Horatio',
    'Irmo',
    'Jenkinsville',
    'Kershaw',
    'Lamar',
    'Leesville',
    'Lexington',
    'Liberty Hill',
    'Little Mountain',
    'Lugoff',
    'Lydia',
    'Lynchburg',
    'Ehrhardt',
    'Lodge',
    'Mc Bee',
    'Manning',
    'Mayesville',
    'Monetta',
    'Neeses',
    'Newberry',
    'New Zion',
    'North',
    'Norway',
    'Olanta',
    'Orangeburg',
    'Peak',
    'Pelion',
    'Pinewood',
    'Pomaria',
    'Prosperity',
    'Rembert',
    'Ridge Spring',
    'Ridgeway',
    'Rion',
    'Rowesville',
    'Saint Matthews',
    'Salley',
    'Saluda',
    'Santee',
    'Sardinia',
    'Silverstreet',
    'Springfield',
    'State Park',
    'Summerton',
    'Sumter',
    'Shaw AFB',
    'Swansea',
    'Timmonsville',
    'Turbeville',
    'Vance',
    'Wagener',
    'Ward',
    'Wedgefield',
    'West Columbia',
    'Westville',
    'White Rock',
    'Whitmire',
    'Winnsboro',
    'Columbia',
    'Spartanburg',
    'Boiling Springs',
    'Arcadia',
    'Buffalo',
    'Campobello',
    'Chesnee',
    'Clifton',
    'Clinton',
    'Converse',
    'Cowpens',
    'Cross Anchor',
    'Cross Hill',
    'Drayton',
    'Duncan',
    'Enoree',
    'Fairforest',
    'Fingerville',
    'Gaffney',
    'Glendale',
    'Gramling',
    'Inman',
    'Joanna',
    'Jonesville',
    'Kinards',
    'Landrum',
    'Laurens',
    'Lockhart',
    'Lyman',
    'Mayo',
    'Moore',
    'Mountville',
    'Pacolet',
    'Pacolet Mills',
    'Pauline',
    'Reidville',
    'Roebuck',
    'Startex',
    'Una',
    'Union',
    'Waterloo',
    'Wellford',
    'White Stone',
    'Woodruff',
    'Charleston',
    'Charleston AFB',
    'North Charleston',
    'Hanahan',
    'Adams Run',
    'Awendaw',
    'Bethera',
    'Bonneau',
    'Branchville',
    'Canadys',
    'Cordesville',
    'Cottageville',
    'Cross',
    'Dorchester',
    'Edisto Island',
    'Folly Beach',
    'Georgetown',
    'Goose Creek',
    'Green Pond',
    'Grover',
    'Harleyville',
    'Hollywood',
    'Huger',
    'Isle Of Palms',
    'Jacksonboro',
    'Jamestown',
    'Johns Island',
    'Ladson',
    'Mc Clellanville',
    'Moncks Corner',
    'Mount Pleasant',
    'Pineville',
    'Pinopolis',
    'Ravenel',
    'Reevesville',
    'Ridgeville',
    'Round O',
    'Ruffin',
    'Russellville',
    'Saint George',
    'Saint Stephen',
    'Smoaks',
    'Sullivans Island',
    'Summerville',
    'Wadmalaw Island',
    'Walterboro',
    'Williams',
    'Florence',
    'Andrews',
    'Aynor',
    'Bennettsville',
    'Blenheim',
    'Cades',
    'Centenary',
    'Cheraw',
    'Clio',
    'Conway',
    'Coward',
    'Darlington',
    'Dillon',
    'Effingham',
    'Fork',
    'Galivants Ferry',
    'Green Sea',
    'Gresham',
    'Hamer',
    'Hartsville',
    'Hemingway',
    'Johnsonville',
    'Kingstree',
    'Lake City',
    'Lake View',
    'Lane',
    'Latta',
    'Little River',
    'Little Rock',
    'Longs',
    'Loris',
    'Mc Coll',
    'Marion',
    'Myrtle Beach',
    'Minturn',
    'Mullins',
    'Murrells Inlet',
    'Nesmith',
    'Nichols',
    'North Myrtle Beach',
    'Pamplico',
    'Patrick',
    'Pawleys Island',
    'Rains',
    'Salters',
    'Scranton',
    'Sellers',
    'Society Hill',
    'Tatum',
    'Wallace',
    'Greenville',
    'Abbeville',
    'Anderson',
    'Belton',
    'Calhoun Falls',
    'Central',
    'Clemson',
    'Cleveland',
    'Conestee',
    'Donalds',
    'Due West',
    'Easley',
    'Fair Play',
    'Fountain Inn',
    'Gray Court',
    'Greenwood',
    'Greer',
    'Hodges',
    'Honea Path',
    'Iva',
    'La France',
    'Liberty',
    'Long Creek',
    'Lowndesville',
    'Marietta',
    'Mauldin',
    'Mountain Rest',
    'Newry',
    'Ninety Six',
    'Norris',
    'Pelzer',
    'Pendleton',
    'Pickens',
    'Seneca',
    'Piedmont',
    'Richland',
    'Salem',
    'Sandy Springs',
    'Simpsonville',
    'Six Mile',
    'Slater',
    'Starr',
    'Sunset',
    'Tamassee',
    'Taylors',
    'Tigerville',
    'Townville',
    'Travelers Rest',
    'Walhalla',
    'Ware Shoals',
    'Westminster',
    'West Union',
    'Williamston',
    'Blacksburg',
    'Bowling Green',
    'Catawba',
    'Chester',
    'Fort Mill',
    'Chesterfield',
    'Clover',
    'Edgemoor',
    'Fort Lawn',
    'Hickory Grove',
    'Jefferson',
    'Lancaster',
    'Lando',
    'Mc Connells',
    'Mount Croghan',
    'Pageland',
    'Richburg',
    'Rock Hill',
    'Ruby',
    'Sharon',
    'Smyrna',
    'Van Wyck',
    'York',
    'Aiken',
    'New Ellenton',
    'Allendale',
    'Barnwell',
    'Hilda',
    'Bath',
    'Blackville',
    'Bradley',
    'Clarks Hill',
    'Clearwater',
    'Edgefield',
    'Elko',
    'Fairfax',
    'Gloverville',
    'Graniteville',
    'Jackson',
    'Johnston',
    'Langley',
    'Mc Cormick',
    'Martin',
    'Modoc',
    'Montmorenci',
    'Mount Carmel',
    'North Augusta',
    'Beech Island',
    'Olar',
    'Parksville',
    'Plum Branch',
    'Sycamore',
    'Trenton',
    'Troy',
    'Ulmer',
    'Vaucluse',
    'Warrenville',
    'Williston',
    'Windsor',
    'Beaufort',
    'Parris Island',
    'Okatie',
    'Bluffton',
    'Brunson',
    'Coosawhatchie',
    'Crocketville',
    'Dale',
    'Daufuskie Island',
    'Early Branch',
    'Estill',
    'Saint Helena Island',
    'Furman',
    'Garnett',
    'Gifford',
    'Hampton',
    'Hilton Head Island',
    'Hardeeville',
    'Islandton',
    'Lobeco',
    'Luray',
    'Miley',
    'Pineland',
    'Port Royal',
    'Ridgeland',
    'Scotia',
    'Seabrook',
    'Sheldon',
    'Tillman',
    'Varnville',
    'Yemassee'
  ],
  GA: [
    'Avondale Estates',
    'Norcross',
    'Alpharetta',
    'Marietta',
    'Auburn',
    'Conyers',
    'Covington',
    'Grayson',
    'Jersey',
    'Dacula',
    'Clarkston',
    'Suwanee',
    'Social Circle',
    'North Metro',
    'Cumming',
    'Decatur',
    'Lithonia',
    'Snellville',
    'Lawrenceville',
    'Lilburn',
    'Loganville',
    'Oxford',
    'Mansfield',
    'Newborn',
    'Porterdale',
    'Pine Lake',
    'Redan',
    'Roswell',
    'Scottdale',
    'Smyrna',
    'Stone Mountain',
    'Tucker',
    'Peachtree Corners',
    'Duluth',
    'Acworth',
    'Adairsville',
    'Aragon',
    'Armuchee',
    'Austell',
    'Ball Ground',
    'Bowdon',
    'Bowdon Junction',
    'Bremen',
    'Clarkdale',
    'Carrollton',
    'Buchanan',
    'Canton',
    'Cartersville',
    'Lithia Springs',
    'Cassville',
    'Cave Spring',
    'Cedartown',
    'Mableton',
    'Powder Springs',
    'Coosa',
    'Dallas',
    'Douglasville',
    'Emerson',
    'Esom Hill',
    'Fairmount',
    'Felton',
    'Hiram',
    'Holly Springs',
    'Jasper',
    'Kennesaw',
    'Kingston',
    'Lebanon',
    'Lindale',
    'Marble Hill',
    'Mount Berry',
    'Mount Zion',
    'Nelson',
    'Rockmart',
    'Rome',
    'Roopville',
    'Rydal',
    'Shannon',
    'Silver Creek',
    'Talking Rock',
    'Tallapoosa',
    'Tate',
    'Taylorsville',
    'Temple',
    'Villa Rica',
    'Waco',
    'Waleska',
    'White',
    'Whitesburg',
    'Winston',
    'Woodstock',
    'Barnesville',
    'Brooks',
    'Concord',
    'Experiment',
    'Fairburn',
    'Fayetteville',
    'Flovilla',
    'Franklin',
    'Gay',
    'Glenn',
    'Grantville',
    'Greenville',
    'Griffin',
    'Hampton',
    'Haralson',
    'Hogansville',
    'Jackson',
    'Jenkinsburg',
    'Jonesboro',
    'Lagrange',
    'Locust Grove',
    'Lovejoy',
    'Luthersville',
    'Mcdonough',
    'Meansville',
    'Milner',
    'Molena',
    'Moreland',
    'Morrow',
    'Newnan',
    'Orchard Hill',
    'Palmetto',
    'Peachtree City',
    'Red Oak',
    'Rex',
    'Riverdale',
    'Sargent',
    'Senoia',
    'Sharpsburg',
    'Stockbridge',
    'Sunny Side',
    'The Rock',
    'Thomaston',
    'Conley',
    'Turin',
    'Tyrone',
    'Union City',
    'Williamson',
    'Woodbury',
    'Ellenwood',
    'Zebulon',
    'Forest Park',
    'Atlanta',
    'Swainsboro',
    'Ailey',
    'Alamo',
    'Alston',
    'Bartow',
    'Bellville',
    'Brooklet',
    'Claxton',
    'Cobbtown',
    'Collins',
    'Daisy',
    'Dover',
    'Garfield',
    'Girard',
    'Glennville',
    'Glenwood',
    'Hagan',
    'Louisville',
    'Lyons',
    'Manassas',
    'Metter',
    'Midville',
    'Millen',
    'Mount Vernon',
    'Newington',
    'Norristown',
    'Nunez',
    'Oliver',
    'Portal',
    'Pulaski',
    'Register',
    'Reidsville',
    'Rockledge',
    'Rocky Ford',
    'Sardis',
    'Soperton',
    'Statesboro',
    'Stillmore',
    'Sylvania',
    'Tarrytown',
    'Twin City',
    'Uvalda',
    'Vidalia',
    'Wadley',
    'Gainesville',
    'Chestnut Mountain',
    'Alto',
    'Baldwin',
    'Blairsville',
    'Blue Ridge',
    'Buford',
    'Bowersville',
    'Braselton',
    'Canon',
    'Carnesville',
    'Cherry Log',
    'Clarkesville',
    'Clayton',
    'Clermont',
    'Cleveland',
    'Commerce',
    'Cornelia',
    'Dahlonega',
    'Dawsonville',
    'Demorest',
    'Ellijay',
    'Dillard',
    'Eastanollee',
    'East Ellijay',
    'Epworth',
    'Flowery Branch',
    'Gillsville',
    'Habersham',
    'Helen',
    'Hiawassee',
    'Homer',
    'Hoschton',
    'Jefferson',
    'Lakemont',
    'Lavonia',
    'Lula',
    'Mc Caysville',
    'Martin',
    'Maysville',
    'Mineral Bluff',
    'Morganton',
    'Mountain City',
    'Mount Airy',
    'Murrayville',
    'Nicholson',
    'Oakwood',
    'Pendergrass',
    'Rabun Gap',
    'Sautee Nacoochee',
    'Suches',
    'Tallulah Falls',
    'Talmo',
    'Tiger',
    'Toccoa',
    'Turnerville',
    'Wiley',
    'Young Harris',
    'Toccoa Falls',
    'Athens',
    'Arnoldsville',
    'Bethlehem',
    'Bishop',
    'Bogart',
    'Bostwick',
    'Bowman',
    'Buckhead',
    'Carlton',
    'Colbert',
    'Comer',
    'Crawford',
    'Crawfordville',
    'Danielsville',
    'Dewy Rose',
    'Elberton',
    'Farmington',
    'Franklin Springs',
    'Good Hope',
    'Greensboro',
    'Hartwell',
    'High Shoals',
    'Hull',
    'Ila',
    'Lexington',
    'Madison',
    'Monroe',
    'Rayle',
    'Royston',
    'Rutledge',
    'Sharon',
    'Siloam',
    'Statham',
    'Stephens',
    'Tignall',
    'Union Point',
    'Maxeys',
    'Washington',
    'Watkinsville',
    'White Plains',
    'Winder',
    'Winterville',
    'Calhoun',
    'Chatsworth',
    'Chickamauga',
    'Cisco',
    'Cohutta',
    'Crandall',
    'Dalton',
    'Eton',
    'Flintstone',
    'Graysville',
    'La Fayette',
    'Lyerly',
    'Menlo',
    'Oakman',
    'Plainville',
    'Ranger',
    'Resaca',
    'Ringgold',
    'Rising Fawn',
    'Rock Spring',
    'Rocky Face',
    'Rossville',
    'Fort Oglethorpe',
    'Sugar Valley',
    'Summerville',
    'Lookout Mountain',
    'Tennga',
    'Trenton',
    'Trion',
    'Tunnel Hill',
    'Varnell',
    'Wildwood',
    'Appling',
    'Avera',
    'Blythe',
    'Boneville',
    'Camak',
    'Dearing',
    'Evans',
    'Gibson',
    'Gough',
    'Gracewood',
    'Grovetown',
    'Harlem',
    'Hephzibah',
    'Keysville',
    'Lincolnton',
    'Matthews',
    'Mesena',
    'Mitchell',
    'Norwood',
    'Perkins',
    'Stapleton',
    'Thomson',
    'Warrenton',
    'Waynesboro',
    'Wrens',
    'Augusta',
    'Abbeville',
    'Adrian',
    'Allentown',
    'Bolingbroke',
    'Bonaire',
    'Butler',
    'Byromville',
    'Byron',
    'Cadwell',
    'Cordele',
    'Chauncey',
    'Chester',
    'Clinchfield',
    'Cochran',
    'Culloden',
    'Danville',
    'Davisboro',
    'Dexter',
    'Dry Branch',
    'Dublin',
    'Dudley',
    'Eastman',
    'Eatonton',
    'Elko',
    'East Dublin',
    'Centerville',
    'Forsyth',
    'Fort Valley',
    'Gordon',
    'Gray',
    'Haddock',
    'Hardwick',
    'Harrison',
    'Hawkinsville',
    'Mc Rae Helena',
    'Hillsboro',
    'Howard',
    'Ideal',
    'Irwinton',
    'Jeffersonville',
    'Jewell',
    'Juliette',
    'Kathleen',
    'Kite',
    'Knoxville',
    'Lilly',
    'Lizella',
    'Mc Intyre',
    'Marshallville',
    'Mauk',
    'Milledgeville',
    'Milan',
    'Montezuma',
    'Monticello',
    'Montrose',
    'Musella',
    'Oconee',
    'Oglethorpe',
    'Perry',
    'Pinehurst',
    'Pineview',
    'Pitts',
    'Rentz',
    'Reynolds',
    'Rhine',
    'Roberta',
    'Rochelle',
    'Rupert',
    'Sandersville',
    'Scotland',
    'Seville',
    'Shady Dale',
    'Smarr',
    'Sparta',
    'Warner Robins',
    'Tennille',
    'Toomsboro',
    'Unadilla',
    'Vienna',
    'Warthen',
    'Wrightsville',
    'Yatesville',
    'Macon',
    'Allenhurst',
    'Bloomingdale',
    'Clyo',
    'Crescent',
    'Darien',
    'Eden',
    'Ellabell',
    'Fleming',
    'Hinesville',
    'Guyton',
    'Fort Stewart',
    'Ludowici',
    'Meldrim',
    'Meridian',
    'Midway',
    'Pembroke',
    'Pooler',
    'Riceboro',
    'Richmond Hill',
    'Rincon',
    'Sapelo Island',
    'Tybee Island',
    'Springfield',
    'Townsend',
    'Walthourville',
    'Savannah',
    'Port Wentworth',
    'Waycross',
    'Alma',
    'Ambrose',
    'Baxley',
    'Blackshear',
    'Bristol',
    'Broxton',
    'Brunswick',
    'Saint Simons Island',
    'Jekyll Island',
    'Denton',
    'Douglas',
    'Folkston',
    'Hazlehurst',
    'Hoboken',
    'Hortense',
    'Jacksonville',
    'Jesup',
    'Kings Bay',
    'Kingsland',
    'Lumber City',
    'Manor',
    'Mershon',
    'Millwood',
    'Nahunta',
    'Nicholls',
    'Odum',
    'Offerman',
    'Patterson',
    'Saint Marys',
    'Screven',
    'Sea Island',
    'Saint George',
    'Surrency',
    'Waresboro',
    'Waverly',
    'Waynesville',
    'West Green',
    'White Oak',
    'Woodbine',
    'Valdosta',
    'Adel',
    'Alapaha',
    'Argyle',
    'Axson',
    'Barney',
    'Boston',
    'Cecil',
    'Dixie',
    'Du Pont',
    'Fargo',
    'Hahira',
    'Homerville',
    'Lakeland',
    'Lake Park',
    'Lenox',
    'Morven',
    'Nashville',
    'Naylor',
    'Pearson',
    'Quitman',
    'Ray City',
    'Sparks',
    'Statenville',
    'Stockton',
    'Willacoochee',
    'Moody AFB',
    'Albany',
    'Americus',
    'Andersonville',
    'Arabi',
    'Ashburn',
    'Baconton',
    'Barwick',
    'Berlin',
    'Brookfield',
    'Camilla',
    'Chula',
    'Cobb',
    'Coolidge',
    'Cotton',
    'De Soto',
    'Doerun',
    'Ellenton',
    'Enigma',
    'Fitzgerald',
    'Funston',
    'Hartsfield',
    'Thomasville',
    'Irwinville',
    'Leesburg',
    'Leslie',
    'Meigs',
    'Moultrie',
    'Mystic',
    'Norman Park',
    'Oakfield',
    'Ochlocknee',
    'Ocilla',
    'Omega',
    'Pavo',
    'Pelham',
    'Plains',
    'Poulan',
    'Putney',
    'Rebecca',
    'Sale City',
    'Smithville',
    'Sumner',
    'Sycamore',
    'Sylvester',
    'Tifton',
    'TY TY',
    'Warwick',
    'Wray',
    'Box Springs',
    'Buena Vista',
    'Cataula',
    'Cusseta',
    'Ellaville',
    'Ellerslie',
    'Fortson',
    'Geneva',
    'Hamilton',
    'Junction City',
    'Louvale',
    'Lumpkin',
    'Manchester',
    'Midland',
    'Omaha',
    'Pine Mountain',
    'Pine Mountain Valley',
    'Preston',
    'Richland',
    'Shiloh',
    'Talbotton',
    'Upatoi',
    'Warm Springs',
    'Waverly Hall',
    'Weston',
    'West Point',
    'Woodland',
    'Columbus',
    'Fort Benning',
    'Arlington',
    'Attapulgus',
    'Bainbridge',
    'Blakely',
    'Bluffton',
    'Brinson',
    'Bronwood',
    'Cairo',
    'Calvary',
    'Cedar Springs',
    'Climax',
    'Coleman',
    'Colquitt',
    'Cuthbert',
    'Damascus',
    'Dawson',
    'Donalsonville',
    'Edison',
    'Fort Gaines',
    'Fowlstown',
    'Georgetown',
    'Iron City',
    'Jakin',
    'Leary',
    'Morgan',
    'Morris',
    'Newton',
    'Parrott',
    'Sasser',
    'Shellman',
    'Whigham'
  ],
  FL: [
    'Fleming Island',
    'Ponte Vedra Beach',
    'Bostwick',
    'Branford',
    'Bryceville',
    'Callahan',
    'Day',
    'Lake City',
    'Raiford',
    'Doctors Inlet',
    'Elkton',
    'Fernandina Beach',
    'Fort White',
    'Glen Saint Mary',
    'Yulee',
    'Graham',
    'Green Cove Springs',
    'Hampton',
    'Hilliard',
    'Middleburg',
    'Jasper',
    'Jennings',
    'Lake Butler',
    'Lawtey',
    'Lee',
    'Live Oak',
    'Lulu',
    'Mc Alpin',
    'Macclenny',
    'Orange Park',
    'Mayo',
    'O Brien',
    'Olustee',
    'Penney Farms',
    'Saint Augustine',
    'Ponte Vedra',
    'Sanderson',
    'Starke',
    'Wellborn',
    'White Springs',
    'Jacksonville',
    'Astor',
    'Barberville',
    'Bunnell',
    'Candler',
    'Crescent City',
    'Citra',
    'Daytona Beach',
    'Port Orange',
    'De Leon Springs',
    'East Palatka',
    'Edgewater',
    'Eastlake Weir',
    'Fort Mc Coy',
    'Palm Coast',
    'Flagler Beach',
    'Grandin',
    'Georgetown',
    'Florahome',
    'Hastings',
    'Hollister',
    'Interlachen',
    'Lake Como',
    'Lady Lake',
    'Lake Geneva',
    'The Villages',
    'New Smyrna Beach',
    'Ormond Beach',
    'Palatka',
    'Ocklawaha',
    'Pierson',
    'Pomona Park',
    'Orange Springs',
    'Putnam Hall',
    'San Mateo',
    'Satsuma',
    'Seville',
    'Sparr',
    'Welaka',
    'Weirsdale',
    'Atlantic Beach',
    'Jacksonville Beach',
    'Saint Johns',
    'Neptune Beach',
    'Tallahassee',
    'Apalachicola',
    'Bristol',
    'Carrabelle',
    'Lanark Village',
    'Chattahoochee',
    'Crawfordville',
    'Eastpoint',
    'Greensboro',
    'Greenville',
    'Gretna',
    'Havana',
    'Hosford',
    'Sumatra',
    'Lamont',
    'Lloyd',
    'Madison',
    'Midway',
    'Monticello',
    'Panacea',
    'Perry',
    'Pinetta',
    'Quincy',
    'Saint Marks',
    'Salem',
    'Shady Grove',
    'Sopchoppy',
    'Steinhatchee',
    'Telogia',
    'Wacissa',
    'Woodville',
    'Panama City',
    'Panama City Beach',
    'Mexico Beach',
    'Alford',
    'Altha',
    'Argyle',
    'Bascom',
    'Blountstown',
    'Bonifay',
    'Campbellton',
    'Caryville',
    'Chipley',
    'Clarksville',
    'Cottondale',
    'Cypress',
    'Defuniak Springs',
    'Mossy Head',
    'Ebro',
    'Fountain',
    'Freeport',
    'Graceville',
    'Grand Ridge',
    'Greenwood',
    'Lynn Haven',
    'Malone',
    'Marianna',
    'Wewahitchka',
    'Noma',
    'Point Washington',
    'Ponce De Leon',
    'Port Saint Joe',
    'Santa Rosa Beach',
    'Sneads',
    'Inlet Beach',
    'Vernon',
    'Wausau',
    'Westville',
    'Youngstown',
    'Pensacola',
    'Bagdad',
    'Baker',
    'Cantonment',
    'Century',
    'Crestview',
    'Milligan',
    'Paxton',
    'Destin',
    'Eglin AFB',
    'Hurlburt Field',
    'Fort Walton Beach',
    'Miramar Beach',
    'Gonzalez',
    'Gulf Breeze',
    'Holt',
    'Jay',
    'Navarre',
    'Laurel Hill',
    'Mc David',
    'Mary Esther',
    'Milton',
    'Molino',
    'Niceville',
    'Shalimar',
    'Valparaiso',
    'Gainesville',
    'Alachua',
    'Anthony',
    'Archer',
    'Bell',
    'Bronson',
    'Brooker',
    'Cedar Key',
    'Chiefland',
    'Cross City',
    'Earleton',
    'Evinston',
    'Fairfield',
    'Gulf Hammock',
    'Hawthorne',
    'High Springs',
    'Horseshoe Beach',
    'Island Grove',
    'Keystone Heights',
    'La Crosse',
    'Lochloosa',
    'Lowell',
    'Mc Intosh',
    'Melrose',
    'Micanopy',
    'Morriston',
    'Newberry',
    'Old Town',
    'Orange Lake',
    'Otter Creek',
    'Reddick',
    'Suwannee',
    'Trenton',
    'Waldo',
    'Williston',
    'Worthington Springs',
    'Altamonte Springs',
    'Altoona',
    'Apopka',
    'Cassadaga',
    'Casselberry',
    'Winter Springs',
    'Christmas',
    'Clarcona',
    'Debary',
    'Deland',
    'Glenwood',
    'Deltona',
    'Eustis',
    'Geneva',
    'Goldenrod',
    'Grand Island',
    'Lake Helen',
    'Mid Florida',
    'Lake Mary',
    'Lake Monroe',
    'Longwood',
    'Maitland',
    'Mims',
    'Mount Dora',
    'Oak Hill',
    'Oviedo',
    'Orange City',
    'Osteen',
    'Paisley',
    'Plymouth',
    'Sanford',
    'Scottsmoor',
    'Sorrento',
    'Tangerine',
    'Tavares',
    'Titusville',
    'Umatilla',
    'Winter Park',
    'Zellwood',
    'Orlando',
    'Melbourne',
    'Indialantic',
    'Palm Bay',
    'Cape Canaveral',
    'Cocoa',
    'Patrick AFB',
    'Cocoa Beach',
    'Satellite Beach',
    'Fellsmere',
    'Grant',
    'Malabar',
    'Melbourne Beach',
    'Merritt Island',
    'Rockledge',
    'Roseland',
    'Sebastian',
    'Sharpes',
    'Vero Beach',
    'Wabasso',
    'Winter Beach',
    'Long Key',
    'Hialeah',
    'Dania',
    'Hallandale',
    'Hollywood',
    'Pembroke Pines',
    'Homestead',
    'Islamorada',
    'Key Largo',
    'Key West',
    'Summerland Key',
    'Big Pine Key',
    'Marathon',
    'Key Colony Beach',
    'Marathon Shores',
    'Opa Locka',
    'Miami Gardens',
    'Pompano Beach',
    'Coral Springs',
    'Tavernier',
    'Margate',
    'Coconut Creek',
    'Miami',
    'Miami Beach',
    'Coral Gables',
    'Key Biscayne',
    'North Miami Beach',
    'Fort Lauderdale',
    'Plantation',
    'West Palm Beach',
    'North Palm Beach',
    'Palm Beach Gardens',
    'Wellington',
    'Royal Palm Beach',
    'Boynton Beach',
    'Boca Raton',
    'Belle Glade',
    'Canal Point',
    'Clewiston',
    'Deerfield Beach',
    'Delray Beach',
    'Lake Worth',
    'Greenacres',
    'Hobe Sound',
    'Jupiter',
    'Lake Harbor',
    'Lake Worth Beach',
    'Loxahatchee',
    'Moore Haven',
    'Pahokee',
    'Palm Beach',
    'South Bay',
    'Balm',
    'Brandon',
    'Bushnell',
    'Center Hill',
    'Coleman',
    'Dade City',
    'Crystal Springs',
    'Dover',
    'Durant',
    'Gibsonton',
    'Lacoochee',
    'Lake Panasoffkee',
    'Zephyrhills',
    'Wesley Chapel',
    'Lithia',
    'Lutz',
    'Mango',
    'Odessa',
    'Plant City',
    'Riverview',
    'Ruskin',
    'Sun City Center',
    'Apollo Beach',
    'Saint Leo',
    'San Antonio',
    'Seffner',
    'Sumterville',
    'Sun City',
    'Sydney',
    'Thonotosassa',
    'Trilby',
    'Valrico',
    'Webster',
    'Wimauma',
    'Tampa',
    'Saint Petersburg',
    'Bay Pines',
    'Clearwater',
    'Clearwater Beach',
    'Largo',
    'Seminole',
    'Pinellas Park',
    'Indian Rocks Beach',
    'Belleair Beach',
    'Lakeland',
    'Alturas',
    'Auburndale',
    'Avon Park',
    'Babson Park',
    'Bartow',
    'Bowling Green',
    'Bradley',
    'Davenport',
    'Dundee',
    'Eagle Lake',
    'Eaton Park',
    'Fort Meade',
    'Frostproof',
    'Haines City',
    'Highland City',
    'Homeland',
    'Intercession City',
    'Kathleen',
    'Lake Alfred',
    'Lake Hamilton',
    'Lake Placid',
    'Lake Wales',
    'Lakeshore',
    'Indian Lake Estates',
    'Nalcrest',
    'Lorida',
    'Loughman',
    'Mulberry',
    'Nichols',
    'Ona',
    'River Ranch',
    'Polk City',
    'Sebring',
    'Wauchula',
    'Waverly',
    'Winter Haven',
    'Zolfo Springs',
    'Fort Myers',
    'North Fort Myers',
    'Cape Coral',
    'Alva',
    'Boca Grande',
    'Bokeelia',
    'Captiva',
    'El Jobean',
    'Estero',
    'Felda',
    'Fort Myers Beach',
    'Labelle',
    'Lehigh Acres',
    'Murdock',
    'Palmdale',
    'Pineland',
    'Placida',
    'Rotonda West',
    'Port Charlotte',
    'Punta Gorda',
    'Saint James City',
    'Sanibel',
    'Venus',
    'Naples',
    'Bonita Springs',
    'Copeland',
    'Chokoloskee',
    'Everglades City',
    'Goodland',
    'Ochopee',
    'Immokalee',
    'Marco Island',
    'Bradenton',
    'Cortez',
    'Anna Maria',
    'Bradenton Beach',
    'Holmes Beach',
    'Parrish',
    'Palmetto',
    'Ellenton',
    'Englewood',
    'Longboat Key',
    'Osprey',
    'Sarasota',
    'Terra Ceia',
    'Myakka City',
    'Oneco',
    'Arcadia',
    'Fort Ogden',
    'Nocatee',
    'Tallevast',
    'Laurel',
    'Nokomis',
    'Venice',
    'North Port',
    'Belleview',
    'Crystal River',
    'Dunnellon',
    'Floral City',
    'Hernando',
    'Holder',
    'Homosassa',
    'Homosassa Springs',
    'Inglis',
    'Inverness',
    'Lecanto',
    'Beverly Hills',
    'Ocala',
    'Oxford',
    'Silver Springs',
    'Summerfield',
    'Yankeetown',
    'Brooksville',
    'Spring Hill',
    'Istachatta',
    'Land O Lakes',
    'New Port Richey',
    'Ozona',
    'Nobleton',
    'Hudson',
    'Port Richey',
    'Oldsmar',
    'Aripeka',
    'Elfers',
    'Crystal Beach',
    'Palm Harbor',
    'Tarpon Springs',
    'Holiday',
    'Safety Harbor',
    'Dunedin',
    'Astatula',
    'Clermont',
    'Ferndale',
    'Fruitland Park',
    'Gotha',
    'Groveland',
    'Howey In The Hills',
    'Kenansville',
    'Killarney',
    'Kissimmee',
    'Leesburg',
    'Mascotte',
    'Minneola',
    'Montverde',
    'Oakland',
    'Ocoee',
    'Okahumpka',
    'Saint Cloud',
    'Winter Garden',
    'Wildwood',
    'Windermere',
    'Yalaha',
    'Fort Pierce',
    'Port Saint Lucie',
    'Indiantown',
    'Jensen Beach',
    'Okeechobee',
    'Palm City',
    'Port Salerno',
    'Stuart'
  ],
  AA: [
    'Dpo',
    'Apo',
    'Fpo'
  ],
  AL: [
    'Moody',
    'Adamsville',
    'Adger',
    'Alabaster',
    'Alexander City',
    'Allgood',
    'Alpine',
    'Alton',
    'Arab',
    'Baileyton',
    'Bessemer',
    'Blountsville',
    'Bon Air',
    'Bremen',
    'Brent',
    'Brierfield',
    'Brookside',
    'Burnwell',
    'Calera',
    'Cardiff',
    'Centreville',
    'Chelsea',
    'Childersburg',
    'Clanton',
    'Clay',
    'Cleveland',
    'Columbiana',
    'Cook Springs',
    'Crane Hill',
    'Cropwell',
    'Cullman',
    'Docena',
    'Dolomite',
    'Dora',
    'Empire',
    'Fairfield',
    'Fultondale',
    'Garden City',
    'Gardendale',
    'Goodwater',
    'Graysville',
    'Green Pond',
    'Hanceville',
    'Harpersville',
    'Hayden',
    'Helena',
    'Hollins',
    'Holly Pond',
    'Jemison',
    'Joppa',
    'Kellyton',
    'Kimberly',
    'Leeds',
    'Lincoln',
    'Locust Fork',
    'Logan',
    'Mc Calla',
    'Margaret',
    'Montevallo',
    'Morris',
    'Mount Olive',
    'Mulga',
    'New Castle',
    'Odenville',
    'Oneonta',
    'Palmerdale',
    'Pelham',
    'Pell City',
    'Pinson',
    'Pleasant Grove',
    'Quinton',
    'Ragland',
    'Remlap',
    'Riverside',
    'Rockford',
    'Saginaw',
    'Sayre',
    'Shannon',
    'Shelby',
    'Siluria',
    'Springville',
    'Sterrett',
    'Sumiton',
    'Sycamore',
    'Sylacauga',
    'Talladega',
    'Thorsby',
    'Trafford',
    'Trussville',
    'Union Grove',
    'Vandiver',
    'Vincent',
    'Vinemont',
    'Warrior',
    'Watson',
    'Wattsville',
    'Weogufka',
    'West Blocton',
    'Westover',
    'Wilsonville',
    'Wilton',
    'Woodstock',
    'Birmingham',
    'Tuscaloosa',
    'Abernant',
    'Akron',
    'Aliceville',
    'Boligee',
    'Brookwood',
    'Buhl',
    'Carrollton',
    'Clinton',
    'Coaling',
    'Coker',
    'Cottondale',
    'Duncanville',
    'Echola',
    'Elrod',
    'Emelle',
    'Epes',
    'Ethelsville',
    'Eutaw',
    'Fosters',
    'Gainesville',
    'Gordo',
    'Kellerman',
    'Knoxville',
    'Livingston',
    'Mc Shan',
    'Northport',
    'Moundville',
    'Panola',
    'Peterson',
    'Ralph',
    'Reform',
    'Samantha',
    'Vance',
    'West Greene',
    'Jasper',
    'Addison',
    'Arley',
    'Bankston',
    'Bear Creek',
    'Beaverton',
    'Belk',
    'Berry',
    'Brilliant',
    'Carbon Hill',
    'Cordova',
    'Delmar',
    'Detroit',
    'Double Springs',
    'Eldridge',
    'Fayette',
    'Glen Allen',
    'Goodsprings',
    'Guin',
    'Hackleburg',
    'Haleyville',
    'Hamilton',
    'Hodges',
    'Houston',
    'Kansas',
    'Kennedy',
    'Lynn',
    'Millport',
    'Natural Bridge',
    'Nauvoo',
    'Oakman',
    'Parrish',
    'Phil Campbell',
    'Red Bay',
    'Sipsey',
    'Spruce Pine',
    'Sulligent',
    'Townley',
    'Vernon',
    'Vina',
    'Winfield',
    'Decatur',
    'Anderson',
    'Athens',
    'Belle Mina',
    'Cherokee',
    'Cloverdale',
    'Courtland',
    'Danville',
    'Elkmont',
    'Eva',
    'Falkville',
    'Florence',
    'Hartselle',
    'Hillsboro',
    'Killen',
    'Leighton',
    'Lester',
    'Lexington',
    'Mooresville',
    'Moulton',
    'Mount Hope',
    'Rogersville',
    'Russellville',
    'Sheffield',
    'Muscle Shoals',
    'Somerville',
    'Tanner',
    'Town Creek',
    'Trinity',
    'Tuscumbia',
    'Waterloo',
    'Ardmore',
    'Bridgeport',
    'Brownsboro',
    'Capshaw',
    'Dutton',
    'Estillfork',
    'Fackler',
    'Grant',
    'Gurley',
    'Harvest',
    'Hazel Green',
    'Hollytree',
    'Hollywood',
    'Laceys Spring',
    'Langston',
    'Madison',
    'Meridianville',
    'New Hope',
    'New Market',
    'Normal',
    'Owens Cross Roads',
    'Paint Rock',
    'Pisgah',
    'Princeton',
    'Ryland',
    'Scottsboro',
    'Section',
    'Stevenson',
    'Toney',
    'Trenton',
    'Valhermoso Springs',
    'Woodville',
    'Huntsville',
    'Gadsden',
    'Rainbow City',
    'Albertville',
    'Altoona',
    'Ashville',
    'Attalla',
    'Boaz',
    'Bryant',
    'Cedar Bluff',
    'Centre',
    'Collinsville',
    'Crossville',
    'Dawson',
    'Douglas',
    'Flat Rock',
    'Fort Payne',
    'Fyffe',
    'Gallant',
    'Gaylesville',
    'Geraldine',
    'Groveoak',
    'Guntersville',
    'Henagar',
    'Higdon',
    'Horton',
    'Ider',
    'Leesburg',
    'Mentone',
    'Rainsville',
    'Steele',
    'Sylvania',
    'Valley Head',
    'Walnut Grove',
    'Autaugaville',
    'Banks',
    'Billingsley',
    'Booth',
    'Brantley',
    'Brundidge',
    'Cecil',
    'Chapman',
    'Clayton',
    'Clio',
    'Coosada',
    'Deatsville',
    'East Tallassee',
    'Eclectic',
    'Elmore',
    'Equality',
    'Eufaula',
    'Dozier',
    'Fitzpatrick',
    'Forest Home',
    'Fort Davis',
    'Fort Deposit',
    'Georgiana',
    'Glenwood',
    'Goshen',
    'Grady',
    'Greenville',
    'Gantt',
    'Hardaway',
    'Hayneville',
    'Highland Home',
    'Honoraville',
    'Hope Hull',
    'Kent',
    'Lapine',
    'Letohatchee',
    'Louisville',
    'Luverne',
    'Marbury',
    'Mathews',
    'Midway',
    'Millbrook',
    'Mount Meigs',
    'Perote',
    'Petrey',
    'Pike Road',
    'Pine Level',
    'Prattville',
    'Ramer',
    'Rutledge',
    'Shorter',
    'Tallassee',
    'Troy',
    'Titus',
    'Tuskegee',
    'Tuskegee Institute',
    'Union Springs',
    'Verbena',
    'Wetumpka',
    'Montgomery',
    'Anniston',
    'Oxford',
    'Alexandria',
    'Ashland',
    'Bynum',
    'Choccolocco',
    'Cragford',
    'Daviston',
    'De Armanville',
    'Delta',
    'Eastaboga',
    'Edwardsville',
    'Fruithurst',
    'Graham',
    'Heflin',
    'Jacksonville',
    'Lineville',
    'Millerville',
    'Munford',
    'Muscadine',
    'Ohatchee',
    'Piedmont',
    'Ranburne',
    'Roanoke',
    'Spring Garden',
    'Wadley',
    'Weaver',
    'Wedowee',
    'Wellington',
    'Woodland',
    'Dothan',
    'Abbeville',
    'Ariton',
    'Ashford',
    'Bellwood',
    'Black',
    'Chancellor',
    'Clopton',
    'Coffee Springs',
    'Columbia',
    'Cottonwood',
    'Cowarts',
    'Daleville',
    'Elba',
    'Enterprise',
    'Geneva',
    'Gordon',
    'Hartford',
    'Headland',
    'Jack',
    'Malvern',
    'Midland City',
    'New Brockton',
    'Newton',
    'Newville',
    'Ozark',
    'Fort Rucker',
    'Pansey',
    'Pinckard',
    'Shorterville',
    'Skipperville',
    'Slocomb',
    'Webb',
    'Evergreen',
    'Andalusia',
    'Beatrice',
    'Brewton',
    'Brooklyn',
    'Castleberry',
    'Coy',
    'Dickinson',
    'Excel',
    'Flomaton',
    'Florala',
    'Franklin',
    'Frisco City',
    'Fulton',
    'Uriah',
    'Grove Hill',
    'Kinston',
    'Lenox',
    'Lockhart',
    'Mc Kenzie',
    'Megargel',
    'Mexia',
    'Monroeville',
    'Opp',
    'Perdue Hill',
    'Peterman',
    'Range',
    'Red Level',
    'Repton',
    'River Falls',
    'Samson',
    'Vredenburgh',
    'Whatley',
    'Wing',
    'Jackson',
    'Atmore',
    'Axis',
    'Bay Minette',
    'Bayou La Batre',
    'Bon Secour',
    'Bucks',
    'Calvert',
    'Carlton',
    'Chatom',
    'Chunchula',
    'Citronelle',
    'Coden',
    'Coffeeville',
    'Creola',
    'Daphne',
    'Dauphin Island',
    'Deer Park',
    'Elberta',
    'Fairhope',
    'Foley',
    'Frankville',
    'Fruitdale',
    'Gainestown',
    'Grand Bay',
    'Gulf Shores',
    'Huxford',
    'Irvington',
    'Leroy',
    'Lillian',
    'Little River',
    'Loxley',
    'Mc Intosh',
    'Magnolia Springs',
    'Malcolm',
    'Millry',
    'Montrose',
    'Mount Vernon',
    'Orange Beach',
    'Perdido',
    'Point Clear',
    'Robertsdale',
    'Saint Elmo',
    'Saint Stephens',
    'Saraland',
    'Satsuma',
    'Seminole',
    'Semmes',
    'Silverhill',
    'Spanish Fort',
    'Stapleton',
    'Stockton',
    'Summerdale',
    'Sunflower',
    'Theodore',
    'Tibbie',
    'Vinegar Bend',
    'Wagarville',
    'Wilmer',
    'Mobile',
    'Eight Mile',
    'Selma',
    'Alberta',
    'Annemanie',
    'Arlington',
    'Boykin',
    'Camden',
    'Campbell',
    'Catherine',
    'Demopolis',
    'Dixons Mills',
    'Faunsdale',
    'Forkland',
    'Furman',
    'Gallion',
    'Greensboro',
    'Jefferson',
    'Linden',
    'Jones',
    'Maplesville',
    'Lower Peach Tree',
    'Lowndesboro',
    'Mc Williams',
    'Magnolia',
    'Marion',
    'Plantersville',
    'Marion Junction',
    'Minter',
    'Morvin',
    'Myrtlewood',
    'Nanafalia',
    'Newbern',
    'Oak Hill',
    'Orrville',
    'Pine Apple',
    'Pine Hill',
    'Safford',
    'Sardis',
    'Sawyerville',
    'Sweet Water',
    'Thomaston',
    'Thomasville',
    'Tyler',
    'Uniontown',
    'Stanton',
    'Randolph',
    'Lawley',
    'Opelika',
    'Auburn',
    'Auburn University',
    'Camp Hill',
    'Cottonton',
    'Cusseta',
    'Dadeville',
    'Valley',
    'Five Points',
    'Fort Mitchell',
    'Hatchechubbee',
    'Holy Trinity',
    'Hurtsboro',
    'Jacksons Gap',
    'Lafayette',
    'Lanett',
    'Loachapoka',
    'Notasulga',
    'Phenix City',
    'Pittsview',
    'Salem',
    'Seale',
    'Smiths Station',
    'Waverly',
    'Bellamy',
    'Butler',
    'Cuba',
    'Gilbertown',
    'Jachin',
    'Lisman',
    'Melvin',
    'Needham',
    'Pennington',
    'Silas',
    'Toxey',
    'Ward',
    'York'
  ],
  TN: [
    'Adams',
    'Antioch',
    'Alexandria',
    'Arrington',
    'Ashland City',
    'Auburntown',
    'Beechgrove',
    'Belfast',
    'Bell Buckle',
    'Bethpage',
    'Big Rock',
    'Brentwood',
    'Bon Aqua',
    'Bradyville',
    'Bumpus Mills',
    'Burns',
    'Carthage',
    'Castalian Springs',
    'Cedar Hill',
    'Centerville',
    'Chapel Hill',
    'Chapmansboro',
    'Charlotte',
    'Christiana',
    'Clarksville',
    'College Grove',
    'Cornersville',
    'Cottontown',
    'Cross Plains',
    'Cumberland City',
    'Cumberland Furnace',
    'Cunningham',
    'Dickson',
    'Dixon Springs',
    'Dover',
    'Dowelltown',
    'Eagleville',
    'Erin',
    'Fairview',
    'Fosterville',
    'Franklin',
    'Gallatin',
    'Goodlettsville',
    'Gladeville',
    'Greenbrier',
    'Hartsville',
    'Hendersonville',
    'Hermitage',
    'Hurricane Mills',
    'Indian Mound',
    'Joelton',
    'Kingston Springs',
    'Lafayette',
    'Lascassas',
    'La Vergne',
    'Lebanon',
    'Lewisburg',
    'Liberty',
    'Linden',
    'Lobelville',
    'Lyles',
    'Mc Ewen',
    'Mcminnville',
    'Madison',
    'Milton',
    'Mitchellville',
    'Mount Juliet',
    'Murfreesboro',
    'New Johnsonville',
    'Nolensville',
    'Norene',
    'Nunnelly',
    'Old Hickory',
    'Only',
    'Orlinda',
    'Palmyra',
    'Pegram',
    'Petersburg',
    'Pleasant Shade',
    'Pleasant View',
    'Portland',
    'Readyville',
    'Red Boiling Springs',
    'Riddleton',
    'Ridgetop',
    'Rockvale',
    'Shelbyville',
    'Slayden',
    'Smithville',
    'Smyrna',
    'Southside',
    'Springfield',
    'Spring Hill',
    'Stewart',
    'Tennessee Ridge',
    'Thompsons Station',
    'Unionville',
    'Vanleer',
    'Wartrace',
    'Watertown',
    'Waverly',
    'Westmoreland',
    'White Bluff',
    'White House',
    'Whites Creek',
    'Woodbury',
    'Woodlawn',
    'Nashville',
    'Altamont',
    'Apison',
    'Athens',
    'Bakewell',
    'Beersheba Springs',
    'Belvidere',
    'Benton',
    'Birchwood',
    'Calhoun',
    'Charleston',
    'Cleveland',
    'Coalmont',
    'Coker Creek',
    'Collegedale',
    'Conasauga',
    'Copperhill',
    'Cowan',
    'Dayton',
    'Decatur',
    'Decherd',
    'Delano',
    'Ducktown',
    'Dunlap',
    'Elora',
    'Englewood',
    'Estill Springs',
    'Etowah',
    'Evensville',
    'Farner',
    'Fayetteville',
    'Flintville',
    'Georgetown',
    'Grandview',
    'Graysville',
    'Gruetli Laager',
    'Guild',
    'Harrison',
    'Hillsboro',
    'Hixson',
    'Huntland',
    'Jasper',
    'Kelso',
    'Manchester',
    'Lookout Mountain',
    'Lupton City',
    'Lynchburg',
    'Mc Donald',
    'Madisonville',
    'Monteagle',
    'Morrison',
    'Mulberry',
    'Normandy',
    'Ocoee',
    'Old Fort',
    'Ooltewah',
    'Palmer',
    'Pelham',
    'Pikeville',
    'Reliance',
    'Riceville',
    'Sale Creek',
    'Sequatchie',
    'Sewanee',
    'Sherwood',
    'Signal Mountain',
    'Smartt',
    'Soddy Daisy',
    'South Pittsburg',
    'Spring City',
    'Summitville',
    'Tellico Plains',
    'Tracy City',
    'Tullahoma',
    'Arnold AFB',
    'Turtletown',
    'Viola',
    'Whiteside',
    'Whitwell',
    'Winchester',
    'Chattanooga',
    'Memphis',
    'Johnson City',
    'Afton',
    'Blountville',
    'Bluff City',
    'Bristol',
    'Butler',
    'Chuckey',
    'Church Hill',
    'Elizabethton',
    'Mount Carmel',
    'Erwin',
    'Fall Branch',
    'Flag Pond',
    'Hampton',
    'Jonesborough',
    'Kingsport',
    'Laurel Bloomery',
    'Limestone',
    'Milligan College',
    'Mountain City',
    'Mountain Home',
    'Piney Flats',
    'Roan Mountain',
    'Shady Valley',
    'Telford',
    'Trade',
    'Unicoi',
    'Watauga',
    'Alcoa',
    'Andersonville',
    'Arthur',
    'Bean Station',
    'Blaine',
    'Briceville',
    'Bulls Gap',
    'Bybee',
    'Caryville',
    'Clairfield',
    'Clinton',
    'Coalfield',
    'Corryton',
    'Cosby',
    'Crab Orchard',
    'Cumberland Gap',
    'Dandridge',
    'Deer Lodge',
    'Del Rio',
    'Duff',
    'Eagan',
    'Eidson',
    'Elgin',
    'Rugby',
    'Friendsville',
    'Gatlinburg',
    'Greenback',
    'Greeneville',
    'Harriman',
    'Harrogate',
    'Hartford',
    'Heiskell',
    'Helenwood',
    'Huntsville',
    'Jacksboro',
    'Jefferson City',
    'Jellico',
    'Kingston',
    'Kodak',
    'Kyles Ford',
    'La Follette',
    'Rocky Top',
    'Lancing',
    'Lenoir City',
    'Lone Mountain',
    'Loudon',
    'Louisville',
    'Lowland',
    'Luttrell',
    'Maryville',
    'Mascot',
    'Maynardville',
    'Midway',
    'Mohawk',
    'Mooresburg',
    'Morristown',
    'Mosheim',
    'Newcomb',
    'New Market',
    'Newport',
    'New Tazewell',
    'Niota',
    'Norris',
    'Oakdale',
    'Oak Ridge',
    'Oliver Springs',
    'Oneida',
    'Parrottsville',
    'Petros',
    'Philadelphia',
    'Pioneer',
    'Powder Springs',
    'Powell',
    'Pruden',
    'Robbins',
    'Rockford',
    'Rockwood',
    'Rogersville',
    'Russellville',
    'Rutledge',
    'Sevierville',
    'Pigeon Forge',
    'Seymour',
    'Sharps Chapel',
    'Shawanee',
    'Sneedville',
    'Speedwell',
    'Strawberry Plains',
    'Sunbright',
    'Surgoinsville',
    'Sweetwater',
    'Talbott',
    'Tallassee',
    'Tazewell',
    'Ten Mile',
    'Thorn Hill',
    'Townsend',
    'Vonore',
    'Walland',
    'Wartburg',
    'Washburn',
    'White Pine',
    'Whitesburg',
    'Winfield',
    'Knoxville',
    'Farragut',
    'Alamo',
    'Arlington',
    'Atoka',
    'Bells',
    'Bogota',
    'Bolivar',
    'Braden',
    'Brighton',
    'Brownsville',
    'Brunswick',
    'Burlison',
    'Cordova',
    'Collierville',
    'Covington',
    'Crockett Mills',
    'Drummonds',
    'Dyersburg',
    'Eads',
    'Ellendale',
    'Finley',
    'Friendship',
    'Gallaway',
    'Gates',
    'Grand Junction',
    'Halls',
    'Henning',
    'Hickory Valley',
    'Hornsby',
    'Laconia',
    'La Grange',
    'Lenox',
    'Macon',
    'Mason',
    'Maury City',
    'Middleton',
    'Millington',
    'Moscow',
    'Munford',
    'Newbern',
    'Oakland',
    'Pocahontas',
    'Ripley',
    'Rossville',
    'Saulsbury',
    'Somerville',
    'Stanton',
    'Tigrett',
    'Tipton',
    'Whiteville',
    'Williston',
    'Wynnburg',
    'Tiptonville',
    'Ridgely',
    'Germantown',
    'Mc Kenzie',
    'Atwood',
    'Big Sandy',
    'Buchanan',
    'Como',
    'Cottage Grove',
    'Dresden',
    'Dukedom',
    'Elbridge',
    'Gleason',
    'Greenfield',
    'Henry',
    'Hornbeak',
    'Kenton',
    'Mc Lemoresville',
    'Mansfield',
    'Martin',
    'Obion',
    'Palmersville',
    'Paris',
    'Puryear',
    'Rives',
    'Samburg',
    'Sharon',
    'Springville',
    'South Fulton',
    'Trezevant',
    'Trimble',
    'Troy',
    'Union City',
    'Woodland Mills',
    'Jackson',
    'Adamsville',
    'Bath Springs',
    'Beech Bluff',
    'Bethel Springs',
    'Bradford',
    'Bruceton',
    'Buena Vista',
    'Camden',
    'Cedar Grove',
    'Clarksburg',
    'Counce',
    'Crump',
    'Darden',
    'Decaturville',
    'Dyer',
    'Eaton',
    'Enville',
    'Eva',
    'Finger',
    'Fruitvale',
    'Gadsden',
    'Gibson',
    'Guys',
    'Henderson',
    'Holladay',
    'Hollow Rock',
    'Humboldt',
    'Huntingdon',
    'Huron',
    'Idlewild',
    'Jacks Creek',
    'Lavinia',
    'Lexington',
    'Luray',
    'Medina',
    'Medon',
    'Michie',
    'Milan',
    'Milledgeville',
    'Morris Chapel',
    'Oakfield',
    'Parsons',
    'Pickwick Dam',
    'Pinson',
    'Ramer',
    'Reagan',
    'Rutherford',
    'Saltillo',
    'Sardis',
    'Savannah',
    'Scotts Hill',
    'Selmer',
    'Shiloh',
    'Silerton',
    'Spring Creek',
    'Stantonville',
    'Sugar Tree',
    'Toone',
    'Trenton',
    'Westport',
    'Wildersville',
    'Yorkville',
    'Yuma',
    'Denmark',
    'Mercer',
    'Chewalla',
    'Columbia',
    'Clifton',
    'Ardmore',
    'Collinwood',
    'Culleoka',
    'Cypress Inn',
    'Dellrose',
    'Duck River',
    'Elkton',
    'Ethridge',
    'Five Points',
    'Frankewing',
    'Goodspring',
    'Hampshire',
    'Hohenwald',
    'Iron City',
    'Lawrenceburg',
    'Leoma',
    'Loretto',
    'Lutts',
    'Lynnville',
    'Minor Hill',
    'Mount Pleasant',
    'Olivehill',
    'Primm Springs',
    'Prospect',
    'Pulaski',
    'Saint Joseph',
    'Santa Fe',
    'Summertown',
    'Waynesboro',
    'Westpoint',
    'Williamsport',
    'Taft',
    'Cookeville',
    'Allardt',
    'Allons',
    'Allred',
    'Alpine',
    'Baxter',
    'Bloomington Springs',
    'Brush Creek',
    'Buffalo Valley',
    'Byrdstown',
    'Campaign',
    'Celina',
    'Chestnut Mound',
    'Clarkrange',
    'Crawford',
    'Crossville',
    'Jamestown',
    'Doyle',
    'Elmwood',
    'Gainesboro',
    'Gordonsville',
    'Granville',
    'Grimsley',
    'Hickman',
    'Hilham',
    'Lancaster',
    'Livingston',
    'Monroe',
    'Monterey',
    'Moss',
    'Pall Mall',
    'Pleasant Hill',
    'Quebeck',
    'Rickman',
    'Rock Island',
    'Silver Point',
    'Sparta',
    'Spencer',
    'Walling',
    'Whitleyville',
    'Wilder'
  ],
  MS: [
    'Abbeville',
    'Arkabutla',
    'Ashland',
    'Batesville',
    'Marks',
    'Blue Mountain',
    'Byhalia',
    'Clarksdale',
    'Coahoma',
    'Coldwater',
    'Como',
    'Courtland',
    'Crenshaw',
    'Crowder',
    'Darling',
    'Dumas',
    'Dundee',
    'Etta',
    'Sledge',
    'Falkner',
    'Farrell',
    'Friars Point',
    'Hernando',
    'Hickory Flat',
    'Holly Springs',
    'Horn Lake',
    'Independence',
    'Jonestown',
    'Lake Cormorant',
    'Lamar',
    'Lambert',
    'Lula',
    'Lyon',
    'Michigan City',
    'Mount Pleasant',
    'Myrtle',
    'Nesbit',
    'New Albany',
    'Olive Branch',
    'Oxford',
    'Pope',
    'Potts Camp',
    'Red Banks',
    'Ripley',
    'Robinsonville',
    'Sarah',
    'Sardis',
    'Senatobia',
    'Sherard',
    'Southaven',
    'Taylor',
    'Tiplersville',
    'Memphis Stc',
    'Tunica',
    'University',
    'Victoria',
    'Walls',
    'Walnut',
    'Waterford',
    'Greenville',
    'Alligator',
    'Anguilla',
    'Arcola',
    'Avon',
    'Benoit',
    'Beulah',
    'Boyle',
    'Chatham',
    'Cleveland',
    'Doddsville',
    'Drew',
    'Parchman',
    'Dublin',
    'Duncan',
    'Glen Allan',
    'Grace',
    'Gunnison',
    'Hollandale',
    'Indianola',
    'Inverness',
    'Isola',
    'Leland',
    'Merigold',
    'Metcalfe',
    'Moorhead',
    'Mound Bayou',
    'Pace',
    'Panther Burn',
    'Rena Lara',
    'Rome',
    'Rosedale',
    'Ruleville',
    'Scott',
    'Shaw',
    'Shelby',
    'Stoneville',
    'Sunflower',
    'Wayside',
    'Winstonville',
    'Winterville',
    'Tupelo',
    'Algoma',
    'Amory',
    'Baldwyn',
    'Becker',
    'Belden',
    'Belmont',
    'Blue Springs',
    'Booneville',
    'Burnsville',
    'Corinth',
    'Dennis',
    'Derma',
    'Ecru',
    'Fulton',
    'Gattman',
    'Glen',
    'Golden',
    'Greenwood Springs',
    'Guntown',
    'Houlka',
    'Houston',
    'Iuka',
    'Mantachie',
    'Marietta',
    'Mooreville',
    'Nettleton',
    'New Site',
    'Okolona',
    'Plantersville',
    'Pontotoc',
    'Randolph',
    'Rienzi',
    'Saltillo',
    'Shannon',
    'Sherman',
    'Smithville',
    'Thaxton',
    'Tishomingo',
    'Toccopola',
    'Trebloc',
    'Tremont',
    'Van Vleet',
    'Vardaman',
    'Verona',
    'Wheeler',
    'Grenada',
    'Banner',
    'Big Creek',
    'Bruce',
    'Calhoun City',
    'Carrollton',
    'Cascilla',
    'Charleston',
    'Coffeeville',
    'Coila',
    'Cruger',
    'Duck Hill',
    'Elliott',
    'Enid',
    'Glendora',
    'Gore Springs',
    'Greenwood',
    'Holcomb',
    'Itta Bena',
    'Mc Carley',
    'Minter City',
    'Morgan City',
    'North Carrollton',
    'Oakland',
    'Paris',
    'Philipp',
    'Pittsboro',
    'Schlater',
    'Scobey',
    'Sidon',
    'Slate Spring',
    'Sumner',
    'Swan Lake',
    'Swiftown',
    'Tie Plant',
    'Tillatoba',
    'Tippo',
    'Tutwiler',
    'Vance',
    'Water Valley',
    'Webb',
    'Winona',
    'Belzoni',
    'Benton',
    'Bentonia',
    'Bolton',
    'Brandon',
    'Braxton',
    'Camden',
    'Canton',
    'Carthage',
    'Cary',
    'Clinton',
    'Conehatta',
    'Crystal Springs',
    'Delta City',
    'D LO',
    'Durant',
    'Edwards',
    'Ethel',
    'Fayette',
    'Flora',
    'Pocahontas',
    'Florence',
    'Forest',
    'Gallman',
    'Georgetown',
    'Goodman',
    'Harperville',
    'Harrisville',
    'Hazlehurst',
    'Hermanville',
    'Hillsboro',
    'Holly Bluff',
    'Kosciusko',
    'Lake',
    'Lena',
    'Lexington',
    'Lorman',
    'Louise',
    'Ludlow',
    'Mc Adams',
    'Mc Cool',
    'Madden',
    'Madison',
    'Magee',
    'Mayersville',
    'Mendenhall',
    'Midnight',
    'Mize',
    'Morton',
    'Mount Olive',
    'Natchez',
    'Newhebron',
    'Pattison',
    'Pelahatchie',
    'Pickens',
    'Piney Woods',
    'Pinola',
    'Port Gibson',
    'Puckett',
    'Pulaski',
    'Raleigh',
    'Raymond',
    'Redwood',
    'Ridgeland',
    'Rolling Fork',
    'Sallis',
    'Sandhill',
    'Satartia',
    'Sharon',
    'Sibley',
    'Silver City',
    'Star',
    'Taylorsville',
    'Tchula',
    'Terry',
    'Thomastown',
    'Tinsley',
    'Tougaloo',
    'Utica',
    'Vaiden',
    'Valley Park',
    'Vaughan',
    'Vicksburg',
    'Walnut Grove',
    'Washington',
    'Wesson',
    'West',
    'Whitfield',
    'Yazoo City',
    'Jackson',
    'Pearl',
    'Richland',
    'Flowood',
    'Byram',
    'Meridian',
    'Bailey',
    'Buckatunna',
    'Chunky',
    'Clara',
    'Collinsville',
    'Daleville',
    'Decatur',
    'De Kalb',
    'Enterprise',
    'Hickory',
    'Lauderdale',
    'Lawrence',
    'Little Rock',
    'Louin',
    'Louisville',
    'Macon',
    'Marion',
    'Newton',
    'Noxapater',
    'Pachuta',
    'Paulding',
    'Philadelphia',
    'Porterville',
    'Preston',
    'Quitman',
    'Rose Hill',
    'Scooba',
    'Sebastopol',
    'Shubuta',
    'Shuqualak',
    'State Line',
    'Stonewall',
    'Toomsuba',
    'Union',
    'Vossburg',
    'Waynesboro',
    'Hattiesburg',
    'Bassfield',
    'Bay Springs',
    'Beaumont',
    'Brooklyn',
    'Carriere',
    'Carson',
    'Collins',
    'Columbia',
    'Eastabuchie',
    'Ellisville',
    'Heidelberg',
    'Laurel',
    'Leakesville',
    'Lucedale',
    'Lumberton',
    'Mc Lain',
    'Mc Neill',
    'Moselle',
    'Moss',
    'Neely',
    'New Augusta',
    'Nicholson',
    'Ovett',
    'Petal',
    'Picayune',
    'Poplarville',
    'Prentiss',
    'Purvis',
    'Richton',
    'Sandersville',
    'Sandy Hook',
    'Seminary',
    'Soso',
    'Stringer',
    'Sumrall',
    'Foxworth',
    'Gulfport',
    'Bay Saint Louis',
    'Stennis Space Center',
    'Diamondhead',
    'Biloxi',
    'Diberville',
    'Escatawpa',
    'Gautier',
    'Hurley',
    'Kiln',
    'Lakeshore',
    'Long Beach',
    'Mc Henry',
    'Moss Point',
    'Ocean Springs',
    'Vancleave',
    'Pascagoula',
    'Pass Christian',
    'Pearlington',
    'Perkinston',
    'Saucier',
    'Waveland',
    'Wiggins',
    'Brookhaven',
    'Bogue Chitto',
    'Bude',
    'Centreville',
    'Chatawa',
    'Crosby',
    'Fernwood',
    'Gloster',
    'Jayess',
    'Kokomo',
    'Liberty',
    'Mc Call Creek',
    'Mccomb',
    'Magnolia',
    'Meadville',
    'Monticello',
    'Oak Vale',
    'Osyka',
    'Roxie',
    'Ruth',
    'Silver Creek',
    'Smithdale',
    'Sontag',
    'Summit',
    'Tylertown',
    'Union Church',
    'Woodville',
    'Columbus',
    'Aberdeen',
    'Ackerman',
    'Artesia',
    'Bellefontaine',
    'Brooksville',
    'Caledonia',
    'Cedarbluff',
    'Crawford',
    'Eupora',
    'French Camp',
    'Hamilton',
    'Kilmichael',
    'Maben',
    'Mantee',
    'Mathiston',
    'Mayhew',
    'Montpelier',
    'Pheba',
    'Prairie',
    'Starkville',
    'Mississippi State',
    'Steens',
    'Stewart',
    'Sturgis',
    'Walthall',
    'Weir',
    'West Point',
    'Woodland'
  ],
  KY: [
    'Bagdad',
    'Bardstown',
    'Bedford',
    'Bethlehem',
    'Bloomfield',
    'Bradfordsville',
    'Buckner',
    'Campbellsburg',
    'Chaplin',
    'Coxs Creek',
    'Crestwood',
    'Eastwood',
    'Eminence',
    'Fairfield',
    'Finchville',
    'Fisherville',
    'Glenview',
    'Goshen',
    'Harrods Creek',
    'La Grange',
    'Lebanon',
    'Lockport',
    'Loretto',
    'Mackville',
    'Masonic Home',
    'Milton',
    'Mount Eden',
    'Mount Washington',
    'Nazareth',
    'Nerinx',
    'New Castle',
    'New Haven',
    'New Hope',
    'Pendleton',
    'Pewee Valley',
    'Pleasureville',
    'Port Royal',
    'Prospect',
    'Raywick',
    'Saint Catharine',
    'Saint Francis',
    'Saint Mary',
    'Shelbyville',
    'Simpsonville',
    'Smithfield',
    'Springfield',
    'Sulphur',
    'Taylorsville',
    'Turners Station',
    'Waddy',
    'Westport',
    'Willisburg',
    'Battletown',
    'Boston',
    'Brandenburg',
    'Brooks',
    'Clermont',
    'Cloverport',
    'Custer',
    'Ekron',
    'Fairdale',
    'Falls Of Rough',
    'Fort Knox',
    'Hillview',
    'Garfield',
    'Guston',
    'Hardinsburg',
    'Harned',
    'Hudson',
    'Irvington',
    'Lebanon Junction',
    'Mc Daniels',
    'Mc Quady',
    'Muldraugh',
    'Payneville',
    'Radcliff',
    'Rhodelia',
    'Rineyville',
    'Shepherdsville',
    'Stephensport',
    'Union Star',
    'Vine Grove',
    'Webster',
    'West Point',
    'Westview',
    'Louisville',
    'Burgin',
    'Carlisle',
    'Clay City',
    'Clearfield',
    'Denniston',
    'Elliottville',
    'Farmers',
    'Frenchburg',
    'Georgetown',
    'Gravel Switch',
    'Harrodsburg',
    'Hope',
    'Irvine',
    'Jeffersonville',
    'Keene',
    'Nicholasville',
    'Lawrenceburg',
    'Means',
    'Midway',
    'Millersburg',
    'Moorefield',
    'Morehead',
    'Mount Sterling',
    'New Liberty',
    'North Middletown',
    'Olympia',
    'Owenton',
    'Owingsville',
    'Paris',
    'Perry Park',
    'Preston',
    'Sadieville',
    'Salt Lick',
    'Salvisa',
    'Sharpsburg',
    'Slade',
    'Stamping Ground',
    'Stanton',
    'Versailles',
    'Waco',
    'Wellington',
    'Wilmore',
    'Winchester',
    'Annville',
    'Berea',
    'Bighill',
    'Brodhead',
    'Bryantsville',
    'Crab Orchard',
    'Danville',
    'Gray Hawk',
    'Hustonville',
    'Junction City',
    'Kings Mountain',
    'Lancaster',
    'Livingston',
    'Mc Kee',
    'Mc Kinney',
    'Mitchellsburg',
    'Mount Vernon',
    'Orlando',
    'Paint Lick',
    'Parksville',
    'Perryville',
    'Ravenna',
    'Renfro Valley',
    'Richmond',
    'Sandgap',
    'Stanford',
    'Tyner',
    'Waneta',
    'Waynesburg',
    'Wildie',
    'Winston',
    'Lexington',
    'Frankfort',
    'Corbin',
    'Bush',
    'East Bernstadt',
    'Emlyn',
    'Gray',
    'Keavy',
    'Lily',
    'London',
    'Nevisdale',
    'Pittsburg',
    'Rockholds',
    'Siler',
    'Williamsburg',
    'Woodbine',
    'Ages Brookside',
    'Asher',
    'Baxter',
    'Benham',
    'Big Laurel',
    'Bledsoe',
    'Calvin',
    'Cawood',
    'Chappell',
    'Coalgood',
    'Coldiron',
    'Cranks',
    'Cumberland',
    'Dayhoit',
    'Eolia',
    'Essie',
    'Evarts',
    'Grays Knob',
    'Gulston',
    'Harlan',
    'Helton',
    'Holmes Mill',
    'Hoskinston',
    'Hulen',
    'Kenvir',
    'Lejunior',
    'Loyall',
    'Lynch',
    'Miracle',
    'Mozelle',
    'Partridge',
    'Pathfork',
    'Putney',
    'Stinnett',
    'Totz',
    'Wallins Creek',
    'Warbranch',
    'Arjay',
    'Artemus',
    'Barbourville',
    'Beverly',
    'Big Creek',
    'Bimble',
    'Bryants Store',
    'Cannon',
    'Closplint',
    'Dewitt',
    'Eriline',
    'Fall Rock',
    'Flat Lick',
    'Fourmile',
    'Frakes',
    'Garrard',
    'Girdler',
    'Goose Rock',
    'Green Road',
    'Heidrick',
    'Hima',
    'Hinkle',
    'Ingram',
    'Kettle Island',
    'Manchester',
    'Mary Alice',
    'Middlesboro',
    'Oneida',
    'Pineville',
    'Roark',
    'Saul',
    'Scalf',
    'Sextons Creek',
    'Stoney Fork',
    'Trosper',
    'Walker',
    'Woollum',
    'Alexandria',
    'Augusta',
    'Berry',
    'Brooksville',
    'Burlington',
    'Butler',
    'California',
    'Carrollton',
    'Corinth',
    'Covington',
    'Latonia',
    'Ft Mitchell',
    'Erlanger',
    'Hebron',
    'Florence',
    'Crittenden',
    'Cynthiana',
    'De Mossville',
    'Dover',
    'Dry Ridge',
    'Elizaville',
    'Ewing',
    'Falmouth',
    'Flemingsburg',
    'Foster',
    'Germantown',
    'Ghent',
    'Glencoe',
    'Hillsboro',
    'Independence',
    'Jonesville',
    'Kenton',
    'Mason',
    'Mayslick',
    'Maysville',
    'Melbourne',
    'Milford',
    'Minerva',
    'Morning View',
    'Mount Olivet',
    'Muses Mills',
    'Newport',
    'Bellevue',
    'Dayton',
    'Fort Thomas',
    'Petersburg',
    'Plummers Landing',
    'Sanders',
    'Silver Grove',
    'Sparta',
    'Union',
    'Verona',
    'Wallingford',
    'Walton',
    'Warsaw',
    'Washington',
    'Williamstown',
    'Worthville',
    'Ashland',
    'Argillite',
    'Blaine',
    'Carter',
    'Catlettsburg',
    'Denton',
    'Emerson',
    'Flatwoods',
    'Garrison',
    'Grahn',
    'Grayson',
    'Greenup',
    'Hitchins',
    'Isonville',
    'Martha',
    'Mazie',
    'Olive Hill',
    'Quincy',
    'Rush',
    'Russell',
    'Sandy Hook',
    'Soldier',
    'South Portsmouth',
    'South Shore',
    'Vanceburg',
    'Webbville',
    'Willard',
    'Worthington',
    'Tollesboro',
    'Adams',
    'Beauty',
    'Boons Camp',
    'Debord',
    'East Point',
    'Flatgap',
    'Hagerhill',
    'Inez',
    'Keaton',
    'Louisa',
    'Lovely',
    'Lowmansville',
    'Meally',
    'Oil Springs',
    'Paintsville',
    'Pilgrim',
    'River',
    'Sitka',
    'Staffordsville',
    'Stambaugh',
    'Thelma',
    'Tomahawk',
    'Tutor Key',
    'Ulysses',
    'Van Lear',
    'Warfield',
    'West Van Lear',
    'Williamsport',
    'Wittensville',
    'Campton',
    'Athol',
    'Bays',
    'Beattyville',
    'Bethany',
    'Booneville',
    'Clayhole',
    'Hazel Green',
    'Heidelberg',
    'Island City',
    'Jackson',
    'Lone',
    'Lost Creek',
    'Mistletoe',
    'Mize',
    'Pine Ridge',
    'Primrose',
    'Ricetown',
    'Rogers',
    'Rousseau',
    'Rowdy',
    'Saint Helens',
    'Vancleve',
    'Vincent',
    'Whick',
    'Zoe',
    'Cannel City',
    'Crockett',
    'Elkfork',
    'Ezel',
    'Falcon',
    'Gapville',
    'Malone',
    'Ophir',
    'Royalton',
    'Salyersville',
    'West Liberty',
    'Wrigley',
    'Pikeville',
    'South Williamson',
    'Ashcamp',
    'Belcher',
    'Belfry',
    'Burdine',
    'Canada',
    'Dorton',
    'Elkhorn City',
    'Fedscreek',
    'Fords Branch',
    'Forest Hills',
    'Freeburn',
    'Hardy',
    'Hellier',
    'Huddy',
    'Jenkins',
    'Jonancy',
    'Kimper',
    'Lick Creek',
    'Lookout',
    'Mc Andrews',
    'Mc Carr',
    'Majestic',
    'Mouthcard',
    'Myra',
    'Phelps',
    'Phyllis',
    'Pinsonfork',
    'Raccoon',
    'Ransom',
    'Regina',
    'Robinson Creek',
    'Rockhouse',
    'Shelbiana',
    'Shelby Gap',
    'Sidney',
    'Steele',
    'Stone',
    'Stopover',
    'Varney',
    'Virgie',
    'Allen',
    'Auxier',
    'Banner',
    'Beaver',
    'Betsy Layne',
    'Bevinsville',
    'Blue River',
    'Bypro',
    'Dana',
    'David',
    'Drift',
    'Dwale',
    'Eastern',
    'Garrett',
    'Grethel',
    'Gunlock',
    'Harold',
    'Hi Hat',
    'Hueysville',
    'Ivel',
    'Lackey',
    'Langley',
    'Mc Dowell',
    'Martin',
    'Melvin',
    'Minnie',
    'Prestonsburg',
    'Printer',
    'Stanville',
    'Teaberry',
    'Tram',
    'Wayland',
    'Weeksbury',
    'Wheelwright',
    'Hazard',
    'Ary',
    'Avawam',
    'Bear Branch',
    'Bonnyman',
    'Buckhorn',
    'Bulan',
    'Busy',
    'Carrie',
    'Chavies',
    'Combs',
    'Cornettsville',
    'Delphia',
    'Dice',
    'Dwarf',
    'Emmalena',
    'Fisty',
    'Gays Creek',
    'Happy',
    'Hardburly',
    'Hyden',
    'Jeff',
    'Krypton',
    'Sassafras',
    'Scuddy',
    'Sizerock',
    'Slemp',
    'Smilax',
    'Thousandsticks',
    'Vest',
    'Vicco',
    'Viper',
    'Wendover',
    'Wooton',
    'Yeaddiss',
    'Yerkes',
    'Blackey',
    'Cromona',
    'Deane',
    'Ermine',
    'Garner',
    'Gordon',
    'Hallie',
    'Hindman',
    'Isom',
    'Jackhorn',
    'Jeremiah',
    'Kite',
    'Leburn',
    'Letcher',
    'Linefork',
    'Littcarr',
    'Mc Roberts',
    'Mallie',
    'Mayking',
    'Millstone',
    'Mousie',
    'Neon',
    'Pine Top',
    'Pippa Passes',
    'Premium',
    'Redfox',
    'Roxana',
    'Seco',
    'Thornton',
    'Whitesburg',
    'Dema',
    'Raven',
    'Topmost',
    'Paducah',
    'Almo',
    'Arlington',
    'Bandana',
    'Bardwell',
    'Barlow',
    'Benton',
    'Boaz',
    'Burna',
    'Calvert City',
    'Clinton',
    'Columbus',
    'Crayne',
    'Cunningham',
    'Dexter',
    'Dycusburg',
    'Eddyville',
    'Fancy Farm',
    'Farmington',
    'Fulton',
    'Gilbertsville',
    'Grand Rivers',
    'Hampton',
    'Hardin',
    'Hazel',
    'Hickman',
    'Hickory',
    'Kevil',
    'Kirksey',
    'Kuttawa',
    'La Center',
    'Ledbetter',
    'Lovelaceville',
    'Lowes',
    'Lynnville',
    'Marion',
    'Mayfield',
    'Melber',
    'Milburn',
    'Murray',
    'New Concord',
    'Salem',
    'Sedalia',
    'Smithland',
    'Symsonia',
    'Tiline',
    'Tolu',
    'Water Valley',
    'West Paducah',
    'Wickliffe',
    'Wingo',
    'Bowling Green',
    'Adolphus',
    'Alvaton',
    'Austin',
    'Beaumont',
    'Cave City',
    'Edmonton',
    'Eighty Eight',
    'Etoile',
    'Fountain Run',
    'Franklin',
    'Gamaliel',
    'Glasgow',
    'Hestand',
    'Hiseville',
    'Holland',
    'Knob Lick',
    'Lucas',
    'Mount Hermon',
    'Oakland',
    'Park City',
    'Rocky Hill',
    'Scottsville',
    'Summer Shade',
    'Tompkinsville',
    'Woodburn',
    'Smiths Grove',
    'Aberdeen',
    'Adairville',
    'Allensville',
    'Auburn',
    'Bee Spring',
    'Brownsville',
    'Cadiz',
    'Center',
    'Cerulean',
    'Clifty',
    'Crofton',
    'Dunbar',
    'Elkton',
    'Fairview',
    'Fort Campbell',
    'Gracey',
    'Guthrie',
    'Herndon',
    'Hopkinsville',
    'Jetson',
    'La Fayette',
    'Lewisburg',
    'Mammoth Cave',
    'Morgantown',
    'Oak Grove',
    'Olmstead',
    'Pembroke',
    'Rochester',
    'Rockfield',
    'Roundhill',
    'Russellville',
    'Sharon Grove',
    'South Union',
    'Sweeden',
    'Trenton',
    'Welchs Creek',
    'Woodbury',
    'Owensboro',
    'Beaver Dam',
    'Beech Creek',
    'Beech Grove',
    'Beechmont',
    'Belton',
    'Bremen',
    'Browder',
    'Calhoun',
    'Centertown',
    'Central City',
    'Cleaton',
    'Cromwell',
    'Curdsville',
    'Drakesboro',
    'Dundee',
    'Dunmor',
    'Fordsville',
    'Graham',
    'Greenville',
    'Hartford',
    'Hawesville',
    'Horse Branch',
    'Island',
    'Lewisport',
    'Livermore',
    'Mc Henry',
    'Maceo',
    'Maple Mount',
    'Olaton',
    'Philpot',
    'Powderly',
    'Reynolds Station',
    'Rockport',
    'Rosine',
    'Rumsey',
    'Sacramento',
    'South Carrollton',
    'Stanley',
    'Utica',
    'West Louisville',
    'Whitesville',
    'Baskett',
    'Blackford',
    'Clay',
    'Corydon',
    'Dawson Springs',
    'Dixon',
    'Earlington',
    'Fredonia',
    'Hanson',
    'Henderson',
    'Madisonville',
    'Manitou',
    'Morganfield',
    'Mortons Gap',
    'Nebo',
    'Nortonville',
    'Poole',
    'Princeton',
    'Providence',
    'Reed',
    'Robards',
    'Saint Charles',
    'Sebree',
    'Slaughters',
    'Smith Mills',
    'Spottsville',
    'Sturgis',
    'Sullivan',
    'Uniontown',
    'Waverly',
    'Wheatcroft',
    'White Plains',
    'Somerset',
    'Bethelridge',
    'Bronston',
    'Burnside',
    'Dunnville',
    'Ferguson',
    'Liberty',
    'Middleburg',
    'Nancy',
    'Science Hill',
    'Tateville',
    'West Somerset',
    'Windsor',
    'Yosemite',
    'Eubank',
    'Albany',
    'Alpha',
    'Jamestown',
    'Marshes Siding',
    'Monticello',
    'Parkers Lake',
    'Pine Knot',
    'Revelo',
    'Russell Springs',
    'Stearns',
    'Strunk',
    'Whitley City',
    'Elizabethtown',
    'Big Clifty',
    'Bonnieville',
    'Breeding',
    'Buffalo',
    'Burkesville',
    'Campbellsville',
    'Cane Valley',
    'Caneyville',
    'Canmer',
    'Cecilia',
    'Clarkson',
    'Columbia',
    'Cub Run',
    'Dubre',
    'Eastview',
    'Elk Horn',
    'Glendale',
    'Glens Fork',
    'Gradyville',
    'Greensburg',
    'Hardyville',
    'Hodgenville',
    'Horse Cave',
    'Knifley',
    'Leitchfield',
    'Magnolia',
    'Mannsville',
    'Marrowbone',
    'Millwood',
    'Mount Sherman',
    'Munfordville',
    'Sonora',
    'Summersville',
    'Upton',
    'White Mills'
  ],
  OH: [
    'Alexandria',
    'Amlin',
    'Ashley',
    'Blacklick',
    'Bladensburg',
    'Brinkhaven',
    'Broadway',
    'Buckeye Lake',
    'Cable',
    'Catawba',
    'Centerburg',
    'Croton',
    'Danville',
    'Delaware',
    'Dublin',
    'Etna',
    'Fredericktown',
    'Galena',
    'Gambier',
    'Granville',
    'Hebron',
    'Hilliard',
    'Homer',
    'Howard',
    'Irwin',
    'Jacksontown',
    'Johnstown',
    'Kilbourne',
    'Kirkersville',
    'Lewis Center',
    'Magnetic Springs',
    'Martinsburg',
    'Marysville',
    'Mechanicsburg',
    'Milford Center',
    'Millersport',
    'Mingo',
    'Mount Liberty',
    'Mount Vernon',
    'New Albany',
    'Newark',
    'Heath',
    'North Lewisburg',
    'Ostrander',
    'Pataskala',
    'Plain City',
    'Powell',
    'Radnor',
    'Raymond',
    'Reynoldsburg',
    'Rosewood',
    'Saint Louisville',
    'Saint Paris',
    'Summit Station',
    'Sunbury',
    'Thornville',
    'Unionville Center',
    'Urbana',
    'Utica',
    'Westerville',
    'Westville',
    'Woodstock',
    'Columbus',
    'Adelphi',
    'Amanda',
    'Ashville',
    'Baltimore',
    'Bloomingburg',
    'Bremen',
    'Brice',
    'Canal Winchester',
    'Carbon Hill',
    'Carroll',
    'Circleville',
    'Clarksburg',
    'Commercial Point',
    'Derby',
    'Galloway',
    'Grove City',
    'Groveport',
    'Harrisburg',
    'Haydenville',
    'Jeffersonville',
    'Lancaster',
    'Laurelville',
    'Lithopolis',
    'Lockbourne',
    'Logan',
    'London',
    'Milledgeville',
    'Mount Sterling',
    'Murray City',
    'New Holland',
    'Orient',
    'Pickerington',
    'Pleasantville',
    'Rockbridge',
    'Rushville',
    'Sedalia',
    'South Bloomingville',
    'South Solon',
    'Stoutsville',
    'Sugar Grove',
    'Tarlton',
    'Thurston',
    'Union Furnace',
    'Washington Court House',
    'West Jefferson',
    'West Rushville',
    'Williamsport',
    'Marion',
    'Belle Center',
    'Bellefontaine',
    'Caledonia',
    'Cardington',
    'Carey',
    'Chesterville',
    'De Graff',
    'East Liberty',
    'Edison',
    'Fulton',
    'Green Camp',
    'Harpster',
    'Huntsville',
    'Iberia',
    'Kenton',
    'Kirby',
    'Lakeview',
    'La Rue',
    'Lewistown',
    'Marengo',
    'Martel',
    'Middleburg',
    'Morral',
    'Mount Gilead',
    'Mount Victory',
    'New Bloomington',
    'Prospect',
    'Quincy',
    'Richwood',
    'Ridgeway',
    'Roundhead',
    'Rushsylvania',
    'Russells Point',
    'Shauck',
    'Sparta',
    'Upper Sandusky',
    'Waldo',
    'West Liberty',
    'West Mansfield',
    'Wharton',
    'Zanesfield',
    'Bowling Green',
    'Bradner',
    'Burgoon',
    'Clay Center',
    'Clyde',
    'Curtice',
    'Cygnet',
    'Dunbridge',
    'Elmore',
    'Fremont',
    'Genoa',
    'Gibsonburg',
    'Graytown',
    'Gypsum',
    'Harbor View',
    'Helena',
    'Isle Saint George',
    'Jerry City',
    'Kelleys Island',
    'Lacarne',
    'Lakeside Marblehead',
    'Lemoyne',
    'Lindsey',
    'Luckey',
    'Martin',
    'Middle Bass',
    'Millbury',
    'Oak Harbor',
    'Pemberville',
    'Portage',
    'Port Clinton',
    'Put In Bay',
    'Risingsun',
    'Rocky Ridge',
    'Rossford',
    'Rudolph',
    'Stony Ridge',
    'Vickery',
    'Walbridge',
    'Wayne',
    'West Millgrove',
    'Williston',
    'Woodville',
    'Alvordton',
    'Archbold',
    'Berkey',
    'Blakeslee',
    'Bryan',
    'Colton',
    'Custar',
    'Defiance',
    'Delta',
    'Deshler',
    'Edgerton',
    'Edon',
    'Evansport',
    'Farmer',
    'Fayette',
    'Grand Rapids',
    'Grelton',
    'Hamler',
    'Haskins',
    'Hicksville',
    'Holgate',
    'Holland',
    'Hoytville',
    'Jewell',
    'Kunkle',
    'Liberty Center',
    'Lyons',
    'Mc Clure',
    'Malinta',
    'Mark Center',
    'Maumee',
    'Metamora',
    'Milton Center',
    'Monclova',
    'Montpelier',
    'Napoleon',
    'Neapolis',
    'New Bavaria',
    'Ney',
    'Okolona',
    'Perrysburg',
    'Pettisville',
    'Pioneer',
    'Ridgeville Corners',
    'Sherwood',
    'Stryker',
    'Swanton',
    'Sylvania',
    'Tontogany',
    'Waterville',
    'Wauseon',
    'Weston',
    'West Unity',
    'Whitehouse',
    'Toledo',
    'Oregon',
    'Northwood',
    'Zanesville',
    'Ava',
    'Barnesville',
    'Beallsville',
    'Belle Valley',
    'Belmont',
    'Bethesda',
    'Blue Rock',
    'Brownsville',
    'Buffalo',
    'Byesville',
    'Caldwell',
    'Cambridge',
    'Chandlersville',
    'Chesterhill',
    'Corning',
    'Crooksville',
    'Cumberland',
    'Derwent',
    'Duncan Falls',
    'East Fultonham',
    'Fairview',
    'Fultonham',
    'Glenford',
    'Gratiot',
    'Hopewell',
    'Jerusalem',
    'Junction City',
    'Kimbolton',
    'Kipling',
    'Laings',
    'Lewisville',
    'Lore City',
    'Mcconnelsville',
    'Malaga',
    'Malta',
    'Morristown',
    'Mount Perry',
    'Moxahala',
    'New Concord',
    'New Lexington',
    'New Straitsville',
    'Norwich',
    'Old Washington',
    'Philo',
    'Pleasant City',
    'Quaker City',
    'Roseville',
    'Salesville',
    'Sarahsville',
    'Senecaville',
    'Shawnee',
    'Somerset',
    'Stafford',
    'Stockport',
    'Summerfield',
    'Sycamore Valley',
    'White Cottage',
    'Woodsfield',
    'Adamsville',
    'Bakersville',
    'Baltic',
    'Blissfield',
    'Conesville',
    'Coshocton',
    'Dresden',
    'Frazeysburg',
    'Fresno',
    'Keene',
    'Nashport',
    'Newcomerstown',
    'Plainfield',
    'Port Washington',
    'Stone Creek',
    'Trinway',
    'Walhonding',
    'Warsaw',
    'West Lafayette',
    'Adena',
    'Alledonia',
    'Amsterdam',
    'Barton',
    'Bellaire',
    'Cadiz',
    'Bergholz',
    'Blaine',
    'Bloomingdale',
    'Bridgeport',
    'Brilliant',
    'Cameron',
    'Clarington',
    'Colerain',
    'Dillonvale',
    'East Liverpool',
    'East Springfield',
    'Empire',
    'Fairpoint',
    'Glencoe',
    'Hammondsville',
    'Hannibal',
    'Irondale',
    'Jacobsburg',
    'Lansing',
    'Martins Ferry',
    'Maynard',
    'Mingo Junction',
    'Mount Pleasant',
    'Neffs',
    'Piney Fork',
    'Powhatan Point',
    'Rayland',
    'Richmond',
    'Salineville',
    'Sardis',
    'Shadyside',
    'Smithfield',
    'Saint Clairsville',
    'Lafferty',
    'Steubenville',
    'Stratton',
    'Summitville',
    'Tiltonsville',
    'Toronto',
    'Warnock',
    'Wellsville',
    'Wolf Run',
    'Yorkville',
    'Bannock',
    'Freeport',
    'Harrisville',
    'Hopedale',
    'Flushing',
    'New Athens',
    'Piedmont',
    'New Rumley',
    'Holloway',
    'Jewett',
    'Scio',
    'Amherst',
    'Andover',
    'Ashtabula',
    'Austinburg',
    'Avon',
    'Avon Lake',
    'Berea',
    'Burton',
    'Chagrin Falls',
    'Chardon',
    'Chesterland',
    'Columbia Station',
    'Conneaut',
    'Dorset',
    'East Claridon',
    'Elyria',
    'North Ridgeville',
    'Gates Mills',
    'Geneva',
    'Grafton',
    'Grand River',
    'Huntsburg',
    'Jefferson',
    'Kingsville',
    'Kipton',
    'Lagrange',
    'Lorain',
    'Sheffield Lake',
    'Macedonia',
    'Madison',
    'Mentor',
    'Middlefield',
    'Montville',
    'Newbury',
    'Northfield',
    'North Kingsville',
    'North Olmsted',
    'Novelty',
    'Oberlin',
    'Orwell',
    'Painesville',
    'Parkman',
    'Perry',
    'Pierpont',
    'Rock Creek',
    'Rome',
    'Thompson',
    'Twinsburg',
    'Unionville',
    'Vermilion',
    'Wellington',
    'Wickliffe',
    'Williamsfield',
    'Willoughby',
    'Eastlake',
    'Windsor',
    'Cleveland',
    'Lakewood',
    'Rocky River',
    'Euclid',
    'Beachwood',
    'Independence',
    'North Royalton',
    'Strongsville',
    'Maple Heights',
    'Olmsted Falls',
    'Solon',
    'Bay Village',
    'Brecksville',
    'Brookpark',
    'Westlake',
    'Bedford',
    'Broadview Heights',
    'Atwater',
    'Aurora',
    'Barberton',
    'Bath',
    'Brady Lake',
    'Brunswick',
    'Burbank',
    'Chippewa Lake',
    'Clinton',
    'Creston',
    'Cuyahoga Falls',
    'Stow',
    'Doylestown',
    'Garrettsville',
    'Green',
    'Hinckley',
    'Hiram',
    'Homerville',
    'Hudson',
    'Kent',
    'Streetsboro',
    'Lakemore',
    'Westfield Center',
    'Litchfield',
    'Lodi',
    'Mantua',
    'Medina',
    'Mogadore',
    'Munroe Falls',
    'Peninsula',
    'Randolph',
    'Ravenna',
    'Rittman',
    'Rootstown',
    'Seville',
    'Sharon Center',
    'Spencer',
    'Sterling',
    'Tallmadge',
    'Valley City',
    'Wadsworth',
    'Wayland',
    'Richfield',
    'West Salem',
    'Windham',
    'Akron',
    'Fairlawn',
    'Berlin Center',
    'Bristolville',
    'Brookfield',
    'Burghill',
    'Campbell',
    'Canfield',
    'Columbiana',
    'Cortland',
    'Deerfield',
    'Diamond',
    'East Palestine',
    'Elkton',
    'Ellsworth',
    'Farmdale',
    'Fowler',
    'Girard',
    'Greenford',
    'Hanoverton',
    'Hartford',
    'Hubbard',
    'Kensington',
    'Kinsman',
    'Lake Milton',
    'Leavittsburg',
    'Leetonia',
    'Lisbon',
    'Lowellville',
    'Mc Donald',
    'Masury',
    'Mesopotamia',
    'Mineral Ridge',
    'Negley',
    'New Middletown',
    'New Springfield',
    'Newton Falls',
    'New Waterford',
    'Niles',
    'North Benton',
    'North Bloomfield',
    'North Jackson',
    'North Lima',
    'Orangeville',
    'Petersburg',
    'Rogers',
    'Salem',
    'Southington',
    'Struthers',
    'Vienna',
    'Warren',
    'Washingtonville',
    'West Farmington',
    'West Point',
    'Winona',
    'Youngstown',
    'Alliance',
    'Apple Creek',
    'Augusta',
    'Beach City',
    'Beloit',
    'Berlin',
    'Big Prairie',
    'Bolivar',
    'Brewster',
    'Canal Fulton',
    'Carrollton',
    'Charm',
    'Dalton',
    'Damascus',
    'Dellroy',
    'Dennison',
    'Dover',
    'Dundee',
    'East Rochester',
    'East Sparta',
    'Fredericksburg',
    'Glenmont',
    'Gnadenhutten',
    'Greentown',
    'Harlem Springs',
    'Hartville',
    'Holmesville',
    'Homeworth',
    'Kidron',
    'Killbuck',
    'Lakeville',
    'Leesville',
    'Limaville',
    'Louisville',
    'Magnolia',
    'Malvern',
    'Marshallville',
    'Massillon',
    'Maximo',
    'Mechanicstown',
    'Middlebranch',
    'Midvale',
    'Millersburg',
    'Mineral City',
    'Minerva',
    'Mount Eaton',
    'Mount Hope',
    'Nashville',
    'Navarre',
    'New Philadelphia',
    'North Georgetown',
    'North Lawrence',
    'Orrville',
    'Paris',
    'Robertsville',
    'Sandyville',
    'Sebring',
    'Sherrodsville',
    'Shreve',
    'Smithville',
    'Somerdale',
    'Stillwater',
    'Strasburg',
    'Sugarcreek',
    'Tuscarawas',
    'Uhrichsville',
    'Uniontown',
    'Walnut Creek',
    'Waynesburg',
    'Wilmot',
    'Winesburg',
    'Wooster',
    'Deersville',
    'Bowerston',
    'Zoar',
    'Tippecanoe',
    'Canton',
    'North Canton',
    'East Canton',
    'Alvada',
    'Arcadia',
    'Ashland',
    'Attica',
    'Bascom',
    'Bellevue',
    'Bellville',
    'Berlin Heights',
    'Bettsville',
    'Birmingham',
    'Bloomdale',
    'Bloomville',
    'Bucyrus',
    'Butler',
    'Castalia',
    'Chatfield',
    'Collins',
    'Crestline',
    'Flat Rock',
    'Fostoria',
    'Galion',
    'Green Springs',
    'Greenwich',
    'Hayesville',
    'Huron',
    'Jeromesville',
    'Kansas',
    'Loudonville',
    'Lucas',
    'Mc Cutchenville',
    'Melmore',
    'Milan',
    'Monroeville',
    'Nankin',
    'Nevada',
    'New Haven',
    'New London',
    'New Riegel',
    'New Washington',
    'North Fairfield',
    'North Robinson',
    'Norwalk',
    'Nova',
    'Oceola',
    'Old Fort',
    'Ontario',
    'Perrysville',
    'Plymouth',
    'Polk',
    'Republic',
    'Sandusky',
    'Savannah',
    'Shelby',
    'Shiloh',
    'Sullivan',
    'Sulphur Springs',
    'Sycamore',
    'Tiffin',
    'Tiro',
    'Willard',
    'Wakeman',
    'Mansfield',
    'Addyston',
    'Cleves',
    'College Corner',
    'Collinsville',
    'Franklin',
    'Hamilton',
    'Fairfield',
    'Harrison',
    'Harveysburg',
    'Hooven',
    'Kings Mills',
    'Lebanon',
    'Maineville',
    'Mason',
    'Miamitown',
    'Middletown',
    'Monroe',
    'Mount Saint Joseph',
    'North Bend',
    'Okeana',
    'Oregonia',
    'Overpeck',
    'Oxford',
    'Ross',
    'Seven Mile',
    'Shandon',
    'Somerville',
    'South Lebanon',
    'Springboro',
    'Trenton',
    'Waynesville',
    'West Chester',
    'West Elkton',
    'Aberdeen',
    'Amelia',
    'Batavia',
    'Bentonville',
    'Bethel',
    'Blanchester',
    'Buford',
    'Camp Dennison',
    'Chilo',
    'Clarksville',
    'Cuba',
    'Decatur',
    'Fayetteville',
    'Feesburg',
    'Felicity',
    'Georgetown',
    'Goshen',
    'Greenfield',
    'Hamersville',
    'Higginsport',
    'Highland',
    'Hillsboro',
    'Leesburg',
    'Lees Creek',
    'Loveland',
    'Lynchburg',
    'Manchester',
    'Marathon',
    'Martinsville',
    'Miamiville',
    'Midland',
    'Milford',
    'Morrow',
    'Moscow',
    'Mount Orab',
    'Mowrystown',
    'Neville',
    'New Richmond',
    'Newtonsville',
    'New Vienna',
    'Owensville',
    'Pleasant Plain',
    'Port William',
    'Reesville',
    'Ripley',
    'Russellville',
    'Sabina',
    'Sardinia',
    'Sinking Spring',
    'Terrace Park',
    'Williamsburg',
    'Wilmington',
    'Cincinnati',
    'Alpha',
    'Anna',
    'Ansonia',
    'Arcanum',
    'Bellbrook',
    'Botkins',
    'Bowersville',
    'Bradford',
    'Brookville',
    'Burkettsville',
    'Camden',
    'Casstown',
    'Cedarville',
    'Clayton',
    'Clifton',
    'Conover',
    'Covington',
    'Donnelsville',
    'Eaton',
    'Eldorado',
    'Englewood',
    'Enon',
    'Fairborn',
    'Farmersville',
    'Fletcher',
    'Germantown',
    'Gettysburg',
    'Gratis',
    'Greenville',
    'Hollansburg',
    'Houston',
    'Jackson Center',
    'Jamestown',
    'Kettlersville',
    'Laura',
    'Lewisburg',
    'Ludlow Falls',
    'Maplewood',
    'Medway',
    'Miamisburg',
    'New Carlisle',
    'New Lebanon',
    'New Madison',
    'New Paris',
    'New Weston',
    'North Hampton',
    'North Star',
    'Osgood',
    'Palestine',
    'Pemberton',
    'Phillipsburg',
    'Piqua',
    'Pitsburg',
    'Pleasant Hill',
    'Port Jefferson',
    'Potsdam',
    'Rossburg',
    'Russia',
    'Sidney',
    'South Charleston',
    'South Vienna',
    'Spring Valley',
    'Tipp City',
    'Tremont City',
    'Troy',
    'Vandalia',
    'Verona',
    'Versailles',
    'West Alexandria',
    'West Manchester',
    'West Milton',
    'Wilberforce',
    'Xenia',
    'Yellow Springs',
    'Yorkshire',
    'Christiansburg',
    'Union City',
    'Dayton',
    'Springfield',
    'Chillicothe',
    'Bainbridge',
    'Beaver',
    'Bidwell',
    'Blue Creek',
    'Bourneville',
    'Cherry Fork',
    'Chesapeake',
    'Cheshire',
    'Coalton',
    'Creola',
    'Crown City',
    'Cynthiana',
    'Frankfort',
    'Franklin Furnace',
    'Friendship',
    'Gallipolis',
    'Hallsville',
    'Hamden',
    'Haverhill',
    'Ironton',
    'Jackson',
    'Jasper',
    'Kerr',
    'Kingston',
    'Kitts Hill',
    'Latham',
    'Londonderry',
    'Lucasville',
    'Lynx',
    'Mc Arthur',
    'Mc Dermott',
    'Minford',
    'New Plymouth',
    'Oak Hill',
    'Otway',
    'Patriot',
    'Pedro',
    'Peebles',
    'Piketon',
    'Portsmouth',
    'West Portsmouth',
    'Proctorville',
    'Rarden',
    'Ray',
    'Richmond Dale',
    'Rio Grande',
    'Rock Camp',
    'Scioto Furnace',
    'Scottown',
    'Seaman',
    'South Point',
    'South Salem',
    'South Webster',
    'Stockdale',
    'Stout',
    'Thurman',
    'Vinton',
    'Wakefield',
    'Waterloo',
    'Waverly',
    'Wellston',
    'West Union',
    'Wheelersburg',
    'Wilkesville',
    'Willow Wood',
    'Winchester',
    'Zaleski',
    'Athens',
    'Albany',
    'Amesville',
    'Barlow',
    'Bartlett',
    'Belpre',
    'Beverly',
    'Buchtel',
    'Carbondale',
    'Chauncey',
    'Chester',
    'Coal Run',
    'Coolville',
    'Cutler',
    'Dexter City',
    'Fleming',
    'Glouster',
    'Graysville',
    'Guysville',
    'Hockingport',
    'Jacksonville',
    'Langsville',
    'Little Hocking',
    'Long Bottom',
    'Lowell',
    'Lower Salem',
    'Macksburg',
    'Marietta',
    'Middleport',
    'Millfield',
    'Nelsonville',
    'New Marshfield',
    'New Matamoras',
    'Newport',
    'Pomeroy',
    'Portland',
    'Racine',
    'Reedsville',
    'Reno',
    'Rutland',
    'Shade',
    'Sharpsburg',
    'Stewart',
    'Syracuse',
    'The Plains',
    'Trimble',
    'Tuppers Plains',
    'Vincent',
    'Waterford',
    'Watertown',
    'Whipple',
    'Wingett Run',
    'Lima',
    'Beaverdam',
    'Gomer',
    'Ada',
    'Alger',
    'Antwerp',
    'Arlington',
    'Belmore',
    'Benton Ridge',
    'Bluffton',
    'Buckland',
    'Cairo',
    'Cecil',
    'Celina',
    'Chickasaw',
    'Cloverdale',
    'Coldwater',
    'Columbus Grove',
    'Continental',
    'Convoy',
    'Delphos',
    'Dola',
    'Dunkirk',
    'Dupont',
    'Elgin',
    'Findlay',
    'Jenera',
    'Forest',
    'Fort Jennings',
    'Fort Loramie',
    'Fort Recovery',
    'Glandorf',
    'Grover Hill',
    'Harrod',
    'Haviland',
    'Kalida',
    'Lafayette',
    'Latty',
    'Leipsic',
    'Mc Comb',
    'Mc Guffey',
    'Maria Stein',
    'Melrose',
    'Mendon',
    'Middle Point',
    'Miller City',
    'Minster',
    'Montezuma',
    'Mount Blanchard',
    'Mount Cory',
    'New Bremen',
    'New Hampshire',
    'New Knoxville',
    'North Baltimore',
    'Oakwood',
    'Ohio City',
    'Ottawa',
    'Ottoville',
    'Pandora',
    'Paulding',
    'Payne',
    'Rawson',
    'Rockford',
    'Saint Henry',
    'Saint Johns',
    'Saint Marys',
    'Scott',
    'Spencerville',
    'Uniopolis',
    'Van Buren',
    'Vanlue',
    'Van Wert',
    'Vaughnsville',
    'Venedocia',
    'Wapakoneta',
    'Waynesfield',
    'Williamstown',
    'Willshire',
    'Wren'
  ],
  IN: [
    'Alexandria',
    'Anderson',
    'Arcadia',
    'Atlanta',
    'Carmel',
    'Cicero',
    'Colfax',
    'Elwood',
    'Fishers',
    'Forest',
    'Fortville',
    'Frankfort',
    'Frankton',
    'Goldsmith',
    'Hobbs',
    'Ingalls',
    'Kempton',
    'Kirklin',
    'Lapel',
    'Lebanon',
    'Mccordsville',
    'Markleville',
    'Michigantown',
    'Mulberry',
    'Noblesville',
    'Orestes',
    'Pendleton',
    'Rossville',
    'Sedalia',
    'Sharpsville',
    'Sheridan',
    'Summitville',
    'Thorntown',
    'Tipton',
    'Westfield',
    'Whitestown',
    'Windfall',
    'Zionsville',
    'Advance',
    'Amo',
    'Arlington',
    'Bainbridge',
    'Bargersville',
    'Beech Grove',
    'Boggstown',
    'Brooklyn',
    'Brownsburg',
    'Camby',
    'Carthage',
    'Charlottesville',
    'Clayton',
    'Cloverdale',
    'Coatesville',
    'Danville',
    'Avon',
    'Edinburgh',
    'Eminence',
    'Fairland',
    'Falmouth',
    'Fillmore',
    'Finly',
    'Fountaintown',
    'Franklin',
    'Glenwood',
    'Greencastle',
    'Greenfield',
    'Greenwood',
    'Gwynneville',
    'Homer',
    'Jamestown',
    'Knightstown',
    'Lizton',
    'Manilla',
    'Martinsville',
    'Maxwell',
    'Mays',
    'Milroy',
    'Monrovia',
    'Mooresville',
    'Morgantown',
    'Morristown',
    'Needham',
    'New Palestine',
    'Nineveh',
    'North Salem',
    'Paragon',
    'Pittsboro',
    'Plainfield',
    'Putnamville',
    'Reelsville',
    'Roachdale',
    'Rushville',
    'Russellville',
    'Shelbyville',
    'Stilesville',
    'Trafalgar',
    'Waldron',
    'West Newton',
    'Whiteland',
    'Wilkinson',
    'Indianapolis',
    'Beverly Shores',
    'Boone Grove',
    'Cedar Lake',
    'Chesterton',
    'Crown Point',
    'Demotte',
    'Dyer',
    'East Chicago',
    'Griffith',
    'Hammond',
    'Munster',
    'Highland',
    'Hanna',
    'Hebron',
    'Hobart',
    'Kingsbury',
    'Kingsford Heights',
    'Kouts',
    'La Crosse',
    'Lake Village',
    'La Porte',
    'Leroy',
    'Lowell',
    'Michigan City',
    'Mill Creek',
    'North Judson',
    'Portage',
    'Rolling Prairie',
    'Roselawn',
    'Saint John',
    'San Pierre',
    'Schererville',
    'Schneider',
    'Shelby',
    'Sumava Resorts',
    'Tefft',
    'Thayer',
    'Union Mills',
    'Valparaiso',
    'Wanatah',
    'Westville',
    'Wheatfield',
    'Wheeler',
    'Whiting',
    'Gary',
    'Lake Station',
    'Merrillville',
    'Argos',
    'Atwood',
    'Bourbon',
    'Bremen',
    'Bristol',
    'Burket',
    'Claypool',
    'Culver',
    'Donaldson',
    'Elkhart',
    'Etna Green',
    'Goshen',
    'Granger',
    'Grovertown',
    'Hamlet',
    'Knox',
    'Lakeville',
    'Lapaz',
    'Leesburg',
    'Mentone',
    'Middlebury',
    'Milford',
    'Millersburg',
    'Mishawaka',
    'Nappanee',
    'New Carlisle',
    'New Paris',
    'North Liberty',
    'North Webster',
    'Notre Dame',
    'Osceola',
    'Pierceton',
    'Plymouth',
    'Shipshewana',
    'Syracuse',
    'Tippecanoe',
    'Topeka',
    'Tyner',
    'Wakarusa',
    'Walkerton',
    'Warsaw',
    'Winona Lake',
    'Wyatt',
    'South Bend',
    'Albion',
    'Andrews',
    'Angola',
    'Arcola',
    'Ashley',
    'Auburn',
    'Avilla',
    'Berne',
    'Bippus',
    'Bluffton',
    'Butler',
    'Churubusco',
    'Columbia City',
    'Corunna',
    'Craigville',
    'Cromwell',
    'Decatur',
    'Fremont',
    'Garrett',
    'Geneva',
    'Grabill',
    'Hamilton',
    'Harlan',
    'Hoagland',
    'Howe',
    'Hudson',
    'Huntertown',
    'Huntington',
    'Kendallville',
    'Keystone',
    'Kimmell',
    'Lagrange',
    'Laotto',
    'Larwill',
    'Leo',
    'Liberty Center',
    'Ligonier',
    'Linn Grove',
    'Markle',
    'Mongo',
    'Monroe',
    'Monroeville',
    'New Haven',
    'Orland',
    'Ossian',
    'Petroleum',
    'Pleasant Lake',
    'Pleasant Mills',
    'Poneto',
    'Preble',
    'Roanoke',
    'Rome City',
    'Saint Joe',
    'South Milford',
    'South Whitley',
    'Spencerville',
    'Stroh',
    'Uniondale',
    'Warren',
    'Waterloo',
    'Wawaka',
    'Wolcottville',
    'Wolflake',
    'Woodburn',
    'Yoder',
    'Zanesville',
    'Fort Wayne',
    'Kokomo',
    'Akron',
    'Amboy',
    'Athens',
    'Bringhurst',
    'Bunker Hill',
    'Burlington',
    'Burrows',
    'Camden',
    'Converse',
    'Cutler',
    'Deedsville',
    'Delong',
    'Delphi',
    'Denver',
    'Fairmount',
    'Flora',
    'Fowlerton',
    'Fulton',
    'Galveston',
    'Gas City',
    'Grass Creek',
    'Greentown',
    'Hemlock',
    'Jonesboro',
    'Kewanna',
    'La Fontaine',
    'Lagro',
    'Lake Cicott',
    'Laketon',
    'Leiters Ford',
    'Liberty Mills',
    'Logansport',
    'Lucerne',
    'Macy',
    'Marion',
    'Matthews',
    'Mexico',
    'Miami',
    'Monterey',
    'New Waverly',
    'North Manchester',
    'Oakford',
    'Onward',
    'Ora',
    'Peru',
    'Grissom Arb',
    'Roann',
    'Rochester',
    'Rockfield',
    'Royal Center',
    'Russiaville',
    'Servia',
    'Silver Lake',
    'Somerset',
    'Star City',
    'Swayzee',
    'Sweetser',
    'Twelve Mile',
    'Upland',
    'Urbana',
    'Van Buren',
    'Wabash',
    'Walton',
    'West Middleton',
    'Winamac',
    'Young America',
    'Aurora',
    'West College Corner',
    'Batesville',
    'Bath',
    'Bennington',
    'Brookville',
    'Cedar Grove',
    'Cross Plains',
    'Dillsboro',
    'East Enterprise',
    'Florence',
    'Friendship',
    'Guilford',
    'Holton',
    'Laurel',
    'Lawrenceburg',
    'Metamora',
    'Milan',
    'Moores Hill',
    'Morris',
    'Napoleon',
    'New Trenton',
    'Oldenburg',
    'Osgood',
    'Patriot',
    'Pierceville',
    'Rising Sun',
    'Sunman',
    'Versailles',
    'Vevay',
    'West Harrison',
    'Austin',
    'Bethlehem',
    'Borden',
    'Bradford',
    'Campbellsburg',
    'Central',
    'Charlestown',
    'Corydon',
    'Crandall',
    'Depauw',
    'Eckerty',
    'Elizabeth',
    'English',
    'Floyds Knobs',
    'Fredericksburg',
    'Georgetown',
    'Grantsburg',
    'Greenville',
    'Hardinsburg',
    'Henryville',
    'Clarksville',
    'Jeffersonville',
    'Laconia',
    'Lanesville',
    'Leavenworth',
    'Lexington',
    'Little York',
    'Marengo',
    'Marysville',
    'Mauckport',
    'Memphis',
    'Milltown',
    'Mount Saint Francis',
    'Nabb',
    'New Albany',
    'New Middletown',
    'New Salisbury',
    'New Washington',
    'Otisco',
    'Palmyra',
    'Pekin',
    'Ramsey',
    'Salem',
    'Scottsburg',
    'Sellersburg',
    'Sulphur',
    'Taswell',
    'Underwood',
    'Columbus',
    'Brownstown',
    'Butlerville',
    'Canaan',
    'Clarksburg',
    'Clifford',
    'Commiskey',
    'Cortland',
    'Crothersville',
    'Deputy',
    'Dupont',
    'Elizabethtown',
    'Flat Rock',
    'Freetown',
    'Grammer',
    'Greensburg',
    'Hanover',
    'Hartsville',
    'Hayden',
    'Hope',
    'Jonesville',
    'Kurtz',
    'Madison',
    'Medora',
    'Millhousen',
    'New Point',
    'Norman',
    'North Vernon',
    'Paris Crossing',
    'Saint Paul',
    'Scipio',
    'Seymour',
    'Taylorsville',
    'Vallonia',
    'Vernon',
    'Westport',
    'Muncie',
    'Albany',
    'Bentonville',
    'Boston',
    'Brownsville',
    'Bryant',
    'Cambridge City',
    'Centerville',
    'Connersville',
    'Daleville',
    'Dublin',
    'Dunkirk',
    'Dunreith',
    'Eaton',
    'Economy',
    'Farmland',
    'Fountain City',
    'Gaston',
    'Greensboro',
    'Greens Fork',
    'Hagerstown',
    'Hartford City',
    'Kennard',
    'Lewisville',
    'Liberty',
    'Losantville',
    'Lynn',
    'Middletown',
    'Milton',
    'Modoc',
    'Montpelier',
    'Mooreland',
    'Mount Summit',
    'New Castle',
    'New Lisbon',
    'Oakville',
    'Parker City',
    'Pennville',
    'Pershing',
    'Portland',
    'Redkey',
    'Richmond',
    'Ridgeville',
    'Salamonia',
    'Saratoga',
    'Selma',
    'Shirley',
    'Spiceland',
    'Springport',
    'Straughn',
    'Sulphur Springs',
    'Union City',
    'Webster',
    'Williamsburg',
    'Winchester',
    'Yorktown',
    'Bloomington',
    'Avoca',
    'Bedford',
    'Bloomfield',
    'Clear Creek',
    'Coal City',
    'Ellettsville',
    'Fort Ritner',
    'Freedom',
    'French Lick',
    'Gosport',
    'Harrodsburg',
    'Helmsburg',
    'Heltonville',
    'Huron',
    'Jasonville',
    'Koleen',
    'Linton',
    'Lyons',
    'Midland',
    'Mitchell',
    'Nashville',
    'Newberry',
    'Oolitic',
    'Orleans',
    'Owensburg',
    'Paoli',
    'Patricksburg',
    'Quincy',
    'Scotland',
    'Smithville',
    'Solsberry',
    'Spencer',
    'Springville',
    'Stanford',
    'Stinesville',
    'Switz City',
    'Tunnelton',
    'Unionville',
    'West Baden Springs',
    'Williams',
    'Worthington',
    'Washington',
    'Bicknell',
    'Birdseye',
    'Branchville',
    'Bristow',
    'Bruceville',
    'Cannelburg',
    'Cannelton',
    'Celestine',
    'Crane',
    'Dale',
    'Decker',
    'Derby',
    'Dubois',
    'Edwardsport',
    'Elnora',
    'Evanston',
    'Ferdinand',
    'Freelandville',
    'Fulda',
    'Gentryville',
    'Holland',
    'Huntingburg',
    'Ireland',
    'Jasper',
    'Lamar',
    'Leopold',
    'Lincoln City',
    'Loogootee',
    'Mariah Hill',
    'Monroe City',
    'Montgomery',
    'Oaktown',
    'Odon',
    'Otwell',
    'Petersburg',
    'Plainville',
    'Ragsdale',
    'Rome',
    'Saint Anthony',
    'Saint Croix',
    'Saint Meinrad',
    'Sandborn',
    'Santa Claus',
    'Schnellville',
    'Shoals',
    'Spurgeon',
    'Stendal',
    'Tell City',
    'Troy',
    'Velpen',
    'Vincennes',
    'Westphalia',
    'Wheatland',
    'Winslow',
    'Boonville',
    'Chandler',
    'Chrisney',
    'Cynthiana',
    'Elberfeld',
    'Folsomville',
    'Grandview',
    'Griffin',
    'Hatfield',
    'Inglefield',
    'Lynnville',
    'Mount Vernon',
    'Newburgh',
    'New Harmony',
    'Poseyville',
    'Richland',
    'Rockport',
    'Tennyson',
    'Wadesville',
    'Haubstadt',
    'Hazleton',
    'Buckskin',
    'Fort Branch',
    'Francisco',
    'Mackey',
    'Oakland City',
    'Owensville',
    'Patoka',
    'Princeton',
    'Somerville',
    'Evansville',
    'Terre Haute',
    'Bellmore',
    'Blanford',
    'Bloomingdale',
    'Bowling Green',
    'Brazil',
    'Bridgeton',
    'Carbon',
    'Carlisle',
    'Centerpoint',
    'Clay City',
    'Clinton',
    'Coalmont',
    'Cory',
    'Dana',
    'Dugger',
    'Fairbanks',
    'Farmersburg',
    'Fontanet',
    'Graysville',
    'Harmony',
    'Hillsdale',
    'Hymera',
    'Judson',
    'Knightsville',
    'Lewis',
    'Marshall',
    'Mecca',
    'Merom',
    'Montezuma',
    'New Goshen',
    'Sullivan',
    'Paxton',
    'Pimento',
    'Poland',
    'Prairie Creek',
    'Prairieton',
    'Riley',
    'Rockville',
    'Rosedale',
    'Saint Bernice',
    'Saint Mary Of The Woods',
    'Seelyville',
    'Shelburn',
    'Shepardsville',
    'Staunton',
    'Universal',
    'West Terre Haute',
    'Lafayette',
    'West Lafayette',
    'Alamo',
    'Ambia',
    'Attica',
    'Battle Ground',
    'Boswell',
    'Brook',
    'Brookston',
    'Buck Creek',
    'Buffalo',
    'Burnettsville',
    'Cayuga',
    'Chalmers',
    'Clarks Hill',
    'Covington',
    'Crawfordsville',
    'Darlington',
    'Dayton',
    'Earl Park',
    'Fair Oaks',
    'Fowler',
    'Francesville',
    'Goodland',
    'Hillsboro',
    'Idaville',
    'Kentland',
    'Kingman',
    'Ladoga',
    'Linden',
    'Medaryville',
    'Mellott',
    'Monon',
    'Monticello',
    'Montmorenci',
    'Morocco',
    'Mount Ayr',
    'New Market',
    'Newport',
    'New Richmond',
    'New Ross',
    'Newtown',
    'Otterbein',
    'Oxford',
    'Perrysville',
    'Pine Village',
    'Remington',
    'Rensselaer',
    'Reynolds',
    'Romney',
    'State Line',
    'Stockwell',
    'Talbot',
    'Templeton',
    'Veedersburg',
    'Wallace',
    'Waveland',
    'Waynetown',
    'West Lebanon',
    'Westpoint',
    'Williamsport',
    'Wingate',
    'Wolcott',
    'Yeoman'
  ],
  MI: [
    'Algonac',
    'Allenton',
    'Almont',
    'Anchorville',
    'Armada',
    'Avoca',
    'Troy',
    'Birmingham',
    'Capac',
    'Center Line',
    'Clawson',
    'Eastpointe',
    'Emmett',
    'Fair Haven',
    'Franklin',
    'Fraser',
    'Goodells',
    'Harsens Island',
    'Hazel Park',
    'Jeddo',
    'Southfield',
    'Clinton Township',
    'Marine City',
    'Marysville',
    'Memphis',
    'Macomb',
    'Mount Clemens',
    'Harrison Township',
    'New Baltimore',
    'New Haven',
    'North Street',
    'East China',
    'Fort Gratiot',
    'Port Huron',
    'Richmond',
    'Columbus',
    'Casco',
    'Romeo',
    'Roseville',
    'Royal Oak',
    'Pleasant Ridge',
    'Huntington Woods',
    'Madison Heights',
    'Berkley',
    'Smiths Creek',
    'Saint Clair',
    'Saint Clair Shores',
    'Warren',
    'Washington',
    'Ray',
    'Yale',
    'Allen Park',
    'Ann Arbor',
    'Azalia',
    'Belleville',
    'Brighton',
    'Bridgewater',
    'Carleton',
    'Chelsea',
    'Dearborn',
    'Melvindale',
    'Dearborn Heights',
    'Dexter',
    'Dundee',
    'Erie',
    'Flat Rock',
    'Garden City',
    'Gregory',
    'Grosse Ile',
    'Hamburg',
    'Ida',
    'Inkster',
    'Lakeland',
    'Lambertville',
    'La Salle',
    'Lincoln Park',
    'Livonia',
    'Luna Pier',
    'Manchester',
    'Maybee',
    'Milan',
    'Monroe',
    'New Boston',
    'New Hudson',
    'Newport',
    'Northville',
    'Pinckney',
    'Plymouth',
    'Rockwood',
    'Romulus',
    'Salem',
    'Saline',
    'Samaria',
    'South Lyon',
    'South Rockwood',
    'Taylor',
    'Temperance',
    'Trenton',
    'Wayne',
    'Westland',
    'Canton',
    'Whitmore Lake',
    'Whittaker',
    'Willis',
    'Wyandotte',
    'Riverview',
    'Southgate',
    'Ypsilanti',
    'Detroit',
    'Highland Park',
    'Hamtramck',
    'River Rouge',
    'Ferndale',
    'Harper Woods',
    'Ecorse',
    'Grosse Pointe',
    'Oak Park',
    'Redford',
    'Bloomfield Hills',
    'Rochester',
    'Sterling Heights',
    'Utica',
    'Keego Harbor',
    'Auburn Hills',
    'West Bloomfield',
    'Waterford',
    'Drayton Plains',
    'Farmington',
    'Pontiac',
    'Clarkston',
    'Davisburg',
    'Hartland',
    'Highland',
    'Lake Orion',
    'Oakland',
    'Lakeville',
    'Leonard',
    'Oxford',
    'Novi',
    'Milford',
    'Commerce Township',
    'White Lake',
    'Union Lake',
    'Walled Lake',
    'Wixom',
    'Applegate',
    'Argyle',
    'Atlas',
    'Attica',
    'Bad Axe',
    'Bancroft',
    'Birch Run',
    'Brown City',
    'Burt',
    'Byron',
    'Carsonville',
    'Clio',
    'Columbiaville',
    'Croswell',
    'Davison',
    'Decker',
    'Deckerville',
    'Dryden',
    'Durand',
    'Fenton',
    'Filion',
    'Flushing',
    'Forestville',
    'Fostoria',
    'Gaines',
    'Genesee',
    'Goodrich',
    'Grand Blanc',
    'Hadley',
    'Harbor Beach',
    'Holly',
    'Imlay City',
    'Kinde',
    'Lapeer',
    'Lennon',
    'Lexington',
    'Linden',
    'Marlette',
    'Melvin',
    'Metamora',
    'Minden City',
    'Montrose',
    'Mount Morris',
    'New Lothrop',
    'North Branch',
    'Ortonville',
    'Otisville',
    'Otter Lake',
    'Palms',
    'Peck',
    'Port Austin',
    'Port Hope',
    'Port Sanilac',
    'Ruth',
    'Sandusky',
    'Snover',
    'Swartz Creek',
    'Ubly',
    'Vernon',
    'Flint',
    'Burton',
    'Saginaw',
    'Alger',
    'Auburn',
    'Beaverton',
    'Bentley',
    'Brant',
    'Breckenridge',
    'Chesaning',
    'Clare',
    'Coleman',
    'Comins',
    'Edenville',
    'Fairview',
    'Farwell',
    'Freeland',
    'Gladwin',
    'Harrison',
    'Hemlock',
    'Higgins Lake',
    'Hope',
    'Houghton Lake',
    'Houghton Lake Heights',
    'Kawkawlin',
    'Lake',
    'Lake George',
    'Linwood',
    'Lupton',
    'Luzerne',
    'Merrill',
    'Midland',
    'Mio',
    'Oakley',
    'Pinconning',
    'Prudenville',
    'Rhodes',
    'Roscommon',
    'Rose City',
    'Saint Charles',
    'Saint Helen',
    'Sanford',
    'Standish',
    'Sterling',
    'West Branch',
    'Wheeler',
    'Akron',
    'Au Gres',
    'Barton City',
    'Bay City',
    'University Center',
    'Bay Port',
    'Black River',
    'Bridgeport',
    'Caro',
    'Carrollton',
    'Caseville',
    'Cass City',
    'Clifford',
    'Curran',
    'Deford',
    'East Tawas',
    'Elkton',
    'Essexville',
    'Fairgrove',
    'Frankenmuth',
    'Gagetown',
    'Gilford',
    'Glennie',
    'Greenbush',
    'Hale',
    'Harrisville',
    'Kingston',
    'Lincoln',
    'Long Lake',
    'Mayville',
    'Mikado',
    'Millington',
    'Munger',
    'National City',
    'Omer',
    'Oscoda',
    'Owendale',
    'Pigeon',
    'Prescott',
    'Reese',
    'Richville',
    'Sebewaing',
    'Silverwood',
    'South Branch',
    'Spruce',
    'Tawas City',
    'Turner',
    'Twining',
    'Unionville',
    'Vassar',
    'Whittemore',
    'Alma',
    'Mount Pleasant',
    'Okemos',
    'Ashley',
    'Bannister',
    'Bath',
    'Belding',
    'Carson City',
    'Cedar Lake',
    'Charlotte',
    'Clarksville',
    'Cohoctah',
    'Corunna',
    'Crystal',
    'Dansville',
    'Dewitt',
    'Dimondale',
    'Eagle',
    'East Lansing',
    'Eaton Rapids',
    'Edmore',
    'Elm Hall',
    'Elsie',
    'Elwell',
    'Eureka',
    'Fenwick',
    'Fowler',
    'Fowlerville',
    'Grand Ledge',
    'Greenville',
    'Haslett',
    'Henderson',
    'Holt',
    'Howell',
    'Hubbardston',
    'Ionia',
    'Ithaca',
    'Laingsburg',
    'Lake Odessa',
    'Lakeview',
    'Lyons',
    'Mcbrides',
    'Maple Rapids',
    'Mason',
    'Middleton',
    'Morrice',
    'Muir',
    'Mulliken',
    'North Star',
    'Orleans',
    'Ovid',
    'Owosso',
    'Palo',
    'Perrinton',
    'Perry',
    'Pewamo',
    'Pompeii',
    'Portland',
    'Potterville',
    'Riverdale',
    'Rosebush',
    'Saint Johns',
    'Saint Louis',
    'Saranac',
    'Shaftsburg',
    'Shepherd',
    'Sheridan',
    'Sidney',
    'Six Lakes',
    'Smyrna',
    'Stanton',
    'Sumner',
    'Sunfield',
    'Vestaburg',
    'Webberville',
    'Weidman',
    'Westphalia',
    'Williamston',
    'Winn',
    'Woodland',
    'Lansing',
    'Kalamazoo',
    'Portage',
    'Allegan',
    'Athens',
    'Augusta',
    'Bangor',
    'Battle Creek',
    'Bedford',
    'Bellevue',
    'Benton Harbor',
    'Bloomingdale',
    'Breedsville',
    'Bronson',
    'Burlington',
    'Burr Oak',
    'Cassopolis',
    'Centreville',
    'Ceresco',
    'Climax',
    'Cloverdale',
    'Coldwater',
    'Coloma',
    'Hagar Shores',
    'Colon',
    'Comstock',
    'Constantine',
    'Covert',
    'Decatur',
    'Delton',
    'Dowagiac',
    'Dowling',
    'East Leroy',
    'Fulton',
    'Galesburg',
    'Gobles',
    'Grand Junction',
    'Hartford',
    'Hastings',
    'Hickory Corners',
    'Jones',
    'Kendall',
    'Lacota',
    'Lawrence',
    'Lawton',
    'Leonidas',
    'Marcellus',
    'Marshall',
    'Martin',
    'Mattawan',
    'Mendon',
    'Nashville',
    'Nazareth',
    'Nottawa',
    'Olivet',
    'Oshtemo',
    'Otsego',
    'Paw Paw',
    'Plainwell',
    'Quincy',
    'Richland',
    'Riverside',
    'Saint Joseph',
    'Schoolcraft',
    'Scotts',
    'Sherwood',
    'South Haven',
    'Sturgis',
    'Tekonsha',
    'Three Rivers',
    'Union City',
    'Vandalia',
    'Vermontville',
    'Vicksburg',
    'Watervliet',
    'White Pigeon',
    'Baroda',
    'Berrien Center',
    'Berrien Springs',
    'Bridgman',
    'Buchanan',
    'Eau Claire',
    'Edwardsburg',
    'Galien',
    'Harbert',
    'Lakeside',
    'New Buffalo',
    'New Troy',
    'Niles',
    'Sawyer',
    'Sodus',
    'Stevensville',
    'Three Oaks',
    'Union Pier',
    'Union',
    'Jackson',
    'Addison',
    'Adrian',
    'Albion',
    'Allen',
    'Blissfield',
    'Britton',
    'Brooklyn',
    'Camden',
    'Cement City',
    'Clarklake',
    'Clayton',
    'Clinton',
    'Concord',
    'Deerfield',
    'Frontier',
    'Grass Lake',
    'Hanover',
    'Hillsdale',
    'Homer',
    'Horton',
    'Hudson',
    'Jasper',
    'Jerome',
    'Jonesville',
    'Leslie',
    'Litchfield',
    'Manitou Beach',
    'Michigan Center',
    'Montgomery',
    'Morenci',
    'Moscow',
    'Mosherville',
    'Munith',
    'Napoleon',
    'North Adams',
    'Norvell',
    'Onondaga',
    'Onsted',
    'Osseo',
    'Ottawa Lake',
    'Palmyra',
    'Parma',
    'Petersburg',
    'Pittsford',
    'Pleasant Lake',
    'Reading',
    'Riga',
    'Rives Junction',
    'Sand Creek',
    'Somerset',
    'Somerset Center',
    'Spring Arbor',
    'Springport',
    'Stockbridge',
    'Tecumseh',
    'Tipton',
    'Waldron',
    'Weston',
    'Ada',
    'Alto',
    'Bailey',
    'Baldwin',
    'Barryton',
    'Belmont',
    'Big Rapids',
    'Bitely',
    'Blanchard',
    'Bradley',
    'Brohman',
    'Burnips',
    'Byron Center',
    'Caledonia',
    'Cannonsburg',
    'Casnovia',
    'Cedar Springs',
    'Chippewa Lake',
    'Comstock Park',
    'Coral',
    'Dorr',
    'Freeport',
    'Gowen',
    'Grant',
    'Hopkins',
    'Howard City',
    'Kent City',
    'Lowell',
    'Mecosta',
    'Middleville',
    'Moline',
    'Morley',
    'Newaygo',
    'Paris',
    'Pierson',
    'Remus',
    'Rockford',
    'Rodney',
    'Sand Lake',
    'Shelbyville',
    'Sparta',
    'Stanwood',
    'Trufant',
    'Wayland',
    'White Cloud',
    'Allendale',
    'Branch',
    'Conklin',
    'Coopersville',
    'Custer',
    'Douglas',
    'Fennville',
    'Ferrysburg',
    'Fountain',
    'Free Soil',
    'Fremont',
    'Fruitport',
    'Glenn',
    'Grand Haven',
    'Grandville',
    'Hamilton',
    'Hart',
    'Hesperia',
    'Holland',
    'Holton',
    'Hudsonville',
    'Jamestown',
    'Jenison',
    'Lamont',
    'Ludington',
    'Macatawa',
    'Marne',
    'Mears',
    'Montague',
    'Muskegon',
    'New Era',
    'Nunica',
    'Pentwater',
    'Pullman',
    'Ravenna',
    'Rothbury',
    'Saugatuck',
    'Scottville',
    'Shelby',
    'Spring Lake',
    'Twin Lake',
    'Walhalla',
    'Walkerville',
    'West Olive',
    'Whitehall',
    'Sylvan Beach',
    'Zeeland',
    'Grand Rapids',
    'Wyoming',
    'Cadillac',
    'Acme',
    'Alba',
    'Alden',
    'Arcadia',
    'Bear Lake',
    'Bellaire',
    'Benzonia',
    'Beulah',
    'Boon',
    'Brethren',
    'Buckley',
    'Cedar',
    'Central Lake',
    'Chase',
    'Copemish',
    'Eastlake',
    'Eastport',
    'Elberta',
    'Elk Rapids',
    'Empire',
    'Evart',
    'Falmouth',
    'Fife Lake',
    'Filer City',
    'Frankfort',
    'Glen Arbor',
    'Grawn',
    'Harrietta',
    'Hersey',
    'Honor',
    'Idlewild',
    'Interlochen',
    'Irons',
    'Kaleva',
    'Kalkaska',
    'Kewadin',
    'Kingsley',
    'Lake Ann',
    'Lake City',
    'Lake Leelanau',
    'Leland',
    'Leroy',
    'Luther',
    'Mc Bain',
    'Mancelona',
    'Manistee',
    'Manton',
    'Maple City',
    'Marion',
    'Mayfield',
    'Merritt',
    'Mesick',
    'Northport',
    'Old Mission',
    'Omena',
    'Onekama',
    'Rapid City',
    'Reed City',
    'Sears',
    'South Boardman',
    'Suttons Bay',
    'Thompsonville',
    'Traverse City',
    'Tustin',
    'Wellston',
    'Williamsburg',
    'Mackinaw City',
    'Afton',
    'Alanson',
    'Alpena',
    'Atlanta',
    'Barbeau',
    'Bay Shore',
    'Boyne City',
    'Boyne Falls',
    'Brimley',
    'Brutus',
    'Burt Lake',
    'Carp Lake',
    'Cedarville',
    'Charlevoix',
    'Cheboygan',
    'Conway',
    'Cross Village',
    'Dafter',
    'De Tour Village',
    'Drummond Island',
    'East Jordan',
    'Eckerman',
    'Ellsworth',
    'Elmira',
    'Frederic',
    'Gaylord',
    'Goetzville',
    'Good Hart',
    'Grayling',
    'Harbor Springs',
    'Hawks',
    'Herron',
    'Hessel',
    'Hillman',
    'Hubbard Lake',
    'Hulbert',
    'Indian River',
    'Johannesburg',
    'Kinross',
    'Lachine',
    'Levering',
    'Lewiston',
    'Mackinac Island',
    'Millersburg',
    'Moran',
    'Mullett Lake',
    'Naubinway',
    'Oden',
    'Onaway',
    'Ossineke',
    'Paradise',
    'Pellston',
    'Petoskey',
    'Pickford',
    'Pointe Aux Pins',
    'Posen',
    'Presque Isle',
    'Rogers City',
    'Rudyard',
    'Saint Ignace',
    'Beaver Island',
    'Sault Sainte Marie',
    'Kincheloe',
    'Topinabee',
    'Tower',
    'Trout Lake',
    'Vanderbilt',
    'Walloon Lake',
    'Waters',
    'Wolverine',
    'Iron Mountain',
    'Kingsford',
    'Allouez',
    'Au Train',
    'Bark River',
    'Big Bay',
    'Carney',
    'Champion',
    'Channing',
    'Chatham',
    'Cooks',
    'Cornell',
    'Arnold',
    'Curtis',
    'Daggett',
    'Deerton',
    'Eben Junction',
    'Rumely',
    'Engadine',
    'Escanaba',
    'Felch',
    'Little Lake',
    'Foster City',
    'Garden',
    'Germfask',
    'Gladstone',
    'Gould City',
    'Grand Marais',
    'Gulliver',
    'Gwinn',
    'Harris',
    'Hermansville',
    'Ingalls',
    'Ishpeming',
    'Loretto',
    'Mc Millan',
    'Manistique',
    'Marquette',
    'Menominee',
    'Michigamme',
    'Munising',
    'Nadeau',
    'Nahma',
    'National Mine',
    'Negaunee',
    'Newberry',
    'Norway',
    'Palmer',
    'Perkins',
    'Perronville',
    'Powers',
    'Quinnesec',
    'Ralph',
    'Rapid River',
    'Republic',
    'Rock',
    'Sagola',
    'Seney',
    'Shingleton',
    'Skandia',
    'Spalding',
    'Stephenson',
    'Trenary',
    'Vulcan',
    'Wallace',
    'Wells',
    'Wetmore',
    'Wilson',
    'Ahmeek',
    'Alpha',
    'Amasa',
    'Atlantic Mine',
    'Baraga',
    'Bergland',
    'Bessemer',
    'Bruce Crossing',
    'Calumet',
    'Caspian',
    'Chassell',
    'Copper City',
    'Copper Harbor',
    'Covington',
    'Crystal Falls',
    'Dodgeville',
    'Dollar Bay',
    'Ewen',
    'Gaastra',
    'Greenland',
    'Hancock',
    'Houghton',
    'Hubbell',
    'Iron River',
    'Ironwood',
    'Kearsarge',
    'Lake Linden',
    'Lanse',
    'Marenisco',
    'Mass City',
    'Mohawk',
    'Nisula',
    'Ontonagon',
    'Painesdale',
    'Pelkie',
    'Ramsay',
    'Rockland',
    'Sidnaw',
    'Skanee',
    'South Range',
    'Stambaugh',
    'Toivola',
    'Trout Creek',
    'Wakefield',
    'Watersmeet',
    'Watton',
    'White Pine'
  ],
  IA: [
    'Ackworth',
    'Adair',
    'Adel',
    'Albion',
    'Alden',
    'Alleman',
    'Allerton',
    'Altoona',
    'Ames',
    'Anita',
    'Ankeny',
    'Atlantic',
    'Audubon',
    'Bagley',
    'Barnes City',
    'Baxter',
    'Bayard',
    'Beaver',
    'Berwick',
    'Bevington',
    'Blairsburg',
    'Bondurant',
    'Boone',
    'Booneville',
    'Bouton',
    'Boxholm',
    'Bradford',
    'Brayton',
    'Buckeye',
    'Bussey',
    'Cambridge',
    'Carlisle',
    'Casey',
    'Chariton',
    'Churdan',
    'Clemons',
    'Clio',
    'Colfax',
    'Collins',
    'Colo',
    'Columbia',
    'Coon Rapids',
    'Cooper',
    'Corydon',
    'Cumming',
    'Melcher Dallas',
    'Dallas Center',
    'Dana',
    'Davis City',
    'Dawson',
    'Decatur',
    'Derby',
    'De Soto',
    'Dexter',
    'Dows',
    'Earlham',
    'Elkhart',
    'Ellston',
    'Ellsworth',
    'Exira',
    'Ferguson',
    'Galt',
    'Garden City',
    'Garden Grove',
    'Gibson',
    'Gilbert',
    'Gilman',
    'Grand Junction',
    'Grand River',
    'Granger',
    'Gray',
    'Grimes',
    'Grinnell',
    'Guthrie Center',
    'Hamilton',
    'Hamlin',
    'Hartford',
    'Harvey',
    'Haverhill',
    'Hubbard',
    'Humeston',
    'Huxley',
    'Indianola',
    'Iowa Falls',
    'Ira',
    'Jamaica',
    'Jefferson',
    'Jewell',
    'Johnston',
    'Kamrar',
    'Kellerton',
    'Kelley',
    'Kellogg',
    'Keswick',
    'Killduff',
    'Knoxville',
    'Lacona',
    'Lamoni',
    'Laurel',
    'Le Grand',
    'Leighton',
    'Leon',
    'Liberty Center',
    'Linden',
    'Lineville',
    'Liscomb',
    'Lorimor',
    'Lovilia',
    'Lucas',
    'Luther',
    'Lynnville',
    'Mc Callsburg',
    'Macksburg',
    'Madrid',
    'Malcom',
    'Marshalltown',
    'Martensdale',
    'Maxwell',
    'Melbourne',
    'Menlo',
    'Millerton',
    'Milo',
    'Minburn',
    'Mingo',
    'Mitchellville',
    'Monroe',
    'Montezuma',
    'Montour',
    'Murray',
    'Nevada',
    'New Providence',
    'New Sharon',
    'Newton',
    'New Virginia',
    'Norwalk',
    'Ogden',
    'Osceola',
    'Otley',
    'Panora',
    'Paton',
    'Patterson',
    'Pella',
    'Perry',
    'Peru',
    'Pilot Mound',
    'Pleasantville',
    'Polk City',
    'Popejoy',
    'Prairie City',
    'Prole',
    'Radcliffe',
    'Randall',
    'Reasnor',
    'Redfield',
    'Rhodes',
    'Rippey',
    'Roland',
    'Runnells',
    'Russell',
    'Saint Anthony',
    'Saint Charles',
    'Saint Marys',
    'Searsboro',
    'Sheldahl',
    'Slater',
    'Stanhope',
    'State Center',
    'Story City',
    'Stratford',
    'Stuart',
    'Sully',
    'Swan',
    'Thayer',
    'Thornburg',
    'Tracy',
    'Truro',
    'Union',
    'Gifford',
    'Van Meter',
    'Van Wert',
    'Waukee',
    'Weldon',
    'West Des Moines',
    'What Cheer',
    'Whitten',
    'Williams',
    'Williamson',
    'Winterset',
    'Wiota',
    'Woodburn',
    'Woodward',
    'Yale',
    'Zearing',
    'Des Moines',
    'Urbandale',
    'Windsor Heights',
    'Clive',
    'Pleasant Hill',
    'Mason City',
    'Alexander',
    'Belmond',
    'Britt',
    'Buffalo Center',
    'Carpenter',
    'Chapin',
    'Clear Lake',
    'Corwith',
    'Coulter',
    'Crystal Lake',
    'Dougherty',
    'Fertile',
    'Floyd',
    'Forest City',
    'Garner',
    'Goodell',
    'Grafton',
    'Hampton',
    'Hanlontown',
    'Joice',
    'Kanawha',
    'Kensett',
    'Klemme',
    'Lake Mills',
    'Lakota',
    'Latimer',
    'Leland',
    'Little Cedar',
    'Mc Intire',
    'Manly',
    'Meservey',
    'Nora Springs',
    'Northwood',
    'Orchard',
    'Osage',
    'Plymouth',
    'Rake',
    'Riceville',
    'Rock Falls',
    'Rockford',
    'Rockwell',
    'Rowan',
    'Rudd',
    'Saint Ansgar',
    'Scarville',
    'Sheffield',
    'Stacyville',
    'Swaledale',
    'Thompson',
    'Thornton',
    'Titonka',
    'Toeterville',
    'Ventura',
    'Wesley',
    'Woden',
    'Fort Dodge',
    'Albert City',
    'Algona',
    'Armstrong',
    'Ayrshire',
    'Badger',
    'Bancroft',
    'Barnum',
    'Bode',
    'Bradgate',
    'Burnside',
    'Burt',
    'Callender',
    'Clare',
    'Clarion',
    'Curlew',
    'Cylinder',
    'Dakota City',
    'Dayton',
    'Dolliver',
    'Duncombe',
    'Eagle Grove',
    'Early',
    'Emmetsburg',
    'Farnhamville',
    'Fenton',
    'Fonda',
    'Gilmore City',
    'Goldfield',
    'Gowrie',
    'Harcourt',
    'Hardy',
    'Havelock',
    'Humboldt',
    'Jolley',
    'Knierim',
    'Laurens',
    'Ledyard',
    'Lehigh',
    'Livermore',
    'Lone Rock',
    'Lu Verne',
    'Lytton',
    'Mallard',
    'Manson',
    'Marathon',
    'Moorland',
    'Nemaha',
    'Newell',
    'Otho',
    'Ottosen',
    'Palmer',
    'Plover',
    'Pocahontas',
    'Pomeroy',
    'Rembrandt',
    'Renwick',
    'Ringsted',
    'Rockwell City',
    'Rolfe',
    'Rutland',
    'Sac City',
    'Sioux Rapids',
    'Somers',
    'Storm Lake',
    'Swea City',
    'Thor',
    'Truesdale',
    'Varina',
    'Vincent',
    'Webster City',
    'West Bend',
    'Whittemore',
    'Woolstock',
    'Ackley',
    'Allison',
    'Alta Vista',
    'Aplington',
    'Aredale',
    'Arlington',
    'Aurora',
    'Austinville',
    'Beaman',
    'Bristow',
    'Buckingham',
    'Cedar Falls',
    'Charles City',
    'Clarksville',
    'Colwell',
    'Conrad',
    'Denver',
    'Dewar',
    'Dike',
    'Dumont',
    'Dunkerton',
    'Eldora',
    'Elma',
    'Fairbank',
    'Fredericksburg',
    'Frederika',
    'Garwin',
    'Geneva',
    'Gilbertville',
    'Gladbrook',
    'Greene',
    'Grundy Center',
    'Hazleton',
    'Holland',
    'Hudson',
    'Independence',
    'Ionia',
    'Janesville',
    'Jesup',
    'Kesley',
    'Lamont',
    'La Porte City',
    'Lincoln',
    'Marble Rock',
    'Masonville',
    'Maynard',
    'Morrison',
    'Nashua',
    'New Hampton',
    'New Hartford',
    'North Washington',
    'Oelwein',
    'Oran',
    'Parkersburg',
    'Plainfield',
    'Raymond',
    'Readlyn',
    'Reinbeck',
    'Shell Rock',
    'Stanley',
    'Steamboat Rock',
    'Stout',
    'Sumner',
    'Traer',
    'Tripoli',
    'Waverly',
    'Wellsburg',
    'Westgate',
    'Winthrop',
    'Waterloo',
    'Evansdale',
    'Creston',
    'Afton',
    'Arispe',
    'Bedford',
    'Benton',
    'Blockton',
    'Bridgewater',
    'Carbon',
    'Clearfield',
    'Corning',
    'Cromwell',
    'Cumberland',
    'Diagonal',
    'Fontanelle',
    'Grant',
    'Gravity',
    'Greenfield',
    'Lenox',
    'Massena',
    'Mount Ayr',
    'Nodaway',
    'Orient',
    'Prescott',
    'Redding',
    'Shannon City',
    'Sharpsburg',
    'Tingley',
    'Villisca',
    'Akron',
    'Alta',
    'Alton',
    'Anthon',
    'Aurelia',
    'Battle Creek',
    'Bronson',
    'Brunsville',
    'Calumet',
    'Castana',
    'Chatsworth',
    'Cherokee',
    'Cleghorn',
    'Climbing Hill',
    'Correctionville',
    'Cushing',
    'Danbury',
    'Galva',
    'Granville',
    'Hawarden',
    'Hinton',
    'Holstein',
    'Hornick',
    'Ireton',
    'Kingsley',
    'Larrabee',
    'Lawton',
    'Le Mars',
    'Linn Grove',
    'Mapleton',
    'Marcus',
    'Maurice',
    'Meriden',
    'Merrill',
    'Moville',
    'Onawa',
    'Orange City',
    'Oto',
    'Oyens',
    'Paullina',
    'Peterson',
    'Pierson',
    'Quimby',
    'Remsen',
    'Rodney',
    'Salix',
    'Schaller',
    'Sergeant Bluff',
    'Sloan',
    'Smithland',
    'Sutherland',
    'Ute',
    'Washta',
    'Westfield',
    'Whiting',
    'Sioux City',
    'Sheldon',
    'Alvord',
    'Archer',
    'Ashton',
    'Boyden',
    'Doon',
    'George',
    'Hospers',
    'Hull',
    'Inwood',
    'Larchwood',
    'Lester',
    'Little Rock',
    'Matlock',
    'Primghar',
    'Rock Rapids',
    'Rock Valley',
    'Sanborn',
    'Sibley',
    'Sioux Center',
    'Spencer',
    'Arnolds Park',
    'Dickens',
    'Estherville',
    'Everly',
    'Fostoria',
    'Gillett Grove',
    'Graettinger',
    'Greenville',
    'Gruver',
    'Harris',
    'Hartley',
    'Lake Park',
    'Melvin',
    'Milford',
    'Ocheyedan',
    'Okoboji',
    'Royal',
    'Ruthven',
    'Spirit Lake',
    'Superior',
    'Terril',
    'Wallingford',
    'Webb',
    'Carroll',
    'Arcadia',
    'Arthur',
    'Aspinwall',
    'Auburn',
    'Breda',
    'Charter Oak',
    'Dedham',
    'Deloit',
    'Denison',
    'Glidden',
    'Halbur',
    'Ida Grove',
    'Irwin',
    'Kirkman',
    'Kiron',
    'Lake City',
    'Lake View',
    'Lanesboro',
    'Lidderdale',
    'Lohrville',
    'Manilla',
    'Manning',
    'Odebolt',
    'Ralston',
    'Ricketts',
    'Schleswig',
    'Scranton',
    'Templeton',
    'Vail',
    'Wall Lake',
    'Westside',
    'Council Bluffs',
    'Carter Lake',
    'Arion',
    'Avoca',
    'Blencoe',
    'Carson',
    'Crescent',
    'Defiance',
    'Dow City',
    'Dunlap',
    'Earling',
    'Elk Horn',
    'Elliott',
    'Emerson',
    'Glenwood',
    'Griswold',
    'Hancock',
    'Harlan',
    'Hastings',
    'Henderson',
    'Honey Creek',
    'Kimballton',
    'Lewis',
    'Little Sioux',
    'Logan',
    'Mc Clelland',
    'Macedonia',
    'Magnolia',
    'Malvern',
    'Marne',
    'Minden',
    'Mineola',
    'Missouri Valley',
    'Modale',
    'Mondamin',
    'Moorhead',
    'Neola',
    'Oakland',
    'Pacific Junction',
    'Panama',
    'Persia',
    'Pisgah',
    'Portsmouth',
    'Red Oak',
    'Shelby',
    'Silver City',
    'Soldier',
    'Stanton',
    'Treynor',
    'Underwood',
    'Walnut',
    'Westphalia',
    'Woodbine',
    'Shenandoah',
    'Blanchard',
    'Braddyville',
    'Clarinda',
    'Coin',
    'College Springs',
    'Essex',
    'Farragut',
    'Hamburg',
    'Imogene',
    'New Market',
    'Northboro',
    'Percival',
    'Randolph',
    'Riverton',
    'Shambaugh',
    'Sidney',
    'Tabor',
    'Thurman',
    'Yorktown',
    'Dubuque',
    'Andrew',
    'Bellevue',
    'Bernard',
    'Cascade',
    'Colesburg',
    'Delaware',
    'Delmar',
    'Dundee',
    'Durango',
    'Dyersville',
    'Earlville',
    'Edgewood',
    'Elkader',
    'Elkport',
    'Epworth',
    'Farley',
    'Farmersburg',
    'Garber',
    'Garnavillo',
    'Greeley',
    'Guttenberg',
    'Holy Cross',
    'La Motte',
    'Luxemburg',
    'Manchester',
    'Maquoketa',
    'Miles',
    'New Vienna',
    'North Buena Vista',
    'Peosta',
    'Preston',
    'Sabula',
    'Saint Donatus',
    'Saint Olaf',
    'Sherrill',
    'Spragueville',
    'Springbrook',
    'Strawberry Point',
    'Volga',
    'Worthington',
    'Zwingle',
    'Decorah',
    'Calmar',
    'Castalia',
    'Chester',
    'Clermont',
    'Cresco',
    'Dorchester',
    'Elgin',
    'Fayette',
    'Fort Atkinson',
    'Harpers Ferry',
    'Hawkeye',
    'Highlandville',
    'Lansing',
    'Lawler',
    'Lime Springs',
    'Luana',
    'Mc Gregor',
    'Marquette',
    'Monona',
    'New Albin',
    'Ossian',
    'Postville',
    'Protivin',
    'Randalia',
    'Ridgeway',
    'Saint Lucas',
    'Spillville',
    'Wadena',
    'Waterville',
    'Waucoma',
    'Waukon',
    'West Union',
    'Ainsworth',
    'Alburnett',
    'Amana',
    'Anamosa',
    'Atkins',
    'Baldwin',
    'Belle Plaine',
    'Blairstown',
    'Brandon',
    'Brooklyn',
    'Center Junction',
    'Center Point',
    'Central City',
    'Chelsea',
    'Clarence',
    'Clutier',
    'Coggon',
    'Prairieburg',
    'Conroy',
    'Guernsey',
    'Deep River',
    'Delhi',
    'Dysart',
    'Elberon',
    'Ely',
    'Fairfax',
    'Garrison',
    'Harper',
    'Hartwick',
    'Hiawatha',
    'Hills',
    'Homestead',
    'Hopkinton',
    'Iowa City',
    'Coralville',
    'Kalona',
    'Keota',
    'Keystone',
    'Ladora',
    'Langworthy',
    'Lisbon',
    'Lost Nation',
    'Lowden',
    'Luzerne',
    'Marengo',
    'Marion',
    'Martelle',
    'Mechanicsville',
    'Middle Amana',
    'Millersburg',
    'Monmouth',
    'Monticello',
    'Morley',
    'Mount Auburn',
    'Mount Vernon',
    'Newhall',
    'North English',
    'North Liberty',
    'Norway',
    'Oakdale',
    'Olin',
    'Onslow',
    'Oxford',
    'Oxford Junction',
    'Palo',
    'Parnell',
    'Quasqueton',
    'Riverside',
    'Robins',
    'Rowley',
    'Ryan',
    'Shellsburg',
    'Solon',
    'South Amana',
    'South English',
    'Springville',
    'Stanwood',
    'Swisher',
    'Tama',
    'Tiffin',
    'Toddville',
    'Toledo',
    'Troy Mills',
    'Urbana',
    'Van Horne',
    'Victor',
    'Vining',
    'Vinton',
    'Viola',
    'Walford',
    'Walker',
    'Washington',
    'Watkins',
    'Webster',
    'Wellman',
    'West Branch',
    'West Chester',
    'Williamsburg',
    'Wyoming',
    'Cedar Rapids',
    'Ottumwa',
    'Agency',
    'Albia',
    'Batavia',
    'Beacon',
    'Birmingham',
    'Blakesburg',
    'Bloomfield',
    'West Grove',
    'Brighton',
    'Cantril',
    'Cedar',
    'Centerville',
    'Chillicothe',
    'Cincinnati',
    'Delta',
    'Douds',
    'Drakesville',
    'Eddyville',
    'Eldon',
    'Exline',
    'Fairfield',
    'Floris',
    'Fremont',
    'Hayesville',
    'Hedrick',
    'Keosauqua',
    'Kirkville',
    'Libertyville',
    'Martinsburg',
    'Melrose',
    'Milton',
    'Moravia',
    'Moulton',
    'Mount Sterling',
    'Mystic',
    'Ollie',
    'Oskaloosa',
    'Packwood',
    'Plano',
    'Promise City',
    'Pulaski',
    'Richland',
    'Rose Hill',
    'Selma',
    'Seymour',
    'Sigourney',
    'Udell',
    'Unionville',
    'University Park',
    'Burlington',
    'Argyle',
    'Bonaparte',
    'Crawfordsville',
    'Danville',
    'Denmark',
    'Donnellson',
    'Farmington',
    'Fort Madison',
    'Hillsboro',
    'Houghton',
    'Keokuk',
    'Lockridge',
    'Mediapolis',
    'Middletown',
    'Montrose',
    'Morning Sun',
    'Mount Pleasant',
    'Rome',
    'Mount Union',
    'New London',
    'Oakville',
    'Olds',
    'Pilot Grove',
    'Salem',
    'Sperry',
    'Stockport',
    'Swedesburg',
    'Wapello',
    'Wayland',
    'West Burlington',
    'West Point',
    'Saint Paul',
    'Wever',
    'Winfield',
    'Yarmouth',
    'Andover',
    'Atalissa',
    'Bennett',
    'Bettendorf',
    'Blue Grass',
    'Bryant',
    'Buffalo',
    'Calamus',
    'Camanche',
    'Charlotte',
    'Clinton',
    'Columbus City',
    'Columbus Junction',
    'Conesville',
    'De Witt',
    'Dixon',
    'Donahue',
    'Durant',
    'Eldridge',
    'Fruitland',
    'Goose Lake',
    'Grand Mound',
    'Grandview',
    'Le Claire',
    'Letts',
    'Lone Tree',
    'Long Grove',
    'Low Moor',
    'Mc Causland',
    'Montpelier',
    'Moscow',
    'Muscatine',
    'New Liberty',
    'Nichols',
    'Pleasant Valley',
    'Princeton',
    'Stockton',
    'Teeds Grove',
    'Tipton',
    'Walcott',
    'Welton',
    'West Liberty',
    'Wheatland',
    'Wilton',
    'Davenport'
  ],
  WI: [
    'Adell',
    'Allenton',
    'Ashippun',
    'Belgium',
    'Brookfield',
    'Brownsville',
    'Butler',
    'Campbellsport',
    'Cascade',
    'Cedarburg',
    'Cedar Grove',
    'Chilton',
    'Cleveland',
    'Clyman',
    'Colgate',
    'Delafield',
    'Eden',
    'Elkhart Lake',
    'Fredonia',
    'Germantown',
    'Glenbeulah',
    'Grafton',
    'Greenbush',
    'Hartford',
    'Hartland',
    'Hingham',
    'Horicon',
    'Hubertus',
    'Hustisford',
    'Iron Ridge',
    'Ixonia',
    'Jackson',
    'Johnson Creek',
    'Juneau',
    'Kewaskum',
    'Kiel',
    'Kohler',
    'Lannon',
    'Lebanon',
    'Lomira',
    'Malone',
    'Mayville',
    'Menomonee Falls',
    'Merton',
    'Mount Calvary',
    'Nashotah',
    'Neosho',
    'Newburg',
    'New Holstein',
    'Newton',
    'North Lake',
    'Oakfield',
    'Oconomowoc',
    'Okauchee',
    'Oostburg',
    'Pewaukee',
    'Plymouth',
    'Port Washington',
    'Random Lake',
    'Richfield',
    'Rubicon',
    'Saint Cloud',
    'Saukville',
    'Sheboygan',
    'Sheboygan Falls',
    'Slinger',
    'Stockbridge',
    'Sussex',
    'West Bend',
    'Theresa',
    'Mequon',
    'Waldo',
    'Watertown',
    'Woodland',
    'Bassett',
    'Benet Lake',
    'Big Bend',
    'Bristol',
    'Burlington',
    'Caledonia',
    'Camp Lake',
    'Cudahy',
    'Darien',
    'Delavan',
    'Dousman',
    'Eagle',
    'East Troy',
    'Elkhorn',
    'Elm Grove',
    'Fontana',
    'Franksville',
    'Genesee Depot',
    'Genoa City',
    'Greendale',
    'Hales Corners',
    'Franklin',
    'Helenville',
    'Honey Creek',
    'Kansasville',
    'Kenosha',
    'New Berlin',
    'Lake Geneva',
    'Lyons',
    'Mukwonago',
    'Muskego',
    'New Munster',
    'North Prairie',
    'Oak Creek',
    'Palmyra',
    'Pell Lake',
    'Pleasant Prairie',
    'Powers Lake',
    'Rochester',
    'Salem',
    'Silver Lake',
    'Somers',
    'South Milwaukee',
    'Springfield',
    'Sturtevant',
    'Sullivan',
    'Trevor',
    'Twin Lakes',
    'Union Grove',
    'Wales',
    'Walworth',
    'Waterford',
    'Waukesha',
    'Whitewater',
    'Williams Bay',
    'Wilmot',
    'Woodworth',
    'Zenda',
    'Milwaukee',
    'Saint Francis',
    'Racine',
    'Afton',
    'Albany',
    'Arena',
    'Argyle',
    'Avalon',
    'Avoca',
    'Barneveld',
    'Belleville',
    'Belmont',
    'Beloit',
    'Black Earth',
    'Blanchardville',
    'Blue Mounds',
    'Blue River',
    'Brodhead',
    'Brooklyn',
    'Browntown',
    'Cambridge',
    'Clinton',
    'Cobb',
    'Cottage Grove',
    'Cross Plains',
    'Dane',
    'Darlington',
    'Deerfield',
    'Deforest',
    'Dodgeville',
    'Edgerton',
    'Edmund',
    'Evansville',
    'Footville',
    'Fort Atkinson',
    'Gotham',
    'Gratiot',
    'Hanover',
    'Highland',
    'Hollandale',
    'Janesville',
    'Jefferson',
    'Juda',
    'Lake Mills',
    'Linden',
    'Livingston',
    'Lodi',
    'Lone Rock',
    'Lowell',
    'Mcfarland',
    'Marshall',
    'Mazomanie',
    'Merrimac',
    'Middleton',
    'Milton',
    'Mineral Point',
    'Monroe',
    'Montfort',
    'Monticello',
    'Morrisonville',
    'Mount Horeb',
    'Muscoda',
    'New Glarus',
    'Oregon',
    'Orfordville',
    'Plain',
    'Prairie Du Sac',
    'Reeseville',
    'Rewey',
    'Richland Center',
    'Ridgeway',
    'Sauk City',
    'Sextonville',
    'Sharon',
    'Shullsburg',
    'South Wayne',
    'Spring Green',
    'Stoughton',
    'Sun Prairie',
    'Verona',
    'Waterloo',
    'Waunakee',
    'Windsor',
    'Woodford',
    'Madison',
    'Bagley',
    'Beetown',
    'Benton',
    'Bloomington',
    'Boscobel',
    'Cassville',
    'Cuba City',
    'Dickeyville',
    'Fennimore',
    'Glen Haven',
    'Hazel Green',
    'Kieler',
    'Lancaster',
    'Mount Hope',
    'Patch Grove',
    'Platteville',
    'Potosi',
    'Prairie Du Chien',
    'Sinsinawa',
    'Stitzer',
    'Wauzeka',
    'Woodman',
    'Portage',
    'Adams',
    'Arlington',
    'Baraboo',
    'Beaver Dam',
    'Brandon',
    'Briggsville',
    'Burnett',
    'Cambria',
    'Cazenovia',
    'Columbus',
    'Dalton',
    'Dellwood',
    'Doylestown',
    'Elroy',
    'Endeavor',
    'Fairwater',
    'Fall River',
    'Fox Lake',
    'Friendship',
    'Friesland',
    'Grand Marsh',
    'Hillpoint',
    'Kingston',
    'Lake Delton',
    'La Valle',
    'Lime Ridge',
    'Loganville',
    'Lyndon Station',
    'Markesan',
    'Marquette',
    'Mauston',
    'Montello',
    'New Lisbon',
    'North Freedom',
    'Oxford',
    'Packwaukee',
    'Pardeeville',
    'Poynette',
    'Randolph',
    'Reedsburg',
    'Rio',
    'Rock Springs',
    'Union Center',
    'Waupun',
    'Westfield',
    'Wisconsin Dells',
    'Wonewoc',
    'Wyocena',
    'Amery',
    'Baldwin',
    'Beldenville',
    'Clayton',
    'Clear Lake',
    'Cushing',
    'Deer Park',
    'Dresser',
    'East Ellsworth',
    'Ellsworth',
    'Glenwood City',
    'Hager City',
    'Hammond',
    'Hudson',
    'New Richmond',
    'Osceola',
    'Prescott',
    'River Falls',
    'Roberts',
    'Saint Croix Falls',
    'Somerset',
    'Star Prairie',
    'Wilson',
    'Woodville',
    'Houlton',
    'Abrams',
    'Amberg',
    'Armstrong Creek',
    'Athelstane',
    'Black Creek',
    'Bonduel',
    'Brillion',
    'Cecil',
    'Coleman',
    'Combined Locks',
    'Crivitz',
    'De Pere',
    'Dunbar',
    'Fence',
    'Florence',
    'Forest Junction',
    'Gillett',
    'Goodman',
    'Greenleaf',
    'Green Valley',
    'Gresham',
    'Hilbert',
    'Kaukauna',
    'Freedom',
    'Keshena',
    'Kimberly',
    'Krakow',
    'Lakewood',
    'Lena',
    'Little Chute',
    'Little Suamico',
    'Marinette',
    'Mountain',
    'Neopit',
    'Niagara',
    'Nichols',
    'Oconto',
    'Oconto Falls',
    'Oneida',
    'Pembine',
    'Peshtigo',
    'Porterfield',
    'Potter',
    'Pound',
    'Pulaski',
    'Seymour',
    'Shawano',
    'Sherwood',
    'Shiocton',
    'Sobieski',
    'Suamico',
    'Suring',
    'Townsend',
    'Wausaukee',
    'Wrightstown',
    'Zachow',
    'Algoma',
    'Baileys Harbor',
    'Brussels',
    'Casco',
    'Collins',
    'Denmark',
    'Egg Harbor',
    'Ellison Bay',
    'Ephraim',
    'Fish Creek',
    'Forestville',
    'Francis Creek',
    'Kellnersville',
    'Kewaunee',
    'Luxemburg',
    'Manitowoc',
    'Maplewood',
    'Maribel',
    'Mishicot',
    'New Franken',
    'Reedsville',
    'Saint Nazianz',
    'Sister Bay',
    'Sturgeon Bay',
    'Tisch Mills',
    'Two Rivers',
    'Valders',
    'Washington Island',
    'Whitelaw',
    'Green Bay',
    'Wausau',
    'Marshfield',
    'Abbotsford',
    'Amherst',
    'Amherst Junction',
    'Aniwa',
    'Antigo',
    'Arpin',
    'Athens',
    'Auburndale',
    'Babcock',
    'Birnamwood',
    'Blenker',
    'Bowler',
    'Brokaw',
    'Bryant',
    'Chili',
    'Colby',
    'Curtiss',
    'Custer',
    'Deerbrook',
    'Dorchester',
    'Edgar',
    'Eland',
    'Elcho',
    'Elderon',
    'Elton',
    'Galloway',
    'Gilman',
    'Jump River',
    'Gleason',
    'Granton',
    'Greenwood',
    'Hannibal',
    'Hatley',
    'Hewitt',
    'Irma',
    'Junction City',
    'Loyal',
    'Lublin',
    'Marathon',
    'Mattoon',
    'Medford',
    'Merrill',
    'Milladore',
    'Mosinee',
    'Neillsville',
    'Nekoosa',
    'Nelsonville',
    'Ogema',
    'Owen',
    'Pearson',
    'Pelican Lake',
    'Phlox',
    'Pickerel',
    'Pittsville',
    'Plover',
    'Port Edwards',
    'Rib Lake',
    'Ringle',
    'Rosholt',
    'Rothschild',
    'Rudolph',
    'Schofield',
    'Spencer',
    'Stetsonville',
    'Stevens Point',
    'Stratford',
    'Summit Lake',
    'Tigerton',
    'Tomahawk',
    'Unity',
    'Vesper',
    'Westboro',
    'White Lake',
    'Willard',
    'Wisconsin Rapids',
    'Withee',
    'Wittenberg',
    'Rhinelander',
    'Argonne',
    'Boulder Junction',
    'Brantwood',
    'Butternut',
    'Catawba',
    'Clam Lake',
    'Conover',
    'Crandon',
    'Eagle River',
    'Fifield',
    'Gile',
    'Glen Flora',
    'Glidden',
    'Harshaw',
    'Hawkins',
    'Hazelhurst',
    'Heafford Junction',
    'Hurley',
    'Iron Belt',
    'Kennan',
    'Lac Du Flambeau',
    'Lake Tomahawk',
    'Land O Lakes',
    'Laona',
    'Long Lake',
    'Mc Naughton',
    'Manitowish Waters',
    'Mellen',
    'Mercer',
    'Minocqua',
    'Montreal',
    'Park Falls',
    'Phelps',
    'Phillips',
    'Prentice',
    'Presque Isle',
    'Saint Germain',
    'Saxon',
    'Sayner',
    'Star Lake',
    'Three Lakes',
    'Tony',
    'Tripoli',
    'Upson',
    'Wabeno',
    'Woodruff',
    'La Crosse',
    'Alma',
    'Alma Center',
    'Arcadia',
    'Arkdale',
    'Bangor',
    'Black River Falls',
    'Blair',
    'Camp Douglas',
    'Cashton',
    'Cataract',
    'Chaseburg',
    'Cochrane',
    'Coon Valley',
    'De Soto',
    'Dodge',
    'Eastman',
    'Ettrick',
    'Ferryville',
    'Fountain City',
    'Galesville',
    'Gays Mills',
    'Genoa',
    'Hillsboro',
    'Hixton',
    'Holmen',
    'Hustler',
    'Kendall',
    'La Farge',
    'Lynxville',
    'Mather',
    'Melrose',
    'Millston',
    'Mindoro',
    'Mount Sterling',
    'Necedah',
    'Norwalk',
    'Oakdale',
    'Onalaska',
    'Ontario',
    'Readstown',
    'Rockland',
    'Seneca',
    'Soldiers Grove',
    'Sparta',
    'Steuben',
    'Stoddard',
    'Taylor',
    'Tomah',
    'Trempealeau',
    'Tunnel City',
    'Viola',
    'Viroqua',
    'Warrens',
    'Westby',
    'West Salem',
    'Wilton',
    'Eau Claire',
    'Altoona',
    'Arkansaw',
    'Augusta',
    'Bay City',
    'Bloomer',
    'Boyceville',
    'Boyd',
    'Cadott',
    'Chetek',
    'Chippewa Falls',
    'Colfax',
    'Conrath',
    'Cornell',
    'Dallas',
    'Downing',
    'Downsville',
    'Durand',
    'Eau Galle',
    'Eleva',
    'Elk Mound',
    'Elmwood',
    'Fairchild',
    'Fall Creek',
    'Gilmanton',
    'Holcombe',
    'Humbird',
    'Independence',
    'Jim Falls',
    'Knapp',
    'Maiden Rock',
    'Menomonie',
    'Merrillan',
    'Mondovi',
    'Nelson',
    'New Auburn',
    'Osseo',
    'Pepin',
    'Pigeon Falls',
    'Plum City',
    'Prairie Farm',
    'Ridgeland',
    'Rock Falls',
    'Sand Creek',
    'Sheldon',
    'Spring Valley',
    'Stanley',
    'Stockholm',
    'Strum',
    'Thorp',
    'Wheeler',
    'Whitehall',
    'Spooner',
    'Almena',
    'Ashland',
    'Balsam Lake',
    'Barron',
    'Barronett',
    'Bayfield',
    'Benoit',
    'Birchwood',
    'Brill',
    'Bruce',
    'Brule',
    'Cable',
    'Cameron',
    'Centuria',
    'Comstock',
    'Cornucopia',
    'Couderay',
    'Cumberland',
    'Danbury',
    'Drummond',
    'Edgewater',
    'Exeland',
    'Foxboro',
    'Frederic',
    'Gordon',
    'Grand View',
    'Grantsburg',
    'Haugen',
    'Hawthorne',
    'Hayward',
    'Herbster',
    'Hertel',
    'High Bridge',
    'Iron River',
    'Ladysmith',
    'Lake Nebagamon',
    'La Pointe',
    'Luck',
    'Maple',
    'Marengo',
    'Mason',
    'Mikana',
    'Milltown',
    'Minong',
    'Odanah',
    'Ojibwa',
    'Poplar',
    'Port Wing',
    'Radisson',
    'Rice Lake',
    'Sarona',
    'Shell Lake',
    'Siren',
    'Solon Springs',
    'South Range',
    'Springbrook',
    'Stone Lake',
    'Superior',
    'Trego',
    'Turtle Lake',
    'Wascott',
    'Washburn',
    'Webster',
    'Weyerhaeuser',
    'Winter',
    'Oshkosh',
    'Almond',
    'Appleton',
    'Bancroft',
    'Bear Creek',
    'Berlin',
    'Big Falls',
    'Butte Des Morts',
    'Caroline',
    'Clintonville',
    'Coloma',
    'Dale',
    'Eldorado',
    'Embarrass',
    'Eureka',
    'Fond Du Lac',
    'Fremont',
    'Green Lake',
    'Greenville',
    'Hancock',
    'Hortonville',
    'Iola',
    'King',
    'Larsen',
    'Leopolis',
    'Manawa',
    'Marion',
    'Menasha',
    'Neenah',
    'Neshkoro',
    'New London',
    'Ogdensburg',
    'Omro',
    'Pickett',
    'Pine River',
    'Plainfield',
    'Poy Sippi',
    'Princeton',
    'Readfield',
    'Redgranite',
    'Ripon',
    'Rosendale',
    'Saxeville',
    'Scandinavia',
    'Tilleda',
    'Van Dyne',
    'Waukau',
    'Waupaca',
    'Wautoma',
    'Weyauwega',
    'Wild Rose',
    'Winnebago',
    'Winneconne'
  ],
  MN: [
    'Afton',
    'Almelund',
    'Bayport',
    'Bethel',
    'Braham',
    'Brook Park',
    'Cambridge',
    'Cannon Falls',
    'Castle Rock',
    'Cedar',
    'Center City',
    'Chisago City',
    'Circle Pines',
    'Cottage Grove',
    'Dalbo',
    'Dennison',
    'Dundas',
    'Elko New Market',
    'Faribault',
    'Farmington',
    'Forest Lake',
    'Frontenac',
    'Goodhue',
    'Grandy',
    'Grasston',
    'Hampton',
    'Harris',
    'Hastings',
    'Henriette',
    'Hinckley',
    'Hugo',
    'Isanti',
    'Lake City',
    'Lake Elmo',
    'Lakeland',
    'Lakeville',
    'Lindstrom',
    'Lonsdale',
    'Marine On Saint Croix',
    'Medford',
    'Mora',
    'Morristown',
    'Nerstrand',
    'Newport',
    'North Branch',
    'Northfield',
    'Owatonna',
    'Pine City',
    'Randolph',
    'Red Wing',
    'Rock Creek',
    'Rosemount',
    'Rush City',
    'Saint Francis',
    'Saint Paul Park',
    'Sandstone',
    'Scandia',
    'Shafer',
    'South Saint Paul',
    'Inver Grove Heights',
    'Stacy',
    'Stanchfield',
    'Stillwater',
    'Taylors Falls',
    'Vermillion',
    'Warsaw',
    'Webster',
    'Welch',
    'Willernie',
    'Wyoming',
    'Saint Paul',
    'Mendota',
    'Albertville',
    'Annandale',
    'Anoka',
    'Andover',
    'Hopkins',
    'Burnsville',
    'Arlington',
    'Becker',
    'Big Lake',
    'Bird Island',
    'Maple Grove',
    'Brownton',
    'Buffalo',
    'Buffalo Lake',
    'Carver',
    'Champlin',
    'Chanhassen',
    'Chaska',
    'Clear Lake',
    'Clearwater',
    'Cokato',
    'Cologne',
    'Crystal Bay',
    'Darwin',
    'Dassel',
    'Dayton',
    'Delano',
    'Eden Valley',
    'Elk River',
    'Excelsior',
    'Fairfax',
    'Franklin',
    'Gaylord',
    'Gibbon',
    'Glencoe',
    'Green Isle',
    'Hamburg',
    'Hamel',
    'Hanover',
    'Hector',
    'Eden Prairie',
    'Minnetonka',
    'Maple Plain',
    'Howard Lake',
    'Hutchinson',
    'Jordan',
    'Kimball',
    'Lester Prairie',
    'Litchfield',
    'Long Lake',
    'Loretto',
    'Maple Lake',
    'Mayer',
    'Minnetonka Beach',
    'Monticello',
    'Montrose',
    'Mound',
    'New Auburn',
    'New Germany',
    'Norwood Young America',
    'Osseo',
    'Plato',
    'Princeton',
    'Prior Lake',
    'Rockford',
    'Rogers',
    'Saint Bonifacius',
    'Saint Michael',
    'Santiago',
    'Savage',
    'Shakopee',
    'Silver Creek',
    'Silver Lake',
    'South Haven',
    'Norwood',
    'Spring Park',
    'Stewart',
    'Victoria',
    'Waconia',
    'Watertown',
    'Watkins',
    'Waverly',
    'Wayzata',
    'Navarre',
    'Young America',
    'Winsted',
    'Winthrop',
    'Zimmerman',
    'Minneapolis',
    'Beaver Bay',
    'Brimson',
    'Finland',
    'Grand Marais',
    'Grand Portage',
    'Hovland',
    'Isabella',
    'Knife River',
    'Lutsen',
    'Schroeder',
    'Silver Bay',
    'Tofte',
    'Two Harbors',
    'Adolph',
    'Alborn',
    'Angora',
    'Askov',
    'Aurora',
    'Babbitt',
    'Barnum',
    'Biwabik',
    'Bovey',
    'Britt',
    'Brookston',
    'Bruno',
    'Buhl',
    'Calumet',
    'Canyon',
    'Carlton',
    'Chisholm',
    'Cloquet',
    'Cohasset',
    'Coleraine',
    'Cook',
    'Cotton',
    'Crane Lake',
    'Cromwell',
    'Grand Rapids',
    'Ely',
    'Embarrass',
    'Esko',
    'Eveleth',
    'Finlayson',
    'Floodwood',
    'Forbes',
    'Gilbert',
    'Goodland',
    'Hibbing',
    'Hill City',
    'Holyoke',
    'Hoyt Lakes',
    'Iron',
    'Jacobson',
    'Keewatin',
    'Kerrick',
    'Kettle River',
    'Kinney',
    'Mcgregor',
    'Makinen',
    'Marble',
    'Meadowlands',
    'Melrude',
    'Moose Lake',
    'Mountain Iron',
    'Nashwauk',
    'Orr',
    'Nett Lake',
    'Pengilly',
    'Virginia',
    'Saginaw',
    'Sawyer',
    'Side Lake',
    'Soudan',
    'Sturgeon Lake',
    'Swan River',
    'Swatara',
    'Taconite',
    'Tamarack',
    'Tower',
    'Twig',
    'Warba',
    'Willow River',
    'Winton',
    'Wrenshall',
    'Wright',
    'Duluth',
    'Rochester',
    'Adams',
    'Altura',
    'Austin',
    'Blooming Prairie',
    'Brownsdale',
    'Brownsville',
    'Byron',
    'Caledonia',
    'Canton',
    'Chatfield',
    'Claremont',
    'Dakota',
    'Dexter',
    'Dodge Center',
    'Dover',
    'Eitzen',
    'Elgin',
    'Elkton',
    'Eyota',
    'Fountain',
    'Grand Meadow',
    'Harmony',
    'Hayfield',
    'Hokah',
    'Homer',
    'Houston',
    'Kasson',
    'Kellogg',
    'Kenyon',
    'La Crescent',
    'Lanesboro',
    'Lansing',
    'Le Roy',
    'Lewiston',
    'Lyle',
    'Mabel',
    'Mantorville',
    'Mazeppa',
    'Millville',
    'Minnesota City',
    'Oronoco',
    'Ostrander',
    'Peterson',
    'Pine Island',
    'Plainview',
    'Preston',
    'Racine',
    'Reads Landing',
    'Rollingstone',
    'Rose Creek',
    'Rushford',
    'Saint Charles',
    'Sargeant',
    'Spring Grove',
    'Spring Valley',
    'Stewartville',
    'Taopi',
    'Utica',
    'Wabasha',
    'Waltham',
    'Wanamingo',
    'West Concord',
    'Winona',
    'Stockton',
    'Wykoff',
    'Zumbro Falls',
    'Zumbrota',
    'Mankato',
    'Albert Lea',
    'Alden',
    'Amboy',
    'Belle Plaine',
    'Blue Earth',
    'Bricelyn',
    'Clarks Grove',
    'Cleveland',
    'Comfrey',
    'Conger',
    'Courtland',
    'Darfur',
    'Delavan',
    'Eagle Lake',
    'Easton',
    'Ellendale',
    'Elmore',
    'Elysian',
    'Emmons',
    'Essig',
    'Fairmont',
    'Freeborn',
    'Frost',
    'Garden City',
    'Geneva',
    'Glenville',
    'Good Thunder',
    'Granada',
    'Hanska',
    'Hartland',
    'Hayward',
    'Henderson',
    'Hollandale',
    'Hope',
    'Huntley',
    'Janesville',
    'Kasota',
    'Kiester',
    'Kilkenny',
    'Lafayette',
    'Lake Crystal',
    'La Salle',
    'Le Center',
    'Le Sueur',
    'Lewisville',
    'Madelia',
    'Madison Lake',
    'Mapleton',
    'Minnesota Lake',
    'Montgomery',
    'New Prague',
    'New Richland',
    'New Ulm',
    'Nicollet',
    'Northrop',
    'Pemberton',
    'Saint Clair',
    'Saint James',
    'Saint Peter',
    'Sanborn',
    'Searles',
    'Sleepy Eye',
    'Springfield',
    'Truman',
    'Twin Lakes',
    'Vernon Center',
    'Waldorf',
    'Waseca',
    'Waterville',
    'Wells',
    'Winnebago',
    'Windom',
    'Adrian',
    'Alpha',
    'Arco',
    'Avoca',
    'Balaton',
    'Beaver Creek',
    'Bigelow',
    'Bingham Lake',
    'Brewster',
    'Butterfield',
    'Ceylon',
    'Chandler',
    'Currie',
    'Dovray',
    'Dunnell',
    'Edgerton',
    'Ellsworth',
    'Fulda',
    'Garvin',
    'Hardwick',
    'Hendricks',
    'Heron Lake',
    'Hills',
    'Holland',
    'Ihlen',
    'Iona',
    'Ivanhoe',
    'Jackson',
    'Jasper',
    'Jeffers',
    'Kanaranzi',
    'Kenneth',
    'Lake Benton',
    'Lakefield',
    'Lake Wilson',
    'Lamberton',
    'Leota',
    'Lismore',
    'Luverne',
    'Lynd',
    'Magnolia',
    'Mountain Lake',
    'Odin',
    'Okabena',
    'Ormsby',
    'Pipestone',
    'Reading',
    'Revere',
    'Round Lake',
    'Rushmore',
    'Russell',
    'Ruthton',
    'Sherburn',
    'Slayton',
    'Steen',
    'Storden',
    'Tracy',
    'Trimont',
    'Trosky',
    'Tyler',
    'Walnut Grove',
    'Welcome',
    'Westbrook',
    'Wilmont',
    'Woodstock',
    'Worthington',
    'Willmar',
    'Alberta',
    'Appleton',
    'Atwater',
    'Barry',
    'Beardsley',
    'Bellingham',
    'Belview',
    'Benson',
    'Blomkest',
    'Boyd',
    'Browns Valley',
    'Canby',
    'Chokio',
    'Clara City',
    'Clarkfield',
    'Clements',
    'Clinton',
    'Clontarf',
    'Correll',
    'Cosmos',
    'Cottonwood',
    'Danube',
    'Danvers',
    'Dawson',
    'Donnelly',
    'Dumont',
    'Echo',
    'Ghent',
    'Graceville',
    'Granite Falls',
    'Grove City',
    'Hancock',
    'Hanley Falls',
    'Herman',
    'Holloway',
    'Kandiyohi',
    'Kerkhoven',
    'Lake Lillian',
    'Lucan',
    'Madison',
    'Marietta',
    'Marshall',
    'Maynard',
    'Milan',
    'Milroy',
    'Minneota',
    'Montevideo',
    'Morgan',
    'Morris',
    'Morton',
    'Murdock',
    'New London',
    'Norcross',
    'Odessa',
    'Olivia',
    'Ortonville',
    'Pennock',
    'Porter',
    'Prinsburg',
    'Raymond',
    'Redwood Falls',
    'Renville',
    'Sacred Heart',
    'Seaforth',
    'Spicer',
    'Sunburg',
    'Taunton',
    'Vesta',
    'Wabasso',
    'Wanda',
    'Watson',
    'Wheaton',
    'Wood Lake',
    'Saint Cloud',
    'Albany',
    'Alexandria',
    'Ashby',
    'Avon',
    'Barrett',
    'Belgrade',
    'Bock',
    'Bowlus',
    'Brandon',
    'Brooten',
    'Buckman',
    'Burtrum',
    'Carlos',
    'Cold Spring',
    'Collegeville',
    'Cyrus',
    'Dalton',
    'Elrosa',
    'Evansville',
    'Farwell',
    'Flensburg',
    'Foley',
    'Foreston',
    'Freeport',
    'Garfield',
    'Gilman',
    'Glenwood',
    'Greenwald',
    'Grey Eagle',
    'Hillman',
    'Hoffman',
    'Holdingford',
    'Holmes City',
    'Isle',
    'Kensington',
    'Lastrup',
    'Little Falls',
    'Long Prairie',
    'Lowry',
    'Mc Grath',
    'Melrose',
    'Milaca',
    'Miltona',
    'Nelson',
    'New Munich',
    'Oak Park',
    'Ogilvie',
    'Onamia',
    'Osakis',
    'Parkers Prairie',
    'Paynesville',
    'Pease',
    'Pierz',
    'Rice',
    'Richmond',
    'Rockville',
    'Roscoe',
    'Royalton',
    'Saint Joseph',
    'Saint Stephen',
    'Saint Martin',
    'Sartell',
    'Sauk Centre',
    'Sauk Rapids',
    'Starbuck',
    'Swanville',
    'Upsala',
    'Villard',
    'Wahkon',
    'Waite Park',
    'West Union',
    'Brainerd',
    'Baxter',
    'Ah Gwah Ching',
    'Aitkin',
    'Akeley',
    'Aldrich',
    'Backus',
    'Benedict',
    'Bertha',
    'Browerville',
    'Clarissa',
    'Crosby',
    'Crosslake',
    'Cushing',
    'Deerwood',
    'Eagle Bend',
    'Emily',
    'Fifty Lakes',
    'Fort Ripley',
    'Garrison',
    'Hackensack',
    'Hewitt',
    'Ironton',
    'Jenkins',
    'Lake George',
    'Lake Hubert',
    'Laporte',
    'Menahga',
    'Merrifield',
    'Motley',
    'Nevis',
    'Nisswa',
    'Palisade',
    'Park Rapids',
    'Pequot Lakes',
    'Pillager',
    'Pine River',
    'Randall',
    'Sebeka',
    'Nimrod',
    'Staples',
    'Verndale',
    'Wadena',
    'Walker',
    'Detroit Lakes',
    'Ada',
    'Audubon',
    'Barnesville',
    'Battle Lake',
    'Bejou',
    'Beltrami',
    'Bluffton',
    'Borup',
    'Breckenridge',
    'Callaway',
    'Campbell',
    'Climax',
    'Clitherall',
    'Comstock',
    'Deer Creek',
    'Dent',
    'Dilworth',
    'Elbow Lake',
    'Elizabeth',
    'Erhard',
    'Erskine',
    'Felton',
    'Fergus Falls',
    'Fertile',
    'Flom',
    'Fosston',
    'Foxhome',
    'Frazee',
    'Gary',
    'Georgetown',
    'Glyndon',
    'Halstad',
    'Hawley',
    'Hendrum',
    'Henning',
    'Hitterdal',
    'Kent',
    'Lake Park',
    'Mcintosh',
    'Mahnomen',
    'Moorhead',
    'Nashua',
    'Naytahwaush',
    'New York Mills',
    'Nielsville',
    'Ogema',
    'Osage',
    'Ottertail',
    'Pelican Rapids',
    'Perham',
    'Perley',
    'Ponsford',
    'Richville',
    'Richwood',
    'Rochert',
    'Rothsay',
    'Sabin',
    'Shelly',
    'Tintah',
    'Twin Valley',
    'Ulen',
    'Underwood',
    'Vergas',
    'Vining',
    'Waubun',
    'Wendell',
    'White Earth',
    'Winger',
    'Wolf Lake',
    'Wolverton',
    'Bemidji',
    'Bagley',
    'Baudette',
    'Bena',
    'Big Falls',
    'Bigfork',
    'Birchdale',
    'Blackduck',
    'Bowstring',
    'Cass Lake',
    'Clearbrook',
    'Deer River',
    'Talmoon',
    'Effie',
    'Federal Dam',
    'Gonvick',
    'Gully',
    'Hines',
    'International Falls',
    'Kelliher',
    'Lengby',
    'Leonard',
    'Littlefork',
    'Loman',
    'Longville',
    'Marcell',
    'Margie',
    'Max',
    'Mizpah',
    'Northome',
    'Outing',
    'Pennington',
    'Ponemah',
    'Puposky',
    'Ranier',
    'Kabetogama',
    'Redby',
    'Redlake',
    'Remer',
    'Roosevelt',
    'Shevlin',
    'Solway',
    'South International Falls',
    'Spring Lake',
    'Squaw Lake',
    'Swift',
    'Tenstrike',
    'Trail',
    'Waskish',
    'Williams',
    'Wilton',
    'Wirt',
    'Thief River Falls',
    'Alvarado',
    'Angle Inlet',
    'Argyle',
    'Badger',
    'Brooks',
    'Crookston',
    'Donaldson',
    'East Grand Forks',
    'Euclid',
    'Fisher',
    'Gatzke',
    'Goodridge',
    'Greenbush',
    'Grygla',
    'Hallock',
    'Halma',
    'Humboldt',
    'Karlstad',
    'Kennedy',
    'Lake Bronson',
    'Lancaster',
    'Mentor',
    'Middle River',
    'Newfolden',
    'Noyes',
    'Oak Island',
    'Oklee',
    'Oslo',
    'Plummer',
    'Red Lake Falls',
    'Roseau',
    'Saint Hilaire',
    'Saint Vincent',
    'Salol',
    'Stephen',
    'Strandquist',
    'Strathcona',
    'Viking',
    'Wannaska',
    'Warren',
    'Warroad'
  ],
  SD: [
    'Alcester',
    'Aurora',
    'Baltic',
    'Beresford',
    'Brandon',
    'Brookings',
    'Burbank',
    'Canistota',
    'Canton',
    'Centerville',
    'Chancellor',
    'Chester',
    'Colman',
    'Colton',
    'Crooks',
    'Davis',
    'Dell Rapids',
    'Egan',
    'Elk Point',
    'Elkton',
    'Fairview',
    'Flandreau',
    'Freeman',
    'Garretson',
    'Gayville',
    'Harrisburg',
    'Hartford',
    'Hudson',
    'Humboldt',
    'Hurley',
    'Irene',
    'Jefferson',
    'Lennox',
    'Lesterville',
    'Lyons',
    'Madison',
    'Marion',
    'Menno',
    'Mission Hill',
    'Monroe',
    'Montrose',
    'North Sioux City',
    'Nunda',
    'Oldham',
    'Olivet',
    'Parker',
    'Ramona',
    'Renner',
    'Rowena',
    'Rutland',
    'Salem',
    'Scotland',
    'Sinai',
    'Springfield',
    'Tabor',
    'Tea',
    'Trent',
    'Tyndall',
    'Utica',
    'Valley Springs',
    'Vermillion',
    'Viborg',
    'Volga',
    'Volin',
    'Wakonda',
    'Wentworth',
    'Winfred',
    'Worthing',
    'Yankton',
    'Sioux Falls',
    'Watertown',
    'Arlington',
    'Astoria',
    'Badger',
    'Big Stone City',
    'Bradley',
    'Brandt',
    'Bristol',
    'Bruce',
    'Bryant',
    'Castlewood',
    'Claire City',
    'Clark',
    'Clear Lake',
    'Corona',
    'De Smet',
    'Eden',
    'Erwin',
    'Estelline',
    'Florence',
    'Garden City',
    'Gary',
    'Goodwin',
    'Grenville',
    'Hayti',
    'Hazel',
    'Henry',
    'Kranzburg',
    'Labolt',
    'Lake City',
    'Lake Norden',
    'Lake Preston',
    'Marvin',
    'Milbank',
    'New Effington',
    'Ortley',
    'Peever',
    'Raymond',
    'Revillo',
    'Rosholt',
    'Roslyn',
    'Sisseton',
    'South Shore',
    'Stockholm',
    'Strandburg',
    'Summit',
    'Toronto',
    'Twin Brooks',
    'Veblen',
    'Vienna',
    'Wallace',
    'Waubay',
    'Webster',
    'White',
    'Willow Lake',
    'Wilmot',
    'Mitchell',
    'Alexandria',
    'Alpena',
    'Armour',
    'Artesian',
    'Avon',
    'Bonesteel',
    'Bridgewater',
    'Canova',
    'Carpenter',
    'Carthage',
    'Cavour',
    'Chamberlain',
    'Corsica',
    'Dante',
    'Delmont',
    'Dimock',
    'Emery',
    'Ethan',
    'Fairfax',
    'Fedora',
    'Fort Thompson',
    'Fulton',
    'Gann Valley',
    'Geddes',
    'Harrison',
    'Highmore',
    'Stephan',
    'Hitchcock',
    'Howard',
    'Huron',
    'Iroquois',
    'Kaylor',
    'Kimball',
    'Lake Andes',
    'Lane',
    'Letcher',
    'Marty',
    'Miller',
    'Mount Vernon',
    'New Holland',
    'Oacoma',
    'Parkston',
    'Pickstown',
    'Plankinton',
    'Platte',
    'Pukwana',
    'Ree Heights',
    'Saint Lawrence',
    'Spencer',
    'Stickney',
    'Tripp',
    'Virgil',
    'Wagner',
    'Wessington',
    'Wessington Springs',
    'White Lake',
    'Wolsey',
    'Woonsocket',
    'Yale',
    'Aberdeen',
    'Akaska',
    'Amherst',
    'Andover',
    'Ashton',
    'Barnard',
    'Bath',
    'Bowdle',
    'Brentford',
    'Britton',
    'Claremont',
    'Columbia',
    'Conde',
    'Cresbard',
    'Doland',
    'Eureka',
    'Faulkton',
    'Ferney',
    'Frankfort',
    'Frederick',
    'Gettysburg',
    'Groton',
    'Hecla',
    'Hosmer',
    'Houghton',
    'Hoven',
    'Ipswich',
    'Java',
    'Langford',
    'Lebanon',
    'Leola',
    'Long Lake',
    'Mansfield',
    'Mellette',
    'Northville',
    'Onaka',
    'Orient',
    'Pierpont',
    'Redfield',
    'Rockham',
    'Roscoe',
    'Selby',
    'Seneca',
    'Stratford',
    'Tolstoy',
    'Tulare',
    'Turton',
    'Warner',
    'Westport',
    'Pierre',
    'Agar',
    'Belvidere',
    'Blunt',
    'Burke',
    'Colome',
    'Dallas',
    'Draper',
    'Fort Pierre',
    'Gregory',
    'Hamill',
    'Harrold',
    'Hayes',
    'Herrick',
    'Holabird',
    'Ideal',
    'Kadoka',
    'Kennebec',
    'Long Valley',
    'Lower Brule',
    'Martin',
    'Midland',
    'Milesville',
    'Mission',
    'Murdo',
    'Norris',
    'Okaton',
    'Okreek',
    'Onida',
    'Parmelee',
    'Philip',
    'Presho',
    'Reliance',
    'Rosebud',
    'Saint Charles',
    'Saint Francis',
    'Tuthill',
    'Vivian',
    'Wanblee',
    'White River',
    'Winner',
    'Witten',
    'Wood',
    'Mobridge',
    'Bison',
    'Bullhead',
    'Cherry Creek',
    'Dupree',
    'Eagle Butte',
    'Faith',
    'Glencross',
    'Glenham',
    'Herreid',
    'Isabel',
    'Keldron',
    'Lantry',
    'Lemmon',
    'Little Eagle',
    'Lodgepole',
    'Mc Intosh',
    'Mc Laughlin',
    'Meadow',
    'Morristown',
    'Mound City',
    'Pollock',
    'Prairie City',
    'Ralph',
    'Reva',
    'Ridgeview',
    'Timber Lake',
    'Trail City',
    'Wakpala',
    'Walker',
    'Watauga',
    'Whitehorse',
    'Rapid City',
    'Ellsworth AFB',
    'Allen',
    'Batesland',
    'Belle Fourche',
    'Black Hawk',
    'Box Elder',
    'Buffalo',
    'Buffalo Gap',
    'Camp Crook',
    'Caputa',
    'Custer',
    'Deadwood',
    'Edgemont',
    'Enning',
    'Fairburn',
    'Fort Meade',
    'Hermosa',
    'Hill City',
    'Hot Springs',
    'Howes',
    'Interior',
    'Keystone',
    'Kyle',
    'Lead',
    'Ludlow',
    'Manderson',
    'Mud Butte',
    'Nemo',
    'Newell',
    'New Underwood',
    'Nisland',
    'Oelrichs',
    'Oglala',
    'Oral',
    'Owanka',
    'Piedmont',
    'Pine Ridge',
    'Porcupine',
    'Pringle',
    'Quinn',
    'Redig',
    'Saint Onge',
    'Scenic',
    'Smithwick',
    'Spearfish',
    'Sturgis',
    'Union Center',
    'Vale',
    'Wall',
    'Wasta',
    'White Owl',
    'Whitewood',
    'Wounded Knee'
  ],
  ND: [
    'Abercrombie',
    'Absaraka',
    'Amenia',
    'Argusville',
    'Arthur',
    'Ayr',
    'Barney',
    'Blanchard',
    'Buffalo',
    'Casselton',
    'Cayuga',
    'Christine',
    'Clifford',
    'Cogswell',
    'Colfax',
    'Davenport',
    'Enderlin',
    'Erie',
    'Fairmount',
    'Fingal',
    'Forman',
    'Fort Ransom',
    'Galesburg',
    'Gardner',
    'Grandin',
    'Gwinner',
    'Hankinson',
    'Harwood',
    'Havana',
    'Hillsboro',
    'Hope',
    'Horace',
    'Hunter',
    'Kathryn',
    'Kindred',
    'Leonard',
    'Lidgerwood',
    'Lisbon',
    'Luverne',
    'Mcleod',
    'Mantador',
    'Mapleton',
    'Milnor',
    'Mooreton',
    'Nome',
    'Oriska',
    'Page',
    'Pillsbury',
    'Rutland',
    'Sheldon',
    'Stirum',
    'Tower City',
    'Valley City',
    'Wahpeton',
    'Walcott',
    'West Fargo',
    'Wheatland',
    'Wyndmere',
    'Fargo',
    'Grand Forks',
    'Grand Forks AFB',
    'Adams',
    'Aneta',
    'Arvilla',
    'Bathgate',
    'Buxton',
    'Caledonia',
    'Cavalier',
    'Crystal',
    'Cummings',
    'Dahlen',
    'Drayton',
    'Edinburg',
    'Emerado',
    'Fairdale',
    'Finley',
    'Fordville',
    'Forest River',
    'Gilby',
    'Glasston',
    'Grafton',
    'Hamilton',
    'Hannah',
    'Hatton',
    'Hensel',
    'Hoople',
    'Inkster',
    'Langdon',
    'Lankin',
    'Larimore',
    'Mcville',
    'Maida',
    'Manvel',
    'Mayville',
    'Mekinock',
    'Michigan',
    'Milton',
    'Minto',
    'Mountain',
    'Neche',
    'Niagara',
    'Northwood',
    'Osnabrock',
    'Park River',
    'Pembina',
    'Petersburg',
    'Pisek',
    'Portland',
    'Reynolds',
    'Saint Thomas',
    'Sharon',
    'Thompson',
    'Wales',
    'Walhalla',
    'Devils Lake',
    'Agate',
    'Alsen',
    'Balta',
    'Belcourt',
    'Bisbee',
    'Bottineau',
    'Bremen',
    'Brocket',
    'Calvin',
    'Cando',
    'Churchs Ferry',
    'Crary',
    'Dunseith',
    'Edmore',
    'Egeland',
    'Esmond',
    'Fort Totten',
    'Hampden',
    'Hansboro',
    'Harvey',
    'Knox',
    'Lakota',
    'Lawton',
    'Leeds',
    'Maddock',
    'Minnewaukan',
    'Munich',
    'Mylo',
    'Nekoma',
    'New Rockford',
    'Oberon',
    'Pekin',
    'Penn',
    'Perth',
    'Rocklake',
    'Rolette',
    'Rolla',
    'Rugby',
    'Saint John',
    'Saint Michael',
    'Sarles',
    'Sheyenne',
    'Starkweather',
    'Tokio',
    'Tolna',
    'Warwick',
    'Webster',
    'Willow City',
    'Wolford',
    'York',
    'Jamestown',
    'Ashley',
    'Berlin',
    'Binford',
    'Bowdon',
    'Buchanan',
    'Carrington',
    'Cathay',
    'Chaseley',
    'Cleveland',
    'Cooperstown',
    'Courtenay',
    'Dawson',
    'Dazey',
    'Denhoff',
    'Dickey',
    'Edgeley',
    'Ellendale',
    'Fessenden',
    'Forbes',
    'Fredonia',
    'Fullerton',
    'Gackle',
    'Glenfield',
    'Goodrich',
    'Grace City',
    'Hannaford',
    'Hurdsfield',
    'Jessie',
    'Jud',
    'Kensal',
    'Kulm',
    'Lamoure',
    'Lehr',
    'Litchville',
    'Mcclusky',
    'Mchenry',
    'Marion',
    'Medina',
    'Montpelier',
    'Oakes',
    'Pettibone',
    'Pingree',
    'Regan',
    'Robinson',
    'Rogers',
    'Sanborn',
    'Spiritwood',
    'Steele',
    'Streeter',
    'Sutton',
    'Sykeston',
    'Tappen',
    'Tuttle',
    'Verona',
    'Wimbledon',
    'Wing',
    'Wishek',
    'Woodworth',
    'Ypsilanti',
    'Bismarck',
    'Almont',
    'Baldwin',
    'Beulah',
    'Braddock',
    'Cannon Ball',
    'Carson',
    'Center',
    'Coleharbor',
    'Driscoll',
    'Elgin',
    'Flasher',
    'Fort Yates',
    'Garrison',
    'Golden Valley',
    'Hague',
    'Hazelton',
    'Hazen',
    'Kintyre',
    'Linton',
    'Mandan',
    'Menoken',
    'Mercer',
    'Moffit',
    'Napoleon',
    'New Leipzig',
    'New Salem',
    'Raleigh',
    'Riverdale',
    'Saint Anthony',
    'Selfridge',
    'Shields',
    'Solen',
    'Stanton',
    'Sterling',
    'Strasburg',
    'Turtle Lake',
    'Underwood',
    'Washburn',
    'Wilton',
    'Zap',
    'Zeeland',
    'Dickinson',
    'Amidon',
    'Beach',
    'Belfield',
    'Bowman',
    'Dodge',
    'Dunn Center',
    'Fairfield',
    'Gladstone',
    'Glen Ullin',
    'Golva',
    'Grassy Butte',
    'Halliday',
    'Hebron',
    'Hettinger',
    'Killdeer',
    'Lefor',
    'Manning',
    'Marmarth',
    'Marshall',
    'Medora',
    'Mott',
    'New England',
    'Reeder',
    'Regent',
    'Rhame',
    'Richardton',
    'Scranton',
    'Sentinel Butte',
    'South Heart',
    'Taylor',
    'Minot',
    'Minot AFB',
    'Anamoose',
    'Antler',
    'Balfour',
    'Bantry',
    'Benedict',
    'Berthold',
    'Bowbells',
    'Burlington',
    'Butte',
    'Carpio',
    'Columbus',
    'Crosby',
    'Deering',
    'Des Lacs',
    'Donnybrook',
    'Douglas',
    'Drake',
    'Flaxton',
    'Glenburn',
    'Granville',
    'Karlsruhe',
    'Kenmare',
    'Kramer',
    'Lansford',
    'Lignite',
    'Mcgregor',
    'Makoti',
    'Mandaree',
    'Martin',
    'Max',
    'Maxbass',
    'Mohall',
    'Newburg',
    'New Town',
    'Noonan',
    'Norwich',
    'Palermo',
    'Parshall',
    'Plaza',
    'Portal',
    'Powers Lake',
    'Roseglen',
    'Ross',
    'Ruso',
    'Ryder',
    'Sawyer',
    'Sherwood',
    'Souris',
    'Stanley',
    'Surrey',
    'Tolley',
    'Towner',
    'Upham',
    'Velva',
    'Voltaire',
    'Westhope',
    'White Earth',
    'Wildrose',
    'Williston',
    'Alamo',
    'Alexander',
    'Ambrose',
    'Arnegard',
    'Cartwright',
    'Epping',
    'Fortuna',
    'Grenora',
    'Keene',
    'Ray',
    'Tioga',
    'Trenton',
    'Watford City',
    'Zahl'
  ],
  MT: [
    'Absarokee',
    'Acton',
    'Ashland',
    'Ballantine',
    'Bearcreek',
    'Belfry',
    'Bighorn',
    'Big Timber',
    'Birney',
    'Boyd',
    'Bridger',
    'Broadview',
    'Busby',
    'Clyde Park',
    'Columbus',
    'Cooke City',
    'Crow Agency',
    'Custer',
    'Decker',
    'Edgar',
    'Emigrant',
    'Fishtail',
    'Fromberg',
    'Gardiner',
    'Garryowen',
    'Grass Range',
    'Greycliff',
    'Hardin',
    'Fort Smith',
    'Harlowton',
    'Huntley',
    'Hysham',
    'Ingomar',
    'Joliet',
    'Lame Deer',
    'Laurel',
    'Lavina',
    'Livingston',
    'Lodge Grass',
    'Mc Leod',
    'Martinsdale',
    'Melstone',
    'Melville',
    'Molt',
    'Mosby',
    'Musselshell',
    'Nye',
    'Otter',
    'Park City',
    'Pompeys Pillar',
    'Pray',
    'Pryor',
    'Rapelje',
    'Red Lodge',
    'Reed Point',
    'Roberts',
    'Roscoe',
    'Roundup',
    'Ryegate',
    'Saint Xavier',
    'Sanders',
    'Sand Springs',
    'Shawmut',
    'Shepherd',
    'Silver Gate',
    'Springdale',
    'Sumatra',
    'Teigen',
    'Two Dot',
    'Wilsall',
    'Winnett',
    'Worden',
    'Wyola',
    'Billings',
    'Wolf Point',
    'Antelope',
    'Bainville',
    'Brockton',
    'Brockway',
    'Circle',
    'Crane',
    'Culbertson',
    'Dagmar',
    'Fairview',
    'Flaxville',
    'Fort Peck',
    'Frazer',
    'Froid',
    'Glasgow',
    'Saint Marie',
    'Glentana',
    'Hinsdale',
    'Homestead',
    'Lambert',
    'Larslan',
    'Medicine Lake',
    'Nashua',
    'Opheim',
    'Outlook',
    'Peerless',
    'Plentywood',
    'Poplar',
    'Raymond',
    'Redstone',
    'Reserve',
    'Richey',
    'Richland',
    'Saco',
    'Savage',
    'Scobey',
    'Sidney',
    'Vandalia',
    'Vida',
    'Westby',
    'Whitetail',
    'Miles City',
    'Alzada',
    'Angela',
    'Baker',
    'Biddle',
    'Bloomfield',
    'Boyes',
    'Broadus',
    'Brusett',
    'Capitol',
    'Cohagen',
    'Colstrip',
    'Ekalaka',
    'Fallon',
    'Forsyth',
    'Glendive',
    'Hammond',
    'Hathaway',
    'Ismay',
    'Jordan',
    'Kinsey',
    'Lindsay',
    'Mildred',
    'Olive',
    'Plevna',
    'Powderville',
    'Rosebud',
    'Terry',
    'Volborg',
    'Wibaux',
    'Willard',
    'Great Falls',
    'Malmstrom AFB',
    'Augusta',
    'Babb',
    'Belt',
    'Black Eagle',
    'Brady',
    'Browning',
    'Buffalo',
    'Bynum',
    'Carter',
    'Cascade',
    'Choteau',
    'Coffee Creek',
    'Conrad',
    'Cut Bank',
    'Denton',
    'Dupuyer',
    'Dutton',
    'East Glacier Park',
    'Ethridge',
    'Fairfield',
    'Floweree',
    'Forest Grove',
    'Fort Benton',
    'Fort Shaw',
    'Galata',
    'Geraldine',
    'Geyser',
    'Heart Butte',
    'Highwood',
    'Hilger',
    'Hobson',
    'Judith Gap',
    'Kevin',
    'Ledger',
    'Lewistown',
    'Loma',
    'Lothair',
    'Moccasin',
    'Monarch',
    'Moore',
    'Neihart',
    'Oilmont',
    'Pendroy',
    'Power',
    'Raynesford',
    'Roy',
    'Sand Coulee',
    'Shelby',
    'Simms',
    'Stanford',
    'Stockett',
    'Sunburst',
    'Sun River',
    'Sweet Grass',
    'Ulm',
    'Valier',
    'Vaughn',
    'Winifred',
    'Havre',
    'Big Sandy',
    'Box Elder',
    'Chester',
    'Chinook',
    'Dodson',
    'Gildford',
    'Harlem',
    'Hays',
    'Hingham',
    'Hogeland',
    'Inverness',
    'Joplin',
    'Kremlin',
    'Lloyd',
    'Loring',
    'Malta',
    'Rudyard',
    'Turner',
    'Whitewater',
    'Whitlash',
    'Zortman',
    'Zurich',
    'Helena',
    'Basin',
    'Boulder',
    'Canyon Creek',
    'Clancy',
    'East Helena',
    'Fort Harrison',
    'Jefferson City',
    'Lincoln',
    'Marysville',
    'Radersburg',
    'Ringling',
    'Toston',
    'Townsend',
    'White Sulphur Springs',
    'Winston',
    'Wolf Creek',
    'Butte',
    'Alder',
    'Anaconda',
    'Avon',
    'Belgrade',
    'Bozeman',
    'Big Sky',
    'Cameron',
    'Cardwell',
    'Deer Lodge',
    'Dell',
    'Dillon',
    'Divide',
    'Elliston',
    'Ennis',
    'Gallatin Gateway',
    'Garrison',
    'Glen',
    'Gold Creek',
    'Harrison',
    'Jackson',
    'Lima',
    'Mc Allister',
    'Manhattan',
    'Melrose',
    'Norris',
    'Polaris',
    'Pony',
    'Ramsay',
    'Sheridan',
    'Silver Star',
    'Three Forks',
    'Twin Bridges',
    'Virginia City',
    'Warm Springs',
    'West Yellowstone',
    'Whitehall',
    'Willow Creek',
    'Wisdom',
    'Wise River',
    'Missoula',
    'Alberton',
    'Arlee',
    'Bonner',
    'Charlo',
    'Clinton',
    'Condon',
    'Conner',
    'Corvallis',
    'Darby',
    'De Borgia',
    'Dixon',
    'Drummond',
    'Florence',
    'Frenchtown',
    'Grantsdale',
    'Hall',
    'Hamilton',
    'Pinesdale',
    'Haugan',
    'Helmville',
    'Heron',
    'Hot Springs',
    'Huson',
    'Lolo',
    'Lonepine',
    'Milltown',
    'Noxon',
    'Ovando',
    'Pablo',
    'Paradise',
    'Philipsburg',
    'Plains',
    'Polson',
    'Ravalli',
    'Ronan',
    'Saint Ignatius',
    'Saint Regis',
    'Saltese',
    'Seeley Lake',
    'Stevensville',
    'Sula',
    'Superior',
    'Thompson Falls',
    'Trout Creek',
    'Victor',
    'Kalispell',
    'Big Arm',
    'Bigfork',
    'Columbia Falls',
    'Coram',
    'Dayton',
    'Elmo',
    'Essex',
    'Eureka',
    'Fortine',
    'Hungry Horse',
    'Kila',
    'Lake Mc Donald',
    'Lakeside',
    'Libby',
    'Marion',
    'Martin City',
    'Olney',
    'Polebridge',
    'Proctor',
    'Rexford',
    'Rollins',
    'Somers',
    'Stryker',
    'Trego',
    'Troy',
    'West Glacier',
    'Whitefish'
  ],
  IL: [
    'Alden',
    'Antioch',
    'Arlington Heights',
    'Elk Grove Village',
    'Rolling Meadows',
    'Barrington',
    'Crystal Lake',
    'Cary',
    'Deerfield',
    'Des Plaines',
    'Fox Lake',
    'Fox River Grove',
    'Glencoe',
    'Glenview',
    'Golf',
    'Grayslake',
    'Gurnee',
    'Harvard',
    'Hebron',
    'Highland Park',
    'Fort Sheridan',
    'Palatine',
    'Highwood',
    'Ingleside',
    'Island Lake',
    'Kenilworth',
    'Lake Bluff',
    'Lake Forest',
    'Lake Villa',
    'Lake Zurich',
    'Libertyville',
    'Mchenry',
    'Morton Grove',
    'Mount Prospect',
    'Mundelein',
    'Vernon Hills',
    'Northbrook',
    'North Chicago',
    'Park Ridge',
    'Lincolnshire',
    'Prospect Heights',
    'Richmond',
    'Ringwood',
    'Round Lake',
    'Russell',
    'Skokie',
    'Waukegan',
    'Spring Grove',
    'Techny',
    'Wadsworth',
    'Wauconda',
    'Great Lakes',
    'Buffalo Grove',
    'Wheeling',
    'Wilmette',
    'Winnetka',
    'Winthrop Harbor',
    'Wonder Lake',
    'Woodstock',
    'Zion',
    'Addison',
    'Algonquin',
    'Bartlett',
    'Bellwood',
    'Bensenville',
    'Streamwood',
    'Bloomingdale',
    'Burlington',
    'Carpentersville',
    'Clare',
    'Cortland',
    'Creston',
    'Dekalb',
    'Carol Stream',
    'Dundee',
    'Elburn',
    'Elgin',
    'Elmhurst',
    'Esmond',
    'Forest Park',
    'Franklin Park',
    'Hanover Park',
    'Geneva',
    'Genoa',
    'Gilberts',
    'Glen Ellyn',
    'Glendale Heights',
    'Hampshire',
    'Hines',
    'Huntley',
    'Itasca',
    'Kaneville',
    'Kingston',
    'Kirkland',
    'Lafox',
    'Lombard',
    'Malta',
    'Maple Park',
    'Marengo',
    'Maywood',
    'Westchester',
    'Broadview',
    'Lake In The Hills',
    'Medinah',
    'Schaumburg',
    'Melrose Park',
    'Hillside',
    'Berkeley',
    'Stone Park',
    'Hoffman Estates',
    'Plato Center',
    'River Grove',
    'Roselle',
    'Saint Charles',
    'Schiller Park',
    'South Elgin',
    'Sycamore',
    'Union',
    'Villa Park',
    'Wasco',
    'Wayne',
    'West Chicago',
    'Wheaton',
    'Winfield',
    'Wood Dale',
    'Evanston',
    'Chicago',
    'Oak Park',
    'River Forest',
    'Beecher',
    'Berwyn',
    'Crest Hill',
    'Shorewood',
    'Blue Island',
    'Braceville',
    'Braidwood',
    'Calumet City',
    'Channahon',
    'Chicago Heights',
    'Chicago Ridge',
    'Coal City',
    'Crete',
    'Crestwood',
    'Dolton',
    'Dwight',
    'Elwood',
    'Flossmoor',
    'Frankfort',
    'Gardner',
    'Glenwood',
    'Harvey',
    'Markham',
    'Hazel Crest',
    'Homewood',
    'Joliet',
    'Kinsman',
    'Lansing',
    'Lemont',
    'Bolingbrook',
    'Lockport',
    'Manhattan',
    'Matteson',
    'Mazon',
    'Midlothian',
    'Romeoville',
    'Minooka',
    'Mokena',
    'Monee',
    'Morris',
    'New Lenox',
    'Oak Forest',
    'Oak Lawn',
    'Bridgeview',
    'Hometown',
    'Hickory Hills',
    'Justice',
    'Burbank',
    'Odell',
    'Olympia Fields',
    'Orland Park',
    'Palos Heights',
    'Palos Park',
    'Palos Hills',
    'Park Forest',
    'Peotone',
    'Posen',
    'Ransom',
    'Richton Park',
    'Robbins',
    'South Holland',
    'South Wilmington',
    'Steger',
    'Thornton',
    'Tinley Park',
    'Country Club Hills',
    'Verona',
    'Willow Springs',
    'Wilmington',
    'Worth',
    'University Park',
    'Homer Glen',
    'Bedford Park',
    'Summit Argo',
    'Aurora',
    'Batavia',
    'Big Rock',
    'Bristol',
    'Brookfield',
    'Clarendon Hills',
    'Downers Grove',
    'Woodridge',
    'Earlville',
    'Eola',
    'Hinckley',
    'Hinsdale',
    'Oak Brook',
    'La Grange',
    'La Grange Park',
    'Willowbrook',
    'Lee',
    'Leland',
    'Lisle',
    'Lyons',
    'Millbrook',
    'Millington',
    'Montgomery',
    'Mooseheart',
    'Naperville',
    'Newark',
    'North Aurora',
    'Oswego',
    'Plainfield',
    'Plano',
    'Riverside',
    'Sandwich',
    'Serena',
    'Shabbona',
    'Sheridan',
    'Somonauk',
    'Steward',
    'Sugar Grove',
    'Warrenville',
    'Waterman',
    'Wedron',
    'Western Springs',
    'Westmont',
    'Yorkville',
    'Darien',
    'Fox Valley',
    'Harwood Heights',
    'Elmwood Park',
    'Lincolnwood',
    'Niles',
    'Alsip',
    'Cicero',
    'Evergreen Park',
    'Riverdale',
    'Kankakee',
    'Aroma Park',
    'Ashkum',
    'Beaverville',
    'Bonfield',
    'Bourbonnais',
    'Bradley',
    'Buckingham',
    'Buckley',
    'Cabery',
    'Campus',
    'Chatsworth',
    'Chebanse',
    'Cissna Park',
    'Claytonville',
    'Clifton',
    'Crescent City',
    'Cullom',
    'Danforth',
    'Donovan',
    'East Lynn',
    'Elliott',
    'Emington',
    'Essex',
    'Gibson City',
    'Gilman',
    'Goodwine',
    'Grant Park',
    'Herscher',
    'Hoopeston',
    'Hopkins Park',
    'Iroquois',
    'Kempton',
    'Loda',
    'Ludlow',
    'Manteno',
    'Martinton',
    'Melvin',
    'Milford',
    'Momence',
    'Onarga',
    'Papineau',
    'Paxton',
    'Pembroke Township',
    'Piper City',
    'Rankin',
    'Reddick',
    'Roberts',
    'Rossville',
    'Saint Anne',
    'Sheldon',
    'Stockland',
    'Thawville',
    'Union Hill',
    'Watseka',
    'Wellington',
    'Woodland',
    'Apple River',
    'Ashton',
    'Baileyville',
    'Belvidere',
    'Byron',
    'Caledonia',
    'Capron',
    'Cedarville',
    'Chadwick',
    'Chana',
    'Cherry Valley',
    'Dakota',
    'Davis',
    'Davis Junction',
    'Dixon',
    'Durand',
    'East Dubuque',
    'Eleroy',
    'Elizabeth',
    'Forreston',
    'Franklin Grove',
    'Freeport',
    'Galena',
    'Galt',
    'Garden Prairie',
    'German Valley',
    'Hanover',
    'Harmon',
    'Holcomb',
    'Kent',
    'Lanark',
    'Leaf River',
    'Lena',
    'Lindenwood',
    'Mc Connell',
    'Milledgeville',
    'Monroe Center',
    'Mount Carroll',
    'Mount Morris',
    'Nachusa',
    'Nora',
    'Orangeville',
    'Oregon',
    'Pearl City',
    'Pecatonica',
    'Polo',
    'Poplar Grove',
    'Ridott',
    'Rochelle',
    'Rock City',
    'Rock Falls',
    'Rockton',
    'Roscoe',
    'Savanna',
    'Scales Mound',
    'Seward',
    'Shannon',
    'Shirland',
    'South Beloit',
    'Sterling',
    'Stillman Valley',
    'Stockton',
    'Warren',
    'Winnebago',
    'Winslow',
    'Woosung',
    'Rockford',
    'Loves Park',
    'Machesney Park',
    'Rock Island',
    'Albany',
    'Aledo',
    'Andalusia',
    'Andover',
    'Annawan',
    'Atkinson',
    'Barstow',
    'Buffalo Prairie',
    'Cambridge',
    'Carbon Cliff',
    'Coal Valley',
    'Colona',
    'Cordova',
    'Deer Grove',
    'East Moline',
    'Erie',
    'Fenton',
    'Fulton',
    'Geneseo',
    'Hampton',
    'Hillsdale',
    'Hooppole',
    'Illinois City',
    'Joy',
    'Lyndon',
    'Lynn Center',
    'Matherville',
    'Milan',
    'Moline',
    'Morrison',
    'New Boston',
    'Orion',
    'Osco',
    'Port Byron',
    'Preemption',
    'Prophetstown',
    'Rapids City',
    'Reynolds',
    'Sherrard',
    'Silvis',
    'Tampico',
    'Taylor Ridge',
    'Thomson',
    'La Salle',
    'Amboy',
    'Ancona',
    'Arlington',
    'Blackstone',
    'Buda',
    'Bureau',
    'Cedar Point',
    'Cherry',
    'Compton',
    'Cornell',
    'Dalzell',
    'Dana',
    'Depue',
    'Dover',
    'Eldena',
    'Grand Ridge',
    'Granville',
    'Hennepin',
    'Kasbeer',
    'Ladd',
    'La Moille',
    'Lee Center',
    'Leonore',
    'Long Point',
    'Lostant',
    'Mc Nabb',
    'Magnolia',
    'Malden',
    'Manlius',
    'Mark',
    'Marseilles',
    'Mendota',
    'Mineral',
    'Neponset',
    'New Bedford',
    'Oglesby',
    'Ohio',
    'Ottawa',
    'Paw Paw',
    'Peru',
    'Princeton',
    'Rutland',
    'Seatonville',
    'Seneca',
    'Sheffield',
    'Spring Valley',
    'Standard',
    'Streator',
    'Sublette',
    'Tiskilwa',
    'Toluca',
    'Tonica',
    'Triumph',
    'Troy Grove',
    'Utica',
    'Van Orin',
    'Varna',
    'Walnut',
    'Wenona',
    'West Brooklyn',
    'Wyanet',
    'Galesburg',
    'Abingdon',
    'Adair',
    'Alexis',
    'Alpha',
    'Altona',
    'Avon',
    'Bardolph',
    'Berwick',
    'Biggsville',
    'Bishop Hill',
    'Blandinsville',
    'Bradford',
    'Bushnell',
    'Cameron',
    'Camp Grove',
    'Carman',
    'Castleton',
    'Cuba',
    'Dahinda',
    'East Galesburg',
    'Ellisville',
    'Fairview',
    'Fiatt',
    'Galva',
    'Gerlaw',
    'Gilson',
    'Gladstone',
    'Good Hope',
    'Henderson',
    'Industry',
    'Ipava',
    'Keithsburg',
    'Kewanee',
    'Kirkwood',
    'Knoxville',
    'La Fayette',
    'La Harpe',
    'Laura',
    'Littleton',
    'Little York',
    'Lomax',
    'Macomb',
    'Maquon',
    'Marietta',
    'Media',
    'Monmouth',
    'New Windsor',
    'North Henderson',
    'Oneida',
    'Ophiem',
    'Oquawka',
    'Prairie City',
    'Raritan',
    'Rio',
    'Roseville',
    'Saint Augustine',
    'Sciota',
    'Seaton',
    'Smithfield',
    'Smithshire',
    'Speer',
    'Stronghurst',
    'Table Grove',
    'Toulon',
    'Vermont',
    'Victoria',
    'Viola',
    'Wataga',
    'Williamsfield',
    'Woodhull',
    'Wyoming',
    'Astoria',
    'Benson',
    'Brimfield',
    'Bryant',
    'Canton',
    'Chillicothe',
    'Dunfermline',
    'Dunlap',
    'Edelstein',
    'Edwards',
    'Elmwood',
    'Eureka',
    'Farmington',
    'Forest City',
    'Glasford',
    'Green Valley',
    'Groveland',
    'Hanna City',
    'Henry',
    'Kingston Mines',
    'Lacon',
    'La Rose',
    'Lewistown',
    'Liverpool',
    'London Mills',
    'Lowpoint',
    'Manito',
    'Mapleton',
    'Metamora',
    'Morton',
    'Mossville',
    'Norris',
    'Pekin',
    'Princeville',
    'Putnam',
    'Roanoke',
    'Rome',
    'Saint David',
    'South Pekin',
    'Sparland',
    'Topeka',
    'Tremont',
    'Trivoli',
    'Washburn',
    'Washington',
    'Yates City',
    'Peoria',
    'Creve Coeur',
    'East Peoria',
    'Peoria Heights',
    'Bloomington',
    'Anchor',
    'Armington',
    'Arrowsmith',
    'Atlanta',
    'Bellflower',
    'Carlock',
    'Chenoa',
    'Clinton',
    'Colfax',
    'Congerville',
    'Cooksville',
    'Cropsey',
    'Danvers',
    'Deer Creek',
    'Delavan',
    'Dewitt',
    'Downs',
    'Ellsworth',
    'El Paso',
    'Fairbury',
    'Flanagan',
    'Forrest',
    'Goodfield',
    'Graymont',
    'Gridley',
    'Heyworth',
    'Hopedale',
    'Hudson',
    'Kenney',
    'Lane',
    'Lawndale',
    'Le Roy',
    'Lexington',
    'Mc Lean',
    'Mackinaw',
    'Maroa',
    'Merna',
    'Minier',
    'Minonk',
    'Normal',
    'Pontiac',
    'Saunemin',
    'Saybrook',
    'Secor',
    'Shirley',
    'Sibley',
    'Stanford',
    'Strawn',
    'Towanda',
    'Wapella',
    'Waynesville',
    'Urbana',
    'Allerton',
    'Alvin',
    'Armstrong',
    'Bement',
    'Bismarck',
    'Bondville',
    'Broadlands',
    'Catlin',
    'Cerro Gordo',
    'Champaign',
    'Cisco',
    'Collison',
    'Danville',
    'Tilton',
    'De Land',
    'Dewey',
    'Fairmount',
    'Farmer City',
    'Fisher',
    'Fithian',
    'Foosland',
    'Georgetown',
    'Gifford',
    'Henning',
    'Homer',
    'Indianola',
    'Ivesdale',
    'Longview',
    'Mahomet',
    'Mansfield',
    'Milmine',
    'Monticello',
    'Muncie',
    'Oakwood',
    'Ogden',
    'Penfield',
    'Pesotum',
    'Philo',
    'Potomac',
    'Rantoul',
    'Ridge Farm',
    'Royal',
    'Sadorus',
    'Saint Joseph',
    'Savoy',
    'Seymour',
    'Sidell',
    'Sidney',
    'Thomasboro',
    'Tolono',
    'Weldon',
    'Westville',
    'White Heath',
    'Arcola',
    'Arthur',
    'Ashmore',
    'Atwood',
    'Bethany',
    'Brocton',
    'Camargo',
    'Charleston',
    'Chrisman',
    'Dalton City',
    'Gays',
    'Hammond',
    'Hindsboro',
    'Humboldt',
    'Hume',
    'Kansas',
    'La Place',
    'Lovington',
    'Mattoon',
    'Metcalf',
    'Murdock',
    'Newman',
    'Oakland',
    'Paris',
    'Redmon',
    'Sullivan',
    'Tuscola',
    'Vermilion',
    'Villa Grove',
    'Windsor',
    'Alhambra',
    'Alton',
    'Batchtown',
    'Benld',
    'Bethalto',
    'Bingham',
    'Brighton',
    'Brussels',
    'Bunker Hill',
    'Butler',
    'Carrollton',
    'Coffeen',
    'Cottage Hills',
    'Donnellson',
    'Dorsey',
    'Dow',
    'Eagarville',
    'East Alton',
    'Edwardsville',
    'Eldred',
    'Elsah',
    'Fidelity',
    'Fieldon',
    'Fillmore',
    'Gillespie',
    'Glen Carbon',
    'Godfrey',
    'Golden Eagle',
    'Grafton',
    'Granite City',
    'Greenfield',
    'Hamburg',
    'Hamel',
    'Hardin',
    'Hartford',
    'Hillsboro',
    'Hillview',
    'Irving',
    'Jerseyville',
    'Kampsville',
    'Kane',
    'Litchfield',
    'Livingston',
    'Lovejoy',
    'Madison',
    'Marine',
    'Maryville',
    'Medora',
    'Michael',
    'Moro',
    'Mount Olive',
    'Mozier',
    'National Stock Yards',
    'New Douglas',
    'Nokomis',
    'Ohlman',
    'Panama',
    'Patterson',
    'Piasa',
    'Ramsey',
    'Rockbridge',
    'Roodhouse',
    'Rosamond',
    'Roxana',
    'Sawyerville',
    'Sorento',
    'South Roxana',
    'Staunton',
    'Taylor Springs',
    'Venice',
    'Walshville',
    'White Hall',
    'Wilsonville',
    'Witt',
    'Wood River',
    'Worden',
    'Wrights',
    'East Saint Louis',
    'Fairview Heights',
    'Addieville',
    'Albers',
    'Aviston',
    'Baldwin',
    'Bartelso',
    'Beckemeyer',
    'Belleville',
    'Mascoutah',
    'Scott Air Force Base',
    'Breese',
    'Carlyle',
    'Caseyville',
    'Chester',
    'Collinsville',
    'Columbia',
    'Coulterville',
    'Cutler',
    'Dupo',
    'East Carondelet',
    'Ellis Grove',
    'Evansville',
    'Freeburg',
    'Fults',
    'Germantown',
    'Greenville',
    'Hagarstown',
    'Hecker',
    'Highland',
    'Hoffman',
    'Huey',
    'Keyesport',
    'Lebanon',
    'Lenzburg',
    'Maeystown',
    'Marissa',
    'Menard',
    'Millstadt',
    'Modoc',
    'Mulberry Grove',
    'Nashville',
    'New Athens',
    'New Baden',
    'New Memphis',
    'Oakdale',
    'O Fallon',
    'Okawville',
    'Percy',
    'Pierron',
    'Pinckneyville',
    'Pocahontas',
    'Prairie Du Rocher',
    'Red Bud',
    'Renault',
    'Rockwood',
    'Saint Jacob',
    'Saint Libory',
    'Smithboro',
    'Smithton',
    'Sparta',
    'Steeleville',
    'Summerfield',
    'Tilden',
    'Trenton',
    'Troy',
    'Valmeyer',
    'Walsh',
    'Waterloo',
    'Quincy',
    'Augusta',
    'Barry',
    'Basco',
    'Baylis',
    'Bowen',
    'Camden',
    'Camp Point',
    'Carthage',
    'Chambersburg',
    'Clayton',
    'Coatsburg',
    'Colchester',
    'Colusa',
    'Dallas City',
    'Elvaston',
    'Ferris',
    'Fowler',
    'Golden',
    'Griggsville',
    'Hamilton',
    'Hull',
    'Huntsville',
    'Kinderhook',
    'La Prairie',
    'Liberty',
    'Lima',
    'Loraine',
    'Mendon',
    'Milton',
    'Mount Sterling',
    'Nauvoo',
    'Nebo',
    'New Canton',
    'New Salem',
    'Niota',
    'Paloma',
    'Payson',
    'Pearl',
    'Perry',
    'Pittsfield',
    'Plainville',
    'Pleasant Hill',
    'Plymouth',
    'Rockport',
    'Sutter',
    'Tennessee',
    'Timewell',
    'Ursa',
    'Versailles',
    'Warsaw',
    'West Point',
    'Effingham',
    'Allendale',
    'Altamont',
    'Annapolis',
    'Beecher City',
    'Bridgeport',
    'Brownstown',
    'Calhoun',
    'Casey',
    'Claremont',
    'Cowden',
    'Dennison',
    'Dieterich',
    'Dundas',
    'Edgewood',
    'Flat Rock',
    'Greenup',
    'Herrick',
    'Hidalgo',
    'Hutsonville',
    'Ingraham',
    'Janesville',
    'Jewett',
    'Lakewood',
    'Lawrenceville',
    'Lerna',
    'Marshall',
    'Martinsville',
    'Mason',
    'Mode',
    'Montrose',
    'Mount Erie',
    'Neoga',
    'Newton',
    'Oblong',
    'Olney',
    'Palestine',
    'Parkersburg',
    'Robinson',
    'Saint Elmo',
    'Sainte Marie',
    'Saint Francisville',
    'Shumway',
    'Sigel',
    'Stewardson',
    'Stoy',
    'Strasburg',
    'Sumner',
    'Teutopolis',
    'Toledo',
    'Trilla',
    'Vandalia',
    'Watson',
    'Westfield',
    'West Liberty',
    'West Salem',
    'West Union',
    'West York',
    'Wheeler',
    'Willow Hill',
    'Yale',
    'Argenta',
    'Assumption',
    'Beason',
    'Blue Mound',
    'Boody',
    'Buffalo',
    'Bulpitt',
    'Chestnut',
    'Cornland',
    'Dawson',
    'Decatur',
    'Divernon',
    'Edinburg',
    'Elwin',
    'Farmersville',
    'Findlay',
    'Forsyth',
    'Glenarm',
    'Harristown',
    'Harvel',
    'Illiopolis',
    'Kincaid',
    'Lake Fork',
    'Latham',
    'Macon',
    'Mechanicsburg',
    'Morrisonville',
    'Mount Auburn',
    'Mount Pulaski',
    'Mt Zion',
    'Moweaqua',
    'Niantic',
    'Oconee',
    'Oreana',
    'Owaneco',
    'Palmer',
    'Pana',
    'Pawnee',
    'Raymond',
    'Riverton',
    'Rochester',
    'Shelbyville',
    'Stonington',
    'Taylorville',
    'Tovey',
    'Tower Hill',
    'Waggoner',
    'Warrensburg',
    'Alexander',
    'Alsey',
    'Arenzville',
    'Ashland',
    'Athens',
    'Auburn',
    'Bath',
    'Beardstown',
    'Bluffs',
    'Bluff Springs',
    'Browning',
    'Cantrall',
    'Carlinville',
    'Chandlerville',
    'Chapin',
    'Chatham',
    'Chesterfield',
    'Concord',
    'Easton',
    'Elkhart',
    'Emden',
    'Franklin',
    'Frederick',
    'Girard',
    'Greenview',
    'Hartsburg',
    'Havana',
    'Hettick',
    'Jacksonville',
    'Kilbourne',
    'Lincoln',
    'Lincolns New Salem',
    'Literberry',
    'Loami',
    'Lowder',
    'Manchester',
    'Mason City',
    'Meredosia',
    'Middletown',
    'Modesto',
    'Murrayville',
    'New Berlin',
    'New Holland',
    'Nilwood',
    'Oakford',
    'Palmyra',
    'Petersburg',
    'Pleasant Plains',
    'Rushville',
    'San Jose',
    'Scottville',
    'Sherman',
    'Shipman',
    'Tallula',
    'Thayer',
    'Virden',
    'Virginia',
    'Waverly',
    'Williamsville',
    'Winchester',
    'Woodson',
    'Springfield',
    'Centralia',
    'Hoyleton',
    'Akin',
    'Albion',
    'Alma',
    'Ashley',
    'Barnhill',
    'Belle Rive',
    'Bellmont',
    'Benton',
    'Bluford',
    'Bone Gap',
    'Bonnie',
    'Broughton',
    'Browns',
    'Buckner',
    'Burnt Prairie',
    'Carmi',
    'Christopher',
    'Cisne',
    'Clay City',
    'Coello',
    'Crossville',
    'Dahlgren',
    'Dale',
    'Dix',
    'Du Bois',
    'Du Quoin',
    'Ellery',
    'Emma',
    'Enfield',
    'Ewing',
    'Fairfield',
    'Farina',
    'Flora',
    'Frankfort Heights',
    'Freeman Spur',
    'Geff',
    'Golden Gate',
    'Grayville',
    'Ina',
    'Irvington',
    'Iuka',
    'Johnsonville',
    'Keenes',
    'Keensburg',
    'Kell',
    'Kinmundy',
    'Lancaster',
    'Logan',
    'Loogootee',
    'Louisville',
    'Mc Leansboro',
    'Macedonia',
    'Maunie',
    'Mill Shoals',
    'Mount Carmel',
    'Mount Vernon',
    'Mulkeytown',
    'Nason',
    'New Haven',
    'Noble',
    'Norris City',
    'Odin',
    'Omaha',
    'Opdyke',
    'Orient',
    'Patoka',
    'Radom',
    'Richview',
    'Rinard',
    'Sailor Springs',
    'Saint Peter',
    'Salem',
    'Sandoval',
    'Scheller',
    'Sesser',
    'Shobonier',
    'Sims',
    'Springerton',
    'Tamaroa',
    'Texico',
    'Thompsonville',
    'Valier',
    'Vernon',
    'Walnut Hill',
    'Waltonville',
    'Wayne City',
    'West Frankfort',
    'Whittington',
    'Woodlawn',
    'Xenia',
    'Carbondale',
    'Alto Pass',
    'Anna',
    'Ava',
    'Belknap',
    'Boles',
    'Brookport',
    'Buncombe',
    'Cairo',
    'Cambria',
    'Campbell Hill',
    'Carrier Mills',
    'Carterville',
    'Cave In Rock',
    'Cobden',
    'Colp',
    'Creal Springs',
    'Cypress',
    'De Soto',
    'Dongola',
    'Dowell',
    'Eddyville',
    'Eldorado',
    'Elizabethtown',
    'Elkville',
    'Energy',
    'Equality',
    'Galatia',
    'Golconda',
    'Goreville',
    'Gorham',
    'Grand Chain',
    'Grand Tower',
    'Grantsburg',
    'Harrisburg',
    'Herod',
    'Herrin',
    'Hurst',
    'Jacob',
    'Johnston City',
    'Jonesboro',
    'Joppa',
    'Junction',
    'Karbers Ridge',
    'Karnak',
    'Mc Clure',
    'Makanda',
    'Marion',
    'Metropolis',
    'Millcreek',
    'Miller City',
    'Mound City',
    'Mounds',
    'Muddy',
    'Murphysboro',
    'New Burnside',
    'Olive Branch',
    'Olmsted',
    'Oraville',
    'Ozark',
    'Perks',
    'Pittsburg',
    'Pomona',
    'Pulaski',
    'Raleigh',
    'Ridgway',
    'Rosiclare',
    'Royalton',
    'Shawneetown',
    'Simpson',
    'Stonefort',
    'Tamms',
    'Thebes',
    'Ullin',
    'Unity',
    'Vergennes',
    'Vienna',
    'Villa Ridge',
    'Willisville',
    'Wolf Lake',
    'Zeigler'
  ],
  MO: [
    'Allenton',
    'Chesterfield',
    'Arnold',
    'Ballwin',
    'Barnhart',
    'Beaufort',
    'Berger',
    'Catawissa',
    'Cedar Hill',
    'Crystal City',
    'De Soto',
    'Dittmer',
    'Eureka',
    'Fenton',
    'Festus',
    'Fletcher',
    'Florissant',
    'French Village',
    'Gerald',
    'Wildwood',
    'Gray Summit',
    'Grubville',
    'Hazelwood',
    'Maryland Heights',
    'Bridgeton',
    'Earth City',
    'Hematite',
    'Herculaneum',
    'High Ridge',
    'Hillsboro',
    'House Springs',
    'Imperial',
    'Kimmswick',
    'Labadie',
    'Leslie',
    'Liguori',
    'Lonedell',
    'Luebbering',
    'Mapaville',
    'Morse Mill',
    'New Haven',
    'Pacific',
    'Pevely',
    'Richwoods',
    'Robertsville',
    'Saint Albans',
    'Saint Ann',
    'Saint Clair',
    'Stanton',
    'Sullivan',
    'Union',
    'Valles Mines',
    'Valley Park',
    'Villa Ridge',
    'Washington',
    'Rosebud',
    'Saint Louis',
    'Saint Charles',
    'Annada',
    'Augusta',
    'Bellflower',
    'Bowling Green',
    'Clarksville',
    'Cottleville',
    'Curryville',
    'Defiance',
    'Dutzow',
    'Elsberry',
    'Eolia',
    'Farber',
    'Flinthill',
    'Foley',
    'Foristell',
    'Hawk Point',
    'High Hill',
    'Jonesburg',
    'Laddonia',
    'Louisiana',
    'Marthasville',
    'Middletown',
    'Montgomery City',
    'Moscow Mills',
    'New Florence',
    'New Melle',
    'O Fallon',
    'Lake Saint Louis',
    'Old Monroe',
    'Olney',
    'Portage Des Sioux',
    'Saint Peters',
    'Silex',
    'Treloar',
    'Troy',
    'Truesdale',
    'Truxton',
    'Vandalia',
    'Warrenton',
    'Wellsville',
    'Wentzville',
    'West Alton',
    'Whiteside',
    'Williamsburg',
    'Winfield',
    'Wright City',
    'Hannibal',
    'Alexandria',
    'Anabel',
    'Arbela',
    'Ashburn',
    'Bethel',
    'Canton',
    'Center',
    'Clarence',
    'Durham',
    'Emden',
    'Ewing',
    'Frankford',
    'Granger',
    'Hunnewell',
    'Kahoka',
    'Knox City',
    'La Belle',
    'La Grange',
    'Lentner',
    'Leonard',
    'Lewistown',
    'Luray',
    'Maywood',
    'Monroe City',
    'Monticello',
    'Newark',
    'New London',
    'Novelty',
    'Palmyra',
    'Perry',
    'Philadelphia',
    'Plevna',
    'Revere',
    'Saint Patrick',
    'Saverton',
    'Shelbina',
    'Shelbyville',
    'Taylor',
    'Wayland',
    'Williamstown',
    'Wyaconda',
    'Kirksville',
    'Atlanta',
    'Baring',
    'Bevier',
    'Brashear',
    'Callao',
    'Coatsville',
    'Downing',
    'Edina',
    'Elmer',
    'Ethel',
    'Gibbs',
    'Glenwood',
    'Gorin',
    'Green Castle',
    'Green City',
    'Greentop',
    'Hurdland',
    'Lancaster',
    'La Plata',
    'Livonia',
    'Macon',
    'Memphis',
    'Milan',
    'New Boston',
    'New Cambria',
    'Novinger',
    'Pollock',
    'Queen City',
    'Rutledge',
    'Unionville',
    'Winigan',
    'Worthington',
    'Park Hills',
    'Annapolis',
    'Arcadia',
    'Belgrade',
    'Belleview',
    'Bismarck',
    'Black',
    'Blackwell',
    'Bloomsdale',
    'Bonne Terre',
    'Bunker',
    'Cadet',
    'Caledonia',
    'Cascade',
    'Centerville',
    'Des Arc',
    'Doe Run',
    'Ellington',
    'Farmington',
    'Fredericktown',
    'Irondale',
    'Ironton',
    'Knob Lick',
    'Leadwood',
    'Lesterville',
    'Marquand',
    'Middle Brook',
    'Mineral Point',
    'Patton',
    'Pilot Knob',
    'Potosi',
    'Redford',
    'Reynolds',
    'Sainte Genevieve',
    'Saint Mary',
    'Tiff',
    'Vulcan',
    'Cape Girardeau',
    'Advance',
    'Altenburg',
    'Bell City',
    'Benton',
    'Brazeau',
    'Brownwood',
    'Burfordville',
    'Chaffee',
    'Commerce',
    'Daisy',
    'Delta',
    'Dutchtown',
    'Farrar',
    'Friedheim',
    'Frohna',
    'Gipsy',
    'Glenallen',
    'Gordonville',
    'Jackson',
    'Kelso',
    'Leopold',
    'Mc Gee',
    'Marble Hill',
    'Millersville',
    'Morley',
    'Oak Ridge',
    'Old Appleton',
    'Oran',
    'Perkins',
    'Perryville',
    'Mc Bride',
    'Pocahontas',
    'Scott City',
    'Sedgewickville',
    'Sturdivant',
    'Uniontown',
    'Vanduser',
    'Whitewater',
    'Zalma',
    'Sikeston',
    'Anniston',
    'Arbyrd',
    'Bernie',
    'Bertrand',
    'Blodgett',
    'Bloomfield',
    'Braggadocio',
    'Bragg City',
    'Canalou',
    'Cardwell',
    'Caruthersville',
    'Catron',
    'Charleston',
    'Clarkton',
    'Cooter',
    'Deering',
    'Dexter',
    'East Prairie',
    'Essex',
    'Gibson',
    'Gideon',
    'Gobler',
    'Grayridge',
    'Hayti',
    'Holcomb',
    'Holland',
    'Hornersville',
    'Kennett',
    'Kewanee',
    'Lilbourn',
    'Malden',
    'Marston',
    'Matthews',
    'Morehouse',
    'New Madrid',
    'Parma',
    'Portageville',
    'Risco',
    'Rives',
    'Senath',
    'Steele',
    'Tallapoosa',
    'Wardell',
    'Whiteoak',
    'Wolf Island',
    'Wyatt',
    'Poplar Bluff',
    'Briar',
    'Broseley',
    'Campbell',
    'Clubb',
    'Doniphan',
    'Dudley',
    'Ellsinore',
    'Fagus',
    'Fairdealing',
    'Fisk',
    'Fremont',
    'Gatewood',
    'Grandin',
    'Greenville',
    'Harviell',
    'Lodi',
    'Lowndes',
    'Mill Spring',
    'Naylor',
    'Neelyville',
    'Oxly',
    'Patterson',
    'Piedmont',
    'Puxico',
    'Qulin',
    'Rombauer',
    'Shook',
    'Silva',
    'Van Buren',
    'Wappapello',
    'Williamsville',
    'Alma',
    'Lees Summit',
    'Bates City',
    'Belton',
    'Blue Springs',
    'Buckner',
    'Camden',
    'Camden Point',
    'Centerview',
    'Concordia',
    'Corder',
    'Dover',
    'Excelsior Springs',
    'Farley',
    'Grain Valley',
    'Grandview',
    'Greenwood',
    'Hardin',
    'Henrietta',
    'Higginsville',
    'Holden',
    'Holt',
    'Independence',
    'Kearney',
    'Kingsville',
    'Lawson',
    'Levasy',
    'Lexington',
    'Liberty',
    'Lone Jack',
    'Mayview',
    'Missouri City',
    'Mosby',
    'Napoleon',
    'Oak Grove',
    'Odessa',
    'Orrick',
    'Peculiar',
    'Platte City',
    'Pleasant Hill',
    'Raymore',
    'Rayville',
    'Richmond',
    'Sibley',
    'Smithville',
    'Strasburg',
    'Waldron',
    'Warrensburg',
    'Waverly',
    'Wellington',
    'Weston',
    'Kansas City',
    'Riverside',
    'Agency',
    'Albany',
    'Allendale',
    'Amazonia',
    'Amity',
    'Barnard',
    'Bethany',
    'Blythedale',
    'Bolckow',
    'Burlington Junction',
    'Cameron',
    'Clarksdale',
    'Clearmont',
    'Clyde',
    'Conception',
    'Conception Junction',
    'Cosby',
    'Craig',
    'Darlington',
    'Dearborn',
    'De Kalb',
    'Denver',
    'Eagleville',
    'Easton',
    'Edgerton',
    'Elmo',
    'Fairfax',
    'Maysville',
    'Faucett',
    'Fillmore',
    'Forest City',
    'Gentry',
    'Gower',
    'Graham',
    'Grant City',
    'Guilford',
    'Hatfield',
    'Helena',
    'Hopkins',
    'King City',
    'Lathrop',
    'Maitland',
    'Martinsville',
    'Maryville',
    'Mound City',
    'New Hampton',
    'Oregon',
    'Osborn',
    'Parnell',
    'Pickering',
    'Plattsburg',
    'Ravenwood',
    'Rea',
    'Ridgeway',
    'Rock Port',
    'Rosendale',
    'Rushville',
    'Savannah',
    'Sheridan',
    'Skidmore',
    'Stanberry',
    'Stewartsville',
    'Tarkio',
    'Trimble',
    'Turney',
    'Union Star',
    'Watson',
    'Weatherby',
    'Westboro',
    'Worth',
    'Saint Joseph',
    'Chillicothe',
    'Altamont',
    'Bogard',
    'Bosworth',
    'Braymer',
    'Breckenridge',
    'Brookfield',
    'Browning',
    'Bucklin',
    'Cainsville',
    'Carrollton',
    'Chula',
    'Coffey',
    'Cowgill',
    'Dawn',
    'De Witt',
    'Gallatin',
    'Galt',
    'Gilman City',
    'Hale',
    'Hamilton',
    'Harris',
    'Humphreys',
    'Jameson',
    'Jamesport',
    'Kidder',
    'Kingston',
    'Laclede',
    'Laredo',
    'Linneus',
    'Lock Springs',
    'Lucerne',
    'Ludlow',
    'Mc Fall',
    'Marceline',
    'Meadville',
    'Mendon',
    'Mercer',
    'Mooresville',
    'Newtown',
    'Norborne',
    'Pattonsburg',
    'Polo',
    'Powersville',
    'Princeton',
    'Purdin',
    'Rothville',
    'Spickard',
    'Stet',
    'Sumner',
    'Tina',
    'Trenton',
    'Utica',
    'Wheeling',
    'Winston',
    'Harrisonville',
    'Adrian',
    'Amoret',
    'Amsterdam',
    'Appleton City',
    'Archie',
    'Blairstown',
    'Bronaugh',
    'Butler',
    'Chilhowee',
    'Cleveland',
    'Clinton',
    'Collins',
    'Creighton',
    'Deepwater',
    'Deerfield',
    'Drexel',
    'East Lynne',
    'El Dorado Springs',
    'Foster',
    'Freeman',
    'Garden City',
    'Golden City',
    'Harwood',
    'Hume',
    'Jasper',
    'Jerico Springs',
    'Lamar',
    'Leeton',
    'Liberal',
    'Lowry City',
    'Metz',
    'Milford',
    'Milo',
    'Mindenmines',
    'Montrose',
    'Moundville',
    'Nevada',
    'Osceola',
    'Richards',
    'Rich Hill',
    'Rockville',
    'Roscoe',
    'Schell City',
    'Sheldon',
    'Urich',
    'Walker',
    'Joplin',
    'Alba',
    'Anderson',
    'Asbury',
    'Avilla',
    'Carl Junction',
    'Carterville',
    'Carthage',
    'Diamond',
    'Duenweg',
    'Fairview',
    'Goodman',
    'Granby',
    'Lanagan',
    'La Russell',
    'Neck City',
    'Neosho',
    'Newtonia',
    'Noel',
    'Oronogo',
    'Pineville',
    'Purcell',
    'Racine',
    'Reeds',
    'Rocky Comfort',
    'Sarcoxie',
    'South West City',
    'Saginaw',
    'Seneca',
    'Stark City',
    'Stella',
    'Tiff City',
    'Waco',
    'Webb City',
    'Wentworth',
    'Wheaton',
    'Argyle',
    'Ashland',
    'Barnett',
    'Belle',
    'Bland',
    'Bonnots Mill',
    'Brumley',
    'California',
    'Camdenton',
    'Centertown',
    'Chamois',
    'Clarksburg',
    'Eldon',
    'Eugene',
    'Fortuna',
    'Freeburg',
    'Gasconade',
    'Gravois Mills',
    'Laurie',
    'Hartsburg',
    'Henley',
    'Hermann',
    'High Point',
    'Holts Summit',
    'Jamestown',
    'Kaiser',
    'Koeltztown',
    'Lake Ozark',
    'Latham',
    'Linn',
    'Linn Creek',
    'Lohman',
    'Loose Creek',
    'Mc Girk',
    'Meta',
    'Mokane',
    'Morrison',
    'Mount Sterling',
    'New Bloomfield',
    'Olean',
    'Osage Beach',
    'Owensville',
    'Portland',
    'Prairie Home',
    'Rhineland',
    'Rocky Mount',
    'Russellville',
    'Saint Elizabeth',
    'Saint Thomas',
    'Steedman',
    'Stover',
    'Sunrise Beach',
    'Tebbetts',
    'Tipton',
    'Tuscumbia',
    'Ulman',
    'Versailles',
    'Westphalia',
    'Jefferson City',
    'Columbia',
    'Armstrong',
    'Auxvasse',
    'Benton City',
    'Boonville',
    'Brunswick',
    'Bunceton',
    'Cairo',
    'Centralia',
    'Clark',
    'Clifton Hill',
    'Dalton',
    'Excello',
    'Fayette',
    'Franklin',
    'Fulton',
    'Glasgow',
    'Hallsville',
    'Harrisburg',
    'Higbee',
    'Holliday',
    'Huntsville',
    'Jacksonville',
    'Keytesville',
    'Kingdom City',
    'Madison',
    'Martinsburg',
    'Mexico',
    'Moberly',
    'New Franklin',
    'Paris',
    'Pilot Grove',
    'Renick',
    'Rocheport',
    'Rush Hill',
    'Salisbury',
    'Santa Fe',
    'Stoutsville',
    'Sturgeon',
    'Thompson',
    'Triplett',
    'Wooldridge',
    'Sedalia',
    'Whiteman Air Force Base',
    'Arrow Rock',
    'Blackburn',
    'Blackwater',
    'Calhoun',
    'Climax Springs',
    'Cole Camp',
    'Edwards',
    'Emma',
    'Florence',
    'Gilliam',
    'Green Ridge',
    'Houstonia',
    'Hughesville',
    'Ionia',
    'Knob Noster',
    'La Monte',
    'Lincoln',
    'Malta Bend',
    'Marshall',
    'Miami',
    'Mora',
    'Nelson',
    'Otterville',
    'Slater',
    'Smithton',
    'Sweet Springs',
    'Syracuse',
    'Warsaw',
    'Windsor',
    'Rolla',
    'Beulah',
    'Birch Tree',
    'Bixby',
    'Boss',
    'Bourbon',
    'Brinktown',
    'Bucyrus',
    'Cherryville',
    'Cook Sta',
    'Crocker',
    'Cuba',
    'Davisville',
    'Devils Elbow',
    'Dixon',
    'Duke',
    'Edgar Springs',
    'Eldridge',
    'Elk Creek',
    'Eminence',
    'Eunice',
    'Falcon',
    'Fort Leonard Wood',
    'Hartshorn',
    'Houston',
    'Huggins',
    'Iberia',
    'Jadwin',
    'Jerome',
    'Lake Spring',
    'Laquey',
    'Leasburg',
    'Lebanon',
    'Lenox',
    'Licking',
    'Lynchburg',
    'Montier',
    'Mountain View',
    'Newburg',
    'Plato',
    'Raymondville',
    'Richland',
    'Roby',
    'Saint James',
    'Salem',
    'Solo',
    'Steelville',
    'Viburnum',
    'Stoutland',
    'Success',
    'Summersville',
    'Vichy',
    'Vienna',
    'Waynesville',
    'Saint Robert',
    'Wesco',
    'Winona',
    'Yukon',
    'Long Lane',
    'Montreal',
    'Aldrich',
    'Arcola',
    'Ash Grove',
    'Aurora',
    'Alton',
    'Caplinger Mills',
    'Ava',
    'Bakersfield',
    'Billings',
    'Blue Eye',
    'Bois D Arc',
    'Bolivar',
    'Bradleyville',
    'Branson',
    'Brighton',
    'Brixey',
    'Brookline',
    'Bruner',
    'Buffalo',
    'Butterfield',
    'Cape Fair',
    'Cassville',
    'Caulfield',
    'Cedarcreek',
    'Chadwick',
    'Chestnutridge',
    'Clever',
    'Conway',
    'Crane',
    'Cross Timbers',
    'Dadeville',
    'Diggins',
    'Dora',
    'Drury',
    'Dunnegan',
    'Eagle Rock',
    'Elkland',
    'Morrisville',
    'Everton',
    'Exeter',
    'Fair Grove',
    'Fair Play',
    'Flemington',
    'Fordland',
    'Forsyth',
    'Freistatt',
    'Gainesville',
    'Galena',
    'Garrison',
    'Golden',
    'Graff',
    'Greenfield',
    'Grovespring',
    'Half Way',
    'Halltown',
    'Hardenville',
    'Hartville',
    'Hermitage',
    'Highlandville',
    'Hollister',
    'Humansville',
    'Hurley',
    'Isabella',
    'Kirbyville',
    'Kissee Mills',
    'Lampe',
    'Lockwood',
    'Louisburg',
    'Kimberling City',
    'Brandsville',
    'Cabool',
    'Couch',
    'Koshkonong',
    'Mc Clurg',
    'Macomb',
    'Mansfield',
    'Marionville',
    'Marshfield',
    'Miller',
    'Monett',
    'Mountain Grove',
    'Mount Vernon',
    'Niangua',
    'Nixa',
    'Noble',
    'Norwood',
    'Oldfield',
    'Ozark',
    'Phillipsburg',
    'Pierce City',
    'Pittsburg',
    'Pleasant Hope',
    'Point Lookout',
    'Polk',
    'Ponce De Leon',
    'Pontiac',
    'Powell',
    'Powersite',
    'Preston',
    'Protem',
    'Purdy',
    'Quincy',
    'Reeds Spring',
    'Republic',
    'Ridgedale',
    'Rockaway Beach',
    'Rockbridge',
    'Rogersville',
    'Rueter',
    'Seligman',
    'Seymour',
    'Shell Knob',
    'South Greenfield',
    'Sparta',
    'Spokane',
    'Squires',
    'Stotts City',
    'Strafford',
    'Taneyville',
    'Tecumseh',
    'Theodosia',
    'Thornfield',
    'Tunas',
    'Turners',
    'Udall',
    'Urbana',
    'Vanzant',
    'Verona',
    'Walnut Grove',
    'Walnut Shade',
    'Washburn',
    'Wasola',
    'Weaubleau',
    'West Plains',
    'South Fork',
    'Moody',
    'Myrtle',
    'Wheatland',
    'Willard',
    'Windyville',
    'Zanoni',
    'Stockton',
    'Macks Creek',
    'Roach',
    'Peace Valley',
    'Pomona',
    'Pottersville',
    'Thayer',
    'Willow Springs',
    'Springfield',
    'Lead Hill'
  ],
  KS: [
    'Atchison',
    'Baldwin City',
    'Basehor',
    'Bendena',
    'Blue Mound',
    'Bonner Springs',
    'Bucyrus',
    'Centerville',
    'Colony',
    'Cummings',
    'Denton',
    'De Soto',
    'Clearview City',
    'Easton',
    'Edgerton',
    'Effingham',
    'Elwood',
    'Eudora',
    'Fontana',
    'Fort Leavenworth',
    'Gardner',
    'New Century',
    'Garnett',
    'Greeley',
    'Highland',
    'Hillsdale',
    'Kincaid',
    'La Cygne',
    'Lancaster',
    'Lane',
    'Lansing',
    'Lawrence',
    'Leavenworth',
    'Lecompton',
    'Olathe',
    'Linwood',
    'Louisburg',
    'Mc Louth',
    'Mound City',
    'Muscotah',
    'Nortonville',
    'Osawatomie',
    'Oskaloosa',
    'Ottawa',
    'Ozawkie',
    'Paola',
    'Parker',
    'Perry',
    'Pleasanton',
    'Pomona',
    'Potter',
    'Princeton',
    'Rantoul',
    'Richmond',
    'Spring Hill',
    'Stilwell',
    'Tonganoxie',
    'Troy',
    'Valley Falls',
    'Wathena',
    'Welda',
    'Wellsville',
    'Westphalia',
    'White Cloud',
    'Williamsburg',
    'Winchester',
    'Kansas City',
    'Edwardsville',
    'Mission',
    'Shawnee',
    'Overland Park',
    'Leawood',
    'Prairie Village',
    'Lenexa',
    'Shawnee Mission',
    'Alma',
    'Auburn',
    'Axtell',
    'Baileyville',
    'Beattie',
    'Belvue',
    'Bern',
    'Berryton',
    'Blue Rapids',
    'Bremen',
    'Burlingame',
    'Carbondale',
    'Centralia',
    'Circleville',
    'Corning',
    'Delia',
    'Denison',
    'Dover',
    'Emmett',
    'Eskridge',
    'Everest',
    'Fairview',
    'Fostoria',
    'Frankfort',
    'Goff',
    'Grantville',
    'Harveyville',
    'Havensville',
    'Hiawatha',
    'Holton',
    'Home',
    'Horton',
    'Hoyt',
    'Junction City',
    'Fort Riley',
    'Leonardville',
    'Lyndon',
    'Mc Farland',
    'Manhattan',
    'Maple Hill',
    'Marysville',
    'Mayetta',
    'Melvern',
    'Meriden',
    'Milford',
    'Morrill',
    'Netawaka',
    'Ogden',
    'Oketo',
    'Olsburg',
    'Onaga',
    'Oneida',
    'Osage City',
    'Overbrook',
    'Paxico',
    'Powhattan',
    'Quenemo',
    'Riley',
    'Robinson',
    'Rossville',
    'Sabetha',
    'Saint George',
    'Saint Marys',
    'Scranton',
    'Seneca',
    'Silver Lake',
    'Soldier',
    'Summerfield',
    'Tecumseh',
    'Vassar',
    'Vermillion',
    'Wakarusa',
    'Wamego',
    'Waterville',
    'Westmoreland',
    'Wetmore',
    'Whiting',
    'Randolph',
    'Topeka',
    'Fort Scott',
    'Altoona',
    'Arcadia',
    'Arma',
    'Baxter Springs',
    'Benedict',
    'Bronson',
    'Buffalo',
    'Chanute',
    'Cherokee',
    'Columbus',
    'Crestline',
    'Elsmore',
    'Erie',
    'Farlington',
    'Franklin',
    'Fredonia',
    'Fulton',
    'Galena',
    'Galesburg',
    'Garland',
    'Gas',
    'Girard',
    'Hepler',
    'Humboldt',
    'Iola',
    'La Harpe',
    'Mc Cune',
    'Mapleton',
    'Moran',
    'Mulberry',
    'Neodesha',
    'Neosho Falls',
    'New Albany',
    'Opolis',
    'Piqua',
    'Pittsburg',
    'Frontenac',
    'Prescott',
    'Redfield',
    'Riverton',
    'Saint Paul',
    'Savonburg',
    'Scammon',
    'Stark',
    'Thayer',
    'Toronto',
    'Treece',
    'Uniontown',
    'Walnut',
    'Weir',
    'West Mineral',
    'Yates Center',
    'Emporia',
    'Admire',
    'Allen',
    'Alta Vista',
    'Americus',
    'Burdick',
    'Burlington',
    'Burns',
    'Cassoday',
    'Cedar Point',
    'Cottonwood Falls',
    'Council Grove',
    'Dwight',
    'Elmdale',
    'Florence',
    'Gridley',
    'Hamilton',
    'Hartford',
    'Lamont',
    'Lebo',
    'Le Roy',
    'Lincolnville',
    'Lost Springs',
    'Madison',
    'Marion',
    'Matfield Green',
    'Neal',
    'Neosho Rapids',
    'Olpe',
    'Peabody',
    'Reading',
    'Strong City',
    'Virgil',
    'Waverly',
    'White City',
    'Wilsey',
    'Concordia',
    'Agenda',
    'Athol',
    'Barnes',
    'Belleville',
    'Burr Oak',
    'Clifton',
    'Clyde',
    'Courtland',
    'Cuba',
    'Esbon',
    'Formoso',
    'Greenleaf',
    'Haddam',
    'Hanover',
    'Hollenberg',
    'Jamestown',
    'Jewell',
    'Kensington',
    'Lebanon',
    'Linn',
    'Mahaska',
    'Mankato',
    'Morrowville',
    'Munden',
    'Narka',
    'Norway',
    'Palmer',
    'Randall',
    'Republic',
    'Scandia',
    'Smith Center',
    'Washington',
    'Webber',
    'Andale',
    'Andover',
    'Anthony',
    'Argonia',
    'Arkansas City',
    'Atlanta',
    'Attica',
    'Augusta',
    'Beaumont',
    'Belle Plaine',
    'Bentley',
    'Benton',
    'Bluff City',
    'Burden',
    'Burrton',
    'Byers',
    'Caldwell',
    'Cambridge',
    'Cedar Vale',
    'Cheney',
    'Clearwater',
    'Coats',
    'Coldwater',
    'Colwich',
    'Conway Springs',
    'Cunningham',
    'Danville',
    'Derby',
    'Dexter',
    'Douglass',
    'Elbing',
    'El Dorado',
    'Eureka',
    'Fall River',
    'Freeport',
    'Garden Plain',
    'Geuda Springs',
    'Goddard',
    'Goessel',
    'Greensburg',
    'Greenwich',
    'Halstead',
    'Hardtner',
    'Harper',
    'Haviland',
    'Haysville',
    'Hazelton',
    'Hesston',
    'Hillsboro',
    'Isabel',
    'Iuka',
    'Kechi',
    'Kingman',
    'Kiowa',
    'Lake City',
    'Latham',
    'Lehigh',
    'Leon',
    'Maize',
    'Maple City',
    'Mayfield',
    'Medicine Lodge',
    'Milan',
    'Milton',
    'Moundridge',
    'Mount Hope',
    'Mullinville',
    'Mulvane',
    'Murdock',
    'Nashville',
    'Newton',
    'North Newton',
    'Norwich',
    'Oxford',
    'Peck',
    'Piedmont',
    'Potwin',
    'Pratt',
    'Protection',
    'Rock',
    'Rosalia',
    'Rose Hill',
    'Sawyer',
    'Sedgwick',
    'Severy',
    'Sharon',
    'South Haven',
    'Spivey',
    'Sun City',
    'Towanda',
    'Udall',
    'Valley Center',
    'Viola',
    'Waldron',
    'Walton',
    'Wellington',
    'Whitewater',
    'Wilmore',
    'Winfield',
    'Zenda',
    'Wichita',
    'Mcconnell AFB',
    'Independence',
    'Altamont',
    'Bartlett',
    'Caney',
    'Chautauqua',
    'Cherryvale',
    'Chetopa',
    'Coffeyville',
    'Dearing',
    'Dennis',
    'Edna',
    'Elk City',
    'Elk Falls',
    'Grenola',
    'Havana',
    'Howard',
    'Liberty',
    'Longton',
    'Moline',
    'Mound Valley',
    'Niotaze',
    'Oswego',
    'Parsons',
    'Peru',
    'Sedan',
    'Sycamore',
    'Tyro',
    'Salina',
    'Abilene',
    'Assaria',
    'Aurora',
    'Barnard',
    'Beloit',
    'Bennington',
    'Beverly',
    'Brookville',
    'Bushton',
    'Canton',
    'Cawker City',
    'Chapman',
    'Clay Center',
    'Delphos',
    'Downs',
    'Durham',
    'Ellsworth',
    'Enterprise',
    'Falun',
    'Galva',
    'Geneseo',
    'Glasco',
    'Glen Elder',
    'Green',
    'Gypsum',
    'Herington',
    'Holyrood',
    'Hope',
    'Hunter',
    'Kanopolis',
    'Lincoln',
    'Lindsborg',
    'Little River',
    'Longford',
    'Lorraine',
    'Mcpherson',
    'Marquette',
    'Miltonvale',
    'Minneapolis',
    'Morganville',
    'New Cambria',
    'Osborne',
    'Portis',
    'Ramona',
    'Roxbury',
    'Simpson',
    'Solomon',
    'Sylvan Grove',
    'Talmage',
    'Tampa',
    'Tescott',
    'Tipton',
    'Wakefield',
    'Wilson',
    'Windom',
    'Woodbine',
    'Hutchinson',
    'South Hutchinson',
    'Abbyville',
    'Albert',
    'Alden',
    'Alexander',
    'Arlington',
    'Arnold',
    'Bazine',
    'Beeler',
    'Belpre',
    'Bison',
    'Brownell',
    'Buhler',
    'Burdett',
    'Chase',
    'Claflin',
    'Ellinwood',
    'Garfield',
    'Great Bend',
    'Haven',
    'Hoisington',
    'Hudson',
    'Inman',
    'Kinsley',
    'La Crosse',
    'Larned',
    'Lewis',
    'Liebenthal',
    'Lyons',
    'Mc Cracken',
    'Macksville',
    'Nekoma',
    'Ness City',
    'Nickerson',
    'Offerle',
    'Olmitz',
    'Otis',
    'Partridge',
    'Pawnee Rock',
    'Plevna',
    'Pretty Prairie',
    'Ransom',
    'Raymond',
    'Rozel',
    'Rush Center',
    'St John',
    'Stafford',
    'Sterling',
    'Sylvia',
    'Turon',
    'Utica',
    'Yoder',
    'Hays',
    'Agra',
    'Almena',
    'Alton',
    'Bogue',
    'Bunker Hill',
    'Catharine',
    'Cedar',
    'Clayton',
    'Collyer',
    'Damar',
    'Dorrance',
    'Dresden',
    'Ellis',
    'Gaylord',
    'Glade',
    'Gorham',
    'Hill City',
    'Jennings',
    'Kirwin',
    'Lenora',
    'Logan',
    'Long Island',
    'Lucas',
    'Luray',
    'Morland',
    'Natoma',
    'Norcatur',
    'Norton',
    'Ogallah',
    'Palco',
    'Paradise',
    'Penokee',
    'Pfeifer',
    'Phillipsburg',
    'Plainville',
    'Prairie View',
    'Russell',
    'Schoenchen',
    'Stockton',
    'Victoria',
    'Wakeeney',
    'Waldo',
    'Walker',
    'Woodston',
    'Colby',
    'Atwood',
    'Bird City',
    'Brewster',
    'Edson',
    'Gem',
    'Goodland',
    'Gove',
    'Grainfield',
    'Grinnell',
    'Herndon',
    'Hoxie',
    'Kanorado',
    'Levant',
    'Ludell',
    'Mc Donald',
    'Monument',
    'Oakley',
    'Oberlin',
    'Park',
    'Quinter',
    'Rexford',
    'Saint Francis',
    'Selden',
    'Sharon Springs',
    'Wallace',
    'Weskan',
    'Winona',
    'Dodge City',
    'Ashland',
    'Bucklin',
    'Cimarron',
    'Coolidge',
    'Copeland',
    'Deerfield',
    'Dighton',
    'Englewood',
    'Ensign',
    'Ford',
    'Fort Dodge',
    'Fowler',
    'Garden City',
    'Hanston',
    'Healy',
    'Holcomb',
    'Ingalls',
    'Jetmore',
    'Johnson',
    'Kendall',
    'Kismet',
    'Lakin',
    'Leoti',
    'Manter',
    'Marienthal',
    'Meade',
    'Minneola',
    'Montezuma',
    'Pierceville',
    'Plains',
    'Satanta',
    'Scott City',
    'Spearville',
    'Sublette',
    'Syracuse',
    'Tribune',
    'Ulysses',
    'Wright',
    'Liberal',
    'Elkhart',
    'Hugoton',
    'Moscow',
    'Richfield',
    'Rolla'
  ],
  NE: [
    'Abie',
    'Arlington',
    'Ashland',
    'Bancroft',
    'Bellevue',
    'Bennington',
    'Blair',
    'Boys Town',
    'Bruno',
    'Cedar Bluffs',
    'Cedar Creek',
    'Ceresco',
    'Colon',
    'Craig',
    'Decatur',
    'Elkhorn',
    'Fort Calhoun',
    'Fremont',
    'Gretna',
    'Herman',
    'Homer',
    'Hooper',
    'Ithaca',
    'Kennard',
    'Linwood',
    'Louisville',
    'Lyons',
    'Macy',
    'Malmo',
    'Mead',
    'Memphis',
    'Nickerson',
    'Oakland',
    'Papillion',
    'Pender',
    'Plattsmouth',
    'Prague',
    'Rosalie',
    'St Columbans',
    'Scribner',
    'South Bend',
    'Springfield',
    'Tekamah',
    'Thurston',
    'Uehling',
    'Valley',
    'Valparaiso',
    'Wahoo',
    'Walthill',
    'Washington',
    'Waterloo',
    'Weston',
    'Winnebago',
    'Winslow',
    'Yutan',
    'Omaha',
    'Offutt AFB',
    'La Vista',
    'Adams',
    'Alexandria',
    'Alvo',
    'Auburn',
    'Avoca',
    'Barneston',
    'Beatrice',
    'Beaver Crossing',
    'Bee',
    'Belvidere',
    'Benedict',
    'Bennet',
    'Blue Springs',
    'Bradshaw',
    'Brock',
    'Brownville',
    'Bruning',
    'Burchard',
    'Burr',
    'Byron',
    'Carleton',
    'Chester',
    'Clatonia',
    'Cook',
    'Cordova',
    'Cortland',
    'Crab Orchard',
    'Crete',
    'Davenport',
    'Davey',
    'Dawson',
    'Daykin',
    'Denton',
    'Deshler',
    'De Witt',
    'Diller',
    'Dorchester',
    'Douglas',
    'Du Bois',
    'Dunbar',
    'Eagle',
    'Elk Creek',
    'Elmwood',
    'Endicott',
    'Exeter',
    'Fairbury',
    'Fairmont',
    'Falls City',
    'Filley',
    'Firth',
    'Friend',
    'Garland',
    'Geneva',
    'Gilead',
    'Goehner',
    'Grafton',
    'Greenwood',
    'Gresham',
    'Hallam',
    'Hebron',
    'Henderson',
    'Hickman',
    'Hubbell',
    'Humboldt',
    'Jansen',
    'Johnson',
    'Julian',
    'Lewiston',
    'Liberty',
    'Lorton',
    'Mc Cool Junction',
    'Malcolm',
    'Manley',
    'Martell',
    'Milford',
    'Milligan',
    'Murdock',
    'Murray',
    'Nebraska City',
    'Nehawka',
    'Nemaha',
    'Odell',
    'Ohiowa',
    'Otoe',
    'Palmyra',
    'Panama',
    'Pawnee City',
    'Peru',
    'Pickrell',
    'Pleasant Dale',
    'Plymouth',
    'Raymond',
    'Reynolds',
    'Roca',
    'Rulo',
    'Salem',
    'Seward',
    'Shickley',
    'Shubert',
    'Sprague',
    'Staplehurst',
    'Steele City',
    'Steinauer',
    'Stella',
    'Sterling',
    'Strang',
    'Swanton',
    'Syracuse',
    'Table Rock',
    'Talmage',
    'Tecumseh',
    'Ong',
    'Tobias',
    'Unadilla',
    'Union',
    'Utica',
    'Verdon',
    'Virginia',
    'Waco',
    'Walton',
    'Waverly',
    'Weeping Water',
    'Western',
    'Wilber',
    'Wymore',
    'York',
    'Lincoln',
    'Columbus',
    'Albion',
    'Ames',
    'Bartlett',
    'Belgrade',
    'Bellwood',
    'Brainard',
    'Cedar Rapids',
    'Clarks',
    'Clarkson',
    'Creston',
    'David City',
    'Dodge',
    'Duncan',
    'Dwight',
    'Elgin',
    'Ericson',
    'Fullerton',
    'Genoa',
    'Howells',
    'Humphrey',
    'Leigh',
    'Lindsay',
    'Monroe',
    'Morse Bluff',
    'North Bend',
    'Osceola',
    'Petersburg',
    'Platte Center',
    'Polk',
    'Primrose',
    'Rising City',
    'Rogers',
    'Saint Edward',
    'Schuyler',
    'Shelby',
    'Silver Creek',
    'Snyder',
    'Spalding',
    'Stromsburg',
    'Surprise',
    'Ulysses',
    'Norfolk',
    'Allen',
    'Amelia',
    'Atkinson',
    'Bassett',
    'Battle Creek',
    'Beemer',
    'Belden',
    'Bloomfield',
    'Bristow',
    'Brunswick',
    'Butte',
    'Carroll',
    'Center',
    'Chambers',
    'Clearwater',
    'Coleridge',
    'Concord',
    'Creighton',
    'Crofton',
    'Dakota City',
    'Dixon',
    'Emerson',
    'Emmet',
    'Ewing',
    'Fordyce',
    'Hadar',
    'Hartington',
    'Hoskins',
    'Hubbard',
    'Inman',
    'Jackson',
    'Laurel',
    'Lynch',
    'Mclean',
    'Madison',
    'Magnet',
    'Maskell',
    'Meadow Grove',
    'Mills',
    'Naper',
    'Neligh',
    'Newcastle',
    'Newman Grove',
    'Newport',
    'Niobrara',
    'Oakdale',
    'Oneill',
    'Orchard',
    'Osmond',
    'Page',
    'Pierce',
    'Pilger',
    'Plainview',
    'Ponca',
    'Randolph',
    'Royal',
    'Saint Helena',
    'South Sioux City',
    'Spencer',
    'Springview',
    'Stanton',
    'Stuart',
    'Tilden',
    'Verdigre',
    'Wakefield',
    'Waterbury',
    'Wausa',
    'Wayne',
    'West Point',
    'Winnetoon',
    'Winside',
    'Wisner',
    'Wynot',
    'Grand Island',
    'Alda',
    'Amherst',
    'Anselmo',
    'Ansley',
    'Arcadia',
    'Archer',
    'Ashton',
    'Aurora',
    'Boelus',
    'Brewster',
    'Broken Bow',
    'Burwell',
    'Cairo',
    'Callaway',
    'Central City',
    'Chapman',
    'Comstock',
    'Dannebrog',
    'Doniphan',
    'Dunning',
    'Eddyville',
    'Elba',
    'Elm Creek',
    'Elyria',
    'Farwell',
    'Gibbon',
    'Giltner',
    'Greeley',
    'Hampton',
    'Hazard',
    'Kearney',
    'Hordville',
    'Lexington',
    'Litchfield',
    'Loup City',
    'Marquette',
    'Mason City',
    'Merna',
    'Miller',
    'North Loup',
    'Oconto',
    'Odessa',
    'Ord',
    'Overton',
    'Palmer',
    'Phillips',
    'Pleasanton',
    'Ravenna',
    'Riverdale',
    'Rockville',
    'Saint Libory',
    'Saint Paul',
    'Sargent',
    'Scotia',
    'Shelton',
    'Sumner',
    'Taylor',
    'Westerville',
    'Wolbach',
    'Wood River',
    'Hastings',
    'Alma',
    'Arapahoe',
    'Atlanta',
    'Axtell',
    'Ayr',
    'Beaver City',
    'Bertrand',
    'Bladen',
    'Bloomington',
    'Blue Hill',
    'Campbell',
    'Clay Center',
    'Deweese',
    'Edgar',
    'Edison',
    'Elwood',
    'Fairfield',
    'Franklin',
    'Funk',
    'Glenvil',
    'Guide Rock',
    'Hardy',
    'Harvard',
    'Heartwell',
    'Hendley',
    'Hildreth',
    'Holbrook',
    'Holdrege',
    'Holstein',
    'Inavale',
    'Inland',
    'Juniata',
    'Kenesaw',
    'Lawrence',
    'Loomis',
    'Minden',
    'Naponee',
    'Nelson',
    'Oak',
    'Orleans',
    'Oxford',
    'Ragan',
    'Red Cloud',
    'Republican City',
    'Riverton',
    'Roseland',
    'Ruskin',
    'Saronville',
    'Smithfield',
    'Stamford',
    'Superior',
    'Sutton',
    'Trumbull',
    'Upland',
    'Wilcox',
    'Mc Cook',
    'Bartley',
    'Benkelman',
    'Cambridge',
    'Champion',
    'Culbertson',
    'Curtis',
    'Danbury',
    'Enders',
    'Eustis',
    'Farnam',
    'Haigler',
    'Hayes Center',
    'Imperial',
    'Indianola',
    'Lebanon',
    'Max',
    'Maywood',
    'Moorefield',
    'Palisade',
    'Parks',
    'Stockville',
    'Stratton',
    'Trenton',
    'Wauneta',
    'Wilsonville',
    'North Platte',
    'Arnold',
    'Arthur',
    'Big Springs',
    'Brady',
    'Broadwater',
    'Brule',
    'Bushnell',
    'Chappell',
    'Cozad',
    'Dalton',
    'Dickens',
    'Dix',
    'Elsie',
    'Elsmere',
    'Gothenburg',
    'Grant',
    'Gurley',
    'Halsey',
    'Hershey',
    'Keystone',
    'Kimball',
    'Lemoyne',
    'Lewellen',
    'Lisco',
    'Lodgepole',
    'Madrid',
    'Maxwell',
    'Mullen',
    'Ogallala',
    'Oshkosh',
    'Paxton',
    'Potter',
    'Purdum',
    'Sidney',
    'Seneca',
    'Stapleton',
    'Sutherland',
    'Thedford',
    'Tryon',
    'Venango',
    'Wallace',
    'Wellfleet',
    'Willow Island',
    'Valentine',
    'Ainsworth',
    'Cody',
    'Crookston',
    'Johnstown',
    'Kilgore',
    'Long Pine',
    'Merriman',
    'Nenzel',
    'Sparks',
    'Wood Lake',
    'Alliance',
    'Angora',
    'Ashby',
    'Bayard',
    'Bingham',
    'Bridgeport',
    'Chadron',
    'Crawford',
    'Ellsworth',
    'Gering',
    'Gordon',
    'Harrisburg',
    'Harrison',
    'Hay Springs',
    'Hemingford',
    'Hyannis',
    'Lakeside',
    'Lyman',
    'Mcgrew',
    'Marsland',
    'Melbeta',
    'Minatare',
    'Mitchell',
    'Morrill',
    'Rushville',
    'Scottsbluff',
    'Whiteclay',
    'Whitman',
    'Whitney'
  ],
  LA: [
    'Metairie',
    'Des Allemands',
    'Ama',
    'Arabi',
    'Barataria',
    'Belle Chasse',
    'Boothville',
    'Boutte',
    'Braithwaite',
    'Buras',
    'Chalmette',
    'Destrehan',
    'Edgard',
    'Empire',
    'Garyville',
    'Gramercy',
    'Gretna',
    'Hahnville',
    'Harvey',
    'Kenner',
    'Lafitte',
    'La Place',
    'Luling',
    'Lutcher',
    'Marrero',
    'Meraux',
    'Mount Airy',
    'New Sarpy',
    'Norco',
    'Paradis',
    'Pilottown',
    'Pointe A La Hache',
    'Port Sulphur',
    'Reserve',
    'Saint Bernard',
    'Saint James',
    'Saint Rose',
    'Vacherie',
    'Venice',
    'Violet',
    'Westwego',
    'New Orleans',
    'Thibodaux',
    'Pierre Part',
    'Amelia',
    'Belle Rose',
    'Berwick',
    'Bourg',
    'Chauvin',
    'Cut Off',
    'Donaldsonville',
    'Donner',
    'Dulac',
    'Galliano',
    'Gheens',
    'Gibson',
    'Golden Meadow',
    'Grand Isle',
    'Gray',
    'Houma',
    'Kraemer',
    'Labadieville',
    'Larose',
    'Lockport',
    'Mathews',
    'Montegut',
    'Morgan City',
    'Napoleonville',
    'Paincourtville',
    'Patterson',
    'Plattenville',
    'Raceland',
    'Schriever',
    'Theriot',
    'Hammond',
    'Abita Springs',
    'Akers',
    'Amite',
    'Angie',
    'Bogalusa',
    'Bush',
    'Covington',
    'Fluker',
    'Folsom',
    'Franklinton',
    'Greensburg',
    'Husser',
    'Independence',
    'Kentwood',
    'Lacombe',
    'Loranger',
    'Madisonville',
    'Mandeville',
    'Maurepas',
    'Mount Hermon',
    'Natalbany',
    'Pearl River',
    'Pine Grove',
    'Ponchatoula',
    'Robert',
    'Roseland',
    'Saint Benedict',
    'Slidell',
    'Springfield',
    'Sun',
    'Talisheek',
    'Tangipahoa',
    'Tickfaw',
    'Varnado',
    'Lafayette',
    'Abbeville',
    'Arnaudville',
    'Avery Island',
    'Baldwin',
    'Basile',
    'Branch',
    'Breaux Bridge',
    'Broussard',
    'Cade',
    'Carencro',
    'Cecilia',
    'Centerville',
    'Charenton',
    'Chataignier',
    'Church Point',
    'Crowley',
    'Delcambre',
    'Duson',
    'Egan',
    'Elton',
    'Erath',
    'Estherwood',
    'Eunice',
    'Evangeline',
    'Franklin',
    'Garden City',
    'Grand Coteau',
    'Gueydan',
    'Iota',
    'Jeanerette',
    'Jennings',
    'Kaplan',
    'Lake Arthur',
    'Lawtell',
    'Leonville',
    'Loreauville',
    'Mamou',
    'Maurice',
    'Mermentau',
    'Milton',
    'Morse',
    'New Iberia',
    'Lydia',
    'Opelousas',
    'Perry',
    'Pine Prairie',
    'Port Barre',
    'Rayne',
    'Reddell',
    'Roanoke',
    'Saint Martinville',
    'Scott',
    'Sunset',
    'Turkey Creek',
    'Ville Platte',
    'Washington',
    'Welsh',
    'Youngsville',
    'Lake Charles',
    'Bell City',
    'Cameron',
    'Creole',
    'Dequincy',
    'Deridder',
    'Dry Creek',
    'Elizabeth',
    'Evans',
    'Fenton',
    'Grand Chenier',
    'Grant',
    'Hackberry',
    'Hayes',
    'Iowa',
    'Kinder',
    'Lacassine',
    'Leblanc',
    'Longville',
    'Merryville',
    'Mittie',
    'Oberlin',
    'Pitkin',
    'Ragley',
    'Reeves',
    'Rosepine',
    'Singer',
    'Starks',
    'Sugartown',
    'Sulphur',
    'Vinton',
    'Westlake',
    'Baker',
    'Denham Springs',
    'Gonzales',
    'Addis',
    'Albany',
    'Angola',
    'Batchelor',
    'Brittany',
    'Brusly',
    'Carville',
    'Clinton',
    'Convent',
    'Darrow',
    'Duplessis',
    'Erwinville',
    'Ethel',
    'Fordoche',
    'French Settlement',
    'Geismar',
    'Glynn',
    'Burnside',
    'Greenwell Springs',
    'Grosse Tete',
    'Hester',
    'Holden',
    'Innis',
    'Jackson',
    'Jarreau',
    'Krotz Springs',
    'Lakeland',
    'Lettsworth',
    'Livingston',
    'Livonia',
    'Lottie',
    'Maringouin',
    'Morganza',
    'New Roads',
    'Norwood',
    'Oscar',
    'Paulina',
    'Plaquemine',
    'Port Allen',
    'Prairieville',
    'Pride',
    'Rosedale',
    'Rougon',
    'Saint Amant',
    'Saint Francisville',
    'Saint Gabriel',
    'Slaughter',
    'Sorrento',
    'Sunshine',
    'Tunica',
    'Ventress',
    'Wakefield',
    'Walker',
    'Watson',
    'Weyanoke',
    'White Castle',
    'Wilson',
    'Zachary',
    'Uncle Sam',
    'Baton Rouge',
    'Arcadia',
    'Ashland',
    'Athens',
    'Belcher',
    'Benton',
    'Bethany',
    'Bienville',
    'Blanchard',
    'Castor',
    'Cotton Valley',
    'Coushatta',
    'Cullen',
    'Doyline',
    'Dubberly',
    'Frierson',
    'Gibsland',
    'Gilliam',
    'Gloster',
    'Goldonna',
    'Grand Cane',
    'Greenwood',
    'Hall Summit',
    'Haughton',
    'Haynesville',
    'Heflin',
    'Homer',
    'Hosston',
    'Ida',
    'Jamestown',
    'Keatchie',
    'Keithville',
    'Lisbon',
    'Logansport',
    'Longstreet',
    'Elm Grove',
    'Mansfield',
    'Minden',
    'Mooringsport',
    'Oil City',
    'Pelican',
    'Plain Dealing',
    'Pleasant Hill',
    'Powhatan',
    'Princeton',
    'Ringgold',
    'Rodessa',
    'Saline',
    'Sarepta',
    'Shongaloo',
    'Sibley',
    'Springhill',
    'Stonewall',
    'Summerfield',
    'Taylor',
    'Vivian',
    'Shreveport',
    'Barksdale AFB',
    'Bossier City',
    'Monroe',
    'Archibald',
    'Baskin',
    'Bastrop',
    'Bernice',
    'Bonita',
    'Calhoun',
    'Chatham',
    'Choudrant',
    'Collinston',
    'Crowville',
    'Delhi',
    'Delta',
    'Downsville',
    'Dubach',
    'Epps',
    'Eros',
    'Fairbanks',
    'Farmerville',
    'Forest',
    'Fort Necessity',
    'Grambling',
    'Hodge',
    'Jigger',
    'Jones',
    'Jonesboro',
    'Kilbourne',
    'Lake Providence',
    'Lillie',
    'Mangham',
    'Marion',
    'Mer Rouge',
    'Oak Grove',
    'Oak Ridge',
    'Pioneer',
    'Quitman',
    'Rayville',
    'Ruston',
    'Simsboro',
    'Sondheimer',
    'Spearsville',
    'Start',
    'Sterlington',
    'Swartz',
    'Tallulah',
    'Transylvania',
    'West Monroe',
    'Winnsboro',
    'Alexandria',
    'Acme',
    'Bordelonville',
    'Bunkie',
    'Center Point',
    'Chase',
    'Cheneyville',
    'Clayton',
    'Cottonport',
    'Deville',
    'Dupont',
    'Echo',
    'Effie',
    'Evergreen',
    'Ferriday',
    'Gilbert',
    'Hamburg',
    'Harrisonburg',
    'Hessmer',
    'Jena',
    'Jonesville',
    'Lebeau',
    'Lecompte',
    'Libuse',
    'Mansura',
    'Marksville',
    'Melville',
    'Monterey',
    'Moreauville',
    'Morrow',
    'Newellton',
    'Palmetto',
    'Pineville',
    'Plaucheville',
    'Rhinehart',
    'Ruby',
    'Saint Joseph',
    'Saint Landry',
    'Sicily Island',
    'Simmesport',
    'Trout',
    'Vidalia',
    'Waterproof',
    'Wildsville',
    'Wisner',
    'Aimwell',
    'Anacoco',
    'Atlanta',
    'Ball',
    'Belmont',
    'Bentley',
    'Boyce',
    'Calvin',
    'Campti',
    'Clarence',
    'Clarks',
    'Cloutierville',
    'Colfax',
    'Columbia',
    'Converse',
    'Dodson',
    'Dry Prong',
    'Elmer',
    'Enterprise',
    'Fisher',
    'Flatwoods',
    'Flora',
    'Florien',
    'Forest Hill',
    'Gardner',
    'Georgetown',
    'Glenmora',
    'Gorum',
    'Grayson',
    'Hineston',
    'Hornbeck',
    'Joyce',
    'Kelly',
    'Kurthwood',
    'Leesville',
    'Lena',
    'Longleaf',
    'Many',
    'Marthaville',
    'Melrose',
    'Montgomery',
    'Mora',
    'Natchez',
    'Natchitoches',
    'Fort Polk',
    'Negreet',
    'New Llano',
    'Noble',
    'Oakdale',
    'Olla',
    'Otis',
    'Pollock',
    'Provencal',
    'Robeline',
    'Saint Maurice',
    'Sieper',
    'Sikes',
    'Simpson',
    'Slagle',
    'Tioga',
    'Tullos',
    'Urania',
    'Winnfield',
    'Woodworth',
    'Zwolle'
  ],
  AR: [
    'Pine Bluff',
    'White Hall',
    'Arkansas City',
    'Banks',
    'Crossett',
    'Dermott',
    'Dumas',
    'Eudora',
    'Fountain Hill',
    'Gould',
    'Grady',
    'Hamburg',
    'Hermitage',
    'Jersey',
    'Kingsland',
    'Lake Village',
    'Mc Gehee',
    'Monticello',
    'Montrose',
    'Moscow',
    'New Edinburg',
    'Parkdale',
    'Pickens',
    'Portland',
    'Rison',
    'Rohwer',
    'Star City',
    'Tillar',
    'Warren',
    'Watson',
    'Wilmar',
    'Wilmot',
    'Winchester',
    'Yorktown',
    'Camden',
    'Bearden',
    'Beirne',
    'Bluff City',
    'Calion',
    'Carthage',
    'Chidester',
    'Curtis',
    'El Dorado',
    'Emerson',
    'Fordyce',
    'Gurdon',
    'Hampton',
    'Harrell',
    'Huttig',
    'Ivan',
    'Junction City',
    'Lawson',
    'Louann',
    'Mc Neil',
    'Magnolia',
    'Mount Holly',
    'Norphlet',
    'Smackover',
    'Sparkman',
    'Stephens',
    'Strong',
    'Thornton',
    'Waldo',
    'Whelen Springs',
    'Hope',
    'Alleene',
    'Ashdown',
    'Ben Lomond',
    'Blevins',
    'Bradley',
    'Buckner',
    'Cale',
    'Columbus',
    'De Queen',
    'Dierks',
    'Doddridge',
    'Emmet',
    'Foreman',
    'Fouke',
    'Fulton',
    'Garland City',
    'Genoa',
    'Gillham',
    'Horatio',
    'Laneburg',
    'Lewisville',
    'Lockesburg',
    'Mc Caskill',
    'Mineral Springs',
    'Nashville',
    'Ogden',
    'Texarkana',
    'Ozan',
    'Prescott',
    'Rosston',
    'Saratoga',
    'Stamps',
    'Taylor',
    'Washington',
    'Willisville',
    'Wilton',
    'Winthrop',
    'Hot Springs National Park',
    'Hot Springs Village',
    'Alpine',
    'Amity',
    'Antoine',
    'Arkadelphia',
    'Bismarck',
    'Board Camp',
    'Bonnerdale',
    'Caddo Gap',
    'Cove',
    'Delight',
    'Donaldson',
    'Friendship',
    'Glenwood',
    'Grannis',
    'Hatfield',
    'Jessieville',
    'Kirby',
    'Langley',
    'Mena',
    'Mountain Pine',
    'Mount Ida',
    'Murfreesboro',
    'Newhope',
    'Norman',
    'Oden',
    'Okolona',
    'Pearcy',
    'Pencil Bluff',
    'Royal',
    'Sims',
    'Story',
    'Umpire',
    'Vandervoort',
    'Wickes',
    'Adona',
    'Alexander',
    'Almyra',
    'Altheimer',
    'Amagon',
    'Augusta',
    'Austin',
    'Bald Knob',
    'Bauxite',
    'Beebe',
    'Bee Branch',
    'Beedeville',
    'Benton',
    'Bigelow',
    'Biscoe',
    'Bradford',
    'Brinkley',
    'Bryant',
    'Cabot',
    'Carlisle',
    'Casa',
    'Casscoe',
    'Center Ridge',
    'Choctaw',
    'Clarendon',
    'Cleveland',
    'Clinton',
    'Conway',
    'Cotton Plant',
    'Coy',
    'Crocketts Bluff',
    'Damascus',
    'Des Arc',
    'De Valls Bluff',
    'De Witt',
    'Diaz',
    'Edgemont',
    'El Paso',
    'England',
    'Enola',
    'Ethel',
    'Fox',
    'Garner',
    'College Station',
    'Gillett',
    'Grapevine',
    'Greenbrier',
    'Gregory',
    'Griffithville',
    'Guy',
    'Hattieville',
    'Hazen',
    'Hensley',
    'Hickory Plains',
    'Higden',
    'Higginson',
    'Holly Grove',
    'Houston',
    'Humnoke',
    'Humphrey',
    'Hunter',
    'Jacksonport',
    'Jacksonville',
    'Jefferson',
    'Jerusalem',
    'Judsonia',
    'Kensett',
    'Keo',
    'Leola',
    'Letona',
    'Lonoke',
    'Lonsdale',
    'Fairfield Bay',
    'Little Rock Air Force Base',
    'Mc Crory',
    'Mc Rae',
    'Mabelvale',
    'Malvern',
    'Jones Mill',
    'Mayflower',
    'Menifee',
    'Monroe',
    'Morrilton',
    'Mount Vernon',
    'Newport',
    'Maumelle',
    'North Little Rock',
    'Sherwood',
    'Pangburn',
    'Paron',
    'Patterson',
    'Perry',
    'Perryville',
    'Plumerville',
    'Poyen',
    'Prattsville',
    'Prim',
    'Quitman',
    'Redfield',
    'Reydell',
    'Roe',
    'Roland',
    'Romance',
    'Rose Bud',
    'Russell',
    'Saint Charles',
    'Scotland',
    'Scott',
    'Searcy',
    'Sheridan',
    'Sherrill',
    'Shirley',
    'Solgohachia',
    'Springfield',
    'Stuttgart',
    'Sweet Home',
    'Thida',
    'Tichnor',
    'Traskwood',
    'Tucker',
    'Tupelo',
    'Ulm',
    'Vilonia',
    'Wabbaseka',
    'Ward',
    'West Point',
    'Wilburn',
    'Woodson',
    'Wooster',
    'Wright',
    'Wrightsville',
    'Little Rock',
    'West Memphis',
    'Armorel',
    'Aubrey',
    'Barton',
    'Bassett',
    'Blytheville',
    'Gosnell',
    'Brickeys',
    'Burdette',
    'Caldwell',
    'Cherry Valley',
    'Clarkedale',
    'Colt',
    'Crawfordsville',
    'Crumrod',
    'Driver',
    'Dyess',
    'Earle',
    'Edmondson',
    'Elaine',
    'Forrest City',
    'Frenchmans Bayou',
    'Gilmore',
    'Goodwin',
    'Haynes',
    'Helena',
    'Heth',
    'Hickory Ridge',
    'Hughes',
    'Joiner',
    'Keiser',
    'La Grange',
    'Lambrook',
    'Lepanto',
    'Lexa',
    'Luxora',
    'Madison',
    'Marianna',
    'Marion',
    'Marked Tree',
    'Marvell',
    'Mellwood',
    'Moro',
    'Oneida',
    'Osceola',
    'Palestine',
    'Parkin',
    'Poplar Grove',
    'Proctor',
    'Rivervale',
    'Snow Lake',
    'Turner',
    'Turrell',
    'Tyronza',
    'Vanndale',
    'Wabash',
    'West Helena',
    'West Ridge',
    'Wheatley',
    'Widener',
    'Wilson',
    'Wynne',
    'Jonesboro',
    'Alicia',
    'Bay',
    'Beech Grove',
    'Biggers',
    'Black Oak',
    'Black Rock',
    'Bono',
    'Brookland',
    'Caraway',
    'Cash',
    'Corning',
    'Datto',
    'Delaplaine',
    'Dell',
    'Egypt',
    'Etowah',
    'Fisher',
    'Greenway',
    'Grubbs',
    'Harrisburg',
    'Hoxie',
    'Imboden',
    'Knobel',
    'Lafe',
    'Lake City',
    'Leachville',
    'Light',
    'Lynn',
    'Mc Dougal',
    'Manila',
    'Marmaduke',
    'Maynard',
    'Minturn',
    'Monette',
    'O Kean',
    'Paragould',
    'Peach Orchard',
    'Piggott',
    'Pocahontas',
    'Pollard',
    'Portia',
    'Powhatan',
    'Ravenden',
    'Ravenden Springs',
    'Rector',
    'Reyno',
    'Saint Francis',
    'Sedgwick',
    'Smithville',
    'State University',
    'Strawberry',
    'Success',
    'Swifton',
    'Trumann',
    'Tuckerman',
    'Walcott',
    'Waldenburg',
    'Walnut Ridge',
    'Warm Springs',
    'Weiner',
    'Williford',
    'Batesville',
    'Horseshoe Bend',
    'Ash Flat',
    'Bexar',
    'Brockwell',
    'Calico Rock',
    'Camp',
    'Cave City',
    'Charlotte',
    'Concord',
    'Cord',
    'Cherokee Village',
    'Cushman',
    'Desha',
    'Dolph',
    'Drasco',
    'Elizabeth',
    'Evening Shade',
    'Fifty Six',
    'Floral',
    'Franklin',
    'Gamaliel',
    'Gepp',
    'Glencoe',
    'Guion',
    'Hardy',
    'Heber Springs',
    'Henderson',
    'Ida',
    'Locust Grove',
    'Magness',
    'Mammoth Spring',
    'Marcella',
    'Melbourne',
    'Mountain View',
    'Mount Pleasant',
    'Newark',
    'Oil Trough',
    'Oxford',
    'Pineville',
    'Pleasant Grove',
    'Pleasant Plains',
    'Poughkeepsie',
    'Rosie',
    'Saffell',
    'Sage',
    'Salado',
    'Salem',
    'Sidney',
    'Sturkie',
    'Sulphur Rock',
    'Tumbling Shoals',
    'Viola',
    'Violet Hill',
    'Wideman',
    'Wiseman',
    'Harrison',
    'Alpena',
    'Beaver',
    'Bergman',
    'Berryville',
    'Big Flat',
    'Bull Shoals',
    'Clarkridge',
    'Compton',
    'Cotter',
    'Deer',
    'Dennard',
    'Diamond City',
    'Eureka Springs',
    'Everton',
    'Flippin',
    'Gassville',
    'Gilbert',
    'Green Forest',
    'Harriet',
    'Hasty',
    'Jasper',
    'Lakeview',
    'Lead Hill',
    'Leslie',
    'Marble Falls',
    'Marshall',
    'Midway',
    'Mountain Home',
    'Mount Judea',
    'Timbo',
    'Norfork',
    'Oak Grove',
    'Oakland',
    'Omaha',
    'Onia',
    'Parthenon',
    'Peel',
    'Pindall',
    'Ponca',
    'Pyatt',
    'Saint Joe',
    'Summit',
    'Tilly',
    'Valley Springs',
    'Vendor',
    'Western Grove',
    'Witts Springs',
    'Yellville',
    'Fayetteville',
    'Avoca',
    'Bentonville',
    'Bella Vista',
    'Canehill',
    'Cave Springs',
    'Centerton',
    'Combs',
    'Decatur',
    'Elkins',
    'Elm Springs',
    'Evansville',
    'Farmington',
    'Garfield',
    'Gateway',
    'Gentry',
    'Goshen',
    'Gravette',
    'Greenland',
    'Hindsville',
    'Hiwasse',
    'Huntsville',
    'Johnson',
    'Kingston',
    'Lincoln',
    'Lowell',
    'Maysville',
    'Morrow',
    'Pea Ridge',
    'Pettigrew',
    'Prairie Grove',
    'Rogers',
    'Saint Paul',
    'Siloam Springs',
    'Springdale',
    'Sulphur Springs',
    'Summers',
    'Tontitown',
    'Wesley',
    'West Fork',
    'Witter',
    'Russellville',
    'Alix',
    'Altus',
    'Atkins',
    'Belleville',
    'Blue Mountain',
    'Bluffton',
    'Briggsville',
    'Centerville',
    'Clarksville',
    'Coal Hill',
    'Danville',
    'Dardanelle',
    'Delaware',
    'Dover',
    'Gravelly',
    'Hagarville',
    'Hartman',
    'Harvey',
    'Havana',
    'Hector',
    'Knoxville',
    'Lamar',
    'London',
    'New Blaine',
    'Oark',
    'Ola',
    'Ozone',
    'Paris',
    'Pelsor',
    'Plainview',
    'Pottsville',
    'Rover',
    'Scranton',
    'Subiaco',
    'Fort Smith',
    'Alma',
    'Barling',
    'Boles',
    'Booneville',
    'Branch',
    'Cecil',
    'Cedarville',
    'Charleston',
    'Chester',
    'Dyer',
    'Greenwood',
    'Hackett',
    'Hartford',
    'Huntington',
    'Lavaca',
    'Magazine',
    'Mansfield',
    'Midland',
    'Mountainburg',
    'Mulberry',
    'Natural Dam',
    'Ozark',
    'Parks',
    'Ratcliff',
    'Rudy',
    'Uniontown',
    'Van Buren',
    'Waldron',
    'Winslow'
  ],
  OK: [
    'Albert',
    'Alex',
    'Edmond',
    'Amber',
    'Anadarko',
    'Apache',
    'Arcadia',
    'Bethany',
    'Binger',
    'Blanchard',
    'Bradley',
    'Calumet',
    'Carnegie',
    'Cashion',
    'Cement',
    'Chickasha',
    'Norman',
    'Choctaw',
    'Colony',
    'Concho',
    'Corn',
    'Coyle',
    'Crescent',
    'Cyril',
    'Davis',
    'Dibble',
    'Dougherty',
    'Eakly',
    'El Reno',
    'Fort Cobb',
    'Geary',
    'Gotebo',
    'Gracemont',
    'Greenfield',
    'Guthrie',
    'Harrah',
    'Hinton',
    'Hydro',
    'Jones',
    'Langston',
    'Lexington',
    'Lindsay',
    'Lookeba',
    'Luther',
    'Marlow',
    'Marshall',
    'Maysville',
    'Meridian',
    'Minco',
    'Morrison',
    'Mountain View',
    'Mulhall',
    'Mustang',
    'Newcastle',
    'Nicoma Park',
    'Ninnekah',
    'Noble',
    'Orlando',
    'Paoli',
    'Pauls Valley',
    'Perry',
    'Piedmont',
    'Pocasset',
    'Purcell',
    'Rush Springs',
    'Spencer',
    'Yukon',
    'Sulphur',
    'Tuttle',
    'Union City',
    'Verden',
    'Washington',
    'Washita',
    'Wayne',
    'Weatherford',
    'Wheatland',
    'Wynnewood',
    'Oklahoma City',
    'Ardmore',
    'Countyline',
    'Burneyville',
    'Coleman',
    'Elmore City',
    'Foster',
    'Fox',
    'Gene Autry',
    'Graham',
    'Healdton',
    'Kingston',
    'Lebanon',
    'Leon',
    'Loco',
    'Lone Grove',
    'Hennepin',
    'Madill',
    'Mannsville',
    'Marietta',
    'Mead',
    'Milburn',
    'Overbrook',
    'Ravia',
    'Ringling',
    'Springer',
    'Thackerville',
    'Tishomingo',
    'Wapanucka',
    'Wilson',
    'Ratliff City',
    'Tatums',
    'Tussy',
    'Velma',
    'Lawton',
    'Fort Sill',
    'Addington',
    'Altus',
    'Altus AFB',
    'Blair',
    'Cache',
    'Chattanooga',
    'Comanche',
    'Davidson',
    'Devol',
    'Duke',
    'Duncan',
    'Eldorado',
    'Elgin',
    'Elmer',
    'Faxon',
    'Fletcher',
    'Frederick',
    'Geronimo',
    'Gould',
    'Grandfield',
    'Granite',
    'Hastings',
    'Headrick',
    'Hollis',
    'Hollister',
    'Indiahoma',
    'Loveland',
    'Mangum',
    'Manitou',
    'Martha',
    'Medicine Park',
    'Meers',
    'Mountain Park',
    'Olustee',
    'Oscar',
    'Randlett',
    'Roosevelt',
    'Ryan',
    'Snyder',
    'Sterling',
    'Temple',
    'Terral',
    'Tipton',
    'Vinson',
    'Walters',
    'Waurika',
    'Clinton',
    'Arapaho',
    'Bessie',
    'Burns Flat',
    'Butler',
    'Canute',
    'Carter',
    'Cheyenne',
    'Cordell',
    'Crawford',
    'Custer City',
    'Dill City',
    'Durham',
    'Elk City',
    'Erick',
    'Fay',
    'Foss',
    'Hammon',
    'Hobart',
    'Leedey',
    'Lone Wolf',
    'Oakwood',
    'Putnam',
    'Reydon',
    'Rocky',
    'Sayre',
    'Seiling',
    'Sentinel',
    'Sweetwater',
    'Taloga',
    'Texola',
    'Thomas',
    'Willow',
    'Enid',
    'Aline',
    'Alva',
    'Ames',
    'Amorita',
    'Bison',
    'Burlington',
    'Canton',
    'Carmen',
    'Carrier',
    'Cherokee',
    'Cleo Springs',
    'Covington',
    'Dacoma',
    'Douglas',
    'Dover',
    'Drummond',
    'Fairmont',
    'Fairview',
    'Garber',
    'Goltry',
    'Helena',
    'Hennessey',
    'Hillsdale',
    'Hitchcock',
    'Hopeton',
    'Isabella',
    'Jet',
    'Kingfisher',
    'Kremlin',
    'Lahoma',
    'Longdale',
    'Loyal',
    'Lucien',
    'Manchester',
    'Medford',
    'Meno',
    'Nash',
    'Okarche',
    'Okeene',
    'Omega',
    'Pond Creek',
    'Ringwood',
    'Southard',
    'Wakita',
    'Watonga',
    'Waukomis',
    'Woodward',
    'Arnett',
    'Buffalo',
    'Camargo',
    'Chester',
    'Fargo',
    'Fort Supply',
    'Freedom',
    'Gage',
    'Gate',
    'Laverne',
    'May',
    'Mooreland',
    'Mutual',
    'Rosston',
    'Sharon',
    'Shattuck',
    'Vici',
    'Waynoka',
    'Adams',
    'Balko',
    'Beaver',
    'Boise City',
    'Felt',
    'Forgan',
    'Goodwell',
    'Guymon',
    'Hardesty',
    'Hooker',
    'Kenton',
    'Keyes',
    'Texhoma',
    'Turpin',
    'Tyrone',
    'Avant',
    'Barnsdall',
    'Bartlesville',
    'Bixby',
    'Bristow',
    'Broken Arrow',
    'Catoosa',
    'Chelsea',
    'Claremore',
    'Cleveland',
    'Collinsville',
    'Copan',
    'Cushing',
    'Davenport',
    'Delaware',
    'Depew',
    'Dewey',
    'Drumright',
    'Foyil',
    'Glencoe',
    'Glenpool',
    'Hallett',
    'Hominy',
    'Inola',
    'Jenks',
    'Jennings',
    'Kellyville',
    'Kiefer',
    'Lenapah',
    'Leonard',
    'Mannford',
    'Maramec',
    'Milfay',
    'Mounds',
    'Nowata',
    'Oakhurst',
    'Ochelata',
    'Oilton',
    'Oologah',
    'Osage',
    'Owasso',
    'Pawhuska',
    'Pawnee',
    'Perkins',
    'Prue',
    'Ramona',
    'Ripley',
    'Sand Springs',
    'Sapulpa',
    'Shamrock',
    'Skiatook',
    'Slick',
    'S Coffeyville',
    'Sperry',
    'Stillwater',
    'Stroud',
    'Talala',
    'Terlton',
    'Vera',
    'Wann',
    'Wynona',
    'Yale',
    'Tulsa',
    'Vinita',
    'Adair',
    'Afton',
    'Big Cabin',
    'Bluejacket',
    'Cardin',
    'Chouteau',
    'Colcord',
    'Commerce',
    'Disney',
    'Eucha',
    'Fairland',
    'Grove',
    'Jay',
    'Kansas',
    'Ketchum',
    'Langley',
    'Locust Grove',
    'Miami',
    'North Miami',
    'Oaks',
    'Picher',
    'Pryor',
    'Quapaw',
    'Rose',
    'Salina',
    'Spavinaw',
    'Strang',
    'Twin Oaks',
    'Welch',
    'Wyandotte',
    'Muskogee',
    'Beggs',
    'Boynton',
    'Braggs',
    'Canadian',
    'Checotah',
    'Cookson',
    'Council Hill',
    'Coweta',
    'Crowder',
    'Dewar',
    'Eufaula',
    'Fort Gibson',
    'Gore',
    'Haskell',
    'Henryetta',
    'Hitchita',
    'Hoyt',
    'Hulbert',
    'Indianola',
    'Moodys',
    'Morris',
    'Okay',
    'Okmulgee',
    'Oktaha',
    'Park Hill',
    'Peggs',
    'Porter',
    'Porum',
    'Preston',
    'Proctor',
    'Redbird',
    'Rentiesville',
    'Schulter',
    'Stidham',
    'Stigler',
    'Taft',
    'Tahlequah',
    'Wagoner',
    'Wainwright',
    'Warner',
    'Webbers Falls',
    'Welling',
    'Whitefield',
    'Mcalester',
    'Albion',
    'Alderson',
    'Antlers',
    'Atoka',
    'Blanco',
    'Blocker',
    'Bromide',
    'Calvin',
    'Caney',
    'Centrahoma',
    'Clarita',
    'Clayton',
    'Coalgate',
    'Daisy',
    'Finley',
    'Gowen',
    'Haileyville',
    'Hartshorne',
    'Honobia',
    'Kinta',
    'Kiowa',
    'Krebs',
    'Lane',
    'Lehigh',
    'Moyers',
    'Nashoba',
    'Panola',
    'Pittsburg',
    'Quinton',
    'Rattan',
    'Red Oak',
    'Savanna',
    'Snow',
    'Stringtown',
    'Stuart',
    'Talihina',
    'Tupelo',
    'Tuskahoma',
    'Wardville',
    'Whitesboro',
    'Wilburton',
    'Ponca City',
    'Billings',
    'Blackwell',
    'Braman',
    'Burbank',
    'Deer Creek',
    'Fairfax',
    'Hunter',
    'Kaw City',
    'Lamont',
    'Marland',
    'Nardin',
    'Newkirk',
    'Ralston',
    'Red Rock',
    'Shidler',
    'Tonkawa',
    'Durant',
    'Achille',
    'Albany',
    'Battiest',
    'Bennington',
    'Bethel',
    'Bokchito',
    'Boswell',
    'Broken Bow',
    'Caddo',
    'Calera',
    'Cartwright',
    'Colbert',
    'Eagletown',
    'Fort Towson',
    'Garvin',
    'Golden',
    'Grant',
    'Haworth',
    'Hendrix',
    'Hugo',
    'Idabel',
    'Kemp',
    'Kenefic',
    'Millerton',
    'Pickens',
    'Platter',
    'Ringold',
    'Rufe',
    'Sawyer',
    'Soper',
    'Spencerville',
    'Swink',
    'Valliant',
    'Wright City',
    'Shawnee',
    'Seminole',
    'Ada',
    'Agra',
    'Allen',
    'Asher',
    'Atwood',
    'Boley',
    'Bowlegs',
    'Byars',
    'Carney',
    'Castle',
    'Chandler',
    'Connerville',
    'Cromwell',
    'Dustin',
    'Earlsboro',
    'Fittstown',
    'Fitzhugh',
    'Francis',
    'Hanna',
    'Holdenville',
    'Konawa',
    'Lamar',
    'Mcloud',
    'Macomb',
    'Maud',
    'Meeker',
    'Mill Creek',
    'Newalla',
    'Okemah',
    'Paden',
    'Prague',
    'Roff',
    'Saint Louis',
    'Sasakwa',
    'Sparks',
    'Stonewall',
    'Stratford',
    'Tecumseh',
    'Tryon',
    'Wanette',
    'Weleetka',
    'Wellston',
    'Wetumka',
    'Wewoka',
    'Arkoma',
    'Pocola',
    'Bokoshe',
    'Bunch',
    'Cameron',
    'Fanshawe',
    'Gans',
    'Heavener',
    'Hodgen',
    'Howe',
    'Keota',
    'Leflore',
    'Lequire',
    'Mccurtain',
    'Marble City',
    'Moffett',
    'Monroe',
    'Muldrow',
    'Muse',
    'Panama',
    'Poteau',
    'Roland',
    'Sallisaw',
    'Shady Point',
    'Smithville',
    'Spiro',
    'Stilwell',
    'Vian',
    'Watson',
    'Watts',
    'Westville',
    'Wister'
  ],
  TX: [
    'Austin',
    'Texhoma',
    'Addison',
    'Allen',
    'Carrollton',
    'Celina',
    'Irving',
    'Coppell',
    'Denison',
    'Flower Mound',
    'Plano',
    'Lewisville',
    'Rowlett',
    'Rockwall',
    'Frisco',
    'Garland',
    'Sachse',
    'Grand Prairie',
    'The Colony',
    'Gunter',
    'Lake Dallas',
    'Little Elm',
    'Mckinney',
    'Pottsboro',
    'Prosper',
    'Richardson',
    'Sherman',
    'Weston',
    'Wylie',
    'Bardwell',
    'Barry',
    'Canton',
    'Cedar Hill',
    'Chatfield',
    'Corsicana',
    'Crandall',
    'Desoto',
    'Duncanville',
    'Edgewood',
    'Elmo',
    'Ennis',
    'Copeville',
    'Eustace',
    'Ferris',
    'Forney',
    'Fruitvale',
    'Fate',
    'Lancaster',
    'Caddo Mills',
    'Grand Saline',
    'Hutchins',
    'Kaufman',
    'Kemp',
    'Kerens',
    'Mabank',
    'Malakoff',
    'Mesquite',
    'Palmer',
    'Powell',
    'Red Oak',
    'Rice',
    'Rosser',
    'Scurry',
    'Seagoville',
    'Terrell',
    'Trinidad',
    'Josephine',
    'Waxahachie',
    'Lavon',
    'Wills Point',
    'Wilmer',
    'Nevada',
    'Balch Springs',
    'Sunnyvale',
    'Royse City',
    'Dallas',
    'Greenville',
    'Princeton',
    'Anna',
    'Alba',
    'Arthur City',
    'Bagwell',
    'Bailey',
    'Bells',
    'Ben Franklin',
    'Blossom',
    'Bogata',
    'Bonham',
    'Brashear',
    'Brookston',
    'Campbell',
    'Celeste',
    'Blue Ridge',
    'Chicota',
    'Clarksville',
    'Commerce',
    'Como',
    'Cooper',
    'Cumby',
    'Cunningham',
    'Deport',
    'Detroit',
    'Dike',
    'Dodd City',
    'Ector',
    'Emory',
    'Enloe',
    'Farmersville',
    'Gober',
    'Golden',
    'Honey Grove',
    'Ivanhoe',
    'Klondike',
    'Ladonia',
    'Lake Creek',
    'Leesburg',
    'Leonard',
    'Lone Oak',
    'Melissa',
    'Mount Pleasant',
    'Mount Vernon',
    'Merit',
    'Howe',
    'Paris',
    'Pattonville',
    'Pecan Gap',
    'Petty',
    'Pickton',
    'Point',
    'Powderly',
    'Quinlan',
    'Randolph',
    'Ravenna',
    'Roxton',
    'Saltillo',
    'Savoy',
    'Scroggins',
    'Sulphur Bluff',
    'Sulphur Springs',
    'Westminster',
    'Sumner',
    'Talco',
    'Telephone',
    'Tom Bean',
    'Trenton',
    'Whitewright',
    'Windom',
    'Winfield',
    'Winnsboro',
    'Van Alstyne',
    'Wolfe City',
    'Yantis',
    'Texarkana',
    'Annona',
    'Atlanta',
    'Avery',
    'Bivins',
    'Bloomburg',
    'Cookville',
    'De Kalb',
    'Douglassville',
    'Hooks',
    'Kildare',
    'Linden',
    'Lodi',
    'Mc Leod',
    'Marietta',
    'Maud',
    'Naples',
    'Nash',
    'New Boston',
    'Omaha',
    'Queen City',
    'Redwater',
    'Simms',
    'Longview',
    'Avinger',
    'Beckville',
    'Carthage',
    'Cason',
    'Clayton',
    'Daingerfield',
    'De Berry',
    'Diana',
    'Easton',
    'Elysian Fields',
    'Gary',
    'Gilmer',
    'Gladewater',
    'Hallsville',
    'Harleton',
    'Henderson',
    'Hughes Springs',
    'Jefferson',
    'Joinerville',
    'Jonesville',
    'Judson',
    'Karnack',
    'Kilgore',
    'Laird Hill',
    'Laneville',
    'Lone Star',
    'Long Branch',
    'Marshall',
    'Minden',
    'Mount Enterprise',
    'New London',
    'Ore City',
    'Overton',
    'Panola',
    'Pittsburg',
    'Price',
    'Scottsville',
    'Selman City',
    'Tatum',
    'Waskom',
    'White Oak',
    'Woodlawn',
    'Tyler',
    'Arp',
    'Athens',
    'Ben Wheeler',
    'Big Sandy',
    'Brownsboro',
    'Bullard',
    'Chandler',
    'Cuney',
    'Cushing',
    'Flint',
    'Frankston',
    'Gallatin',
    'Hawkins',
    'Jacksonville',
    'Larue',
    'Lindale',
    'Maydelle',
    'Mineola',
    'Murchison',
    'Neches',
    'New Summerfield',
    'Poynor',
    'Quitman',
    'Reklaw',
    'Rusk',
    'Sacul',
    'Troup',
    'Van',
    'Whitehouse',
    'Winona',
    'Palestine',
    'Buffalo',
    'Cayuga',
    'Centerville',
    'Centralia',
    'Crockett',
    'Donie',
    'Elkhart',
    'Fairfield',
    'Grapeland',
    'Groveton',
    'Jewett',
    'Kennard',
    'Kirvin',
    'Latexo',
    'Leona',
    'Lovelady',
    'Midway',
    'Montalba',
    'Oakwood',
    'Pennington',
    'Ratcliff',
    'Streetman',
    'Teague',
    'Tennessee Colony',
    'Trinity',
    'Woodlake',
    'Lufkin',
    'Alto',
    'Apple Springs',
    'Bon Wier',
    'Broaddus',
    'Bronson',
    'Brookeland',
    'Burkeville',
    'Call',
    'Camden',
    'Center',
    'Chester',
    'Chireno',
    'Colmesneil',
    'Corrigan',
    'Diboll',
    'Doucette',
    'Douglass',
    'Etoile',
    'Garrison',
    'Hemphill',
    'Huntington',
    'Jasper',
    'Joaquin',
    'Kirbyville',
    'Martinsville',
    'Milam',
    'Moscow',
    'Nacogdoches',
    'Newton',
    'Pineland',
    'Pollok',
    'San Augustine',
    'Shelbyville',
    'Tenaha',
    'Timpson',
    'Wells',
    'Wiergate',
    'Woden',
    'Woodville',
    'Zavalla',
    'Arlington',
    'Aledo',
    'Alvarado',
    'Azle',
    'Bedford',
    'Boyd',
    'Burleson',
    'Cleburne',
    'Colleyville',
    'Cresson',
    'Crowley',
    'Euless',
    'Forreston',
    'Glen Rose',
    'Godley',
    'Granbury',
    'Grandview',
    'Grapevine',
    'Haslet',
    'Hurst',
    'Itasca',
    'Joshua',
    'Keene',
    'Kennedale',
    'Lillian',
    'Mansfield',
    'Maypearl',
    'Midlothian',
    'Millsap',
    'Mineral Wells',
    'Nemo',
    'Newark',
    'Paradise',
    'Rainbow',
    'Rhome',
    'Springtown',
    'Venus',
    'Weatherford',
    'Southlake',
    'Rio Vista',
    'Fort Worth',
    'Haltom City',
    'Naval Air Station Jrb',
    'North Richland Hills',
    'Denton',
    'Alvord',
    'Argyle',
    'Aubrey',
    'Bellevue',
    'Bowie',
    'Collinsville',
    'Decatur',
    'Era',
    'Forestburg',
    'Gainesville',
    'Keller',
    'Gordonville',
    'Greenwood',
    'Justin',
    'Krum',
    'Lindsay',
    'Montague',
    'Muenster',
    'Myra',
    'Nocona',
    'Pilot Point',
    'Ponder',
    'Ringgold',
    'Roanoke',
    'Rosston',
    'Sadler',
    'Saint Jo',
    'Sanger',
    'Slidell',
    'Southmayd',
    'Sunset',
    'Tioga',
    'Valley View',
    'Whitesboro',
    'Wichita Falls',
    'Sheppard AFB',
    'Archer City',
    'Bluegrove',
    'Burkburnett',
    'Byers',
    'Electra',
    'Goree',
    'Harrold',
    'Henrietta',
    'Holliday',
    'Iowa Park',
    'Kamay',
    'Megargel',
    'Munday',
    'Newcastle',
    'Oklaunion',
    'Olney',
    'Petrolia',
    'Scotland',
    'Seymour',
    'Vernon',
    'Weinert',
    'Windthorst',
    'Stephenville',
    'Breckenridge',
    'Bridgeport',
    'Bryson',
    'Caddo',
    'Albany',
    'Chico',
    'Blanket',
    'Bluff Dale',
    'Carbon',
    'Carlton',
    'Cisco',
    'Dennis',
    'Comanche',
    'Cross Plains',
    'De Leon',
    'Desdemona',
    'Dublin',
    'Eastland',
    'Graford',
    'Graham',
    'Energy',
    'Gordon',
    'Gorman',
    'Gustine',
    'Hico',
    'Jacksboro',
    'Jermyn',
    'Loving',
    'Lingleville',
    'Lipan',
    'Mingus',
    'Moran',
    'Morgan Mill',
    'Olden',
    'Paluxy',
    'Proctor',
    'Putnam',
    'Ranger',
    'Rising Star',
    'Santo',
    'Sidney',
    'Strawn',
    'Tolar',
    'South Bend',
    'Throckmorton',
    'Palo Pinto',
    'Peaster',
    'Perrin',
    'Poolville',
    'Whitt',
    'Woodson',
    'Temple',
    'Bartlett',
    'Belton',
    'Buckholts',
    'Burlington',
    'Cameron',
    'Copperas Cove',
    'Davilla',
    'Eddy',
    'Evant',
    'Flat',
    'Florence',
    'Gatesville',
    'Granger',
    'Hamilton',
    'Heidenheimer',
    'Holland',
    'Jarrell',
    'Jonesboro',
    'Kempner',
    'Killeen',
    'Fort Hood',
    'Harker Heights',
    'Lampasas',
    'Little River Academy',
    'Milano',
    'Moody',
    'Mound',
    'Nolanville',
    'Oglesby',
    'Pendleton',
    'Pottsville',
    'Purmela',
    'Rockdale',
    'Rogers',
    'Rosebud',
    'Salado',
    'Schwertner',
    'Taylor',
    'Thorndale',
    'Thrall',
    'Troy',
    'Abbott',
    'Aquilla',
    'Avalon',
    'Axtell',
    'Blooming Grove',
    'Blum',
    'Brandon',
    'Bremond',
    'Bruceville',
    'Bynum',
    'Chilton',
    'China Spring',
    'Clifton',
    'Coolidge',
    'Covington',
    'Cranfills Gap',
    'Crawford',
    'Dawson',
    'Elm Mott',
    'Frost',
    'Groesbeck',
    'Hewitt',
    'Laguna Park',
    'Hillsboro',
    'Hubbard',
    'Iredell',
    'Irene',
    'Italy',
    'Kopperl',
    'Kosse',
    'Leroy',
    'Lorena',
    'Lott',
    'Mc Gregor',
    'Malone',
    'Marlin',
    'Mart',
    'Meridian',
    'Mertens',
    'Mexia',
    'Milford',
    'Morgan',
    'Mount Calm',
    'Penelope',
    'Prairie Hill',
    'Purdon',
    'Reagan',
    'Richland',
    'Riesel',
    'Ross',
    'Satin',
    'Tehuacana',
    'Thornton',
    'Valley Mills',
    'Walnut Springs',
    'West',
    'Whitney',
    'Wortham',
    'Waco',
    'Woodway',
    'Brownwood',
    'Early',
    'Art',
    'Ballinger',
    'Bangs',
    'Bend',
    'Brady',
    'Brookesmith',
    'Burkett',
    'Castell',
    'Cherokee',
    'Coleman',
    'Doole',
    'Eden',
    'Fort Mc Kavett',
    'Fredonia',
    'Goldthwaite',
    'Gouldbusk',
    'Hext',
    'Junction',
    'Lohn',
    'Lometa',
    'London',
    'Lowake',
    'Mason',
    'May',
    'Melvin',
    'Menard',
    'Miles',
    'Millersview',
    'Mullin',
    'Norton',
    'Paint Rock',
    'Pontotoc',
    'Priddy',
    'Richland Springs',
    'Rochelle',
    'Rockwood',
    'Roosevelt',
    'Rowena',
    'San Saba',
    'Santa Anna',
    'Star',
    'Talpa',
    'Telegraph',
    'Valera',
    'Valley Spring',
    'Veribest',
    'Voca',
    'Voss',
    'Zephyr',
    'San Angelo',
    'Goodfellow AFB',
    'Barnhart',
    'Big Lake',
    'Bronte',
    'Carlsbad',
    'Christoval',
    'Eldorado',
    'Eola',
    'Knickerbocker',
    'Mereta',
    'Mertzon',
    'Ozona',
    'Robert Lee',
    'Silver',
    'Sonora',
    'Sterling City',
    'Tennyson',
    'Vancourt',
    'Wall',
    'Water Valley',
    'Houston',
    'Conroe',
    'North Houston',
    'Montgomery',
    'Willis',
    'Huntsville',
    'Kingwood',
    'Ace',
    'Cleveland',
    'Coldspring',
    'Dallardsville',
    'Dobbin',
    'Dodge',
    'Goodrich',
    'Huffman',
    'Hufsmith',
    'Humble',
    'Leggett',
    'Livingston',
    'Magnolia',
    'New Caney',
    'New Waverly',
    'Oakhurst',
    'Onalaska',
    'Pinehurst',
    'Plantersville',
    'Pointblank',
    'Porter',
    'Riverside',
    'Romayor',
    'Rye',
    'Shepherd',
    'Splendora',
    'Spring',
    'Thicket',
    'Tomball',
    'Votaw',
    'Bellaire',
    'Bay City',
    'Richmond',
    'Cypress',
    'Alief',
    'Altair',
    'Barker',
    'Cedar Lane',
    'Beasley',
    'Bellville',
    'Blessing',
    'Boling',
    'Brazoria',
    'Brookshire',
    'Chappell Hill',
    'Collegeport',
    'Damon',
    'Danciger',
    'Danevang',
    'Eagle Lake',
    'East Bernard',
    'Egypt',
    'El Campo',
    'Elmaton',
    'Fulshear',
    'Garwood',
    'Glen Flora',
    'Guy',
    'Hempstead',
    'Prairie View',
    'Hockley',
    'Hungerford',
    'Katy',
    'Kendleton',
    'Kenney',
    'Lane City',
    'Lissie',
    'Louise',
    'Markham',
    'Matagorda',
    'Midfield',
    'Missouri City',
    'Nada',
    'Needville',
    'Old Ocean',
    'Orchard',
    'Palacios',
    'Pattison',
    'Pierce',
    'Pledger',
    'Rock Island',
    'Rosenberg',
    'San Felipe',
    'Sealy',
    'Sheridan',
    'Simonton',
    'Stafford',
    'Sugar Land',
    'Sweeny',
    'Thompsons',
    'Van Vleck',
    'Wadsworth',
    'Waller',
    'Wallis',
    'West Columbia',
    'Wharton',
    'Pasadena',
    'Santa Fe',
    'Alvin',
    'Anahuac',
    'Angleton',
    'Bacliff',
    'Batson',
    'Baytown',
    'Channelview',
    'Clute',
    'Crosby',
    'Daisetta',
    'Danbury',
    'Dayton',
    'Deer Park',
    'Devers',
    'Dickinson',
    'Freeport',
    'Fresno',
    'Friendswood',
    'Galena Park',
    'Galveston',
    'Hankamer',
    'Hardin',
    'Highlands',
    'Hitchcock',
    'Hull',
    'Kemah',
    'Lake Jackson',
    'La Marque',
    'La Porte',
    'League City',
    'Liberty',
    'Liverpool',
    'Manvel',
    'Mont Belvieu',
    'Pearland',
    'Raywood',
    'Rosharon',
    'Saratoga',
    'Seabrook',
    'South Houston',
    'Texas City',
    'Wallisville',
    'Webster',
    'Bridge City',
    'Buna',
    'China',
    'Deweyville',
    'Evadale',
    'Fred',
    'Gilchrist',
    'Groves',
    'Hamshire',
    'High Island',
    'Hillister',
    'Kountze',
    'Mauriceville',
    'Nederland',
    'Nome',
    'Orange',
    'Orangefield',
    'Port Arthur',
    'Port Bolivar',
    'Port Neches',
    'Sabine Pass',
    'Silsbee',
    'Lumberton',
    'Sour Lake',
    'Spurger',
    'Stowell',
    'Vidor',
    'Village Mills',
    'Warren',
    'Winnie',
    'Beaumont',
    'Bryan',
    'Anderson',
    'Bedias',
    'Brenham',
    'Burton',
    'Caldwell',
    'Calvert',
    'Chriesman',
    'College Station',
    'Concord',
    'Deanville',
    'Dime Box',
    'Flynn',
    'Franklin',
    'Gause',
    'Hearne',
    'Iola',
    'Kurten',
    'Lyons',
    'Madisonville',
    'Marquez',
    'Millican',
    'Mumford',
    'Navasota',
    'New Baden',
    'Normangee',
    'North Zulch',
    'Richards',
    'Roans Prairie',
    'Shiro',
    'Snook',
    'Somerville',
    'Washington',
    'Wellborn',
    'Wheelock',
    'Victoria',
    'Austwell',
    'Bloomington',
    'Cuero',
    'Edna',
    'Fannin',
    'Francitas',
    'Ganado',
    'Goliad',
    'Hallettsville',
    'Hochheim',
    'Inez',
    'La Salle',
    'La Ward',
    'Lolita',
    'Mcfaddin',
    'Meyersville',
    'Moulton',
    'Nursery',
    'Placedo',
    'Point Comfort',
    'Port Lavaca',
    'Port O Connor',
    'Seadrift',
    'Shiner',
    'Sublime',
    'Sweet Home',
    'Telferner',
    'Thomaston',
    'Tivoli',
    'Vanderbilt',
    'Weesatche',
    'Westhoff',
    'Yoakum',
    'Artesia Wells',
    'Atascosa',
    'Bandera',
    'Bergheim',
    'Bigfoot',
    'Boerne',
    'Calliham',
    'Campbellton',
    'Castroville',
    'Center Point',
    'Charlotte',
    'Christine',
    'Comfort',
    'Cotulla',
    'Devine',
    'Dilley',
    'Encinal',
    'Fowlerton',
    'George West',
    'Helotes',
    'Hunt',
    'Ingram',
    'Jourdanton',
    'Kendalia',
    'Kerrville',
    'La Coste',
    'Laredo',
    'Leming',
    'Lytle',
    'Macdona',
    'Medina',
    'Mico',
    'Moore',
    'Mountain Home',
    'Natalia',
    'Oakville',
    'Pearsall',
    'Peggy',
    'Pipe Creek',
    'Pleasanton',
    'Poteet',
    'Rio Medina',
    'San Ygnacio',
    'Somerset',
    'Spring Branch',
    'Three Rivers',
    'Tilden',
    'Von Ormy',
    'Waring',
    'Whitsett',
    'Zapata',
    'Adkins',
    'Beeville',
    'Berclair',
    'Cibolo',
    'Converse',
    'Ecleto',
    'Elmendorf',
    'Falls City',
    'Floresville',
    'Geronimo',
    'Gillett',
    'Hobson',
    'Karnes City',
    'Kenedy',
    'La Vernia',
    'Leesville',
    'Mc Queeney',
    'Marion',
    'Mineral',
    'New Braunfels',
    'Canyon Lake',
    'Nixon',
    'Nordheim',
    'Normanna',
    'Pandora',
    'Panna Maria',
    'Pawnee',
    'Pettus',
    'Poth',
    'Universal City',
    'Jbsa Randolph',
    'Runge',
    'Saint Hedwig',
    'Schertz',
    'Seguin',
    'Smiley',
    'Stockdale',
    'Sutherland Springs',
    'Tuleta',
    'Bulverde',
    'Yorktown',
    'San Antonio',
    'Jbsa Ft Sam Houston',
    'Jbsa Lackland',
    'Agua Dulce',
    'Alice',
    'Aransas Pass',
    'Armstrong',
    'Banquete',
    'Bayside',
    'Benavides',
    'Ben Bolt',
    'Bishop',
    'Bruni',
    'Chapman Ranch',
    'Concepcion',
    'Dinero',
    'Driscoll',
    'Edroy',
    'Encino',
    'Falfurrias',
    'Freer',
    'Fulton',
    'Gregory',
    'Guerra',
    'Hebbronville',
    'Ingleside',
    'Kingsville',
    'Mathis',
    'Mirando City',
    'Odem',
    'Oilton',
    'Orange Grove',
    'Port Aransas',
    'Portland',
    'Premont',
    'Realitos',
    'Refugio',
    'Riviera',
    'Robstown',
    'Rockport',
    'Sandia',
    'San Diego',
    'Sarita',
    'Sinton',
    'Skidmore',
    'Taft',
    'Tynan',
    'Woodsboro',
    'Corpus Christi',
    'Mcallen',
    'Alamo',
    'Brownsville',
    'Combes',
    'Delmita',
    'Donna',
    'Edcouch',
    'Edinburg',
    'Elsa',
    'Falcon Heights',
    'Garciasville',
    'Grulla',
    'Hargill',
    'Harlingen',
    'Hidalgo',
    'La Blanca',
    'La Feria',
    'La Joya',
    'Lasara',
    'La Villa',
    'Linn',
    'Lopeno',
    'Los Ebanos',
    'Los Fresnos',
    'Los Indios',
    'Lozano',
    'Lyford',
    'Mercedes',
    'Mission',
    'Olmito',
    'Penitas',
    'Pharr',
    'Port Isabel',
    'Progreso',
    'Raymondville',
    'Rio Grande City',
    'Rio Hondo',
    'Roma',
    'Salineno',
    'San Benito',
    'San Isidro',
    'San Juan',
    'San Perlita',
    'Santa Elena',
    'Santa Maria',
    'Santa Rosa',
    'Sebastian',
    'Sullivan City',
    'Weslaco',
    'South Padre Island',
    'Port Mansfield',
    'Bastrop',
    'Belmont',
    'Bertram',
    'Blanco',
    'Bluffton',
    'Briggs',
    'Buchanan Dam',
    'Buda',
    'Burnet',
    'Cedar Creek',
    'Cedar Park',
    'Cost',
    'Coupland',
    'Dale',
    'Del Valle',
    'Doss',
    'Driftwood',
    'Dripping Springs',
    'Elgin',
    'Fentress',
    'Fischer',
    'Fredericksburg',
    'Georgetown',
    'Gonzales',
    'Harper',
    'Harwood',
    'Hutto',
    'Hye',
    'Johnson City',
    'Kingsbury',
    'Kingsland',
    'Kyle',
    'Leander',
    'Liberty Hill',
    'Llano',
    'Lockhart',
    'Luling',
    'Mc Dade',
    'Mc Neil',
    'Manchaca',
    'Manor',
    'Marble Falls',
    'Martindale',
    'Maxwell',
    'Horseshoe Bay',
    'Ottine',
    'Paige',
    'Pflugerville',
    'Prairie Lea',
    'Red Rock',
    'Round Mountain',
    'Round Rock',
    'San Marcos',
    'Spicewood',
    'Staples',
    'Stonewall',
    'Tow',
    'Walburg',
    'Weir',
    'Willow City',
    'Wimberley',
    'Wrightsboro',
    'Uvalde',
    'Asherton',
    'Barksdale',
    'Batesville',
    'Big Wells',
    'Brackettville',
    'Camp Wood',
    'Carrizo Springs',
    'Catarina',
    'Comstock',
    'Concan',
    'Crystal City',
    'Del Rio',
    'Laughlin AFB',
    'D Hanis',
    'Dryden',
    'Eagle Pass',
    'El Indio',
    'Hondo',
    'Knippa',
    'Langtry',
    'La Pryor',
    'Leakey',
    'Quemado',
    'Rio Frio',
    'Rocksprings',
    'Sabinal',
    'Tarpley',
    'Utopia',
    'Vanderpool',
    'Yancey',
    'Bleiblerville',
    'Carmine',
    'Cat Spring',
    'Columbus',
    'Alleyton',
    'Ellinger',
    'Fayetteville',
    'Flatonia',
    'Giddings',
    'Glidden',
    'Industry',
    'La Grange',
    'Ledbetter',
    'Lexington',
    'Lincoln',
    'Muldoon',
    'New Ulm',
    'Oakland',
    'Plum',
    'Rosanky',
    'Round Top',
    'Schulenburg',
    'Smithville',
    'Waelder',
    'Warda',
    'Warrenton',
    'Weimar',
    'West Point',
    'Adrian',
    'Alanreed',
    'Allison',
    'Booker',
    'Borger',
    'Bovina',
    'Boys Ranch',
    'Briscoe',
    'Bushland',
    'Cactus',
    'Canadian',
    'Canyon',
    'Channing',
    'Claude',
    'Cotton Center',
    'Dalhart',
    'Darrouzett',
    'Dawn',
    'Dimmitt',
    'Dumas',
    'Earth',
    'Edmonson',
    'Farnsworth',
    'Follett',
    'Friona',
    'Fritch',
    'Groom',
    'Gruver',
    'Hale Center',
    'Happy',
    'Hart',
    'Hartley',
    'Hereford',
    'Higgins',
    'Kerrick',
    'Kress',
    'Lazbuddie',
    'Lefors',
    'Lipscomb',
    'Mclean',
    'Masterson',
    'Miami',
    'Mobeetie',
    'Morse',
    'Nazareth',
    'Olton',
    'Pampa',
    'Panhandle',
    'Perryton',
    'Plainview',
    'Samnorwood',
    'Sanford',
    'Shamrock',
    'Skellytown',
    'Spearman',
    'Springlake',
    'Stinnett',
    'Stratford',
    'Summerfield',
    'Sunray',
    'Texline',
    'Tulia',
    'Umbarger',
    'Vega',
    'Waka',
    'Wayside',
    'Wellington',
    'Wheeler',
    'White Deer',
    'Wildorado',
    'Amarillo',
    'Childress',
    'Afton',
    'Aiken',
    'Cee Vee',
    'Chillicothe',
    'Clarendon',
    'Crowell',
    'Dickens',
    'Dodson',
    'Dougherty',
    'Estelline',
    'Flomot',
    'Floydada',
    'Guthrie',
    'Hedley',
    'Lakeview',
    'Lelia Lake',
    'Lockney',
    'Mcadoo',
    'Matador',
    'Memphis',
    'Odell',
    'Paducah',
    'Petersburg',
    'Quail',
    'Quanah',
    'Quitaque',
    'Roaring Springs',
    'Silverton',
    'South Plains',
    'Tell',
    'Turkey',
    'Abernathy',
    'Amherst',
    'Anton',
    'Bledsoe',
    'Brownfield',
    'Bula',
    'Crosbyton',
    'Denver City',
    'Enochs',
    'Farwell',
    'Fieldton',
    'Idalou',
    'Justiceburg',
    'Lamesa',
    'Levelland',
    'Littlefield',
    'Loop',
    'Lorenzo',
    'Maple',
    'Meadow',
    'Morton',
    'Muleshoe',
    'New Deal',
    'Odonnell',
    'Pep',
    'Plains',
    'Post',
    'Ralls',
    'Ropesville',
    'Seagraves',
    'Seminole',
    'Shallowater',
    'Slaton',
    'Ransom Canyon',
    'Smyer',
    'Spade',
    'Spur',
    'Sudan',
    'Sundown',
    'Tahoka',
    'Tokio',
    'Welch',
    'Wellman',
    'Whiteface',
    'Whitharral',
    'Wilson',
    'Wolfforth',
    'New Home',
    'Lubbock',
    'Anson',
    'Aspermont',
    'Avoca',
    'Baird',
    'Benjamin',
    'Blackwell',
    'Buffalo Gap',
    'Clyde',
    'Coahoma',
    'Colorado City',
    'Dunn',
    'Fluvanna',
    'Girard',
    'Goldsboro',
    'Hamlin',
    'Haskell',
    'Hawley',
    'Hermleigh',
    'Ira',
    'Jayton',
    'Knox City',
    'Lawn',
    'Loraine',
    'Lueders',
    'Mc Caulley',
    'Maryneal',
    'Merkel',
    'Nolan',
    'Novice',
    'O Brien',
    'Old Glory',
    'Ovalo',
    'Roby',
    'Rochester',
    'Roscoe',
    'Rotan',
    'Rule',
    'Snyder',
    'Stamford',
    'Sweetwater',
    'Sylvester',
    'Trent',
    'Tuscola',
    'Tye',
    'Westbrook',
    'Wingate',
    'Winters',
    'Abilene',
    'Dyess AFB',
    'Midland',
    'Ackerly',
    'Andrews',
    'Balmorhea',
    'Barstow',
    'Big Spring',
    'Coyanosa',
    'Crane',
    'Forsan',
    'Fort Davis',
    'Fort Stockton',
    'Gail',
    'Garden City',
    'Girvin',
    'Goldsmith',
    'Grandfalls',
    'Imperial',
    'Iraan',
    'Kermit',
    'Knott',
    'Lenorah',
    'Mc Camey',
    'Mentone',
    'Midkiff',
    'Monahans',
    'Gardendale',
    'Notrees',
    'Odessa',
    'Orla',
    'Pecos',
    'Penwell',
    'Pyote',
    'Rankin',
    'Saragosa',
    'Sheffield',
    'Stanton',
    'Tarzan',
    'Toyah',
    'Toyahvale',
    'Wickett',
    'Wink',
    'Anthony',
    'Alpine',
    'Big Bend National Park',
    'Canutillo',
    'Clint',
    'Dell City',
    'Fabens',
    'Fort Hancock',
    'Marathon',
    'Marfa',
    'Presidio',
    'Redford',
    'Salt Flat',
    'Sanderson',
    'San Elizario',
    'Sierra Blanca',
    'Terlingua',
    'Tornillo',
    'Valentine',
    'Van Horn',
    'El Paso',
    'Fort Bliss'
  ],
  CO: [
    'Arvada',
    'Aurora',
    'Broomfield',
    'Commerce City',
    'Dupont',
    'Eldorado Springs',
    'Lafayette',
    'Louisville',
    'Westminster',
    'Wheat Ridge',
    'Agate',
    'Bennett',
    'Byers',
    'Castle Rock',
    'Deer Trail',
    'Elbert',
    'Elizabeth',
    'Englewood',
    'Franktown',
    'Kiowa',
    'Larkspur',
    'Littleton',
    'Lone Tree',
    'Louviers',
    'Monument',
    'Palmer Lake',
    'Parker',
    'Sedalia',
    'Strasburg',
    'Watkins',
    'Denver',
    'Thornton',
    'Boulder',
    'Golden',
    'Alma',
    'Bailey',
    'Black Hawk',
    'Bond',
    'Breckenridge',
    'Buffalo Creek',
    'Burns',
    'Central City',
    'Clark',
    'Climax',
    'Coalmont',
    'Como',
    'Conifer',
    'Cowdrey',
    'Dillon',
    'Dumont',
    'Evergreen',
    'Empire',
    'Fairplay',
    'Fraser',
    'Frisco',
    'Georgetown',
    'Granby',
    'Grand Lake',
    'Grant',
    'Hartsel',
    'Hot Sulphur Springs',
    'Idaho Springs',
    'Idledale',
    'Indian Hills',
    'Jamestown',
    'Jefferson',
    'Kittredge',
    'Kremmling',
    'Leadville',
    'Mc Coy',
    'Morrison',
    'Nederland',
    'Oak Creek',
    'Parshall',
    'Phippsburg',
    'Pine',
    'Pinecliffe',
    'Rand',
    'Rollinsville',
    'Shawnee',
    'Silver Plume',
    'Steamboat Springs',
    'Tabernash',
    'Toponas',
    'Walden',
    'Ward',
    'Winter Park',
    'Yampa',
    'Silverthorne',
    'Longmont',
    'Allenspark',
    'Estes Park',
    'Bellvue',
    'Berthoud',
    'Dacono',
    'Drake',
    'Erie',
    'Firestone',
    'Fort Collins',
    'Frederick',
    'Glen Haven',
    'Hygiene',
    'Johnstown',
    'Laporte',
    'Livermore',
    'Loveland',
    'Lyons',
    'Masonville',
    'Mead',
    'Milliken',
    'Niwot',
    'Red Feather Lakes',
    'Severance',
    'Timnath',
    'Wellington',
    'Windsor',
    'Brighton',
    'Ault',
    'Briggsdale',
    'Carr',
    'Eastlake',
    'Eaton',
    'Evans',
    'Fort Lupton',
    'Galeton',
    'Gilcrest',
    'Gill',
    'Greeley',
    'Henderson',
    'Hudson',
    'Keenesburg',
    'Kersey',
    'La Salle',
    'Lucerne',
    'Nunn',
    'Orchard',
    'Pierce',
    'Platteville',
    'Roggen',
    'Weldona',
    'Wiggins',
    'Fort Morgan',
    'Log Lane Village',
    'Akron',
    'Amherst',
    'Atwood',
    'Brush',
    'Crook',
    'Eckley',
    'Fleming',
    'Grover',
    'Haxtun',
    'Hereford',
    'Hillrose',
    'Holyoke',
    'Idalia',
    'Iliff',
    'Julesburg',
    'Lindon',
    'Merino',
    'New Raymer',
    'Otis',
    'Ovid',
    'Padroni',
    'Paoli',
    'Peetz',
    'Sedgwick',
    'Snyder',
    'Sterling',
    'Stoneham',
    'Vernon',
    'Woodrow',
    'Wray',
    'Yuma',
    'Anton',
    'Arapahoe',
    'Arriba',
    'Bethune',
    'Burlington',
    'Calhan',
    'Cascade',
    'Cheyenne Wells',
    'Cope',
    'Cripple Creek',
    'Divide',
    'Flagler',
    'Florissant',
    'Fountain',
    'Genoa',
    'Green Mountain Falls',
    'Guffey',
    'Hugo',
    'Joes',
    'Karval',
    'Kirk',
    'Kit Carson',
    'Limon',
    'Lake George',
    'Manitou Springs',
    'Matheson',
    'Peyton',
    'Ramah',
    'Rush',
    'Seibert',
    'Simla',
    'Stratton',
    'Usaf Academy',
    'Victor',
    'Vona',
    'Wild Horse',
    'Woodland Park',
    'Yoder',
    'Colorado Springs',
    'Pueblo',
    'Colorado City',
    'Aguilar',
    'Arlington',
    'Avondale',
    'Beulah',
    'Boncarbo',
    'Boone',
    'Branson',
    'Campo',
    'Cheraw',
    'Crowley',
    'Ordway',
    'Eads',
    'Fort Lyon',
    'Fowler',
    'Gardner',
    'Granada',
    'Hartman',
    'Hasty',
    'Haswell',
    'Hoehne',
    'Holly',
    'Kim',
    'La Junta',
    'Lamar',
    'Las Animas',
    'La Veta',
    'Mc Clave',
    'Manzanola',
    'Model',
    'Olney Springs',
    'Pritchett',
    'Rocky Ford',
    'Rye',
    'Sheridan Lake',
    'Springfield',
    'Stonington',
    'Sugar City',
    'Swink',
    'Trinchera',
    'Trinidad',
    'Two Buttes',
    'Vilas',
    'Walsenburg',
    'Walsh',
    'Weston',
    'Wiley',
    'Alamosa',
    'Antonito',
    'Arboles',
    'Bayfield',
    'Blanca',
    'Capulin',
    'Center',
    'Chama',
    'Chimney Rock',
    'Chromo',
    'Conejos',
    'Creede',
    'Crestone',
    'Del Norte',
    'Fort Garland',
    'San Luis',
    'Homelake',
    'Hooper',
    'Ignacio',
    'Jaroso',
    'La Jara',
    'Manassa',
    'Moffat',
    'Monte Vista',
    'Mosca',
    'Pagosa Springs',
    'Romeo',
    'Saguache',
    'Sanford',
    'San Pablo',
    'South Fork',
    'Villa Grove',
    'Salida',
    'Almont',
    'Buena Vista',
    'Canon City',
    'Cimarron',
    'Coal Creek',
    'Coaldale',
    'Cotopaxi',
    'Crested Butte',
    'Florence',
    'Monarch',
    'Granite',
    'Gunnison',
    'Hillside',
    'Howard',
    'Lake City',
    'Nathrop',
    'Ohio City',
    'Parlin',
    'Penrose',
    'Pitkin',
    'Poncha Springs',
    'Powderhorn',
    'Rockvale',
    'Sargents',
    'Twin Lakes',
    'Westcliffe',
    'Wetmore',
    'Durango',
    'Cahone',
    'Cortez',
    'Dolores',
    'Dove Creek',
    'Egnar',
    'Hesperus',
    'Lewis',
    'Mancos',
    'Marvel',
    'Mesa Verde National Park',
    'Pleasant View',
    'Rico',
    'Towaoc',
    'Yellow Jacket',
    'Montrose',
    'Austin',
    'Bedrock',
    'Cedaredge',
    'Cory',
    'Crawford',
    'Delta',
    'Eckert',
    'Hotchkiss',
    'Lazear',
    'Naturita',
    'Norwood',
    'Nucla',
    'Olathe',
    'Ophir',
    'Ouray',
    'Paonia',
    'Paradox',
    'Placerville',
    'Redvale',
    'Ridgway',
    'Silverton',
    'Somerset',
    'Telluride',
    'Grand Junction',
    'Clifton',
    'Fruita',
    'Gateway',
    'Glade Park',
    'Loma',
    'Mack',
    'Palisade',
    'Whitewater',
    'Glenwood Springs',
    'Dinosaur',
    'Aspen',
    'Snowmass Village',
    'Avon',
    'Basalt',
    'Carbondale',
    'Collbran',
    'Craig',
    'De Beque',
    'Eagle',
    'Edwards',
    'Parachute',
    'Battlement Mesa',
    'Gypsum',
    'Hamilton',
    'Hayden',
    'Maybell',
    'Meeker',
    'Meredith',
    'Mesa',
    'Minturn',
    'Molina',
    'New Castle',
    'Rangely',
    'Red Cliff',
    'Rifle',
    'Silt',
    'Slater',
    'Snowmass',
    'Wolcott',
    'Woody Creek',
    'Vail'
  ],
  WY: [
    'Cheyenne',
    'Fe Warren AFB',
    'Albin',
    'Bosler',
    'Buford',
    'Burns',
    'Carpenter',
    'Centennial',
    'Garrett',
    'Granite Canon',
    'Hillsdale',
    'Horse Creek',
    'Jelm',
    'Laramie',
    'Meriden',
    'Pine Bluffs',
    'Rock River',
    'Tie Siding',
    'Yellowstone National Park',
    'Wheatland',
    'Chugwater',
    'Fort Laramie',
    'Glendo',
    'Guernsey',
    'Hartville',
    'Hawk Springs',
    'Huntley',
    'Jay Em',
    'Lagrange',
    'Lance Creek',
    'Lingle',
    'Lost Springs',
    'Lusk',
    'Manville',
    'Shawnee',
    'Torrington',
    'Van Tassell',
    'Veteran',
    'Yoder',
    'Rawlins',
    'Jeffrey City',
    'Baggs',
    'Bairoil',
    'Dixon',
    'Elk Mountain',
    'Encampment',
    'Hanna',
    'Medicine Bow',
    'Saratoga',
    'Savery',
    'Sinclair',
    'Walcott',
    'Wamsutter',
    'Worland',
    'Basin',
    'Burlington',
    'Byron',
    'Cody',
    'Cowley',
    'Deaver',
    'Emblem',
    'Frannie',
    'Greybull',
    'Hyattville',
    'Kirby',
    'Lovell',
    'Manderson',
    'Meeteetse',
    'Otto',
    'Powell',
    'Ralston',
    'Shell',
    'Ten Sleep',
    'Thermopolis',
    'Wapiti',
    'Riverton',
    'Arapahoe',
    'Crowheart',
    'Dubois',
    'Fort Washakie',
    'Hudson',
    'Kinnear',
    'Lander',
    'Pavillion',
    'Saint Stephens',
    'Casper',
    'Shirley Basin',
    'Alcova',
    'Arminto',
    'Douglas',
    'Edgerton',
    'Evansville',
    'Glenrock',
    'Hiland',
    'Kaycee',
    'Linch',
    'Lysite',
    'Midwest',
    'Mills',
    'Natrona',
    'Powder River',
    'Shoshoni',
    'Newcastle',
    'Aladdin',
    'Alva',
    'Beulah',
    'Devils Tower',
    'Four Corners',
    'Gillette',
    'Hulett',
    'Moorcroft',
    'Osage',
    'Recluse',
    'Rozet',
    'Sundance',
    'Upton',
    'Weston',
    'Wright',
    'Sheridan',
    'Arvada',
    'Banner',
    'Big Horn',
    'Buffalo',
    'Clearmont',
    'Dayton',
    'Leiter',
    'Parkman',
    'Ranchester',
    'Saddlestring',
    'Story',
    'Wolf',
    'Wyarno',
    'Rock Springs',
    'Bondurant',
    'Boulder',
    'Cora',
    'Little America',
    'Evanston',
    'Farson',
    'Fort Bridger',
    'Granger',
    'Green River',
    'Lonetree',
    'Lyman',
    'Mc Kinnon',
    'Mountain View',
    'Pinedale',
    'Point Of Rocks',
    'Reliance',
    'Robertson',
    'Superior',
    'Jackson',
    'Kelly',
    'Moose',
    'Moran',
    'Wilson',
    'Teton Village',
    'Kemmerer',
    'Afton',
    'Auburn',
    'Bedford',
    'Big Piney',
    'Cokeville',
    'Daniel',
    'Diamondville',
    'Etna',
    'Fairview',
    'Freedom',
    'Frontier',
    'Grover',
    'La Barge',
    'Opal',
    'Smoot',
    'Thayne',
    'Alpine',
    'Alta'
  ],
  ID: [
    'Pocatello',
    'Fort Hall',
    'Aberdeen',
    'American Falls',
    'Arbon',
    'Arco',
    'Arimo',
    'Atomic City',
    'Bancroft',
    'Basalt',
    'Bern',
    'Blackfoot',
    'Bloomington',
    'Challis',
    'Clayton',
    'Clifton',
    'Cobalt',
    'Conda',
    'Dayton',
    'Dingle',
    'Downey',
    'Ellis',
    'Firth',
    'Franklin',
    'Geneva',
    'Georgetown',
    'Grace',
    'Holbrook',
    'Howe',
    'Inkom',
    'Lava Hot Springs',
    'Mccammon',
    'Mackay',
    'Malad City',
    'May',
    'Montpelier',
    'Moore',
    'Moreland',
    'Paris',
    'Pingree',
    'Preston',
    'Rockland',
    'Saint Charles',
    'Shelley',
    'Soda Springs',
    'Springfield',
    'Stanley',
    'Swanlake',
    'Thatcher',
    'Wayan',
    'Weston',
    'Fish Haven',
    'Twin Falls',
    'Rogerson',
    'Albion',
    'Almo',
    'Bellevue',
    'Bliss',
    'Buhl',
    'Burley',
    'Carey',
    'Castleford',
    'Corral',
    'Declo',
    'Dietrich',
    'Eden',
    'Fairfield',
    'Filer',
    'Gooding',
    'Hagerman',
    'Hailey',
    'Hansen',
    'Hazelton',
    'Heyburn',
    'Hill City',
    'Jerome',
    'Ketchum',
    'Kimberly',
    'Malta',
    'Minidoka',
    'Murtaugh',
    'Oakley',
    'Paul',
    'Picabo',
    'Richfield',
    'Rupert',
    'Shoshone',
    'Sun Valley',
    'Wendell',
    'Idaho Falls',
    'Ashton',
    'Chester',
    'Driggs',
    'Dubois',
    'Felt',
    'Hamer',
    'Iona',
    'Irwin',
    'Island Park',
    'Lewisville',
    'Macks Inn',
    'Menan',
    'Monteview',
    'Newdale',
    'Parker',
    'Rexburg',
    'Rigby',
    'Ririe',
    'Roberts',
    'Saint Anthony',
    'Spencer',
    'Sugar City',
    'Swan Valley',
    'Terreton',
    'Teton',
    'Tetonia',
    'Ucon',
    'Victor',
    'Carmen',
    'Gibbonsville',
    'Leadore',
    'Lemhi',
    'North Fork',
    'Salmon',
    'Tendoy',
    'Shoup',
    'Lewiston',
    'Ahsahka',
    'Cottonwood',
    'Craigmont',
    'Culdesac',
    'Elk City',
    'Ferdinand',
    'Grangeville',
    'Fenn',
    'Greencreek',
    'Juliaetta',
    'Kamiah',
    'Kendrick',
    'Kooskia',
    'Lapwai',
    'Lenore',
    'Lucile',
    'Nezperce',
    'Orofino',
    'Peck',
    'Pierce',
    'Pollock',
    'Reubens',
    'Riggins',
    'Stites',
    'Weippe',
    'White Bird',
    'Winchester',
    'Atlanta',
    'Banks',
    'Bruneau',
    'Caldwell',
    'Cambridge',
    'Cascade',
    'Council',
    'Donnelly',
    'Eagle',
    'Emmett',
    'Fruitland',
    'Garden Valley',
    'Glenns Ferry',
    'Grand View',
    'Greenleaf',
    'Hammett',
    'Homedale',
    'Horseshoe Bend',
    'Huston',
    'Idaho City',
    'Indian Valley',
    'King Hill',
    'Kuna',
    'Lake Fork',
    'Letha',
    'Lowman',
    'Mccall',
    'Marsing',
    'Melba',
    'Meridian',
    'Mesa',
    'Middleton',
    'Midvale',
    'Mountain Home',
    'Mountain Home AFB',
    'Murphy',
    'Nampa',
    'New Meadows',
    'New Plymouth',
    'Notus',
    'Ola',
    'Parma',
    'Payette',
    'Placerville',
    'Star',
    'Sweet',
    'Warren',
    'Weiser',
    'Wilder',
    'Yellow Pine',
    'Boise',
    'Garden City',
    'Athol',
    'Avery',
    'Bayview',
    'Blanchard',
    'Bonners Ferry',
    'Bovill',
    'Calder',
    'Careywood',
    'Cataldo',
    'Clark Fork',
    'Clarkia',
    'Cocolalla',
    'Coeur D Alene',
    'Coolin',
    'Oldtown',
    'Deary',
    'Desmet',
    'Dover',
    'Eastport',
    'Elk River',
    'Fernwood',
    'Genesee',
    'Harrison',
    'Harvard',
    'Hayden',
    'Hope',
    'Kellogg',
    'Kingston',
    'Kootenai',
    'Laclede',
    'Medimont',
    'Moscow',
    'Moyie Springs',
    'Mullan',
    'Naples',
    'Nordman',
    'Osburn',
    'Pinehurst',
    'Plummer',
    'Ponderay',
    'Porthill',
    'Post Falls',
    'Potlatch',
    'Priest River',
    'Princeton',
    'Rathdrum',
    'Sagle',
    'Saint Maries',
    'Sandpoint',
    'Colburn',
    'Santa',
    'Silverton',
    'Smelterville',
    'Spirit Lake',
    'Tensed',
    'Troy',
    'Viola',
    'Wallace',
    'Murray',
    'Worley'
  ],
  UT: [
    'Altamont',
    'Altonah',
    'American Fork',
    'Alpine',
    'Eagle Mountain',
    'Bingham Canyon',
    'Bluebell',
    'Bonanza',
    'South Jordan',
    'Bountiful',
    'Cedar Valley',
    'Centerville',
    'Clearfield',
    'Coalville',
    'Croydon',
    'Draper',
    'Duchesne',
    'Dugway',
    'Dutch John',
    'Echo',
    'Farmington',
    'Fort Duchesne',
    'Fruitland',
    'Garden City',
    'Grantsville',
    'Hanna',
    'Heber City',
    'Henefer',
    'Ibapah',
    'Jensen',
    'Kamas',
    'Kaysville',
    'Laketown',
    'Lapoint',
    'Layton',
    'Lindon',
    'Lehi',
    'Magna',
    'Saratoga Springs',
    'Manila',
    'Midvale',
    'Midway',
    'Morgan',
    'Mountain Home',
    'Myton',
    'Neola',
    'North Salt Lake',
    'Oakley',
    'Hill AFB',
    'Orem',
    'Vineyard',
    'Park City',
    'Peoa',
    'Pleasant Grove',
    'Randlett',
    'Randolph',
    'Riverton',
    'Roosevelt',
    'Roy',
    'Rush Valley',
    'Sandy',
    'Stockton',
    'Tabiona',
    'Talmage',
    'Tooele',
    'Syracuse',
    'Tridell',
    'Vernal',
    'Vernon',
    'West Jordan',
    'Wallsburg',
    'Wendover',
    'Whiterocks',
    'Woodruff',
    'Woods Cross',
    'Herriman',
    'Salt Lake City',
    'West Valley City',
    'Ogden',
    'Bear River City',
    'Brigham City',
    'Cache Junction',
    'Clarkston',
    'Collinston',
    'Corinne',
    'Cornish',
    'Deweyville',
    'Eden',
    'Fielding',
    'Garland',
    'Grouse Creek',
    'Honeyville',
    'Hooper',
    'Howell',
    'Huntsville',
    'Hyde Park',
    'Hyrum',
    'Lewiston',
    'Logan',
    'Mantua',
    'Mendon',
    'Millville',
    'Newton',
    'Paradise',
    'Park Valley',
    'Plymouth',
    'Portage',
    'Providence',
    'Richmond',
    'Riverside',
    'Smithfield',
    'Snowville',
    'Tremonton',
    'Trenton',
    'Wellsville',
    'Willard',
    'Price',
    'Aneth',
    'Blanding',
    'Bluff',
    'Castle Dale',
    'Cisco',
    'Clawson',
    'Cleveland',
    'East Carbon',
    'Elmo',
    'Emery',
    'Ferron',
    'Green River',
    'Helper',
    'Huntington',
    'Kenilworth',
    'La Sal',
    'Mexican Hat',
    'Moab',
    'Lake Powell',
    'Montezuma Creek',
    'Monticello',
    'Monument Valley',
    'Orangeville',
    'Sunnyside',
    'Thompson',
    'Wellington',
    'Provo',
    'Aurora',
    'Axtell',
    'Centerfield',
    'Chester',
    'Delta',
    'Elberta',
    'Ephraim',
    'Eureka',
    'Fairview',
    'Fayette',
    'Fillmore',
    'Fountain Green',
    'Goshen',
    'Gunnison',
    'Hinckley',
    'Holden',
    'Kanosh',
    'Leamington',
    'Levan',
    'Lynndyl',
    'Manti',
    'Mayfield',
    'Meadow',
    'Mona',
    'Moroni',
    'Mount Pleasant',
    'Nephi',
    'Oak City',
    'Payson',
    'Redmond',
    'Salem',
    'Salina',
    'Santaquin',
    'Scipio',
    'Sigurd',
    'Spanish Fork',
    'Spring City',
    'Springville',
    'Mapleton',
    'Sterling',
    'Wales',
    'Richfield',
    'Alton',
    'Annabella',
    'Antimony',
    'Beaver',
    'Beryl',
    'Bicknell',
    'Boulder',
    'Bryce Canyon',
    'Cannonville',
    'Brian Head',
    'Cedar City',
    'Central',
    'Circleville',
    'Elsinore',
    'Enterprise',
    'Escalante',
    'Garrison',
    'Glendale',
    'Glenwood',
    'Greenville',
    'Greenwich',
    'Gunlock',
    'Hanksville',
    'Hatch',
    'Henrieville',
    'Hurricane',
    'Ivins',
    'Joseph',
    'Junction',
    'Kanab',
    'Kanarraville',
    'Kingston',
    'Koosharem',
    'La Verkin',
    'Leeds',
    'Loa',
    'Lyman',
    'Marysvale',
    'Milford',
    'Minersville',
    'Modena',
    'Monroe',
    'Mount Carmel',
    'Newcastle',
    'New Harmony',
    'Orderville',
    'Panguitch',
    'Paragonah',
    'Parowan',
    'Duck Creek Village',
    'Rockville',
    'Bryce',
    'Santa Clara',
    'Sevier',
    'Springdale',
    'Saint George',
    'Summit',
    'Teasdale',
    'Toquerville',
    'Torrey',
    'Tropic',
    'Virgin',
    'Washington',
    'Pine Valley',
    'Veyo',
    'Dammeron Valley',
    'Hildale'
  ],
  AZ: [
    'Phoenix',
    'New River',
    'Apache Junction',
    'Gold Canyon',
    'Bapchule',
    'Casa Grande',
    'Arizona City',
    'Chandler Heights',
    'Coolidge',
    'Eloy',
    'Florence',
    'Hayden',
    'Kearny',
    'Maricopa',
    'San Tan Valley',
    'Picacho',
    'Queen Creek',
    'Red Rock',
    'Sacaton',
    'Stanfield',
    'Superior',
    'Tortilla Flat',
    'Valley Farms',
    'Winkelman',
    'Mesa',
    'Chandler',
    'Gilbert',
    'Higley',
    'Scottsdale',
    'Paradise Valley',
    'Rio Verde',
    'Fort Mcdowell',
    'Fountain Hills',
    'Tempe',
    'Glendale',
    'Luke Air Force Base',
    'Aguila',
    'Ajo',
    'Arlington',
    'Avondale',
    'Black Canyon City',
    'Bouse',
    'Buckeye',
    'Cave Creek',
    'Cibola',
    'Cashion',
    'Congress',
    'Dateland',
    'Ehrenberg',
    'El Mirage',
    'San Luis',
    'Gila Bend',
    'Goodyear',
    'Laveen',
    'Litchfield Park',
    'Lukeville',
    'Morristown',
    'Palo Verde',
    'Parker',
    'Peoria',
    'Quartzsite',
    'Roll',
    'Salome',
    'Somerton',
    'Sun City',
    'Tacna',
    'Tolleson',
    'Tonopah',
    'Waddell',
    'Wellton',
    'Wenden',
    'Wickenburg',
    'Wikieup',
    'Wittmann',
    'Yarnell',
    'Youngtown',
    'Yuma',
    'Poston',
    'Surprise',
    'Sun City West',
    'Carefree',
    'Globe',
    'Bylas',
    'Central',
    'Claypool',
    'Clifton',
    'Duncan',
    'Eden',
    'Fort Thomas',
    'Miami',
    'Morenci',
    'Payson',
    'Peridot',
    'Pima',
    'Pine',
    'Roosevelt',
    'Safford',
    'San Carlos',
    'Solomon',
    'Thatcher',
    'Tonto Basin',
    'Young',
    'Arivaca',
    'Benson',
    'Bisbee',
    'Bowie',
    'Cochise',
    'Douglas',
    'Dragoon',
    'Elfrida',
    'Elgin',
    'Fort Huachuca',
    'Green Valley',
    'Hereford',
    'Huachuca City',
    'Mc Neal',
    'Mammoth',
    'Mount Lemmon',
    'Naco',
    'Nogales',
    'Oracle',
    'Patagonia',
    'Pearce',
    'Pirtleville',
    'Pomerene',
    'Sahuarita',
    'Saint David',
    'San Manuel',
    'San Simon',
    'Sasabe',
    'Sells',
    'Sierra Vista',
    'Sonoita',
    'Tombstone',
    'Topawa',
    'Tumacacori',
    'Vail',
    'Willcox',
    'Amado',
    'Tubac',
    'Rio Rico',
    'Cortaro',
    'Marana',
    'Rillito',
    'Tucson',
    'Catalina',
    'Show Low',
    'Cibecue',
    'White Mountain Lake',
    'Alpine',
    'Blue',
    'Clay Springs',
    'Concho',
    'Eagar',
    'Fort Apache',
    'Greer',
    'Heber',
    'Lakeside',
    'Mcnary',
    'Forest Lakes',
    'Nutrioso',
    'Overgaard',
    'Pinedale',
    'Pinetop',
    'Saint Johns',
    'Snowflake',
    'Springerville',
    'Taylor',
    'Vernon',
    'Whiteriver',
    'Woodruff',
    'Flagstaff',
    'Bellemont',
    'Gray Mountain',
    'Munds Park',
    'Parks',
    'Cameron',
    'Colorado City',
    'Fredonia',
    'Grand Canyon',
    'Happy Jack',
    'Holbrook',
    'Petrified Forest Natl Pk',
    'Sun Valley',
    'Hotevilla',
    'Indian Wells',
    'Joseph City',
    'Kayenta',
    'Keams Canyon',
    'Leupp',
    'Marble Canyon',
    'Mormon Lake',
    'Kykotsmovi Village',
    'Page',
    'Polacca',
    'Second Mesa',
    'Tonalea',
    'Tuba City',
    'Williams',
    'Winslow',
    'North Rim',
    'Kaibeto',
    'Shonto',
    'Prescott',
    'Prescott Valley',
    'Ash Fork',
    'Bagdad',
    'Camp Verde',
    'Chino Valley',
    'Clarkdale',
    'Cornville',
    'Cottonwood',
    'Dewey',
    'Humboldt',
    'Jerome',
    'Kirkland',
    'Mayer',
    'Paulden',
    'Rimrock',
    'Sedona',
    'Seligman',
    'Skull Valley',
    'Lake Montezuma',
    'Crown King',
    'Kingman',
    'Lake Havasu City',
    'Hackberry',
    'Hualapai',
    'Golden Valley',
    'Fort Mohave',
    'Bullhead City',
    'Chloride',
    'Littlefield',
    'Oatman',
    'Peach Springs',
    'Supai',
    'Topock',
    'Valentine',
    'Yucca',
    'Mohave Valley',
    'Dolan Springs',
    'Temple Bar Marina',
    'Meadview',
    'Willow Beach',
    'Chambers',
    'Chinle',
    'Fort Defiance',
    'Ganado',
    'Houck',
    'Lukachukai',
    'Lupton',
    'Pinon',
    'Saint Michaels',
    'Sanders',
    'Teec Nos Pos',
    'Window Rock',
    'Blue Gap',
    'Dennehotso',
    'Many Farms',
    'Nazlini',
    'Red Valley',
    'Rock Point',
    'Round Rock',
    'Tsaile'
  ],
  NM: [
    'Algodones',
    'Belen',
    'Bernalillo',
    'Bluewater',
    'Bosque',
    'Casa Blanca',
    'Cedar Crest',
    'Cedarvale',
    'Cerrillos',
    'Claunch',
    'Coyote',
    'Cuba',
    'Cubero',
    'Edgewood',
    'Estancia',
    'Gallina',
    'Counselor',
    'Grants',
    'Milan',
    'Isleta',
    'Jarales',
    'Jemez Pueblo',
    'Jemez Springs',
    'Laguna',
    'La Jara',
    'La Joya',
    'Lindrith',
    'Los Lunas',
    'Mcintosh',
    'Pueblo Of Acoma',
    'Moriarty',
    'Mountainair',
    'Nageezi',
    'New Laguna',
    'Paguate',
    'Pena Blanca',
    'Peralta',
    'Placitas',
    'Ponderosa',
    'Prewitt',
    'Regina',
    'Sandia Park',
    'Corrales',
    'San Fidel',
    'San Rafael',
    'Santo Domingo Pueblo',
    'San Ysidro',
    'Stanley',
    'Tijeras',
    'Tome',
    'Torreon',
    'Veguita',
    'Willard',
    'Youngsville',
    'Bosque Farms',
    'Clines Corners',
    'Cochiti Pueblo',
    'Cochiti Lake',
    'Albuquerque',
    'Kirtland AFB',
    'Rio Rancho',
    'Gallup',
    'Brimhall',
    'Church Rock',
    'Continental Divide',
    'Crownpoint',
    'Fence Lake',
    'Fort Wingate',
    'Gamerco',
    'Mentmore',
    'Mexican Springs',
    'Ramah',
    'Rehoboth',
    'Thoreau',
    'Tohatchi',
    'Vanderwagen',
    'Zuni',
    'Navajo',
    'Jamestown',
    'Pinehill',
    'Sheep Springs',
    'Smith Lake',
    'Yatahey',
    'Farmington',
    'Aztec',
    'Blanco',
    'Bloomfield',
    'Flora Vista',
    'Fruitland',
    'Kirtland',
    'La Plata',
    'Navajo Dam',
    'Shiprock',
    'Waterflow',
    'Newcomb',
    'Sanostee',
    'Santa Fe',
    'Abiquiu',
    'Alcalde',
    'Amalia',
    'Arroyo Hondo',
    'Arroyo Seco',
    'Canjilon',
    'Canones',
    'Carson',
    'Cebolla',
    'Cerro',
    'Chama',
    'Chamisal',
    'Chimayo',
    'Cordova',
    'Costilla',
    'Taos Ski Valley',
    'Dixon',
    'Dulce',
    'El Prado',
    'El Rito',
    'Embudo',
    'Espanola',
    'Glorieta',
    'Hernandez',
    'Ilfeld',
    'La Madera',
    'Lamy',
    'Llano',
    'Los Alamos',
    'White Rock',
    'Medanales',
    'Ojo Caliente',
    'Los Ojos',
    'Pecos',
    'Penasco',
    'Petaca',
    'Questa',
    'Ranchos De Taos',
    'Red River',
    'Ribera',
    'Rowe',
    'San Cristobal',
    'San Jose',
    'Ohkay Owingeh',
    'Santa Cruz',
    'Serafina',
    'Taos',
    'Tererro',
    'Tesuque',
    'Tierra Amarilla',
    'Trampas',
    'Tres Piedras',
    'Truchas',
    'Vadito',
    'Valdez',
    'Vallecitos',
    'Velarde',
    'Villanueva',
    'Spaceport City',
    'Las Vegas',
    'Angel Fire',
    'Anton Chico',
    'Buena Vista',
    'Chacon',
    'Cimarron',
    'Cleveland',
    'Eagle Nest',
    'Guadalupita',
    'Holman',
    'La Loma',
    'Maxwell',
    'Miami',
    'Mills',
    'Montezuma',
    'Mora',
    'Mosquero',
    'Ocate',
    'Ojo Feliz',
    'Rainsville',
    'Raton',
    'Rociada',
    'Roy',
    'Sapello',
    'Solano',
    'Springer',
    'Ute Park',
    'Valmora',
    'Wagon Mound',
    'Watrous',
    'Socorro',
    'Aragon',
    'Datil',
    'Lemitar',
    'Luna',
    'Magdalena',
    'Pie Town',
    'Polvadera',
    'Quemado',
    'Reserve',
    'San Acacia',
    'San Antonio',
    'Truth Or Consequences',
    'Arrey',
    'Caballo',
    'Derry',
    'Elephant Butte',
    'Garfield',
    'Hatch',
    'Monticello',
    'Rincon',
    'Salem',
    'Williamsburg',
    'Winston',
    'Las Cruces',
    'White Sands Missile Range',
    'Santa Teresa',
    'Playas',
    'Animas',
    'Anthony',
    'Arenas Valley',
    'Bayard',
    'Berino',
    'Buckhorn',
    'Santa Clara',
    'Chamberino',
    'Cliff',
    'Columbus',
    'Deming',
    'Dona Ana',
    'Fairacres',
    'Faywood',
    'Fort Bayard',
    'Gila',
    'Glenwood',
    'Hachita',
    'Hanover',
    'Hillsboro',
    'Hurley',
    'La Mesa',
    'Lordsburg',
    'Mesilla',
    'Mesilla Park',
    'Mesquite',
    'Mimbres',
    'Mule Creek',
    'Organ',
    'Pinos Altos',
    'Radium Springs',
    'Redrock',
    'Rodeo',
    'San Miguel',
    'Silver City',
    'Sunland Park',
    'Tyrone',
    'Vado',
    'Chaparral',
    'Clovis',
    'Cannon AFB',
    'Broadview',
    'Causey',
    'Crossroads',
    'Dora',
    'Elida',
    'Floyd',
    'Fort Sumner',
    'Grady',
    'House',
    'Kenna',
    'Lingo',
    'Melrose',
    'Milnesand',
    'Pep',
    'Portales',
    'Rogers',
    'Saint Vrain',
    'Taiban',
    'Texico',
    'Yeso',
    'Roswell',
    'Artesia',
    'Caprock',
    'Carlsbad',
    'Dexter',
    'Eunice',
    'Hagerman',
    'Hobbs',
    'Hope',
    'Jal',
    'Lake Arthur',
    'Lakewood',
    'Loco Hills',
    'Loving',
    'Lovington',
    'Mcdonald',
    'Malaga',
    'Maljamar',
    'Monument',
    'Tatum',
    'Whites City',
    'Carrizozo',
    'Alamogordo',
    'Alto',
    'Bent',
    'Capitan',
    'Cloudcroft',
    'Corona',
    'Encino',
    'Fort Stanton',
    'Glencoe',
    'High Rolls Mountain Park',
    'Holloman Air Force Base',
    'Hondo',
    'La Luz',
    'Lincoln',
    'Mayhill',
    'Mescalero',
    'Nogal',
    'Orogrande',
    'Picacho',
    'Pinon',
    'Ruidoso',
    'Ruidoso Downs',
    'Sacramento',
    'San Patricio',
    'Sunspot',
    'Timberon',
    'Tinnie',
    'Tularosa',
    'Vaughn',
    'Weed',
    'Tucumcari',
    'Amistad',
    'Bard',
    'Capulin',
    'Clayton',
    'Conchas Dam',
    'Cuervo',
    'Des Moines',
    'Folsom',
    'Garita',
    'Gladstone',
    'Grenville',
    'Logan',
    'Mcalister',
    'Nara Visa',
    'Newkirk',
    'Quay',
    'San Jon',
    'Santa Rosa',
    'Sedan',
    'Trementina'
  ],
  NV: [
    'The Lakes',
    'Alamo',
    'Henderson',
    'Beatty',
    'Blue Diamond',
    'Boulder City',
    'Bunkerville',
    'Caliente',
    'Dyer',
    'Goldfield',
    'Hiko',
    'Indian Springs',
    'Jean',
    'Amargosa Valley',
    'Logandale',
    'Manhattan',
    'Mercury',
    'Mesquite',
    'Moapa',
    'Laughlin',
    'North Las Vegas',
    'Coyote Springs',
    'Cal Nev Ari',
    'Overton',
    'Pahrump',
    'Panaca',
    'Pioche',
    'Round Mountain',
    'Searchlight',
    'Silverpeak',
    'Tonopah',
    'Sloan',
    'Las Vegas',
    'Nellis AFB',
    'Ely',
    'Austin',
    'Baker',
    'Duckwater',
    'Eureka',
    'Lund',
    'Mc Gill',
    'Ruth',
    'Crystal Bay',
    'Dayton',
    'Denio',
    'Empire',
    'Fallon',
    'Fernley',
    'Gabbs',
    'Gardnerville',
    'Genoa',
    'Gerlach',
    'Glenbrook',
    'Golconda',
    'Hawthorne',
    'Imlay',
    'Lovelock',
    'Luning',
    'Mc Dermitt',
    'Mina',
    'Minden',
    'Nixon',
    'Orovada',
    'Paradise Valley',
    'Schurz',
    'Silver City',
    'Silver Springs',
    'Smith',
    'Sparks',
    'Sun Valley',
    'Valmy',
    'Verdi',
    'Virginia City',
    'Wadsworth',
    'Wellington',
    'Winnemucca',
    'Yerington',
    'Zephyr Cove',
    'Stateline',
    'Incline Village',
    'Reno',
    'Carson City',
    'Washoe Valley',
    'Elko',
    'Spring Creek',
    'Battle Mountain',
    'Crescent Valley',
    'Carlin',
    'Deeth',
    'Halleck',
    'Jackpot',
    'Jarbidge',
    'Lamoille',
    'Montello',
    'Mountain City',
    'Owyhee',
    'Ruby Valley',
    'Tuscarora',
    'Wells',
    'West Wendover'
  ],
  CA: [
    'Los Angeles',
    'West Hollywood',
    'Dodgertown',
    'Playa Vista',
    'Bell Gardens',
    'Bell',
    'Beverly Hills',
    'Compton',
    'Culver City',
    'Downey',
    'El Segundo',
    'Gardena',
    'Hawthorne',
    'Hermosa Beach',
    'Huntington Park',
    'Lawndale',
    'Lynwood',
    'Malibu',
    'Manhattan Beach',
    'Maywood',
    'Pacific Palisades',
    'Palos Verdes Peninsula',
    'Rancho Palos Verdes',
    'Redondo Beach',
    'South Gate',
    'Topanga',
    'Venice',
    'Marina Del Rey',
    'Playa Del Rey',
    'Inglewood',
    'Santa Monica',
    'Torrance',
    'Whittier',
    'Buena Park',
    'La Palma',
    'Cypress',
    'La Habra',
    'La Mirada',
    'Montebello',
    'Norwalk',
    'Pico Rivera',
    'Santa Fe Springs',
    'Stanton',
    'Artesia',
    'Cerritos',
    'Avalon',
    'Bellflower',
    'Harbor City',
    'Lakewood',
    'Hawaiian Gardens',
    'Lomita',
    'Los Alamitos',
    'Paramount',
    'San Pedro',
    'Seal Beach',
    'Sunset Beach',
    'Surfside',
    'Wilmington',
    'Carson',
    'Signal Hill',
    'Long Beach',
    'Altadena',
    'Arcadia',
    'Duarte',
    'La Canada Flintridge',
    'Monrovia',
    'Montrose',
    'Mount Wilson',
    'Sierra Madre',
    'South Pasadena',
    'Sunland',
    'Tujunga',
    'Verdugo City',
    'Pasadena',
    'San Marino',
    'Glendale',
    'La Crescenta',
    'Agoura Hills',
    'Calabasas',
    'Canoga Park',
    'Winnetka',
    'West Hills',
    'Castaic',
    'Chatsworth',
    'Encino',
    'Newbury Park',
    'Newhall',
    'Northridge',
    'Porter Ranch',
    'Pacoima',
    'Reseda',
    'San Fernando',
    'Sylmar',
    'North Hills',
    'Granada Hills',
    'Mission Hills',
    'Santa Clarita',
    'Canyon Country',
    'Sun Valley',
    'Valencia',
    'Tarzana',
    'Thousand Oaks',
    'Westlake Village',
    'Woodland Hills',
    'Oak Park',
    'Stevenson Ranch',
    'Van Nuys',
    'Panorama City',
    'Sherman Oaks',
    'Burbank',
    'North Hollywood',
    'Studio City',
    'Valley Village',
    'Universal City',
    'Toluca Lake',
    'Rancho Cucamonga',
    'Azusa',
    'Baldwin Park',
    'Chino',
    'Chino Hills',
    'Claremont',
    'City Of Industry',
    'Covina',
    'El Monte',
    'South El Monte',
    'Glendora',
    'Guasti',
    'La Puente',
    'Hacienda Heights',
    'Rowland Heights',
    'La Verne',
    'Mira Loma',
    'Monterey Park',
    'Ontario',
    'Mt Baldy',
    'Montclair',
    'Diamond Bar',
    'Pomona',
    'Rosemead',
    'San Dimas',
    'San Gabriel',
    'Temple City',
    'Upland',
    'Walnut',
    'West Covina',
    'Alhambra',
    'Alpine',
    'Bonita',
    'Boulevard',
    'Campo',
    'Chula Vista',
    'Descanso',
    'Dulzura',
    'Guatay',
    'Imperial Beach',
    'Jacumba',
    'Jamul',
    'La Mesa',
    'Lemon Grove',
    'Lincoln Acres',
    'Mount Laguna',
    'National City',
    'Pine Valley',
    'Potrero',
    'Spring Valley',
    'Tecate',
    'Bonsall',
    'Borrego Springs',
    'Cardiff By The Sea',
    'Carlsbad',
    'Del Mar',
    'El Cajon',
    'Encinitas',
    'Escondido',
    'Fallbrook',
    'Julian',
    'La Jolla',
    'Lakeside',
    'Oceanside',
    'Camp Pendleton',
    'Pala',
    'Palomar Mountain',
    'Pauma Valley',
    'Poway',
    'Ramona',
    'Ranchita',
    'Rancho Santa Fe',
    'San Luis Rey',
    'San Marcos',
    'Santa Ysabel',
    'Santee',
    'Solana Beach',
    'Vista',
    'Valley Center',
    'Warner Springs',
    'San Diego',
    'Coronado',
    'San Ysidro',
    'Indio',
    'Indian Wells',
    'Palm Desert',
    'Banning',
    'Bard',
    'Beaumont',
    'Blythe',
    'Brawley',
    'Cabazon',
    'Calexico',
    'Calipatria',
    'Cathedral City',
    'Coachella',
    'Desert Center',
    'Desert Hot Springs',
    'Earp',
    'El Centro',
    'La Quinta',
    'Heber',
    'Holtville',
    'Imperial',
    'Joshua Tree',
    'Mecca',
    'Morongo Valley',
    'Niland',
    'North Palm Springs',
    'Ocotillo',
    'Palm Springs',
    'Palo Verde',
    'Parker Dam',
    'Pioneertown',
    'Rancho Mirage',
    'Seeley',
    'Thermal',
    'Salton City',
    'Thousand Palms',
    'Twentynine Palms',
    'Vidal',
    'Westmorland',
    'Whitewater',
    'Winterhaven',
    'Yucca Valley',
    'Landers',
    'Adelanto',
    'Amboy',
    'Angelus Oaks',
    'Apple Valley',
    'Baker',
    'Fort Irwin',
    'Barstow',
    'Grand Terrace',
    'Big Bear City',
    'Big Bear Lake',
    'Bloomington',
    'Blue Jay',
    'Bryn Mawr',
    'Calimesa',
    'Cedar Glen',
    'Cedarpines Park',
    'Cima',
    'Colton',
    'Crestline',
    'Crest Park',
    'Daggett',
    'Death Valley',
    'Phelan',
    'Fontana',
    'Essex',
    'Fawnskin',
    'Ludlow',
    'Forest Falls',
    'Hesperia',
    'Green Valley Lake',
    'Helendale',
    'Highland',
    'Hinkley',
    'Loma Linda',
    'Lake Arrowhead',
    'Lucerne Valley',
    'Lytle Creek',
    'Mentone',
    'Needles',
    'Nipton',
    'Newberry Springs',
    'Mountain Pass',
    'Oro Grande',
    'Patton',
    'Pinon Hills',
    'Redlands',
    'Rialto',
    'Rimforest',
    'Running Springs',
    'Shoshone',
    'Skyforest',
    'Sugarloaf',
    'Tecopa',
    'Twin Peaks',
    'Victorville',
    'Wrightwood',
    'Yermo',
    'Yucaipa',
    'San Bernardino',
    'Riverside',
    'Jurupa Valley',
    'March Air Reserve Base',
    'Lake Elsinore',
    'Aguanga',
    'Anza',
    'Hemet',
    'Homeland',
    'Idyllwild',
    'Moreno Valley',
    'Mountain Center',
    'Murrieta',
    'Nuevo',
    'Perris',
    'San Jacinto',
    'Menifee',
    'Temecula',
    'Wildomar',
    'Winchester',
    'Irvine',
    'Huntington Beach',
    'Laguna Niguel',
    'El Toro',
    'Foothill Ranch',
    'Capistrano Beach',
    'Corona Del Mar',
    'Costa Mesa',
    'Dana Point',
    'Lake Forest',
    'Laguna Woods',
    'East Irvine',
    'Laguna Beach',
    'Laguna Hills',
    'Midway City',
    'Aliso Viejo',
    'Newport Coast',
    'Newport Beach',
    'San Clemente',
    'San Juan Capistrano',
    'Silverado',
    'Trabuco Canyon',
    'Westminster',
    'Rancho Santa Margarita',
    'Mission Viejo',
    'Ladera Ranch',
    'Santa Ana',
    'Fountain Valley',
    'Tustin',
    'Anaheim',
    'Atwood',
    'Brea',
    'Fullerton',
    'Garden Grove',
    'Orange',
    'Norco',
    'Villa Park',
    'Placentia',
    'Corona',
    'Yorba Linda',
    'Ventura',
    'Camarillo',
    'Carpinteria',
    'Fillmore',
    'Moorpark',
    'Oak View',
    'Ojai',
    'Oxnard',
    'Piru',
    'Port Hueneme',
    'Point Mugu Nawc',
    'Port Hueneme Cbc Base',
    'Santa Paula',
    'Simi Valley',
    'Brandeis',
    'Somis',
    'Summerland',
    'Santa Barbara',
    'Goleta',
    'Alpaugh',
    'Armona',
    'Arvin',
    'Avenal',
    'Bodfish',
    'Buttonwillow',
    'California Hot Springs',
    'Camp Nelson',
    'Coalinga',
    'Corcoran',
    'Delano',
    'Ducor',
    'Earlimart',
    'Edison',
    'Exeter',
    'Pine Mountain Club',
    'Farmersville',
    'Fellows',
    'Frazier Park',
    'Glennville',
    'Goshen',
    'Hanford',
    'Huron',
    'Ivanhoe',
    'Kaweah',
    'Kernville',
    'Kettleman City',
    'Lake Isabella',
    'Lamont',
    'Laton',
    'Lebec',
    'Lemon Cove',
    'Lemoore',
    'Lindsay',
    'Lost Hills',
    'Mc Farland',
    'Mc Kittrick',
    'Maricopa',
    'New Cuyama',
    'Onyx',
    'Pixley',
    'Porterville',
    'Posey',
    'Richgrove',
    'Sequoia National Park',
    'Shafter',
    'Springville',
    'Stratford',
    'Strathmore',
    'Taft',
    'Terra Bella',
    'Three Rivers',
    'Tipton',
    'Tulare',
    'Tupman',
    'Visalia',
    'Wasco',
    'Waukena',
    'Weldon',
    'Wofford Heights',
    'Woodlake',
    'Woody',
    'Bakersfield',
    'San Luis Obispo',
    'Los Osos',
    'Arroyo Grande',
    'Atascadero',
    'Avila Beach',
    'Bradley',
    'Buellton',
    'Cambria',
    'Casmalia',
    'Cayucos',
    'Creston',
    'Grover Beach',
    'Guadalupe',
    'Harmony',
    'Lompoc',
    'Los Alamos',
    'Los Olivos',
    'Morro Bay',
    'Nipomo',
    'Oceano',
    'Paso Robles',
    'Pismo Beach',
    'San Ardo',
    'San Miguel',
    'San Simeon',
    'Santa Margarita',
    'Santa Maria',
    'Santa Ynez',
    'Shandon',
    'Solvang',
    'Templeton',
    'Mojave',
    'California City',
    'Acton',
    'Benton',
    'Big Pine',
    'Bishop',
    'Boron',
    'Bridgeport',
    'Caliente',
    'Cantil',
    'Darwin',
    'Edwards',
    'Independence',
    'Inyokern',
    'Johannesburg',
    'June Lake',
    'Keeler',
    'Keene',
    'Lake Hughes',
    'Lancaster',
    'Lee Vining',
    'Little Lake',
    'Littlerock',
    'Llano',
    'Lone Pine',
    'Mammoth Lakes',
    'Olancha',
    'Palmdale',
    'Pearblossom',
    'Randsburg',
    'Ridgecrest',
    'Red Mountain',
    'Rosamond',
    'Tehachapi',
    'Trona',
    'Valyermo',
    'Ahwahnee',
    'Auberry',
    'Badger',
    'Bass Lake',
    'Big Creek',
    'Biola',
    'Burrel',
    'Cantua Creek',
    'Caruthers',
    'Chowchilla',
    'Clovis',
    'Coarsegold',
    'Cutler',
    'Del Rey',
    'Dinuba',
    'Dos Palos',
    'Dunlap',
    'Firebaugh',
    'Fish Camp',
    'Five Points',
    'Fowler',
    'Friant',
    'Helm',
    'Hume',
    'Kerman',
    'Kingsburg',
    'Kings Canyon National Pk',
    'Lakeshore',
    'Los Banos',
    'Madera',
    'Mendota',
    'Miramonte',
    'Mono Hot Springs',
    'North Fork',
    'Oakhurst',
    'O Neals',
    'Orange Cove',
    'Orosi',
    'Parlier',
    'Piedra',
    'Fresno',
    'Prather',
    'Raisin City',
    'Raymond',
    'Reedley',
    'Riverdale',
    'Sanger',
    'San Joaquin',
    'Santa Rita Park',
    'Selma',
    'Shaver Lake',
    'South Dos Palos',
    'Sultana',
    'Tollhouse',
    'Tranquillity',
    'Wishon',
    'Yettem',
    'Traver',
    'Squaw Valley',
    'Salinas',
    'Big Sur',
    'Carmel By The Sea',
    'Carmel',
    'Carmel Valley',
    'Chualar',
    'Gonzales',
    'Greenfield',
    'Jolon',
    'King City',
    'Lockwood',
    'Marina',
    'Monterey',
    'Pacific Grove',
    'Pebble Beach',
    'San Lucas',
    'Seaside',
    'Soledad',
    'Spreckels',
    'Belmont',
    'Brisbane',
    'Burlingame',
    'Daly City',
    'El Granada',
    'Half Moon Bay',
    'La Honda',
    'Loma Mar',
    'Los Altos',
    'Menlo Park',
    'Atherton',
    'Portola Valley',
    'Millbrae',
    'Mountain View',
    'Montara',
    'Moss Beach',
    'Pacifica',
    'Pescadero',
    'Redwood City',
    'San Bruno',
    'San Carlos',
    'San Gregorio',
    'South San Francisco',
    'Sunnyvale',
    'San Francisco',
    'Sacramento',
    'Palo Alto',
    'Stanford',
    'San Mateo',
    'Alameda',
    'American Canyon',
    'Discovery Bay',
    'Danville',
    'Alamo',
    'Angwin',
    'Antioch',
    'Benicia',
    'Bethel Island',
    'Birds Landing',
    'Brentwood',
    'Byron',
    'Calistoga',
    'Canyon',
    'Clayton',
    'Concord',
    'Pleasant Hill',
    'Crockett',
    'Diablo',
    'El Cerrito',
    'Fairfield',
    'Travis AFB',
    'Fremont',
    'Hayward',
    'Castro Valley',
    'Hercules',
    'Knightsen',
    'Lafayette',
    'Livermore',
    'Martinez',
    'Moraga',
    'Napa',
    'Newark',
    'Oakley',
    'Oakville',
    'Orinda',
    'Pinole',
    'Pittsburg',
    'Pleasanton',
    'Pope Valley',
    'Dublin',
    'Port Costa',
    'Rio Vista',
    'Rodeo',
    'Rutherford',
    'Saint Helena',
    'Deer Park',
    'San Leandro',
    'San Lorenzo',
    'San Ramon',
    'Suisun City',
    'Sunol',
    'Union City',
    'Vallejo',
    'Walnut Creek',
    'Yountville',
    'Oakland',
    'Emeryville',
    'Piedmont',
    'Berkeley',
    'Albany',
    'Richmond',
    'El Sobrante',
    'San Pablo',
    'San Rafael',
    'Greenbrae',
    'Kentfield',
    'Belvedere Tiburon',
    'Bodega',
    'Bodega Bay',
    'Bolinas',
    'Corte Madera',
    'Rohnert Park',
    'Dillon Beach',
    'Fairfax',
    'Cotati',
    'Forest Knolls',
    'Inverness',
    'Lagunitas',
    'Larkspur',
    'Marshall',
    'Mill Valley',
    'Novato',
    'Nicasio',
    'Olema',
    'Penngrove',
    'Petaluma',
    'Point Reyes Station',
    'Ross',
    'San Anselmo',
    'San Geronimo',
    'San Quentin',
    'Sausalito',
    'Stinson Beach',
    'Tomales',
    'Valley Ford',
    'Woodacre',
    'Aptos',
    'Alviso',
    'Aromas',
    'Ben Lomond',
    'Boulder Creek',
    'Brookdale',
    'Campbell',
    'Capitola',
    'Castroville',
    'Coyote',
    'Cupertino',
    'Davenport',
    'Felton',
    'Freedom',
    'Gilroy',
    'Hollister',
    'Holy City',
    'Los Gatos',
    'Milpitas',
    'Morgan Hill',
    'Moss Landing',
    'Mount Hermon',
    'New Almaden',
    'Paicines',
    'Redwood Estates',
    'San Juan Bautista',
    'San Martin',
    'Santa Clara',
    'Santa Cruz',
    'Scotts Valley',
    'Saratoga',
    'Soquel',
    'Tres Pinos',
    'Watsonville',
    'San Jose',
    'Mount Hamilton',
    'Stockton',
    'Acampo',
    'Altaville',
    'Angels Camp',
    'Arnold',
    'Avery',
    'Burson',
    'Campo Seco',
    'Clements',
    'Copperopolis',
    'Douglas Flat',
    'Farmington',
    'French Camp',
    'Glencoe',
    'Hathaway Pines',
    'Holt',
    'Linden',
    'Lockeford',
    'Lodi',
    'Mokelumne Hill',
    'Mountain Ranch',
    'Murphys',
    'Rail Road Flat',
    'San Andreas',
    'Vallecito',
    'Valley Springs',
    'Victor',
    'Wallace',
    'West Point',
    'Wilseyville',
    'Woodbridge',
    'Atwater',
    'Ballico',
    'Tracy',
    'Big Oak Flat',
    'Catheys Valley',
    'Ceres',
    'Chinese Camp',
    'Columbia',
    'Coulterville',
    'Cressey',
    'Crows Landing',
    'Pinecrest',
    'Delhi',
    'Denair',
    'El Nido',
    'El Portal',
    'Empire',
    'Escalon',
    'Groveland',
    'Gustine',
    'Hickman',
    'Hilmar',
    'Hornitos',
    'Hughson',
    'Jamestown',
    'Keyes',
    'La Grange',
    'Lathrop',
    'Le Grand',
    'Livingston',
    'Long Barn',
    'Manteca',
    'Mariposa',
    'Merced',
    'Midpines',
    'Mi Wuk Village',
    'Moccasin',
    'Modesto',
    'Newman',
    'Oakdale',
    'Patterson',
    'Planada',
    'Ripon',
    'Riverbank',
    'Salida',
    'Snelling',
    'Sonora',
    'Soulsbyville',
    'Standard',
    'Stevinson',
    'Strawberry',
    'Tuolumne',
    'Turlock',
    'Twain Harte',
    'Vernalis',
    'Waterford',
    'Westley',
    'Winton',
    'Yosemite National Park',
    'Santa Rosa',
    'Albion',
    'Annapolis',
    'Boonville',
    'Boyes Hot Springs',
    'Branscomb',
    'Calpella',
    'Camp Meeker',
    'Caspar',
    'Cazadero',
    'Clearlake',
    'Clearlake Oaks',
    'Clearlake Park',
    'Cloverdale',
    'Cobb',
    'Comptche',
    'Covelo',
    'Dos Rios',
    'Duncans Mills',
    'Eldridge',
    'Elk',
    'El Verano',
    'Finley',
    'Forestville',
    'Fort Bragg',
    'Fulton',
    'Geyserville',
    'Glen Ellen',
    'Glenhaven',
    'Graton',
    'Gualala',
    'Guerneville',
    'Healdsburg',
    'Hopland',
    'Jenner',
    'Kelseyville',
    'Kenwood',
    'Lakeport',
    'Laytonville',
    'Little River',
    'Lower Lake',
    'Lucerne',
    'Manchester',
    'Mendocino',
    'Middletown',
    'Monte Rio',
    'Navarro',
    'Nice',
    'Occidental',
    'Philo',
    'Hidden Valley Lake',
    'Point Arena',
    'Potter Valley',
    'Redwood Valley',
    'Rio Nido',
    'Sebastopol',
    'Sonoma',
    'Stewarts Point',
    'Talmage',
    'Ukiah',
    'Upper Lake',
    'Villa Grande',
    'Vineburg',
    'Westport',
    'Willits',
    'Windsor',
    'Witter Springs',
    'Yorkville',
    'The Sea Ranch',
    'Eureka',
    'Alderpoint',
    'Blocksburg',
    'Arcata',
    'Mckinleyville',
    'Bayside',
    'Blue Lake',
    'Bridgeville',
    'Burnt Ranch',
    'Carlotta',
    'Crescent City',
    'Cutten',
    'Ferndale',
    'Fields Landing',
    'Fort Dick',
    'Fortuna',
    'Garberville',
    'Gasquet',
    'Honeydew',
    'Hoopa',
    'Hydesville',
    'Klamath',
    'Kneeland',
    'Korbel',
    'Loleta',
    'Mad River',
    'Miranda',
    'Myers Flat',
    'Orick',
    'Orleans',
    'Petrolia',
    'Phillipsville',
    'Redway',
    'Rio Dell',
    'Salyer',
    'Samoa',
    'Scotia',
    'Smith River',
    'Somes Bar',
    'Redcrest',
    'Trinidad',
    'Weott',
    'Willow Creek',
    'Leggett',
    'Piercy',
    'Whitethorn',
    'Zenia',
    'Amador City',
    'Auburn',
    'West Sacramento',
    'Brooks',
    'Capay',
    'Carmichael',
    'Citrus Heights',
    'Clarksburg',
    'Coloma',
    'Cool',
    'Courtland',
    'Davis',
    'Diamond Springs',
    'Dixon',
    'El Dorado',
    'Elk Grove',
    'Elmira',
    'Elverta',
    'Esparto',
    'Fair Oaks',
    'Fiddletown',
    'Folsom',
    'Foresthill',
    'Galt',
    'Garden Valley',
    'Georgetown',
    'Greenwood',
    'Grizzly Flats',
    'Guinda',
    'Herald',
    'Hood',
    'Ione',
    'Isleton',
    'Jackson',
    'Kit Carson',
    'Knights Landing',
    'Kirkwood',
    'Lincoln',
    'Loomis',
    'Lotus',
    'Mcclellan',
    'Madison',
    'Martell',
    'Mather',
    'Mount Aukum',
    'Newcastle',
    'Nicolaus',
    'North Highlands',
    'Roseville',
    'Orangevale',
    'Penryn',
    'Pilot Hill',
    'Pine Grove',
    'Pioneer',
    'Placerville',
    'Pleasant Grove',
    'Plymouth',
    'Rancho Cordova',
    'Represa',
    'Rescue',
    'Rio Linda',
    'Rio Oso',
    'River Pines',
    'Robbins',
    'Rocklin',
    'Rumsey',
    'Ryde',
    'Sheridan',
    'Shingle Springs',
    'Sloughhouse',
    'Somerset',
    'Sutter Creek',
    'Thornton',
    'Vacaville',
    'Volcano',
    'Walnut Grove',
    'Wheatland',
    'Wilton',
    'Winters',
    'Woodland',
    'Yolo',
    'Zamora',
    'Drytown',
    'Alta',
    'Applegate',
    'Camino',
    'Chicago Park',
    'Colfax',
    'Dutch Flat',
    'Emigrant Gap',
    'Gold Run',
    'Kyburz',
    'Echo Lake',
    'Meadow Vista',
    'Norden',
    'Pollock Pines',
    'Soda Springs',
    'Twin Bridges',
    'Weimar',
    'Granite Bay',
    'El Dorado Hills',
    'Antelope',
    'Marysville',
    'Beale AFB',
    'Alleghany',
    'Arbuckle',
    'Artois',
    'Bangor',
    'Belden',
    'Berry Creek',
    'Biggs',
    'Browns Valley',
    'Brownsville',
    'Butte City',
    'Camptonville',
    'Canyon Dam',
    'Cedar Ridge',
    'Challenge',
    'Chico',
    'Clipper Mills',
    'Colusa',
    'Crescent Mills',
    'Dobbins',
    'Downieville',
    'Dunnigan',
    'Durham',
    'Elk Creek',
    'Feather Falls',
    'Forbestown',
    'Forest Ranch',
    'Glenn',
    'Goodyears Bar',
    'Grass Valley',
    'Penn Valley',
    'Greenville',
    'Gridley',
    'Grimes',
    'Hamilton City',
    'Live Oak',
    'Magalia',
    'Maxwell',
    'Meadow Valley',
    'Meridian',
    'Nelson',
    'Nevada City',
    'North San Juan',
    'Olivehurst',
    'Oregon House',
    'Orland',
    'Oroville',
    'Paradise',
    'Palermo',
    'Princeton',
    'Quincy',
    'Rackerby',
    'Richvale',
    'Rough And Ready',
    'Smartsville',
    'Stirling City',
    'Stonyford',
    'Storrie',
    'Strawberry Valley',
    'Sutter',
    'Taylorsville',
    'Twain',
    'Washington',
    'Williams',
    'Willows',
    'Yuba City',
    'Redding',
    'Adin',
    'Anderson',
    'Bella Vista',
    'Bieber',
    'Big Bar',
    'Big Bend',
    'Burney',
    'Callahan',
    'Canby',
    'Cassel',
    'Castella',
    'Shasta Lake',
    'Chester',
    'Corning',
    'Cottonwood',
    'Dorris',
    'Douglas City',
    'Dunsmuir',
    'Etna',
    'Fall River Mills',
    'Flournoy',
    'Forks Of Salmon',
    'Fort Jones',
    'French Gulch',
    'Gazelle',
    'Gerber',
    'Greenview',
    'Grenada',
    'Happy Camp',
    'Hat Creek',
    'Hayfork',
    'Hornbrook',
    'Hyampom',
    'Igo',
    'Junction City',
    'Klamath River',
    'Lakehead',
    'Lewiston',
    'Lookout',
    'Los Molinos',
    'Mcarthur',
    'Mccloud',
    'Macdoel',
    'Manton',
    'Mill Creek',
    'Millville',
    'Mineral',
    'Montague',
    'Montgomery Creek',
    'Mount Shasta',
    'Nubieber',
    'Oak Run',
    'Obrien',
    'Old Station',
    'Palo Cedro',
    'Paskenta',
    'Paynes Creek',
    'Platina',
    'Proberta',
    'Red Bluff',
    'Round Mountain',
    'Scott Bar',
    'Seiad Valley',
    'Shasta',
    'Shingletown',
    'Tehama',
    'Trinity Center',
    'Vina',
    'Weaverville',
    'Weed',
    'Whiskeytown',
    'Whitmore',
    'Yreka',
    'Alturas',
    'Blairsden Graeagle',
    'Cedarville',
    'Chilcoot',
    'Clio',
    'Coleville',
    'Davis Creek',
    'Doyle',
    'Eagleville',
    'Floriston',
    'Fort Bidwell',
    'Herlong',
    'Janesville',
    'Lake City',
    'Likely',
    'Litchfield',
    'Loyalton',
    'Madeline',
    'Markleeville',
    'Milford',
    'Portola',
    'Ravendale',
    'Calpine',
    'Sierra City',
    'Sierraville',
    'Susanville',
    'Standish',
    'Beckwourth',
    'Termo',
    'Topaz',
    'Tulelake',
    'Vinton',
    'Wendel',
    'Westwood',
    'Carnelian Bay',
    'Homewood',
    'Tahoma',
    'Kings Beach',
    'Tahoe City',
    'Olympic Valley',
    'Tahoe Vista',
    'South Lake Tahoe',
    'Truckee'
  ],
  AP: [
    'Apo',
    'Dpo',
    'Fpo'
  ],
  HI: [
    'Aiea',
    'Anahola',
    'Captain Cook',
    'Eleele',
    'Ewa Beach',
    'Kapolei',
    'Haiku',
    'Hakalau',
    'Haleiwa',
    'Hana',
    'Hanalei',
    'Hanamaulu',
    'Hanapepe',
    'Hauula',
    'Hawaii National Park',
    'Hawi',
    'Hilo',
    'Princeville',
    'Holualoa',
    'Honaunau',
    'Honokaa',
    'Honomu',
    'Hoolehua',
    'Kaaawa',
    'Kahuku',
    'Kahului',
    'Kailua',
    'Ocean View',
    'Waikoloa',
    'Keauhou',
    'Kailua Kona',
    'Kalaheo',
    'Kalaupapa',
    'Kamuela',
    'Kaneohe',
    'Kapaa',
    'Kaumakani',
    'Kaunakakai',
    'Keaau',
    'Kealakekua',
    'Kealia',
    'Kekaha',
    'Kihei',
    'Kilauea',
    'Kapaau',
    'Koloa',
    'Kualapuu',
    'Kunia',
    'Kurtistown',
    'Lahaina',
    'Laie',
    'Lanai City',
    'Laupahoehoe',
    'Lawai',
    'Lihue',
    'Makawao',
    'Makaweli',
    'Maunaloa',
    'Mountain View',
    'Naalehu',
    'Ninole',
    'Ookala',
    'Paauilo',
    'Pahala',
    'Pahoa',
    'Paia',
    'Papaaloa',
    'Papaikou',
    'Pearl City',
    'Pepeekeo',
    'Puunene',
    'Volcano',
    'Wahiawa',
    'Pukalani',
    'Mililani',
    'Kula',
    'Waialua',
    'Waianae',
    'Wailuku',
    'Waimanalo',
    'Waimea',
    'Waipahu',
    'Honolulu',
    'Jbphh',
    'Wheeler Army Airfield',
    'Schofield Barracks',
    'Fort Shafter',
    'Tripler Army Medical Center',
    'Camp H M Smith',
    'Mcbh Kaneohe Bay',
    'Wake Island'
  ],
  AS: [
    'Pago Pago'
  ],
  GU: [
    'Hagatna',
    'Dededo',
    'Barrigada',
    'Santa Rita',
    'Merizo',
    'Inarajan',
    'Agana Heights',
    'Mangilao',
    'Agat',
    'Yigo',
    'Tamuning'
  ],
  PW: [
    'Ngerulmud',
    'Koror'
  ],
  FM: [
    'Pohnpei',
    'Chuuk',
    'Yap',
    'Kosrae'
  ],
  MP: [
    'Saipan',
    'Rota',
    'Tinian'
  ],
  MH: [
    'Majuro',
    'Ebeye'
  ],
  OR: [
    'Antelope',
    'Aurora',
    'Beaverton',
    'Beavercreek',
    'Boring',
    'Bridal Veil',
    'Brightwood',
    'Canby',
    'Cascade Locks',
    'Clackamas',
    'Clatskanie',
    'Colton',
    'Columbia City',
    'Corbett',
    'Donald',
    'Dufur',
    'Eagle Creek',
    'Estacada',
    'Fairview',
    'Gervais',
    'Gladstone',
    'Government Camp',
    'Grass Valley',
    'Gresham',
    'Hood River',
    'Hubbard',
    'Kent',
    'Lake Oswego',
    'Marylhurst',
    'Maupin',
    'Molalla',
    'Moro',
    'Mosier',
    'Mount Hood Parkdale',
    'Mulino',
    'Odell',
    'Oregon City',
    'Rainier',
    'Rhododendron',
    'Rufus',
    'Saint Helens',
    'Warren',
    'Deer Island',
    'Sandy',
    'Scappoose',
    'Shaniko',
    'The Dalles',
    'Troutdale',
    'Tualatin',
    'Tygh Valley',
    'Vernonia',
    'Wasco',
    'Welches',
    'West Linn',
    'Wilsonville',
    'Woodburn',
    'Happy Valley',
    'Damascus',
    'Amity',
    'Arch Cape',
    'Astoria',
    'Banks',
    'Bay City',
    'Beaver',
    'Buxton',
    'Cannon Beach',
    'Carlton',
    'Cloverdale',
    'Cornelius',
    'Dayton',
    'Dundee',
    'Forest Grove',
    'Gales Creek',
    'Garibaldi',
    'Gaston',
    'Hammond',
    'Hebo',
    'Hillsboro',
    'Manning',
    'Lafayette',
    'Mcminnville',
    'Manzanita',
    'Nehalem',
    'Newberg',
    'North Plains',
    'Oceanside',
    'Pacific City',
    'Rockaway Beach',
    'Saint Paul',
    'Seaside',
    'Sherwood',
    'Tillamook',
    'Netarts',
    'Timber',
    'Tolovana Park',
    'Warrenton',
    'Wheeler',
    'Yamhill',
    'Neskowin',
    'Portland',
    'Salem',
    'Keizer',
    'Albany',
    'Alsea',
    'Aumsville',
    'Blodgett',
    'Brownsville',
    'Cascadia',
    'Corvallis',
    'Crabtree',
    'Crawfordsville',
    'Dallas',
    'Depoe Bay',
    'Detroit',
    'Eddyville',
    'Falls City',
    'Foster',
    'Gates',
    'Grand Ronde',
    'Halsey',
    'Idanha',
    'Independence',
    'Jefferson',
    'Lebanon',
    'Logsden',
    'Lyons',
    'Mill City',
    'Monmouth',
    'Mount Angel',
    'Neotsu',
    'Newport',
    'South Beach',
    'Lincoln City',
    'Otis',
    'Otter Rock',
    'Philomath',
    'Rickreall',
    'Rose Lodge',
    'Saint Benedict',
    'Scio',
    'Scotts Mills',
    'Seal Rock',
    'Shedd',
    'Sheridan',
    'Siletz',
    'Silverton',
    'Stayton',
    'Mehama',
    'Sublimity',
    'Sweet Home',
    'Gleneden Beach',
    'Tangent',
    'Tidewater',
    'Toledo',
    'Turner',
    'Waldport',
    'Willamina',
    'Eugene',
    'Agness',
    'Allegany',
    'Alvadore',
    'Azalea',
    'Bandon',
    'Blachly',
    'Blue River',
    'Broadbent',
    'Brookings',
    'Camas Valley',
    'Canyonville',
    'Cheshire',
    'Coos Bay',
    'Coquille',
    'Cottage Grove',
    'Crescent Lake',
    'Creswell',
    'Culp Creek',
    'Curtin',
    'Days Creek',
    'Deadwood',
    'Dexter',
    'Dillard',
    'Dorena',
    'Drain',
    'Elkton',
    'Elmira',
    'Fall Creek',
    'Florence',
    'Gardiner',
    'Glendale',
    'Glide',
    'Gold Beach',
    'Harrisburg',
    'Idleyld Park',
    'Junction City',
    'Lakeside',
    'Langlois',
    'Lorane',
    'Lowell',
    'Mapleton',
    'Marcola',
    'Pleasant Hill',
    'Monroe',
    'Myrtle Creek',
    'Myrtle Point',
    'North Bend',
    'Noti',
    'Oakland',
    'Oakridge',
    'Ophir',
    'Port Orford',
    'Powers',
    'Reedsport',
    'Riddle',
    'Roseburg',
    'Scottsburg',
    'Springfield',
    'Sixes',
    'Sutherlin',
    'Swisshome',
    'Tenmile',
    'Thurston',
    'Tiller',
    'Umpqua',
    'Veneta',
    'Vida',
    'Walterville',
    'Walton',
    'Wedderburn',
    'Westfir',
    'Westlake',
    'Wilbur',
    'Winchester',
    'Winston',
    'Wolf Creek',
    'Yachats',
    'Yoncalla',
    'Medford',
    'Central Point',
    'White City',
    'Ashland',
    'Butte Falls',
    'Cave Junction',
    'Eagle Point',
    'Gold Hill',
    'Grants Pass',
    'Jacksonville',
    'Kerby',
    'Merlin',
    'Murphy',
    'O Brien',
    'Phoenix',
    'Prospect',
    'Rogue River',
    'Selma',
    'Shady Cove',
    'Talent',
    'Trail',
    'Wilderville',
    'Williams',
    'Klamath Falls',
    'Crater Lake',
    'Adel',
    'Beatty',
    'Bly',
    'Bonanza',
    'Chiloquin',
    'Dairy',
    'Fort Klamath',
    'Keno',
    'Lakeview',
    'Malin',
    'Merrill',
    'Midland',
    'New Pine Creek',
    'Paisley',
    'Plush',
    'Silver Lake',
    'Sprague River',
    'Summer Lake',
    'Christmas Valley',
    'Bend',
    'Fields',
    'Ashwood',
    'Brothers',
    'Burns',
    'Princeton',
    'Diamond',
    'Camp Sherman',
    'Chemult',
    'Crane',
    'Crescent',
    'Culver',
    'Fort Rock',
    'Frenchglen',
    'Gilchrist',
    'Hines',
    'La Pine',
    'Madras',
    'Mitchell',
    'Paulina',
    'Post',
    'Powell Butte',
    'Prineville',
    'Redmond',
    'Riley',
    'Sisters',
    'Terrebonne',
    'Warm Springs',
    'Pendleton',
    'Adams',
    'Arlington',
    'Athena',
    'Baker City',
    'Bates',
    'Boardman',
    'Bridgeport',
    'Canyon City',
    'Condon',
    'Cove',
    'Dayville',
    'Echo',
    'Elgin',
    'Enterprise',
    'Fossil',
    'Haines',
    'Halfway',
    'Helix',
    'Heppner',
    'Hereford',
    'Hermiston',
    'Lexington',
    'Oxbow',
    'Imbler',
    'Imnaha',
    'Ione',
    'Irrigon',
    'John Day',
    'Joseph',
    'Kimberly',
    'La Grande',
    'Long Creek',
    'Lostine',
    'Meacham',
    'Mikkalo',
    'Milton Freewater',
    'Monument',
    'Mount Vernon',
    'North Powder',
    'Pilot Rock',
    'Prairie City',
    'Richland',
    'Seneca',
    'Spray',
    'Stanfield',
    'Summerville',
    'Sumpter',
    'Ukiah',
    'Umatilla',
    'Union',
    'Unity',
    'Wallowa',
    'Weston',
    'Adrian',
    'Arock',
    'Brogan',
    'Drewsey',
    'Durkee',
    'Harper',
    'Huntington',
    'Ironside',
    'Jamieson',
    'Jordan Valley',
    'Juntura',
    'Nyssa',
    'Ontario',
    'Riverside',
    'Vale',
    'Westfall'
  ],
  WA: [
    'Auburn',
    'Federal Way',
    'Bellevue',
    'Black Diamond',
    'Bothell',
    'Burton',
    'Carnation',
    'Duvall',
    'Edmonds',
    'Enumclaw',
    'Fall City',
    'Hobart',
    'Issaquah',
    'Kenmore',
    'Kent',
    'Kirkland',
    'Lynnwood',
    'Maple Valley',
    'Medina',
    'Mercer Island',
    'Mountlake Terrace',
    'North Bend',
    'Pacific',
    'Preston',
    'Ravensdale',
    'Redmond',
    'Redondo',
    'Renton',
    'Rollingbay',
    'Seahurst',
    'Snoqualmie',
    'Snoqualmie Pass',
    'Vashon',
    'Woodinville',
    'Sammamish',
    'Mill Creek',
    'Seattle',
    'Bainbridge Island',
    'Everett',
    'Acme',
    'Anacortes',
    'Blakely Island',
    'Arlington',
    'Baring',
    'Bellingham',
    'Blaine',
    'Bow',
    'Burlington',
    'Clearlake',
    'Clinton',
    'Concrete',
    'Conway',
    'Coupeville',
    'Custer',
    'Darrington',
    'Deer Harbor',
    'Deming',
    'Eastsound',
    'Everson',
    'Ferndale',
    'Freeland',
    'Friday Harbor',
    'Gold Bar',
    'Granite Falls',
    'Greenbank',
    'Hamilton',
    'Index',
    'La Conner',
    'Lake Stevens',
    'North Lakewood',
    'Langley',
    'Lopez Island',
    'Lummi Island',
    'Lyman',
    'Lynden',
    'Maple Falls',
    'Marblemount',
    'Marysville',
    'Monroe',
    'Mount Vernon',
    'Mukilteo',
    'Nooksack',
    'Oak Harbor',
    'Olga',
    'Orcas',
    'Point Roberts',
    'Camano Island',
    'Rockport',
    'Sedro Woolley',
    'Shaw Island',
    'Silvana',
    'Skykomish',
    'Snohomish',
    'Stanwood',
    'Startup',
    'Sultan',
    'Sumas',
    'Waldron',
    'Anderson Island',
    'Ashford',
    'Beaver',
    'Bremerton',
    'Silverdale',
    'Brinnon',
    'Buckley',
    'Burley',
    'Carbonado',
    'Carlsborg',
    'Chimacum',
    'Clallam Bay',
    'Dupont',
    'Eatonville',
    'Gig Harbor',
    'Elbe',
    'Forks',
    'Fox Island',
    'Glenoma',
    'Graham',
    'Port Hadlock',
    'Hansville',
    'Indianola',
    'Joyce',
    'Kapowsin',
    'Keyport',
    'Kingston',
    'La Grande',
    'Lakebay',
    'La Push',
    'Longbranch',
    'Sumner',
    'Manchester',
    'Milton',
    'Mineral',
    'Morton',
    'Neah Bay',
    'Nordland',
    'Olalla',
    'Orting',
    'Packwood',
    'Port Angeles',
    'Port Gamble',
    'Port Ludlow',
    'Port Orchard',
    'Port Townsend',
    'Poulsbo',
    'Puyallup',
    'Quilcene',
    'Randle',
    'Retsil',
    'Seabeck',
    'Sekiu',
    'Sequim',
    'South Colby',
    'South Prairie',
    'Southworth',
    'Spanaway',
    'Steilacoom',
    'Bonney Lake',
    'Suquamish',
    'Tracyton',
    'Vaughn',
    'Wauna',
    'Wilkeson',
    'Longmire',
    'Paradise Inn',
    'Tacoma',
    'Fife',
    'Camp Murray',
    'Mcchord AFB',
    'Lakewood',
    'University Place',
    'Olympia',
    'Lacey',
    'Tumwater',
    'Aberdeen',
    'Adna',
    'Allyn',
    'Amanda Park',
    'Bay Center',
    'Belfair',
    'Bucoda',
    'Centralia',
    'Chehalis',
    'Cinebar',
    'Copalis Beach',
    'Copalis Crossing',
    'Cosmopolis',
    'Curtis',
    'Doty',
    'East Olympia',
    'Elma',
    'Ethel',
    'Galvin',
    'Grapeview',
    'Grayland',
    'Hoodsport',
    'Hoquiam',
    'Humptulips',
    'Lebam',
    'Lilliwaup',
    'Littlerock',
    'Mccleary',
    'Mckenna',
    'Malone',
    'Matlock',
    'Menlo',
    'Moclips',
    'Montesano',
    'Mossyrock',
    'Napavine',
    'Neilton',
    'Oakville',
    'Ocean Shores',
    'Onalaska',
    'Pacific Beach',
    'Pe Ell',
    'Quinault',
    'Rainier',
    'Raymond',
    'Rochester',
    'Roy',
    'Ryderwood',
    'Salkum',
    'Satsop',
    'Shelton',
    'Silver Creek',
    'South Bend',
    'Taholah',
    'Tahuya',
    'Tenino',
    'Tokeland',
    'Toledo',
    'Union',
    'Vader',
    'Westport',
    'Winlock',
    'Yelm',
    'Amboy',
    'Appleton',
    'Ariel',
    'Battle Ground',
    'Bingen',
    'Brush Prairie',
    'Camas',
    'Carrolls',
    'Carson',
    'Castle Rock',
    'Cathlamet',
    'Centerville',
    'Chinook',
    'Cougar',
    'Dallesport',
    'Glenwood',
    'Goldendale',
    'Grays River',
    'Heisson',
    'Husum',
    'Ilwaco',
    'Kalama',
    'Kelso',
    'Klickitat',
    'La Center',
    'Long Beach',
    'Longview',
    'Lyle',
    'Nahcotta',
    'Naselle',
    'North Bonneville',
    'Ocean Park',
    'Oysterville',
    'Ridgefield',
    'Rosburg',
    'Seaview',
    'Silverlake',
    'Skamokawa',
    'Stevenson',
    'Toutle',
    'Trout Lake',
    'Underwood',
    'Vancouver',
    'Wahkiacus',
    'Washougal',
    'White Salmon',
    'Wishram',
    'Woodland',
    'Yacolt',
    'Wenatchee',
    'East Wenatchee',
    'Ardenvoir',
    'Brewster',
    'Bridgeport',
    'Carlton',
    'Cashmere',
    'Chelan',
    'Chelan Falls',
    'Conconully',
    'Dryden',
    'Entiat',
    'Ephrata',
    'George',
    'Leavenworth',
    'Loomis',
    'Malaga',
    'Malott',
    'Mansfield',
    'Manson',
    'Marlin',
    'Mazama',
    'Methow',
    'Monitor',
    'Moses Lake',
    'Okanogan',
    'Omak',
    'Orondo',
    'Oroville',
    'Palisades',
    'Pateros',
    'Peshastin',
    'Quincy',
    'Riverside',
    'Rock Island',
    'Soap Lake',
    'Stehekin',
    'Stratford',
    'Tonasket',
    'Twisp',
    'Warden',
    'Waterville',
    'Wauconda',
    'Wilson Creek',
    'Winthrop',
    'Yakima',
    'Brownstown',
    'Buena',
    'Cle Elum',
    'Cowiche',
    'Easton',
    'Ellensburg',
    'Naches',
    'Grandview',
    'Granger',
    'Harrah',
    'Kittitas',
    'Mabton',
    'Moxee',
    'Outlook',
    'Parker',
    'Ronald',
    'Roslyn',
    'Selah',
    'South Cle Elum',
    'Sunnyside',
    'Thorp',
    'Tieton',
    'Toppenish',
    'Vantage',
    'Wapato',
    'White Swan',
    'Zillah',
    'Airway Heights',
    'Chattaroy',
    'Cheney',
    'Colbert',
    'Deer Park',
    'Edwall',
    'Elk',
    'Fairchild Air Force Base',
    'Fairfield',
    'Ford',
    'Four Lakes',
    'Greenacres',
    'Lamont',
    'Latah',
    'Liberty Lake',
    'Marshall',
    'Mead',
    'Medical Lake',
    'Mica',
    'Newman Lake',
    'Nine Mile Falls',
    'Otis Orchards',
    'Reardan',
    'Rockford',
    'Spangle',
    'Sprague',
    'Tekoa',
    'Tumtum',
    'Valleyford',
    'Veradale',
    'Waverly',
    'Wellpinit',
    'Addy',
    'Albion',
    'Almira',
    'Belmont',
    'Benge',
    'Boyds',
    'Chewelah',
    'Clayton',
    'Colfax',
    'Colton',
    'Colville',
    'Coulee City',
    'Coulee Dam',
    'Creston',
    'Curlew',
    'Cusick',
    'Danville',
    'Davenport',
    'Electric City',
    'Elmer City',
    'Endicott',
    'Evans',
    'Farmington',
    'Fruitland',
    'Garfield',
    'Gifford',
    'Grand Coulee',
    'Harrington',
    'Hartline',
    'Hay',
    'Hunters',
    'Inchelium',
    'Ione',
    'Keller',
    'Kettle Falls',
    'Lacrosse',
    'Lamona',
    'Laurier',
    'Lincoln',
    'Loon Lake',
    'Malden',
    'Malo',
    'Marcus',
    'Metaline',
    'Metaline Falls',
    'Mohler',
    'Nespelem',
    'Newport',
    'Northport',
    'Oakesdale',
    'Odessa',
    'Orient',
    'Palouse',
    'Pullman',
    'Republic',
    'Rice',
    'Ritzville',
    'Rosalia',
    'Saint John',
    'Springdale',
    'Steptoe',
    'Thornton',
    'Uniontown',
    'Usk',
    'Valley',
    'Wilbur',
    'Spokane',
    'Pasco',
    'Benton City',
    'Beverly',
    'Bickleton',
    'Burbank',
    'College Place',
    'Connell',
    'Dayton',
    'Dixie',
    'Eltopia',
    'Hooper',
    'Kahlotus',
    'Kennewick',
    'Lind',
    'Mesa',
    'Othello',
    'Paterson',
    'Plymouth',
    'Pomeroy',
    'Prescott',
    'Mattawa',
    'Prosser',
    'Richland',
    'West Richland',
    'Roosevelt',
    'Royal City',
    'Starbuck',
    'Touchet',
    'Waitsburg',
    'Walla Walla',
    'Wallula',
    'Washtucna',
    'Anatone',
    'Asotin',
    'Clarkston'
  ],
  AK: [
    'Anchorage',
    'Jber',
    'Indian',
    'Kongiganak',
    'Adak',
    'Atka',
    'Chignik Lake',
    'Port Heiden',
    'Port Lions',
    'Akiachak',
    'Akiak',
    'Akutan',
    'Alakanuk',
    'Aleknagik',
    'Anchor Point',
    'Aniak',
    'Anvik',
    'Bethel',
    'Chefornak',
    'Chevak',
    'Chignik',
    'Chignik Lagoon',
    'Chitina',
    'Chugiak',
    'Clam Gulch',
    'Clarks Point',
    'Cold Bay',
    'Cooper Landing',
    'Copper Center',
    'Cordova',
    'Crooked Creek',
    'Dillingham',
    'Eagle River',
    'Eek',
    'Egegik',
    'Ekwok',
    'Emmonak',
    'False Pass',
    'Marshall',
    'Gakona',
    'Girdwood',
    'Glennallen',
    'Goodnews Bay',
    'Grayling',
    'Saint George Island',
    'Holy Cross',
    'Homer',
    'Hooper Bay',
    'Hope',
    'Iliamna',
    'Kalskag',
    'Karluk',
    'Kasigluk',
    'Kasilof',
    'Kenai',
    'King Cove',
    'King Salmon',
    'Kipnuk',
    'Kodiak',
    'Kotlik',
    'Kwethluk',
    'Kwigillingok',
    'Wasilla',
    'Larsen Bay',
    'Levelock',
    'Lower Kalskag',
    'Mc Grath',
    'Manokotak',
    'Mekoryuk',
    'Moose Pass',
    'Mountain Village',
    'Naknek',
    'Napakiak',
    'Nikiski',
    'New Stuyahok',
    'Toksook Bay',
    'Nikolski',
    'Ninilchik',
    'Nondalton',
    'Nunapitchuk',
    'Old Harbor',
    'Ouzinkie',
    'Palmer',
    'Pedro Bay',
    'Perryville',
    'Pilot Point',
    'Pilot Station',
    'Platinum',
    'Big Lake',
    'Port Alsworth',
    'Quinhagak',
    'Red Devil',
    'Russian Mission',
    'Saint Marys',
    'Saint Michael',
    'Saint Paul Island',
    'Sand Point',
    'Scammon Bay',
    'Seldovia',
    'Seward',
    'Shageluk',
    'Nunam Iqua',
    'Skwentna',
    'Sleetmute',
    'Soldotna',
    'South Naknek',
    'Stebbins',
    'Sterling',
    'Sutton',
    'Takotna',
    'Talkeetna',
    'Tatitlek',
    'Togiak',
    'Tuluksak',
    'Tuntutuliak',
    'Tununak',
    'Tyonek',
    'Trapper Creek',
    'Unalakleet',
    'Unalaska',
    'Valdez',
    'Willow',
    'Yakutat',
    'Nightmute',
    'Nikolai',
    'Dutch Harbor',
    'Whittier',
    'Houston',
    'Fairbanks',
    'Eielson AFB',
    'Fort Wainwright',
    'Clear',
    'North Pole',
    'Salcha',
    'Two Rivers',
    'Allakaket',
    'Anaktuvuk Pass',
    'Arctic Village',
    'Barrow',
    'Beaver',
    'Ester',
    'Bettles Field',
    'Buckland',
    'Cantwell',
    'Central',
    'Fort Greely',
    'Chicken',
    'Circle',
    'Prudhoe Bay',
    'Deering',
    'Delta Junction',
    'Eagle',
    'Elim',
    'Fort Yukon',
    'Galena',
    'Gambell',
    'Healy',
    'Anderson',
    'Hughes',
    'Huslia',
    'Kaktovik',
    'Kaltag',
    'Kiana',
    'Kivalina',
    'Kobuk',
    'Kotzebue',
    'Koyuk',
    'Koyukuk',
    'Denali National Park',
    'Manley Hot Springs',
    'Lake Minchumina',
    'Minto',
    'Point Lay',
    'Nenana',
    'Noatak',
    'Nome',
    'Noorvik',
    'Northway',
    'Nulato',
    'Point Hope',
    'Rampart',
    'Ruby',
    'Savoonga',
    'Selawik',
    'Shaktoolik',
    'Shishmaref',
    'Shungnak',
    'Stevens Village',
    'Tanacross',
    'Tanana',
    'Teller',
    'Tok',
    'Venetie',
    'Wainwright',
    'Wales',
    'White Mountain',
    'Brevig Mission',
    'Ambler',
    'Chalkyitsik',
    'Nuiqsut',
    'Atqasuk',
    'Juneau',
    'Angoon',
    'Auke Bay',
    'Douglas',
    'Elfin Cove',
    'Gustavus',
    'Haines',
    'Hoonah',
    'Kake',
    'Pelican',
    'Petersburg',
    'Sitka',
    'Port Alexander',
    'Skagway',
    'Tenakee Springs',
    'Ketchikan',
    'Meyers Chuck',
    'Coffman Cove',
    'Thorne Bay',
    'Craig',
    'Hydaburg',
    'Hyder',
    'Klawock',
    'Metlakatla',
    'Point Baker',
    'Ward Cove',
    'Wrangell'
  ]
}

export const DOCUMENT_TYPES = [
  'Compilance Certificate',
  'Engagement letter',
  'VQC_Document',
  'Appraisal Assignment Snapshot',
  'Appraiser E&O Document',
  'Appraiser Fee Disclosure',
  'Appraiser License',
  'CMS Property Snapshot',
  'EAD SSR',
  'Fannie Mae SSR',
  'Freddie Mac SSR',
  'Other'
];

export const ALLOWED_FILE_TYPES = ['jpeg', 'jpg', 'txt', 'pdf', 'xls', 'xlsx', 'doc', 'docx', 'gif', 'png', 'tif', 'tiff'];
