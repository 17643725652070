import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { CustomPaginatorComponent } from './components/custom-paginator/custom-paginator.component';
import { ChatComponent } from './components/chat/chat.component';
import { SharedService } from './shared.service';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { OrderStatusComponent } from './components/order-status/order-status.component';
import { GlobalMatTableComponent } from './components/global-mat-table/global-mat-table.component';
import { RulesConfigurationsComponent } from './components/rules-configurations/rules-configurations.component';
import { RulesService } from '../rules/rules.service';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { RouterModule } from '@angular/router';
import { TooltipDirective } from './directives/tooltip.directive';
import { HistoryComponent } from './components/history/history.component';
import { FormSelectionModalComponent } from './components/form-selection-modal/form-selection-modal.component';
import { ClientSmartLinkComponent } from './components/client-smart-link/client-smart-link.component';
import { SvgComponent } from './components/svg/svg.component';
import { SmartTruncateDirective } from './directives/smart-truncate.directive';
import { RealTimerDirective } from './directives/real-timer.directive';
import { TierCardComponent } from './components/tier-card/tier-card.component';
import { PriorityMatrixComponent } from './components/priority-matrix/priority-matrix.component';
import { AddNonceDirective } from './directives/add-nonce.directive';
import { LazyLoadImageDirective } from './directives/img-lazyload.directive';

const MODULES: any[] = [CommonModule, ReactiveFormsModule, FormsModule, MaterialModule, LoadingBarModule, RouterModule];
const COMPONENTS: any[] = [
  GlobalMatTableComponent,
  CustomPaginatorComponent,
  ClientSmartLinkComponent,
  ChatComponent,
  RulesConfigurationsComponent,
  ConfirmationModalComponent,
  OrderStatusComponent,
  SvgComponent,
  HistoryComponent,
  FormSelectionModalComponent,
  TierCardComponent,
  PriorityMatrixComponent
];
const COMPONENTS_DYNAMIC: any[] = [];
const DIRECTIVES: any[] = [TooltipDirective, SmartTruncateDirective, RealTimerDirective, AddNonceDirective, LazyLoadImageDirective];
const PIPES: any[] = [LocalDatePipe];
const SERVICES: any[] = [SharedService, RulesService];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ...DIRECTIVES, ...PIPES],
  providers: [...SERVICES, ...PIPES],
})
export class SharedModule {}
