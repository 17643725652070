export const requiredErrorMessage = 'Please fill out this field.'
export const noResultAvailable = 'No Results are Available'
export const generalErrorMessage = 'Something went wrong. Please try again.'

export const authErrorMessages: { [key: string]: string } = {
  "auth/claims-too-large": "The claims payload provided to setCustomUserClaims() exceeds the maximum allowed size of 1000 bytes.",
  "auth/email-already-exists": "The provided email is already in use by an existing user. Each user must have a unique email.",
  "auth/id-token-expired": "The provided Firebase ID token is expired.",
  "auth/id-token-revoked": "The Firebase ID token has been revoked.",
  "auth/insufficient-permission": "The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource. Refer to Set up a Firebase project for documentation on how to generate a credential with appropriate permissions and use it to authenticate the Admin SDKs.",
  "auth/internal-error": "The Authentication server encountered an unexpected error while trying to process the request. The error message should contain the response from the Authentication server containing additional information. If the error persists, please report the problem to our Bug Report support channel.",
  "auth/invalid-argument": "An invalid argument was provided to an Authentication method. The error message should contain additional information.",
  "auth/invalid-claims": "The custom claim attributes provided to setCustomUserClaims() are invalid.",
  "auth/invalid-creation-time": "The creation time must be a valid UTC date string.",
  "auth/invalid-credential": "The credential used to authenticate the Admin SDKs cannot be used to perform the desired action. Certain Authentication methods such as createCustomToken() and verifyIdToken() require the SDK to be initialized with a certificate credential as opposed to a refresh token or Application Default credential. See Initialize the SDK for documentation on how to authenticate the Admin SDKs with a certificate credential.",
  "auth/invalid-disabled-field": "The provided value for the disabled user property is invalid. It must be a boolean.",
  "auth/invalid-display-name": "The provided value for the displayName user property is invalid. It must be a non-empty string.",
  "auth/invalid-email": "The provided value for the email user property is invalid. It must be a string email address.",
  "auth/invalid-email-verified": "The provided value for the emailVerified user property is invalid. It must be a boolean.",
  "auth/invalid-hash-algorithm": "The hash algorithm must match one of the strings in the list of supported algorithms.",
  "auth/invalid-hash-block-size": "The hash block size must be a valid number.",
  "auth/invalid-hash-derived-key-length": "The hash derived key length must be a valid number.",
  "auth/invalid-hash-key": "The hash key must be a valid byte buffer.",
  "auth/invalid-hash-memory-cost": "The hash memory cost must be a valid number.",
  "auth/invalid-hash-parallelization": "The hash parallelization must be a valid number.",
  "auth/invalid-hash-rounds": "The hash rounds must be a valid number.",
  "auth/invalid-hash-salt-separator": "The hashing algorithm salt separator field must be a valid byte buffer.",
  "auth/invalid-id-token": "The provided ID token is not a valid Firebase ID token.",
  "auth/invalid-last-sign-in-time": "The last sign-in time must be a valid UTC date string.",
  "auth/invalid-page-token": "The provided next page token in listUsers() is invalid. It must be a valid non-empty string.",
  "auth/invalid-password": "The provided value for the password user property is invalid. It must be a string with at least six characters.",
  "auth/invalid-password-hash": "The password hash must be a valid byte buffer.",
  "auth/invalid-password-salt": "The password salt must be a valid byte buffer.",
  "auth/invalid-phone-number": "The provided value for the phoneNumber is invalid. It must be a non-empty E.164 standard compliant identifier string.",
  "auth/invalid-photo-url": "The provided value for the photoURL user property is invalid. It must be a string URL.",
  "auth/invalid-provider-data": "The providerData must be a valid array of UserInfo objects.",
  "auth/invalid-provider-id": "The providerId must be a valid supported provider identifier string.",
  "auth/invalid-oauth-responsetype": "Only exactly one OAuth responseType should be set to true.",
  "auth/invalid-session-cookie-duration": "The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.",
  "auth/invalid-uid": "The provided uid must be a non-empty string with at most 128 characters.",
  "auth/invalid-user-import": "The user record to import is invalid.",
  "auth/maximum-user-count-exceeded": "The maximum allowed number of users to import has been exceeded.",
  "auth/missing-android-pkg-name": "An Android Package Name must be provided if the Android App is required to be installed.",
  "auth/missing-continue-uri": "A valid continue URL must be provided in the request.",
  "auth/missing-hash-algorithm": "Importing users with password hashes requires that the hashing algorithm and its parameters be provided.",
  "auth/missing-ios-bundle-id": "The request is missing a Bundle ID.",
  "auth/missing-uid": "A uid identifier is required for the current operation.",
  "auth/missing-oauth-client-secret": "The OAuth configuration client secret is required to enable OIDC code flow.",
  "auth/operation-not-allowed": "The provided sign-in provider is disabled for your Firebase project. Enable it from the Sign-in Method section of the Firebase console.",
  "auth/phone-number-already-exists": "The provided phoneNumber is already in use by an existing user. Each user must have a unique phoneNumber.",
  "auth/project-not-found": "No Firebase project was found for the credential used to initialize the Admin SDKs. Refer to Set up a Firebase project for documentation on how to generate a credential for your project and use it to authenticate the Admin SDKs.",
  "auth/reserved-claims": "One or more custom user claims provided to setCustomUserClaims() are reserved. For example, OIDC specific claims such as (sub, iat, iss, exp, aud, auth_time, etc) should not be used as keys for custom claims.",
  "auth/session-cookie-expired": "The provided Firebase session cookie is expired.",
  "auth/session-cookie-revoked": "The Firebase session cookie has been revoked.",
  "auth/too-many-requests": "Your account has been temporarily locked due to exceeding the maximum number of requests. Please try again later.",
  "auth/uid-already-exists": "The provided uid is already in use by an existing user. Each user must have a unique uid.",
  "auth/unauthorized-continue-uri": "The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase Console.",
  "auth/user-not-found": "There is no existing user record corresponding to the provided identifier.",
  "auth/user-disabled": "Please connect with administrator to activate your account.",
  "auth/admin-restricted-operation": "This operation is restricted to administrators.",
  "auth/argument-error": "Invalid arguments provided.",
  "auth/app-not-authorized": "This app is not authorized to use Firebase.",
  "auth/app-not-installed": "This app is not installed on your device.",
  "auth/captcha-check-failed": "Google reCAPTCHA check failed.",
  "auth/code-expired": "The provided code has expired.",
  "auth/cordova-not-ready": "Cordova is not ready.",
  "auth/cors-unsupported": "CORS is not supported by your browser.",
  "auth/credential-already-in-use": "This credential is already associated with another account.",
  "auth/custom-token-mismatch": "The custom token and API key do not match.",
  "auth/requires-recent-login": "Please login again, as your last login was too long ago.",
  "auth/dependent-sdk-initialized-before-auth": "Please initialize the Firebase Auth SDK before other dependent SDKs.",
  "auth/dynamic-link-not-activated": "Dynamic links are not activated.",
  "auth/email-change-needs-verification": "Please verify your new email address.",
  "auth/email-already-in-use": "The email address is already in use.",
  "auth/emulator-config-failed": "Emulator configuration failed.",
  "auth/expired-action-code": "The action code has expired.",
  "auth/cancelled-popup-request": "The popup request has been cancelled.",
  "auth/invalid-api-key": "The provided API key is invalid.",
  "auth/invalid-app-credential": "The app credential is invalid.",
  "auth/invalid-app-id": "The app ID is invalid.",
  "auth/invalid-user-token": "Invalid user token.",
  "auth/invalid-auth-event": "Invalid authentication event.",
  "auth/invalid-cert-hash": "Invalid certificate hash.",
  "auth/invalid-verification-code": "The provided verification code is invalid.",
  "auth/invalid-continue-uri": "The continue URL is invalid.",
  "auth/invalid-cordova-configuration": "Invalid Cordova configuration.",
  "auth/invalid-custom-token": "The custom token is invalid.",
  "auth/invalid-dynamic-link-domain": "The dynamic link domain is invalid.",
  "auth/invalid-emulator-scheme": "Invalid emulator scheme.",
  "auth/invalid-message-payload": "Invalid message payload.",
  "auth/invalid-multi-factor-session": "Invalid multi-factor authentication session.",
  "auth/invalid-oauth-client-id": "Invalid OAuth client ID.",
  "auth/invalid-oauth-provider": "Invalid OAuth provider.",
  "auth/invalid-action-code": "The reset password action code is invalid.",
  "auth/unauthorized-domain": "Unauthorized domain.",
  "auth/wrong-password": "Incorrect password.",
  "auth/invalid-persistence-type": "Invalid persistence type.",
  "auth/invalid-recipient-email": "Invalid recipient email.",
  "auth/invalid-sender": "Invalid sender.",
  "auth/invalid-verification-id": "Invalid verification ID.",
  "auth/invalid-tenant-id": "Invalid tenant ID.",
  "auth/multi-factor-info-not-found": "Multi-factor authentication information not found.",
  "auth/multi-factor-auth-required": "Multi-factor authentication is required.",
  "auth/missing-app-credential": "Missing app credential.",
  "auth/auth-domain-config-required": "Missing authentication domain configuration.",
  "auth/missing-verification-code": "Missing verification code.",
  "auth/missing-iframe-start": "Missing iframe start.",
  "auth/missing-or-invalid-nonce": "Missing or invalid nonce.",
  "auth/missing-multi-factor-info": "Missing multi-factor authentication information.",
  "auth/missing-multi-factor-session": "Missing multi-factor authentication session.",
  "auth/missing-phone-number": "Missing phone number.",
  "auth/missing-verification-id": "Missing verification ID.",
  "auth/app-deleted": "The authentication module has been deleted.",
  "auth/account-exists-with-different-credential": "An account with this email already exists with a different credential.",
  "auth/network-request-failed": "A network request has failed.",
  "auth/null-user": "No user is currently signed in.",
  "auth/no-auth-event": "No authentication event.",
  "auth/no-such-provider": "No such Identity Provider.",
  "auth/operation-not-supported-in-this-environment": "This operation is not supported in your current environment.",
  "auth/popup-blocked": "Popup blocked by the browser.",
  "auth/popup-closed-by-user": "Popup closed by user.",
  "auth/provider-already-linked": "This provider is already linked to your account.",
  "auth/quota-exceeded": "Quota exceeded.",
  "auth/redirect-cancelled-by-user": "Redirect cancelled by user.",
  "auth/redirect-operation-pending": "Redirect operation is pending.",
  "auth/rejected-credential": "Rejected credential.",
  "auth/second-factor-already-in-use": "Second factor authentication method already in use.",
  "auth/maximum-second-factor-count-exceeded": "Maximum number of second factor methods exceeded.",
  "auth/tenant-id-mismatch": "Tenant ID mismatch.",
  "auth/timeout": "A timeout has occurred.",
  "auth/user-token-expired": "User token has expired.",
  "auth/unsupported-first-factor": "Unsupported first factor authentication method.",
  "auth/unsupported-persistence-type": "Unsupported persistence type.",
  "auth/unsupported-tenant-operation": "Unsupported tenant operation.",
  "auth/unverified-email": "Unverified email address.",
  "auth/user-cancelled": "User cancelled authentication.",
  "auth/user-mismatch": "User mismatch.",
  "auth/user-signed-out": "User has signed out.",
  "auth/weak-password": "The password is weak.",
  "auth/web-storage-unsupported": "Web storage is unsupported by your browser.",
  "auth/already-initialized": "Firebase Auth has already been initialized.",
  "auth/recaptcha-not-enabled": "Google reCAPTCHA is not enabled.",
  "auth/missing-recaptcha-token": "Missing Google reCAPTCHA token.",
  "auth/invalid-recaptcha-token": "Invalid Google reCAPTCHA token.",
  "auth/invalid-recaptcha-action": "Invalid Google reCAPTCHA action.",
  "auth/missing-client-type": "Missing client type.",
  "auth/missing-recaptcha-version": "Missing Google reCAPTCHA version.",
  "auth/invalid-recaptcha-version": "Invalid Google reCAPTCHA version.",
  "auth/invalid-req-type": "Invalid request type."
};

